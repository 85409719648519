import { MultiStepCol } from "../../../../../addEditPopup/multiStepCol";
import { FormControlLabel, FormGroup } from "@mui/material";
import { useState } from "react";
import { CheckboxWithBlueCheck } from "../../../../../../ui/checkbox/checkboxWithBlueTick";
import { CheckboxList } from "../../../../CheckboxList";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { DateSelector } from "../../../../../../ui/pickers/date";

export const OfferForm = ({
  openForm,
  toggleForm,
  activeStep,
  setActiveStep,
  formValues,
  setFormValues,
  handleSaveForm,
  applicantId,
}) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [allSupervisors, setAllSupervisors] = useState([]);
  const [rateTypes, setRateTypes] = useState([]);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [expireDate, setExpireDate] = useState(new Date());

  const getAllApiData = () => {
    Api(
      "GET",
      `Sources/GetSourceList?type=all&companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    ).then((res) => {
      const sourcesData = res.data.reduce(function (rv, x) {
        (rv[x["type"]] = rv[x["type"]] || []).push(x);
        return rv;
      }, {});

      setRateTypes(sourcesData?.ratetypes);
      setShiftTypes(sourcesData?.shifttypes);
      setScheduleTypes(sourcesData?.scheduletypes);
      setEmploymentTypes(sourcesData?.employmenttypes);
    });
  };

  const getAllSupervisors = () => {
    Api(
      "GET",
      `Job/GetRoleBaseUserList?companyId=${companyData.companyId}&type=1`
    )
      .then((res) => {
        const data = [...res.data];
        data.map((rec) => {
          rec.id = rec.userId;
          rec.name = rec.userFirstName + " " + rec.userLastName;
          rec.checked = false;
        });

        formValues?.supervisors?.forEach((element) => {
          const index = data.findIndex((el) => el.userId === element.sId);
          if (index > -1) {
            data[index].checked = true;
          }
        });
        setAllSupervisors(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    getAllApiData();
    getAllSupervisors();
  }, []);

  const handleSubmit = () => {
    handleSaveForm();
  };

  const handleNext = () => {
    console.log(formValues);
    console.log(activeStep);
    console.log("handle clicked");
  };

  const handleCheckboxChange = (checkName, isChecked) => {
    const data = [...allSupervisors];
    data.map((rec) => {
      if (rec.id === checkName) {
        rec.checked = isChecked;
      }
    });
    setAllSupervisors(data);
    const users = [];
    data.filter((d) => {
      if (d.checked === true) {
        users.push(d.id);
      }
    });

    setFormValues({ ...formValues, ["supervisors"]: users });
  };

  const handleStartChange = (newValue) => {
    setStartDate(newValue.toISOString());
    setFormValues({ ...formValues, ["startDate"]: newValue.toISOString() });
  };

  const handleExpireChange = (newValue) => {
    setExpireDate(newValue.toISOString());
    setFormValues({ ...formValues, ["expireDate"]: newValue.toISOString() });
  };

  const handleDraft = (e) => {
    setFormValues({ ...formValues, ["isSaveAsDraft"]: e.target.checked });
  };

  const handleExempt = (e) => {
    setFormValues({
      ...formValues,
      ["isExemptfromovertime"]: e.target.checked,
    });
  };

  return (
    <MultiStepCol
      openForm={openForm}
      toggleForm={toggleForm}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleSubmit={handleSubmit}
      handleNext={handleNext}
      applicantId={applicantId}
      title="Offering Form"
      steps="3"
    >
      {activeStep === 0 && (
        <>
          <div className="forms-input">
            <label>Rate:</label>
            <input
              type="text"
              name="rate"
              value={formValues?.rate}
              placeholder="Rate"
              onChange={handleChange}
            />
          </div>

          <div className="forms-input">
            <label>Rate Type:</label>
            <select
              name="rateTypeId"
              id="rate_type"
              onChange={handleChange}
              value={formValues?.rateTypeId}
            >
              <option>Select</option>
              {rateTypes?.map((rate) => (
                <option value={rate.sourceId} key={rate.sourceId}>
                  {rate.name}
                </option>
              ))}
            </select>
          </div>

          <div className="forms-input">
            <label>Shift Type:</label>
            <select
              name="shiftTypeId"
              id="shift_type"
              onChange={handleChange}
              value={formValues?.shiftTypeId}
            >
              <option>Select</option>
              {shiftTypes?.map((shift) => (
                <option value={shift.sourceId} key={shift.sourceId}>
                  {shift.name}
                </option>
              ))}
            </select>
          </div>

          <div className="forms-input">
            <label>Schedule Type:</label>
            <select
              name="scheduleTypeId"
              id="schedule_type"
              onChange={handleChange}
              value={formValues?.scheduleTypeId}
            >
              <option>Select</option>
              {scheduleTypes?.map((schedule) => (
                <option value={schedule.sourceId} key={schedule.sourceId}>
                  {schedule.name}
                </option>
              ))}
            </select>
          </div>

          <div className="forms-input">
            <label>Employment Type:</label>
            <select
              name="employmentTypeId"
              id="emp_type"
              onChange={handleChange}
              value={formValues?.employmentTypeId}
            >
              <option>Select</option>
              {employmentTypes?.map((emp) => (
                <option value={emp.sourceId} key={emp.sourceId}>
                  {emp.name}
                </option>
              ))}
            </select>
          </div>

          <div className="forms-input">
            <FormGroup>
              <FormControlLabel
                control={
                  <CheckboxWithBlueCheck
                    disableRipple
                    checked={!!formValues?.isExemptfromovertime}
                    value="isExemptfromovertime"
                    key="isExemptfromovertime"
                    onChange={handleExempt}
                  />
                }
                label="Exempt from overtime"
              />
            </FormGroup>
          </div>
        </>
      )}

      {activeStep === 1 && (
        <>
          <h3>Select Supervisors</h3>
          <CheckboxList
            allData={allSupervisors}
            setAllData={setAllSupervisors}
            showSaveContainer={false}
            handleChange={handleCheckboxChange}
          />
        </>
      )}

      {activeStep === 2 && (
        <>
          <div className="forms-input">
            <label>Start Date :</label>
            <DateSelector
              value={formValues?.startDate ? formValues?.startDate : startDate}
              setValue={setStartDate}
              disablePast={true}
              handleDateChange={handleStartChange}
            />
          </div>

          <div className="forms-input">
            <label>Message to Applicant :</label>
            <textarea
              name="applicantMessage"
              placeholder="Message to applicant"
              onChange={handleChange}
              value={formValues?.applicantMessage}
            ></textarea>
          </div>

          <div className="forms-input">
            <label>Expires :</label>
            <DateSelector
              setValue={setExpireDate}
              disablePast={true}
              value={
                formValues?.expireDate ? formValues?.expireDate : expireDate
              }
              handleDateChange={handleExpireChange}
            />
          </div>

          <div className="forms-input">
            <FormGroup>
              <FormControlLabel
                control={
                  <CheckboxWithBlueCheck
                    disableRipple
                    checked={!!formValues?.isSaveAsDraft}
                    onChange={handleDraft}
                    value="isSaveAsDraft"
                    key="isSaveAsDraft"
                  />
                }
                label="Save as Draft"
              />
            </FormGroup>
          </div>
        </>
      )}
    </MultiStepCol>
  );
};
