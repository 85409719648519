import React from "react";
import Loader from "../common/Loader";
import { DropzoneArea } from "material-ui-dropzone";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useRef } from "react";

export const AddEditCompany = ({
  formValues,
  setFormValues,
  disable,
  setSideBar,
  editImageShow,
  setEditImageShow,
  formErrors,
  editMode,
  handleSubmit,
  label,
  allTimeZone,
}) => {
  const attachmentRef = useRef(null);
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const close = () => {
    setSideBar(false);
  };

  const handleClick = () => {
    attachmentRef.current.click();
  };

  return (
    <div>
      <div className="back-shadow"></div>
      <div className="side-bar-parent active">
        <header>
          <span>{label}</span>
          <img
            src={require("../../assets/images/close.svg").default}
            alt="close-img"
            className="close-img"
            onClick={() => close()}
          />
        </header>
        <form onSubmit={handleSubmit} className="inner-container">
          <div className="forms-input">
            <label>Name</label>
            <input
              type="text"
              placeholder="Name"
              name="name"
              id="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <span className="error_msg">{formErrors.name}</span>
          </div>
          <div className="forms-input">
            <label>Job Number Prefix</label>
            <input
              type="text"
              placeholder="Job Number Prefix here"
              name="jobPrefix"
              id="jobPrefix"
              value={formValues.jobPrefix}
              onChange={handleChange}
              readOnly={editMode}
              style={{ backgroundColor: editMode ? "#DCDCDC" : "" }}
            />
            <span className="error_msg">{formErrors.jobPrefix}</span>
          </div>
          {!editMode ? (
            <div className="forms-input">
              <label>Email ID</label>
              <input
                type="text"
                placeholder="Email ID"
                name="emailAddress"
                id="emailAddress"
                value={formValues.emailAddress}
                onChange={handleChange}
              />
              <span className="error_msg">{formErrors.emailAddress}</span>
            </div>
          ) : null}
          <div className="forms-input">
            <label>Timezone</label>
            <select
              name="timeZone"
              id="timeZone"
              value={formValues.timeZone}
              onChange={handleChange}
            >
              <option value="none">Select</option>
              {allTimeZone.map((time) => (
                <option key={time.id} value={time.timeZone}>
                  {time.timeZone}
                </option>
              ))}
            </select>
            <span className="error_msg">{formErrors.timeZone}</span>
          </div>
          <p className="max-applicant">
            Maximum Applicants Allowed per Job (leave 0 for unlimited)
          </p>
          <div className="two-side-by">
            <div className="forms-input">
              <label>Total</label>
              <input
                type="text"
                placeholder="Name here"
                name="totalJob"
                id="totalJob"
                value={formValues.totalJob}
                onChange={handleChange}
              />
              <span className="error_msg">{formErrors.totalJob}</span>
            </div>
            <div className="forms-input">
              <label>Per Job Board</label>
              <input
                type="text"
                placeholder="Name here"
                name="perJobBoard"
                id="perJobBoard"
                value={formValues.perJobBoard}
                onChange={handleChange}
              />
              <span className="error_msg">{formErrors.perJobBoard}</span>
            </div>
          </div>
          {editImageShow ? (
            <div className="upload-file-shower">
              <img
                src={formValues.companyLogo}
                alt="preview-img"
                className="show-img"
              />
              <button
                onClick={() => (
                  setEditImageShow(false), (formValues.companyLogo = "")
                )}
                className="icon icon_button"
              >
                <img
                  src={require("../../assets/images/close-upload.png")}
                  alt="close"
                  className="close-img"
                />
              </button>
            </div>
          ) : (
            <div className="forms-input">
              <DropzoneArea
                acceptedFiles={["image/*"]}
                dropzoneText={"Drag and drop an image here or click"}
                onChange={(files) => (formValues["companyLogo"] = files[0])}
                maxFileSize={500000}
                filesLimit={1}
              />
              {/* <input type="file" hidden ref={attachmentRef} accept="image/*" />
              <div className="file-upload pointer" onClick={handleClick}>
                <div>
                  <FileUploadOutlinedIcon fontSize="large" color="action" />
                </div>
                <span>Upload a file here</span>
              </div> */}
              <span className="error_msg">{formErrors.companyImage}</span>
            </div>
          )}

          <div className="btns-container">
            <input
              type="submit"
              className="add-btn"
              value="Submit"
              disabled={disable}
            />
            {disable ? <Loader inShow={true} /> : null}
            <button className="cancel-btn" type="reset" onClick={() => close()}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
