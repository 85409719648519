import { Login } from "../../views/pages/authentication/Login";
import { Navigate } from "react-router-dom";
import { Otp } from "../../views/pages/authentication/Otp";
import { ForgotPassword } from "../../views/pages/authentication/ForgotPassword";
import { ResetPassword } from "../../views/pages/authentication/ResetPassword";
import { ViewJob } from "../../views/pages/applyForJob/view";
import { ChatByApplicant } from "../../components/common/layouts/applicantInfo/content/applicantInfoTabs/chat/byApplicant";
import { Video } from "../../components/video/Video";
import SelectCompany from "../../views/pages/authentication/SelectCompany";
import { ViewOffer } from "../../components/common/layouts/applicantInfo/content/applicantJobData/OfferLetter/viewOffer";
import { ViewDocPack } from "../../components/common/layouts/applicantInfo/content/applicantJobData/PreHire/viewDocPack";
import { ViewPhysicalPPD } from "../../components/common/layouts/applicantInfo/content/applicantJobData/PreHire/viewPhysicalPPD";
import { ViewRating } from "../../components/common/layouts/applicantInfo/content/applicantJobData/VideoInterview/viewRating";
import { ViewIframe } from "../../views/pages/viewIframe";
import { ViewForm } from "../../views/pages/viewForm";
import { ThankYouPage } from "../../components/common/layouts/applicantInfo/content/applicantJobData/Interviewing/thankYou";
import MeetPage from "../../components/common/layouts/applicantInfo/content/applicantJobData/Interviewing/meetPage";

export const publicRoutes = [
  { path: "/", element: <Navigate to="/login" /> },
  { path: "/login", element: <Login /> },
  { path: "/otp", element: <Otp /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/apply-job/:token", element: <ViewJob /> },
  { path: "/chat", element: <ChatByApplicant /> },
  { path: "/Video/:id", element: <Video /> },
  { path: "/select-company", element: <SelectCompany /> },
  { path: "/offer", element: <ViewOffer /> },

  // Document pack : PreHire stage
  { path: "/documentpack/:id", element: <ViewDocPack /> },
  { path: "/appt/:id", element: <ViewPhysicalPPD /> },

  // Add Rating : Reviewers
  { path: "/review", element: <ViewRating /> },

  // iframe integration
  { path: "/iframe", element: <ViewIframe /> },
  { path: "/ga-form", element: <ViewForm /> },

  // Jitsi meet for live virtual interview
  { path: "/meet/:token", element: <MeetPage /> },
  { path: "/thank-you", element: <ThankYouPage /> },
];
