export const CellPhoneIcon = ({...props}) => {
    return (
        <svg {...props} viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M4 4.25H10M13 2.75V19.25C13 20.0784 12.3284 20.75 11.5 20.75H2.5C1.67157 20.75 1 20.0784 1 19.25V2.75C1 1.92157 1.67157 1.25 2.5 1.25H11.5C12.3284 1.25 13 1.92157 13 2.75Z" 
                stroke="#3954A5" 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    );
}
