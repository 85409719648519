import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { MorePositions } from "../../../components/common/layouts/applicantInfo/morePositions";

export const CompanyMorePositions = () => {
  const [breadCrumb, setBreadCrumb] = useState("");
  const { applicantData } = useSelector((state) => state.applicantDataReducer);

  useEffect(() => {
    if (window.location.href.indexOf("job") > -1) {
      setBreadCrumb("Jobs");
    } else if (window.location.href.indexOf("hires") > -1) {
      setBreadCrumb("Hires");
    } else if (window.location.href.indexOf("hot-list") > -1) {
      setBreadCrumb("Hot List");
    } else if (window.location.href.indexOf("applicant") > -1) {
      setBreadCrumb("Applicants");
    }
  }, []);

  return (
    <div>
      <div className="company_content help-container applicants">
        <div className="top-url">
          <p>
            {breadCrumb} &gt;
            <strong>
              {" "}
              {applicantData.firstName + " " + applicantData.lastName}
            </strong>
          </p>
        </div>

        <div className="common-container users company">
          <div className="inner-container company">
            <MorePositions />
          </div>
        </div>
      </div>
    </div>
  );
};
