import {
  createTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import { ButtonUI } from "../../../components/ui/button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export const HireReport = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageNumber, setPage] = useState(1);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [totalPage, setTotalPage] = useState(1);

  const theme = createTheme({
    palette: {
      action: {
        hover: "#fff",
      },
    },
  });

  useEffect(() => {
    getHireReport();
  }, [pageNumber]);

  const getHireReport = () => {
    setIsLoader(true);

    const query = `companyId=${companyData.companyId}&pageLimit=10&pageNumber=${pageNumber}&orderBy=desc&orderByDirection=createdAt`;
    Api("GET", `Report/HiresReport?${query}`)
      .then((res) => {
        setRows(res.data.data);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const handleSort = () => {};

  const handleCellClick = () => {};

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const Row = rows.map((val, key) => {
    return (
      <tr key={key}>
        <td className="td_name">{val.applicantName}</td>
        <td>{val.jobNameAndNumber}</td>
        <td>{val.companyName}</td>
        <td>{val.hiredDate}</td>
        <td>{val.terminationDate}</td>
      </tr>
    );
  });

  return (
    <div>
      <div className="company_content">
        <div className="content_header">
          <div className="company_text mr-20">Hire Report</div>
          {rows.length > 0 && (
            <ButtonUI
              p="6px"
              leftIcon={<FileDownloadOutlinedIcon />}
              onClick={() => exportToCSV(rows, "report")}
            >
              Download
            </ButtonUI>
          )}
        </div>

        {isLoader ? (
          <Loader inShow={true} />
        ) : rows?.length > 0 ? (
          <>
            <div className="company_table">
              <table>
                <thead>
                  <tr>
                    <th className="th_name">
                      Name
                      <button
                        onClick={() => handleSort("name")}
                        className="icon icon_button"
                      >
                        <img
                          src={
                            require("../../../assets/images/tabsort.svg")
                              .default
                          }
                          alt="sort-icon"
                          className="icon sort_icon"
                        />
                      </button>
                    </th>
                    <th>Job Name & No.</th>
                    <th>Company</th>
                    <th>Hired Date</th>
                    <th>Termination Date</th>
                  </tr>
                </thead>
                <tbody>{Row}</tbody>
              </table>
            </div>
            <StyledPagination
              count={totalPage}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </div>
  );
};
