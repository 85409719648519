import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Android12Switch } from "../../../../ui/android/android2switch";
import { AddEditPopup } from "../../../addEditPopup";

export const EditUserInfo = ({
  formValues,
  setFormValues,
  openSidebar,
  handleNext,
  formErrors,
  editMode,
  handleSubmit,
  label,
  allTimeZone,
  toggleDrawer,
}) => {
  const handleChange = (e) => {
    if (e.target.name == "emailNotification") {
      setFormValues({
        ...formValues,
        [e.target.name]: !formValues.emailNotification,
      });
    } else if (e.target.name == "smsNotification") {
      setFormValues({
        ...formValues,
        [e.target.name]: !formValues.smsNotification,
      });
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };

  const handleUserEdit = (e) => {
    e.preventDefault();
    if (editMode) {
      handleSubmit();
    } else {
      handleNext();
    }
  };

  return (
    <div>
      <AddEditPopup
        title={label}
        openSidebar={openSidebar}
        toggleDrawer={toggleDrawer}
        handleSubmit={handleUserEdit}
      >
        <div className="forms-input">
          <label> First Name</label>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            id="firstName"
            value={formValues.firstName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.firstName}</span>
        </div>
        <div className="forms-input">
          <label>Middle Name</label>
          <input
            type="text"
            placeholder="Middle Name"
            name="middleName"
            id="middleName"
            value={formValues.middleName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.middleName}</span>
        </div>
        <div className="forms-input">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            id="lastName"
            value={formValues.lastName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.lastName}</span>
        </div>
        <div className="forms-input">
          <label>Nick Name</label>
          <input
            type="text"
            placeholder="Nick Name"
            name="nickName"
            id="nickName"
            value={formValues.nickName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.nickName}</span>
        </div>
        <div className="forms-input">
          <label>Title</label>
          <input
            type="text"
            placeholder="Title"
            name="user_title"
            id="user_title"
            value={formValues.user_title}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.user_title}</span>
        </div>
        <div className="forms-input">
          <label>Login ID</label>
          <input
            type="text"
            placeholder="Login ID"
            name="login_id"
            id="login_id"
            value={formValues.login_id}
            onChange={handleChange}
            readOnly={editMode}
            style={{ backgroundColor: editMode ? "#DCDCDC" : "" }}
          />
          <span className="error_msg">{formErrors.login_id}</span>
        </div>
        {!editMode ? (
          <div className="forms-input">
            <label>Email Address</label>
            <input
              type="text"
              placeholder="Email Address"
              name="email_id"
              id="email_id"
              value={formValues.email_id}
              onChange={handleChange}
            />
            <span className="error_msg">{formErrors.email_id}</span>
          </div>
        ) : null}

        <div className="forms-input">
          <label>Cell Phone</label>
          <input
            type="text"
            placeholder="Cell Phone"
            name="cell_phone"
            id="cell_phone"
            value={formValues.cell_phone}
            onChange={handleChange}
            readOnly={editMode}
            style={{ backgroundColor: editMode ? "#DCDCDC" : "" }}
          />
          <span className="error_msg">{formErrors.cell_phone}</span>
        </div>
        {editMode ? (
          <div className="forms-input">
            <FormControlLabel
              control={<Android12Switch />}
              label="Email Notifications"
              name="emailNotification"
              checked={formValues.emailNotification}
              onChange={handleChange}
            />
            <FormControlLabel
              control={<Android12Switch />}
              label="Text Notifications"
              name="smsNotification"
              checked={formValues.smsNotification}
              onChange={handleChange}
            />
          </div>
        ) : null}

        <div className="forms-input">
          <label>Timezone</label>
          <select
            name="timeZone"
            id="timeZone"
            value={formValues.timeZone}
            onChange={handleChange}
          >
            <option value="none">Select</option>
            {allTimeZone.map((time) => (
              <option key={time.id} value={time.timeZone}>
                {time.timeZone}
              </option>
            ))}
          </select>
          <span className="error_msg">{formErrors.timeZone}</span>
        </div>
      </AddEditPopup>
    </div>
  );
};
