import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DropzoneArea } from "material-ui-dropzone";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import { ToastAlert } from "../../../helper/constants";
import Loader from "../../common/Loader";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "../../../assets/css/components/company-detail/_appointments.css";
import { makeStyles } from "@material-ui/core/styles";

import NoRecordsFound from "../../common/NoRecordsFound";
import { ButtonUI } from "../../ui/button";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";
import OpenEditor from "./editor";
import { StyledPagination } from "../../ui/pagination/pagination";

const useStyles = makeStyles((theme) => ({
  dropZoneText: {
    fontSize: "14px",
    whiteSpace: "pre-line",
  },
  preview: {
    objectFit: "cover",
    marginLeft: "25px",
  },
}));

export function Documents() {
  const [tabQuickChats, setTabQuickChats] = useState();

  const [edits, setEdits] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showNoDoc, setShowNoDoc] = useState(true);
  const [editImageShow, setEditImageShow] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("");

  const [newEditorShow, setNewEditorShow] = useState(true);
  const [addUploadBox, setAddUploadBox] = useState(false);
  const [addDocPack, setAddDocPack] = useState(true);

  const initialValues = {
    notesName: "",
    doc_type: "",
    docName: "",
    docFile: "",
    docContent: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [allDocs, setAllDocs] = useState([]);
  const [docList, setDocList] = useState([]);
  const [docPackList, setDocPackList] = useState([]);
  const [allDocTypes, setAllDocTypes] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [openToastBar, setOpenToastBar] = useState(false);
  const [checkedDocs, setCheckedDocs] = useState([]);

  const [editor, setEditor] = useState("");
  const location = useLocation();
  const classes = useStyles();

  const initialValuesChat = {
    doc_pack_name: "",
    pack_doc_type: "",
    add_req_name: "",
    notesNameChat: "",
    imageAllow: false,
  };
  const [formValuesChat, setFormValuesChat] = useState(initialValuesChat);
  const [uploadReqList, setUploadReqList] = useState([]);
  const [formErrorsChat, setFormErrorsChat] = useState({});
  const [isSubmitChat, setIsSubmitChat] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [pageNumber, setPage] = useState(1);
  const [docPageNumber, setDocPage] = useState(1);
  const [docTotalPage, setDocTotalPage] = useState(0);

  const CustomTab = styled(Tab)`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: normal;
    text-transform: capitalize;

    &.Mui-selected {
      font-weight: 700;
      color: #333;
    }
  `;

  // Tab Value
  const [value, setValue] = React.useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  const handleDocPageChange = (e, value) => {
    setDocPage(value);
  };

  useEffect(() => {
    setIsLoader(true);
    getDocs();
    getDocTypes();
    getDocumentList();
    getDocumentPackList();
  }, []);

  useEffect(() => {
    getDocumentList();
  }, [docPageNumber]);

  useEffect(() => {
    getDocumentPackList();
  }, [pageNumber]);

  const handleChangeTab = (event, newValue) => {
    newValue === 1 ? setTabQuickChats(true) : setTabQuickChats(false);
    setValue(newValue);
  };

  const geteditorVal = (val) => {
    fetch(val)
      .then((response) => {
        return response.text();
      })
      .then((html) => {
        setEditor(html);
      });
  };

  const getDocumentList = () => {
    Api(
      "GET",
      `Document/GetDocumentList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&pageLimit=24&pageNumber=${docPageNumber}&searchText&OrderBy=updatedAt&OrderbyDirection=desc`
    )
      .then((res) => {
        if (res.data.data) {
          setDocList(res.data.data);
          setIsLoader(false);
          setDocTotalPage(res.data.totalPage);
        } else {
          setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocumentPackList = () => {
    Api(
      "GET",
      `DocumentPack/GetDocumentPackList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&pageLimit=24&pageNumber=${pageNumber}&searchText&OrderBy=updatedAt&OrderbyDirection=desc`
    )
      .then((res) => {
        if (res.data.data) {
          setDocPackList(res.data.data);
          setIsLoader(false);
        } else {
          setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get all docs dropdown api
  const getDocs = () => {
    Api("GET", "Document/GetDocumentType?type=doctype")
      .then((res) => {
        if (res.data) {
          setAllDocs(res.data);
          setIsLoader(false);
        } else {
          setAllDocs([]);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get document type dropdown
  const getDocTypes = () => {
    Api("GET", "Document/GetDocumentType?type=docgroup")
      .then((res) => {
        if (res.data) {
          setAllDocTypes(res.data);
          setIsLoader(false);
        } else {
          setAllDocTypes([]);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const params = {
        companyId: location.state.companyId,
        name: formValues.docName,
        internalName: formValues.docFile ? formValues.docFile : null,
        htmlContent: formValues.docContent ? formValues.docContent : null,
        documentTypeId: formValues.doc_type,
        note: formValues.notesName,
        documentId: formValues.documentId,
      };
      if (editMode) {
        Api("PUT", "Document/EditDocument", params, "multipart")
          .then((res) => {
            if (res.data.isSuccess == true) {
              toast.success(res.data.message);
              getDocumentList();
              delete formValues.docFileShow;
              delete formValues.htmldocShow;
              setShowSideBar(false);
            } else {
              setShowSideBar(true);
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });

        setNewEditorShow(true);
        setEditor("");
      } else {
        Api("POST", "Document/AddDocument", params, "multipart")
          .then((res) => {
            if (res.data.isSuccess == true) {
              toast.success(res.data.message);
              getDocumentList();
              setShowSideBar(false);
            } else {
              toast.error(res.data.message);
              setShowSideBar(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setOpenToastBar(true);
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDocFiles = (files) => {
    setFormValues({ ...formValues, docFile: files[0] });
  };

  const addDataTable = (e) => {
    setAddEditLabel("Create New Documents");
    setShowSideBar(true);
    setShowNoDoc(false);
  };

  const uploadDataTable = (e) => {
    setAddEditLabel("Upload Documents");
    setShowSideBar(true);
  };

  const errors = {};
  const validate = (values) => {
    if (!values.doc_type) {
      errors.doc_type = "Document Type is required.";
    }
    if (
      !values.htmldocShow &&
      !values.docFileShow &&
      !values.docFile &&
      showNoDoc
    ) {
      errors.docFile = "DocumentFile is required.";
    }
    if (!values.docName) {
      errors.docName = "Document Name is required.";
    }

    if (!values.notesName) {
      errors.notesName = "Notes is required.";
    } else if (!isNaN(values.notesName)) {
      errors.notesName = "Notes must be a text!";
    }

    return errors;
  };

  const formErrorClear = () => {
    formErrors.notesName = "";
    formErrors.docName = "";
    formErrors.docContent = "";
    formErrors.doc_type = "";
  };

  const closeSideBar = () => {
    setFormValues("");
    formErrorClear();
    setShowNoDoc(true);
    setShowSideBar(false);
  };

  useEffect(() => {
    if (Object.keys(formErrorsChat).length === 0 && isSubmitChat) {
      const params = {
        companyId: location.state.companyId,
        name: formValuesChat.doc_pack_name,
        documentUploadRequestsViewModel: uploadReqList,
        documentGroupId: formValuesChat.pack_doc_type,
        documentId: checkedDocs,
        documentPackId: formValuesChat.documentPackId
          ? formValuesChat.documentPackId
          : null,
      };
      if (editMode) {
        Api("PUT", "DocumentPack/EditDocumentPack", params)
          .then((res) => {
            if (res.data.isSuccess == true) {
              toast.success(res.data.message);
              setIsLoader(true);
              getDocumentPackList();
              setShowSideBar(false);
            } else {
              setShowSideBar(true);
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        setOpenToastBar(true);
      } else {
        Api("POST", "DocumentPack/AddDocumentPack", params)
          .then((res) => {
            if (res.data.isSuccess == true) {
              toast.success(res.data.message);
              getDocumentPackList();
              setShowSideBar(false);
            } else {
              setShowSideBar(true);
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setOpenToastBar(true);
      setAddDocPack(false);
    }
  }, [formErrorsChat]);

  const handleSubmitChat = (e) => {
    e.preventDefault();
    setFormErrorsChat(validateChat(formValuesChat));

    if (
      uploadReqList.some((req) => req.err_instructions !== "") ||
      uploadReqList.some((req) => req.err_name !== "")
    ) {
      setIsSubmitChat(false);
    } else {
      uploadReqList.map((req) => {
        delete req.err_instructions;
        delete req.err_name;
      });
      setIsSubmitChat(true);
    }
  };

  const handleChangeChat = (e) => {
    const { name, value } = e.target;
    setFormValuesChat({ ...formValuesChat, [name]: value });
  };

  const addDataTableChat = (e) => {
    setAddEditLabel("Add Document Pack");
    setShowSideBar(true);
  };

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...uploadReqList];

    if (name == "imageAllow") {
      list[index][name] = e.target.checked;
    } else {
      list[index][name] = value;
    }
    setUploadReqList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...uploadReqList];
    list.splice(index, 1);
    setUploadReqList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setUploadReqList([
      ...uploadReqList,
      {
        name: "",
        instructions: "",
        imageAllow: false,
        companyId: location.state.companyId,
      },
    ]);
  };

  const errorsChat = {};
  const validateChat = (values) => {
    if (!values.doc_pack_name) {
      errorsChat.doc_pack_name = "Document pack name is required.";
    }
    if (!values.pack_doc_type) {
      errorsChat.pack_doc_type = "Type is required.";
    }
    if (checkedDocs.length == 0) {
      errorsChat.checked_docs = "Please select one document.";
    }

    if (uploadReqList.length > 0) {
      uploadReqList.map((req) =>
        req.instructions == ""
          ? (req.err_instructions = "Instructions is required.")
          : (req.err_instructions = "")
      );
      uploadReqList.map((req) =>
        req.name == ""
          ? (req.err_name = "Name is required.")
          : (req.err_name = "")
      );
    }

    return errorsChat;
  };

  const formErrorClearChat = () => {
    formErrorsChat.doc_pack_name = "";
    formErrorsChat.pack_doc_type = "";
    formErrorsChat.name = "";
    formErrorsChat.checked_docs = "";
    formErrorsChat.instructions = "";
  };

  const closeSideBarChat = () => {
    setFormValuesChat("");
    formErrorClearChat();
    setAddDocPack(true);
    setShowSideBar(false);
  };

  const addDataCheck = (id) => {
    if (checkedDocs.includes(id)) {
      setCheckedDocs(checkedDocs.filter((docs) => docs != id)); // pop
    } else {
      setCheckedDocs([...checkedDocs, id]); // or push
    }
  };

  function handleEdit(dataId) {
    if (tabQuickChats) {
      setAddDocPack(false);
      setAddEditLabel("Edit Document Pack");
      const listItemValueChat = docPackList.filter((items) => {
        return items.documentPackId === dataId ? items : null;
      });

      setFormValuesChat({
        ["doc_pack_name"]: listItemValueChat[0].name,
        ["pack_doc_type"]: listItemValueChat[0].documentGroupId,
        ["documentPackId"]: listItemValueChat[0].documentPackId,
      });
      setCheckedDocs(listItemValueChat[0].documentId);
      setUploadReqList(listItemValueChat[0].documentUploadRequestsViewModel);
      setShowSideBar(dataId);
    } else {
      setAddEditLabel("Edit Document");

      const listItemValue = docList.filter((items) => {
        return items.documentId === dataId ? items : null;
      });

      if (listItemValue[0].isUpload) {
        setEditImageShow(true);
        setShowNoDoc(true);
      } else {
        setNewEditorShow(false);
        setShowNoDoc(false);
      }
      fetch(listItemValue[0].htmlContent)
        .then((response) => {
          return response.text();
        })
        .then((html) => {
          setEditor(html);
        });

      setFormValues({
        ["docName"]: listItemValue[0].name,
        ["doc_type"]: listItemValue[0].documentTypeId,
        ["notesName"]: listItemValue[0].note,
        ["htmldocShow"]: listItemValue[0].isUpload
          ? null
          : listItemValue[0].htmlContent,
        ["docFileShow"]: listItemValue[0].isUpload
          ? listItemValue[0].htmlContent
          : null,
        ["docFile"]: null,
        ["docContent"]: null,
        ["documentId"]: dataId,
      });
      setShowSideBar(dataId);
      geteditorVal(listItemValue[0].htmlContent);
      console.log(editor);
    }
  }

  function handleDelete(dataId) {
    // Delete data of row table - popup clicked delte
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      if (tabQuickChats) {
        setIsLoader(true);
        Api(
          "DELETE",
          `DocumentPack/DeleteDocumentPack?documentPackId=${dataId}`
        )
          .then((response) => {
            if (response.data.isSuccess) {
              toast.success(response.data.message);
              setIsLoader(true);
              getDocumentPackList();
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setOpenToastBar(true);
        setIsLoader(false);
      } else {
        setIsLoader(true);
        Api(
          "DELETE",
          `Document/DeleteDocument?documentId=${dataId}`,
          formValues
        )
          .then((response) => {
            if (response.data.isSuccess) {
              toast.success(response.data.message);
              setIsLoader(true);
              getDocumentList();
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setOpenToastBar(true);
        setIsLoader(false);
      }
    }
  }

  return (
    <div>
      {/* sidebar for add and edit data into the table */}
      {showSideBar ? <div className="back-shadow"></div> : null}
      {showSideBar ? (
        <div
          className={showSideBar ? "side-bar-parent active" : "side-bar-parent"}
        >
          <header>
            <span>{showAddEditLabel}</span>
            <img
              src={require("../../../assets/images/close.svg").default}
              alt="close-img"
              className="close-img"
              onClick={tabQuickChats ? closeSideBarChat : closeSideBar}
            />
          </header>

          {tabQuickChats ? (
            <form onSubmit={handleSubmitChat} className="inner-container">
              <div className="in-forms">
                <div className="forms-input">
                  <label>Document Pack Name</label>
                  <input
                    type="text"
                    placeholder="Enter a name"
                    name="doc_pack_name"
                    id="doc_pack_name"
                    value={formValuesChat.doc_pack_name}
                    onChange={handleChangeChat}
                  />
                  {formErrorsChat.doc_pack_name ? (
                    <span className="error_msg">
                      {formErrorsChat.doc_pack_name}
                    </span>
                  ) : null}
                </div>
                <div className="forms-input">
                  <label>Type</label>
                  <select
                    name="pack_doc_type"
                    id="pack_doc_type"
                    value={formValuesChat.pack_doc_type}
                    onChange={handleChangeChat}
                  >
                    <option value="none">Select</option>
                    {allDocTypes.map((docs) => (
                      <option
                        key={docs.documentTypeId}
                        value={docs.documentTypeId}
                      >
                        {docs.name}
                      </option>
                    ))}
                  </select>
                  {formErrorsChat.pack_doc_type ? (
                    <span className="error_msg">
                      {formErrorsChat.pack_doc_type}
                    </span>
                  ) : null}
                </div>
                <p className="select-doc">Select Documents</p>
                {formErrorsChat.checked_docs ? (
                  <span className="error_msg">
                    {formErrorsChat.checked_docs}
                  </span>
                ) : null}
                {docList.map((items) => (
                  <div className="select-doc-container" key={items.documentId}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          name="documents_data"
                          id={"docs" + items.documentId}
                          onChange={(e) => addDataCheck(items.documentId)}
                          checked={
                            checkedDocs.includes(items.documentId)
                              ? true
                              : false
                          }
                        />
                      }
                      label={items.documentTypeDisplayName}
                    />
                    <p className="select-doc-desc">{items.name}</p>
                  </div>
                ))}

                {/* add-upload box */}
                {uploadReqList.map((req, i) => (
                  <div className="add-upload-container" key={i}>
                    <div className="text-right">
                      <img
                        src={
                          require("../../../assets/images/add-upload-close.svg")
                            .default
                        }
                        alt="close-img"
                        className="close-img"
                        onClick={() => handleRemoveClick(i)}
                      />
                    </div>
                    <div className="forms-input">
                      <label>Name</label>
                      <input
                        type="text"
                        placeholder="Enter a name"
                        name="name"
                        id="name"
                        value={req.name}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      {req.err_name ? (
                        <span className="error_msg">{req.err_name}</span>
                      ) : null}
                    </div>
                    <div className="forms-input">
                      <label>Instructions</label>
                      <textarea
                        placeholder="Enter a instructions..."
                        name="instructions"
                        id="instructions"
                        cols="20"
                        rows="4"
                        value={req.instructions}
                        onChange={(e) => handleInputChange(e, i)}
                      ></textarea>
                      {req.err_instructions ? (
                        <span className="error_msg">
                          {req.err_instructions}
                        </span>
                      ) : null}
                    </div>
                    <div className="forms-input check-box">
                      <FormControlLabel
                        control={
                          <Checkbox
                            disableRipple
                            name="imageAllow"
                            onChange={(e) => handleInputChange(e, i)}
                            checked={req.imageAllow}
                          />
                        }
                        label="Allow Images Only"
                      />
                    </div>
                  </div>
                ))}

                <p
                  className="add-upload"
                  onClick={() => (setAddUploadBox(true), handleAddClick())}
                >
                  + Add Upload Request
                </p>
              </div>
              <div className="btns-container">
                <input type="submit" className="add-btn" value="Submit" />
                <button
                  className="cancel-btn"
                  type="reset"
                  onClick={() => (
                    setShowSideBar(false),
                    setFormValuesChat(initialValuesChat),
                    formErrorClear()
                  )}
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit} className="inner-container">
              <div>
                <div className="in-forms">
                  <div className="forms-input">
                    <label>Document Name</label>
                    <input
                      type="text"
                      placeholder="Name here"
                      name="docName"
                      id="docName"
                      value={formValues.docName}
                      onChange={handleChange}
                    />
                    {formErrors.docName ? (
                      <span className="error_msg">{formErrors.docName}</span>
                    ) : null}
                  </div>
                  <div className="forms-input">
                    <label>Select Document Type</label>
                    <select
                      name="doc_type"
                      id="doc_type"
                      value={formValues.doc_type}
                      onChange={handleChange}
                    >
                      <option value="none">Select</option>
                      {allDocs.map((docs) => (
                        <option
                          key={docs.documentTypeId}
                          value={docs.documentTypeId}
                        >
                          {docs.name}
                        </option>
                      ))}
                    </select>
                    {formErrors.doc_type ? (
                      <span className="error_msg">{formErrors.doc_type}</span>
                    ) : null}
                  </div>
                  <div className="forms-input">
                    <label>Notes</label>
                    <textarea
                      placeholder="Enter a description..."
                      name="notesName"
                      id="notesName"
                      cols="20"
                      rows="4"
                      value={formValues.notesName}
                      onChange={handleChange}
                    ></textarea>
                    {formErrors.notesName ? (
                      <span className="error_msg">{formErrors.notesName}</span>
                    ) : null}
                  </div>
                  <input
                    type="text"
                    hidden
                    placeholder="notesDate"
                    name="notesDate"
                    id="date"
                    value={formValues.notesDate}
                    onChange={handleChange}
                  />
                </div>

                {showNoDoc ? (
                  editImageShow ? (
                    <div className="upload-file-shower">
                      <a href={formValues.docFileShow} target="_blank">
                        {formValues.docName}
                      </a>
                      <button
                        onClick={() => (
                          setEditImageShow(false), (formValues.docFileShow = "")
                        )}
                        className="icon icon_button"
                      >
                        <img
                          src={require("../../../assets/images/close-upload.png")}
                          alt="close"
                          className="close-img"
                        />
                      </button>
                    </div>
                  ) : (
                    <div className="forms-input">
                      <DropzoneArea
                        dropzoneParagraphClass={classes.dropZoneText}
                        previewGridClasses={
                          {
                            // container: classes.preview,
                            // item: classes.preview,
                            // image: classes.preview,
                          }
                        }
                        getPreviewIcon={(file) => {
                          if (file.file.type.split("/")[0] === "image")
                            return (
                              <img
                                className={classes.preview}
                                src={file.data}
                              />
                            );
                          else
                            return (
                              <img
                                className={classes.preview}
                                src={require("../../../assets/images/file.png")}
                              />
                            );
                        }}
                        acceptedFiles={[
                          ".jpg",
                          ".png",
                          ".svg",
                          ".doc",
                          ".pdf",
                          ".txt",
                          ".html",
                          ".rtf",
                          ".csv",
                          ".xls",
                        ]}
                        dropzoneText={`Drag and drop a file here or click \n Maximum allowed file size is 10 MB \n Allowed Filetypes are \n .doc, .pdf, .txt, .html, .jpg, \n .png, .svg, .rtf, .csv, .xls`}
                        onChange={(files) => handleDocFiles(files)}
                        maxFileSize={1000000}
                        filesLimit={1}
                      />
                      {formErrors.docFile ? (
                        <span className="error_msg">{formErrors.docFile}</span>
                      ) : null}
                    </div>
                  )
                ) : (
                  <div className="forms-input">
                    <OpenEditor
                      formValues={formValues}
                      setFormValues={setFormValues}
                    />
                  </div>
                )}
                <div className="btns-container">
                  <input type="submit" className="add-btn" value="Save" />
                  <button
                    className="cancel-btn"
                    type="reset"
                    onClick={() => (
                      setShowSideBar(false),
                      setFormValues(initialValues),
                      formErrorClear()
                    )}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              {/* } */}
            </form>
          )}
        </div>
      ) : null}
      {openToastBar ? <ToastAlert /> : null}
      <div className="common-container users">
        <div className="inner-container documents">
          <Loader inShow={isLoader} />
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>Documents</strong>
            </p>
          </div>
          <div className="manage-container jobs quick">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="top-search">
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    aria-label="Quick notes tab"
                  >
                    <CustomTab label="Documents" {...a11yProps(0)} />
                    <CustomTab label="Documents Packs" {...a11yProps(1)} />
                  </Tabs>
                  {tabQuickChats ? (
                    <ButtonUI
                      p="12px"
                      onClick={() => (
                        addDataTableChat(),
                        setUploadReqList([]),
                        setCheckedDocs([]),
                        setEditMode(false),
                        setFormValuesChat(initialValuesChat)
                      )}
                      leftIcon={
                        <AddIcon sx={{ height: "16px", width: "16px" }} />
                      }
                    >
                      Add Document Pack
                    </ButtonUI>
                  ) : (
                    <div className="d-flex">
                      <ButtonUI
                        p="12px"
                        className="profile-img"
                        onClick={() => (
                          uploadDataTable(),
                          setEditImageShow(false),
                          setEditMode(false),
                          setFormValues(initialValues),
                          setShowNoDoc(true)
                        )}
                        leftIcon={
                          <AddIcon sx={{ height: "16px", width: "16px" }} />
                        }
                      >
                        Upload Documents
                      </ButtonUI>
                      <ButtonUI
                        p="12px"
                        ml="-10px"
                        leftIcon={
                          <AddIcon sx={{ height: "16px", width: "16px" }} />
                        }
                        onClick={() => (
                          addDataTable(),
                          setEditor(""),
                          setNewEditorShow(true),
                          setEditMode(false),
                          setFormValues(initialValues),
                          setShowNoDoc(false)
                        )}
                      >
                        Create New Documents
                      </ButtonUI>
                    </div>
                  )}
                </div>
                <div className="manage-checkboxes-none">
                  <TabPanel value={value} index={0}>
                    {docList.length > 0 ? (
                      <div className="cards-contain">
                        {docList.map((row) => (
                          <div className="cust-cards" key={row.documentId}>
                            <div className="date-sec">
                              <span>{row.documentTypeDisplayName}</span>
                              <div className="position-relative">
                                <EditDeleteMenu
                                  id={row.documentId}
                                  onEdit={handleEdit}
                                  onDelete={handleDelete}
                                />
                              </div>
                            </div>
                            {row.htmlContent ? (
                              <a href={row.htmlContent} target="_blank">
                                <p>{row.name}</p>
                              </a>
                            ) : (
                              <p>{row.name}</p>
                            )}
                          </div>
                        ))}
                        {docList.length >= 0 && (
                          <StyledPagination
                            count={docTotalPage}
                            page={docPageNumber}
                            variant="outlined"
                            shape="rounded"
                            onChange={handleDocPageChange}
                          />
                        )}
                      </div>
                    ) : (
                      <NoRecordsFound />
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {docPackList.length > 0 ? (
                      <div className="cards-contain">
                        {docPackList.map((row) => (
                          <div className="cust-cards" key={row.documentPackId}>
                            <div className="date-sec">
                              <span>{row.documentGroupDisplayName}</span>
                              <div className="position-relative">
                                <EditDeleteMenu
                                  id={row.documentPackId}
                                  onEdit={handleEdit}
                                  onDelete={handleDelete}
                                />
                              </div>
                            </div>
                            <div className="date-sec active">
                              <p>{row.name}</p>
                              <p>
                                {row.documentId.length +
                                  row.documentUploadRequestsViewModel.length +
                                  " Docs"}
                              </p>
                            </div>
                          </div>
                        ))}
                        {docPackList.length >= 0 && (
                          <StyledPagination
                            count={totalPage}
                            page={pageNumber}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePageChange}
                          />
                        )}
                      </div>
                    ) : (
                      <NoRecordsFound />
                    )}
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
