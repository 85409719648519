export const API_types = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH"
}

export const USERS_LIST = {
    usersGetList: "Users/GetUserList"
}

export const STATUS_CODE = {
    http_ok: 200
}