import { useEffect, useState } from "react";
import { AddEditPopup } from "../../../addEditPopup";
import { CheckboxList } from "../../CheckboxList";

export const UserRole = ({
  openSidebar,
  toggleDrawer,
  usersRoles,
  handleRole,
  formValues,
  formErrors,
  selectedUserRoles,
  setFormValues,
  isSubmitting,
  setSubmitting,
}) => {
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    const test = [...usersRoles];
    test.map((rec) => {
      rec.name = rec.roleName;
      rec.checked = false;
      rec.id = rec.roleId;
      rec.companyId = selectedUserRoles?.companyId;
      rec.userId = selectedUserRoles?.userId;
    });
    setAllData(test);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleRole();
  };

  const handleChange = (checkName, isChecked) => {
    const checkList = allData.map((data) => {
      if (data.id === checkName) {
        data.checked = isChecked;
      }
      return data;
    });

    setAllData(checkList);
    setFormValues({ ["roleCheck"]: checkList });
  };

  return (
    <AddEditPopup
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
      title="User Role Form"
      isSubmitting={isSubmitting}
      setSubmitting={setSubmitting}
    >
      <div className="forms-input">
        <label>User Roles</label>
        <div className="right-checks">
          <div className="user-roles-pop-up">
            <CheckboxList
              allData={allData}
              setAllData={setAllData}
              handleSubmit={handleSubmit}
              hasSelectAll={false}
              showSaveContainer={false}
              handleChange={handleChange}
            />
          </div>
          <span className="error_msg">{formErrors?.roleCheck}</span>
        </div>
      </div>
    </AddEditPopup>
  );
};
