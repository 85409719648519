import {
  FormControlLabel,
  TextareaAutosize,
  MenuItem,
} from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { CloseXIcon } from "../../../../../../../icons";
import { ButtonUI } from "../../../../../../ui/button";
import ChatRight from "../../../../../../../assets/images/chat-right.png";
import ChatLeft from "../../../../../../../assets/images/chat-left.png";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { ColoredLinearProgress } from "../../../../../coloredProgressBar";
import ForumIcon from "@mui/icons-material/Forum";

export const ChatTab = ({ applicantData, selectedSuitCase }) => {
  const attachRef = useRef("null");
  const [attachments, setAttachments] = useState([]);
  const [noteType, setNoteType] = useState("");
  const [connectionString, setConnection] = useState(null);
  const [inputText, setInputText] = useState("");
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [messages, setMessages] = useState([]);
  const [menus, setMenus] = useState([]);
  const [chatConnectionId, setChatConnectionId] = useState("");
  const [isLoader, setLoader] = useState(false);
  const BASEURL = process.env.REACT_APP_BASE_URL;
  const bottomRef = useRef(null);

  const openAttachment = () => {
    attachRef.current.click();
  };

  useEffect(() => {
    // Scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(async () => {
    var the_arr = BASEURL.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/chatHub`)
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(connect);
    connect.on("ReceiveMessage", (message, textAreaId) => {
      setMessages((messages) => [
        ...messages,
        { message: message, textAreaId: textAreaId },
      ]);
    });

    await connect.start();

    saveConnectionId(connect.connection.connectionId);
    getChatConnection();
    // bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    getAllChat();
    getChatQuickNotes();
  }, []);

  const getChatConnection = () => {
    const args = {
      isCandidate: false,
      applicantId: applicantData.applicantId,
      jobId: selectedSuitCase.jobId,
    };

    Api("POST", `Chat/GetApplicantSocketConnection`, args)
      .then((res) => {
        setChatConnectionId(res.data.socketConnectionId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveConnectionId = (connId) => {
    const args = {
      connectionId: connId,
      applicantId: applicantData.applicantId,
      isCandidate: false,
      jobId: selectedSuitCase.jobId,
      companyId: applicantData.companyId,
    };

    Api("POST", `Chat/setConnection`, args)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllChat = () => {
    setLoader(true);
    Api(
      "GET",
      `Chat/GetAllChat?id=${window.btoa(
        applicantData.applicantId
      )}&textAreaId=system&jobId=${window.btoa(selectedSuitCase.jobId)}`
    )
      .then((res) => {
        setMessages(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getChatQuickNotes = () => {
    Api(
      "GET",
      `QuickNote/GetQuickNoteList?quickNoteType=chat&companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        setMenus(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFiles = (e) => {
    setAttachments([...attachments, ...e.target.files]);
  };

  const handleChange = (event) => {
    setNoteType(event.target.value);
    setInputText(event.target.value);
  };

  const removeAttachment = (index) => {
    const updateAttachments = [...attachments];
    updateAttachments.splice(index, 1);
    setAttachments([...updateAttachments]);
  };

  const sendMessage = async () => {
    const params = {
      userId: localStorage.getItem("user"),
      userName: "string",
      message: inputText,
      applicantId: applicantData.applicantId,
      applicantName: applicantData.name,
      isRead: true,
      mediaType: 0,
      companyId: applicantData.companyId,
      jobId: selectedSuitCase.jobId,
      quickNoteId: "string",
      quickNoteType: "string",
      attachments: [],
    };
    setInputText("");
    await connectionString.invoke(
      "SendPrivateMessage",
      params,
      true,
      "Hetal Nathvani",
      localStorage.getItem("user"),
      "system",
      chatConnectionId
    );
    setInputText("");
  };

  return (
    <div className="tab-container">
      <input
        type="file"
        multiple
        ref={attachRef}
        hidden
        onChange={handleFiles}
      />

      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : messages.length > 0 ? (
        <>
          <span className="heading-label p-16 font-blue">
            Chat Messages: ({messages.length})
          </span>
          <div className="chat-container" id="chat-messages">
            {messages.map((message) => (
              <div
                className="message-chat-container p-0-16"
                style={{
                  justifyContent:
                    message.textAreaId === "system" ? "flex-end" : "flex-start",
                  position: "relative",
                  paddingRight: !!message.textAreaId && "24px",
                  paddingLeft: message.textAreaId !== "system" && "24px",
                }}
                key={new Date() * Math.random()}
              >
                <div
                  className="chat-design"
                  style={{
                    right: !!message.textAreaId && "10px",
                    left: message.textAreaId !== "system" && "10px",
                  }}
                >
                  <img
                    src={message.textAreaId === "system" ? ChatRight : ChatLeft}
                    height="100%"
                    width="100%"
                  />
                </div>
                <div
                  className="message-chat"
                  style={{
                    backgroundColor:
                      message.textAreaId === "system" ? "#CCDCFF" : "#F2F6FF",
                  }}
                >
                  <span className="sender-label">
                    {message.textAreaId === "system"
                      ? "You"
                      : message.applicantName}
                  </span>
                  <div className="message-text">{message.message}</div>
                  <div className="chat-footer">
                    <div className="chat-footer-detail">
                      <span className="chat-footer-labels">
                        {moment(message.createdAt).format("MM/DD/YYYY")}
                      </span>
                      <div className="dot-div" />
                      <span className="chat-footer-labels">
                        {moment(message.createdAt).format("LT")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div ref={bottomRef} />
          </div>
          <div className="chat-quick-note-container p-0-16">
            <strong className="font-blue font-14">Quick Notes:</strong>
            <FormControl sx={{ marginTop: 2 }} size="small">
              <Select
                value={noteType}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>Select Note</em>
                </MenuItem>
                {menus.map((menu, index) => (
                  <MenuItem value={menu.note} key={index}>
                    {menu.note.substring(0, 60)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="text-area-container">
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                maxRows={4}
                style={{
                  resize: "none",
                  width: "100%",
                  border: "none",
                  outline: "none",
                  fontFamily: "Inter",
                }}
                onChange={(input) => {
                  setInputText(input.target.value);
                }}
                value={inputText}
              />
            </div>
          </div>
          <div className="chat-main-footer p-0-16">
            {/* <div>
          {" "}
          <FormControlLabel
            control={<CheckboxWithBlueCheck disableRipple />}
            label="Save as Quick Note"
          />
        </div> */}
            <div className="attach-send">
              {/* <div role="button" className="pointer" onClick={openAttachment}>
            <AttachmentIcon width="26px" height="26px" />
          </div> */}
              <ButtonUI
                width={"72px"}
                p={"8px"}
                radius="4px"
                onClick={sendMessage}
              >
                Send
              </ButtonUI>
            </div>
          </div>
        </>
      ) : (
        <>
          <span className="heading-label p-16 font-blue">
            Chat Messages: ({messages.length})
          </span>
          <div className="d-flex flex-column no-message-container">
            <div className="chat-icon mt-15">
              <ForumIcon fontSize="large" />
            </div>
            <span className="chat-icon-label mb-10">
              Start your chat with the applicant by sending your first message !{" "}
            </span>
          </div>
          <div className="chat-quick-note-container p-0-16">
            <strong className="font-blue font-14">Quick Notes</strong>
            <FormControl sx={{ marginTop: 2 }} size="small">
              <Select
                value={noteType}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>Select Note</em>
                </MenuItem>
                {menus.map((menu, index) => (
                  <MenuItem value={menu.note} key={index}>
                    {menu.note.substring(0, 60)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="text-area-container">
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                maxRows={4}
                style={{
                  resize: "none",
                  width: "100%",
                  border: "none",
                  outline: "none",
                  fontFamily: "Inter",
                }}
                onChange={(input) => {
                  setInputText(input.target.value);
                }}
                value={inputText}
              />
            </div>
          </div>
          <div className="chat-main-footer p-0-16">
            {/* <div>
          {" "}
          <FormControlLabel
            control={<CheckboxWithBlueCheck disableRipple />}
            label="Save as Quick Note"
          />
        </div> */}
            <div className="attach-send">
              {/* <div role="button" className="pointer" onClick={openAttachment}>
            <AttachmentIcon width="26px" height="26px" />
          </div> */}
              <ButtonUI
                width={"72px"}
                p={"8px"}
                radius="4px"
                onClick={sendMessage}
              >
                Send
              </ButtonUI>
            </div>
          </div>
        </>
      )}

      <div className="attachment-container">
        {attachments.map((attachment, index) => (
          <div className="attachemt-item" key={index}>
            <div className="attachment">
              <span>{attachment.name}</span>
              <div
                className="attachment-close-icon-container"
                onClick={() => removeAttachment(index)}
              >
                <CloseXIcon height="24px" width="24px" fill="#333333" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
