import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../assets/css/components/company-detail/_jobBoards.css";
import Api from "../../../helper/Api";
import { ToastAlert } from "../../../helper/constants";

export const JobBoards = () => {
  const location = useLocation();
  const [jobBoardsLists, setJobBoardsList] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [openToastBar, setOpenToastBar] = useState(false);

  useEffect(() => {
    getJobBoardList();
  }, [formValues]);

  const getJobBoardList = () => {
    Api(
      "GET",
      `JobBoard/GetJobBoardList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&OrderBy=updatedAt&OrderbyDirection=desc`
    )
      .then((res) => {
        setJobBoardsList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (job, event) => {
    job.postAllowed = event.target.value;
    Api("PUT", `JobBoard/UpdateJobBoardPost`, job)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getJobBoardList();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setOpenToastBar(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {openToastBar ? <ToastAlert /> : null}
      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/dashboard"> Dashboard &gt; </Link>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>Job Boards</strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="job-container">
                  {jobBoardsLists.map((job, k) => (
                    <div className="job-grid" key={k}>
                      <span className="job-board-title">{job.name}</span>
                      <div className="job-right">
                        <span className="job-title">Posts allowed</span>
                        <div className="forms-input">
                          <select
                            value={formValues.postsAllowed}
                            onChange={(event) => handleChange(job, event)}
                            className="width-auto"
                          >
                            <option
                              value="None"
                              selected={
                                job.postAllowed === "None" ? true : false
                              }
                            >
                              None
                            </option>
                            <option
                              value="Limited"
                              selected={
                                job.postAllowed === "Limited" ? true : false
                              }
                            >
                              Limited
                            </option>
                            <option
                              value="Unlimited"
                              selected={
                                job.postAllowed === "Unlimited" ? true : false
                              }
                            >
                              Unlimited
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
