import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { ButtonUI } from "../../../../../../ui/button";
import { HireForm } from "./hireForm";
import { ActionForm } from "./actionForm";
import moment from "moment";

export const HireStatus = ({ selectedSuitCase, applicantId }) => {
  const [openHireForm, setHireForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [hireList, setHireList] = useState([]);
  const [openActionForm, setActionForm] = useState(false);
  const [flag, setFlag] = useState("");

  useEffect(() => {
    getHireList();
  }, []);

  const toggleHireForm = (open) => {
    setHireForm(open);
  };

  const toggleActionForm = (open) => {
    setActionForm(open);
  };

  const getHireList = () => {
    Api(
      "GET",
      `Job/ListHiredApplicant?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}`
    )
      .then((res) => {
        setHireList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveHireForm = () => {
    const params = {
      jobId: selectedSuitCase.jobId,
      companyId: selectedSuitCase.companyId,
      applicantId: applicantId,
      hiredDate: formValues?.hiredDate ?? new Date().toISOString(),
      startdDate: formValues?.startdDate ?? new Date().toISOString(),
      notes: formValues?.notes ?? "",
    };

    Api("POST", `Job/HiredApplicant`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleHireForm(false);
          getHireList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveAction = (note, sdate) => {
    const params = {
      companyId: selectedSuitCase.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantId,
      hiredApplicantId: hireList[0].hiredApplicantId,
      closeDate: sdate ?? new Date().toISOString(),
      notes: note,
      flag: flag === "terminate" ? "1" : flag === "quit" ? "2" : "3",
    };

    Api("POST", `Job/CloseHiredApplicant`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleActionForm(false);
          getHireList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <HireForm
        openForm={openHireForm}
        toggleForm={toggleHireForm}
        applicantId={applicantId}
        formValues={formValues}
        setFormValues={setFormValues}
        saveHireForm={saveHireForm}
      />

      <ActionForm
        openForm={openActionForm}
        toggleForm={toggleActionForm}
        applicantId={applicantId}
        saveAction={saveAction}
        flag={flag}
      />

      {hireList.length === 0 ? (
        <ButtonUI p="9px" ml="96px" onClick={() => toggleHireForm(true)}>
          Hire Applicant
        </ButtonUI>
      ) : (
        <div className="mt-10 border-bottom">
          <div className="d-flex flex-column font-12">
            <strong>Employee {hireList[0].hiredApplicantStatus} </strong>
            <span>
              <strong>Start Date : </strong>
              {moment(hireList[0].startDate).format("MM/DD/YYYY")}
            </span>
            {hireList[0].hiredApplicantStatus === "TERMINATED" && (
              <span>
                <strong>Terminated Date : </strong>
                {moment(hireList[0].terminatedDate).format("MM/DD/YYYY")}
              </span>
            )}
            <span>
              <strong>Notes :</strong>
              {hireList[0].notes}
            </span>
          </div>

          {hireList[0].hiredApplicantStatus === "QUITE" ||
          hireList[0].hiredApplicantStatus === "FURLOUGH" ||
          hireList[0].hiredApplicantStatus === "TERMINATED" ? (
            <ButtonUI
              p="9px"
              ml="96px"
              mt="10px"
              mb="10px"
              onClick={() => toggleHireForm(true)}
            >
              Re-Hire Applicant
            </ButtonUI>
          ) : (
            <div>
              <ButtonUI
                p="9px"
                ml="100px"
                mt="10px"
                onClick={() => (toggleActionForm(true), setFlag("terminate"))}
              >
                Terminate
              </ButtonUI>

              <ButtonUI
                p="9px"
                ml="120px"
                mt="10px"
                onClick={() => (toggleActionForm(true), setFlag("quit"))}
              >
                Quit
              </ButtonUI>

              <ButtonUI
                p="9px"
                ml="105px"
                mt="10px"
                mb="10px"
                onClick={() => (toggleActionForm(true), setFlag("furlough"))}
              >
                Furlough
              </ButtonUI>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
