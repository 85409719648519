import * as React from "react";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { job_access } from "../../../../../helper/constants";
import Api from "../../../../../helper/Api";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AddEditPopup } from "../../../addEditPopup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, roles, theme) {
  return {
    fontWeight:
      roles.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const UserNext = ({
  formValues,
  openSidebar,
  usersRoles,
  setIsNext,
  formErrors,
  handleCreate,
  companyData,
  toggleDrawer,
  label,
}) => {
  const theme = useTheme();
  const [roles, setRoles] = useState([]);
  const [accessData, setAccessData] = useState([]);
  const [message, setMessage] = useState("Please select an option to proceed!");
  const JobAccessCheck = [];
  const [jobAccessCheck, setJobAccessCheck] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleJobAccessCheck = (id, name) => {
    setJobAccessCheck((prev) =>
      jobAccessCheck.find((jobs) => jobs.entId === id)
        ? prev.filter((cur) => cur.entId !== id)
        : [
            ...prev,
            {
              entId: id,
              entName: name,
              isNotify: true,
              isDeleted: false,
              entType: "",
              cardId: "",
            },
          ]
    );
  };

  const handleJobAccess = (e) => {
    let url, id;
    formValues.jobAccess = e.target.value;

    switch (e.target.value) {
      case "department":
        url = `Department/GetDepartmentList?companyId=${companyData.companyId}`;
        id = "departmentId";
        break;
      case "company":
        url = `companies/GetListOfCompany?companyId=${companyData.companyId}`;
        id = "companyId";
        break;
      case "facility":
        url = `Facilities/GetFacilitiesList?companyId=${companyData.companyId}`;
        id = "facilitiesId";
        break;
      case "job":
        url = `Job/GetJobList?companyId=${companyData.companyId}`;
        id = "jobId";
        break;
    }

    if (formValues.jobAccess === "none" || formValues.jobAccess === "Select") {
      setAccessData([]);
      setMessage("No Data Found!");
      return;
    }

    Api("GET", url + `&companyHirerchy=${companyData.companyHirerchy}`)
      .then((res) => {
        formValues.jobAccess === "department" ||
        formValues.jobAccess === "company"
          ? setAccessData(res.data.map((res) => ({ ["id"]: res[id], ...res })))
          : setAccessData(
              res.data.data.map((res) => ({ ["id"]: res[id], ...res }))
            );
        // This condition is for temporary basis untill the job module APIs get prepared
        // The condition for department module is due to the response variables
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formValues.roles = [...roles];

    const entityData = roles
      .map((role) =>
        jobAccessCheck.map((job) => ({ ["roleId"]: role, ...job }))
      )
      .flat(1);
    entityData.map((m) =>
      usersRoles.find((u) =>
        u.roleId === m.roleId ? (m.roleName = u.roleName) : null
      )
    );

    formValues.jobAccessCheck = entityData;
    formValues.jobAccessData = accessData.filter((col) => {
      return formValues.jobAccessCheck.find(
        (checked) => checked.entId === col.id
      );
    });
    handleCreate();
  };

  return (
    <AddEditPopup
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
      openSidebar={openSidebar}
      label={label}
    >
      <div className="forms-input">
        <label>Select User Role</label>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-chip-label">Roles</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={roles}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Roles" />}
            MenuProps={MenuProps}
          >
            {usersRoles.map((role) => (
              <MenuItem
                key={role.roleId}
                value={role.roleId}
                style={getStyles(role.roleName, roles, theme)}
              >
                {role.roleName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <span className="error_msg">{formErrors.roleName}</span>
      </div>
      <div className="forms-input">
        <label>Please select a job access level for this user:</label>
        <select
          name="jobAccess"
          id="jobAccess"
          value={formValues.jobAccess}
          onChange={handleJobAccess}
        >
          <option>Select</option>
          {job_access.map((roles) => (
            <option value={roles.name.toLowerCase()} key={roles.id}>
              {roles.name}
            </option>
          ))}
        </select>
        <span className="error_msg">{formErrors.jobAccess}</span>
      </div>
      <div className="forms-input">
        <label>Select one or more facilities</label>
        <div className="user-roles-pop-up">
          {accessData.length > 0 ? (
            accessData.map((data) => (
              <div className="d-flex flex-column border-bottom" key={data.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      value={data.name}
                      onChange={() => handleJobAccessCheck(data.id, data.name)}
                    />
                  }
                  label={data.name}
                />
                {data.address ? (
                  <span className="sub-desc updated-design">
                    {data.address}
                  </span>
                ) : null}
              </div>
            ))
          ) : (
            <span className="message">{message}</span>
          )}
        </div>
        <span className="error_msg">{formErrors.jobAccessLevel}</span>
      </div>
    </AddEditPopup>
  );
};
