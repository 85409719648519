import { useState } from "react";
import { ButtonUI } from "../../components/ui/button";

export const ViewForm = () => {
  const [formValues, setFormValues] = useState({});
  const [alertMessage, setAlertMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {};

  return (
    <div>
      <div className="white-container form">
        <form>
          <div className="forms-input">
            <label>First Name:</label>
            <input
              type="text"
              placeholder="First Name"
              value={formValues.firstName}
              onChange={handleChange}
            />
          </div>

          <div className="forms-input">
            <label>Last Name:</label>
            <input
              type="text"
              placeholder="Last Name"
              value={formValues.lastName}
              onChange={handleChange}
            />
          </div>

          <div className="forms-input">
            <ButtonUI p="9px" radius="6px" mt="10px" onClick={handleSubmit}>
              Submit
            </ButtonUI>
          </div>

          {alertMessage}
        </form>
      </div>
    </div>
  );
};
