import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const DateSelector = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          inputFormat="MM/dd/yyyy"
          value={props.value}
          minDate={props.minDate}
          onChange={props.handleDateChange}
          renderInput={(params) => <TextField {...params} />}
          disablePast={props.disablePast}
        />
      </Stack>
    </LocalizationProvider>
  );
};
