import "../../../assets/css/components/notification/_notification.css";
import "../../../assets/css/components/applicants/_applicants.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Api from "../../../helper/Api";
import moment from "moment/moment";
import Loader from "../../../components/common/Loader";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../redux/applicantData/actions";
import BackgroundLetterAvatars from "../../../components/common/layouts/BackgroundLetterAvatars";
import { EditDeleteMenu } from "../../../components/ui/editDeleteMenu";
import { toast } from "react-toastify";
import { AddApplicantForm } from "../../../components/common/layouts/jobInfo/content/addApplicant";
import { NoColDataFound } from "../../../components/common/NoColDataFound";
import { ProfileCard } from "../../../components/common/cards/ProfileCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, CircularProgress, ListItemText } from "@mui/material";
import { ColoredLinearProgress } from "../../../components/common/coloredProgressBar";
import { useSelector } from "react-redux";
import { ApplicantContent } from "../../../components/common/layouts/applicantInfo/content";

export function Hires() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [hiredList, setHiredList] = useState([]);
  const [pageNumber, setPage] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [openSidebar, setSidebar] = useState(false);
  const [ids, setIds] = useState({});
  const formData = new FormData();
  const dispatch = useDispatch();
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  useEffect(() => {
    if (searchInput === "") {
      setLoader(true);
      getHiredApplciants("updatedAt", "desc");
    }
  }, [searchInput]);

  useEffect(() => {
    getHiredApplciants("updatedAt", "DESC");
  }, [pageNumber]);

  const applicantDetails = (applicantData) => {
    applicantData.jobId = applicantData.applicantJobList[0].jobId;

    dispatch({
      type: APPLICANT_DATA_ACTIONS.SET_APPLICANT_DATA,
      payload: applicantData,
    });

    navigate("/hires/applicant-details");
  };

  const getHiredApplciants = (columnName, direction) => {
    Api(
      "GET",
      `Job/GetHotandHireApplicantList?companyId=${location.state.companyId}&Type=Hired&pageLimit=24&searchText=${searchInput}&pageNumber=${pageNumber}`
    )
      .then((res) => {
        setLoader(false);
        setTotalPage(res.data.totalPage);
        setHiredList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handlePageChange = (e, value) => {
    setPage(value);
    getHiredApplciants("updatedAt", "desc");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearchApplicant("updatedAt", "desc");
    }
  };

  const getSearchApplicant = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      getHiredApplciants("updatedAt", "desc");
    }
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const editAction = (companyId, jobId, applicantId) => {
    Api(
      "GET",
      `Job/GetApplicantById?companyId=${companyId}&jobId=${jobId}&applicantId=${applicantId}`
    )
      .then((res) => {
        toggleDrawer("right", true);
        setIds({
          companyId: companyId,
          jobId: jobId,
          applicantId: applicantId,
        });
        setFormValues(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editApplicant = () => {
    formData.append("firstName", formValues?.firstName ?? "");
    formData.append("middleName", formValues?.middleName ?? "");
    formData.append("lastName", formValues?.lastName ?? "");
    formData.append("nickName", formValues?.nickName ?? "");
    formData.append("title", formValues?.title ?? "");
    formData.append("phoneNumber", formValues?.phoneNumber ?? "");
    formData.append("resume", formValues?.resume ?? "");
    formData.append("viewResume", formValues?.viewResume ?? "");
    formData.append("companyId", ids.companyId);
    formData.append("jobId", ids.jobId);
    formData.append("applicantId", ids.applicantId);
    formData.append(
      "address.addressLineOne",
      formValues?.address?.addressLineOne ?? ""
    );
    formData.append("address.addressLineTwo", "");
    formData.append("address.country", "");
    formData.append("address.city", formValues?.address?.city ?? "");
    formData.append("address.state", formValues?.address?.state ?? "");
    formData.append("address.zipCode", formValues?.address?.zipCode ?? "");
    formData.append("source", formValues?.source ?? "");

    Api("PUT", `Job/EditApplicant`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
          getHiredApplciants("updatedAt", "desc");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMore = () => {
    setPage((prevState) => prevState + 1);
  };

  const openApplicantDetails = (applicantData) => {
    if (applicantData.applicantList[0].jobCycleStatus === "NEW_APPLICATION") {
      Api(
        "PUT",
        `Job/UpdateCurrentStatus?companyId=${applicantData.companyId}&jobId=${applicantData.applicantList[0].jobId}&applicantId=${applicantData.applicantId}&status=REVIEWING`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            applicantData.applicantList[0].jobCycleStatus = "REVIEWING";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const record = { ...applicantData };
    applicantData.applicantList.forEach((rec) => {
      if (rec.applicantId === applicantData.applicantId) {
        record.isHotList = rec.isHotList;
      }
    });

    const data = [...applicantsArray];
    data.push(record);

    dispatch({
      type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
      payload: data,
    });
  };

  return (
    <div>
      {/* Edit Applicant form */}
      <AddApplicantForm
        openAddForm={openSidebar}
        toggleDrawer={toggleDrawer}
        handleSubmit={editApplicant}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      {/* <div className="company_content help-container applicants">
        <div className="content_header">
          <div className="company_text mr-auto">Hires</div>
          {hiredList.length > 0 ? (
            <div className="company_search company">
              <img
                src={require("../../../assets/images/search_icon.png")}
                alt="search-icon"
                className="search_img"
              />
              <input
                type="text"
                placeholder="Search Applicant"
                className="search_input appointments"
                name="search_input"
                value={searchInput}
                onChange={handleSearch}
                onKeyPress={handleKeyPress}
              />
              <button className="search-btn" onClick={getSearchApplicant}>
                Search
              </button>
            </div>
          ) : null}
        </div>
        <div className="company_table">
          <div className="notification-container">
            {hiredList.length > 0 ? (
              <div className="applicant-cards">
                <Loader inShow={isLoader} />
                {hiredList.map((items) => (
                  <div className="app-card pointer" key={items.applicantId}>
                    <div className="d-flex align-items-center">
                      <p className="dates mr-auto">
                        {moment(items.createdAt).format("DD/MM/YYYY")}
                      </p>
                      <p className="hired">Hired</p>
                      <EditDeleteMenu
                        id={items.applicantId}
                        onEdit={() =>
                          editAction(
                            items.companyId,
                            items.applicantJobList[0].jobId,
                            items.applicantId
                          )
                        }
                        deleteOption={false}
                      />
                    </div>
                    <div
                      className="d-flex align-items-center"
                      onClick={() => applicantDetails(items)}
                    >
                      <BackgroundLetterAvatars
                        name={items.firstName + " " + items.lastName}
                      />
                      <p className="app-profile-name">
                        {items.firstName + " " + items.lastName}
                      </p>
                    </div>
                    {items.applicantJobList.map((job) => (
                      <div
                        key={job.jobId}
                        onClick={() => applicantDetails(items)}
                      >
                        {job.isPrimaryJob === true ? (
                          <div>
                            <p className="app-designation">{job.jobName}</p>
                            <p className="app-status">({job.jobNo})</p>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ) : (
              <NoRecordsFound />
            )}
            {hiredList.length > 9 ? (
              <StyledPagination
                count={totalPage}
                page={pageNumber}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
              />
            ) : null}
          </div>
        </div>
      </div> */}

      <div className="company_content help-container applicants">
        <div className="common-container users company">
          <div className="inner-container company">
            <div className="user-container company">
              <div className="user-list">
                <div className="applicant-profile">
                  <div className="applicant-data">
                    <strong>Hires</strong>
                  </div>
                </div>
                {isLoader === true ? (
                  <ColoredLinearProgress />
                ) : hiredList?.length > 0 ? (
                  <div className="inner-h applicant-list">
                    <div>
                      <ul className="job_template_option_menu pointer">
                        {/* <InfiniteScroll
                          height={750}
                          dataLength={hiredList?.length || 0}
                          next={fetchMore}
                          hasMore={
                            hiredList?.length !== hiredList?.totalRecords
                          }
                          // loader={
                          //   <Box>
                          //     <CircularProgress />
                          //   </Box>
                          // }
                        > */}
                        {/* {hiredList?.length === 0 && (
                            <ListItemText primary={"No Available Resource"} />
                          )} */}
                        {
                          <div>
                            {hiredList?.length > 0 &&
                              hiredList?.map((applicant) => (
                                <div key={applicant.applicantId}>
                                  <ProfileCard
                                    applicantId={applicant.applicantId}
                                    name={
                                      applicant.firstName +
                                      " " +
                                      applicant.lastName
                                    }
                                    jobName={
                                      applicant.applicantJobList[0].jobName
                                    }
                                    jobId={applicant.jobId}
                                    companyId={applicant.companyId}
                                    jobCode={
                                      applicant.applicantJobList[0].jobNo
                                    }
                                    morePositions={applicant.morePositions}
                                    createdAt={applicant.createdAt}
                                    openApplicantDetails={openApplicantDetails}
                                    checked={applicant.checked}
                                    latestActivityMessage={
                                      applicant?.latestActivityMessage
                                    }
                                    applicantList={hiredList}
                                    setApplicantList={setHiredList}
                                    setAllChecked={true}
                                    status={applicant.jobHireStatus}
                                  />
                                </div>
                              ))}
                          </div>
                        }
                        {/* </InfiniteScroll> */}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <NoColDataFound />
                )}
              </div>
              {applicantsArray?.map((data, index) => (
                <ApplicantContent data={data} index={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
