import React from "react";

export function PageNotFound() {

    const mainStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%"
    }
    const labelP = {
        marginTop: "20px"
    }

    return(
        <div style={mainStyle}>
            <img src={require('../../../assets/images/404.png')} width="400px" alt="" />
            <p style={labelP}>404 Page Not Found.
            Page you are looking for does not exist.</p>
        </div>
    );
}
