import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { OutlinedEmailIcon, CellPhoneIcon } from "../../../../icons";
import { Android12Switch } from "../../../ui/android/android2switch";
import { EditUserInfo } from "./add/editUserInfo";
import { UserNext } from "./add/UserNext";
import { UserOverview } from "./add/UserOverview";
import { user_info } from "../../../../helper/constants";
import { UserAccess } from "./access/UserAccess";
import { API_types } from "../../../../helper/enum";
import { validations } from "../../../../helper/validations";

import Api from "../../../../helper/Api";
import NoRecordsFound from "../../NoRecordsFound";
import Loader from "../../Loader";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../../../../assets/css/components/company-detail/_users.css";
import AddIcon from "@mui/icons-material/Add";
import { ButtonUI } from "../../../ui/button";
import { AddUser } from "./add/addUser";
import { EditDeleteMenu } from "../../../ui/editDeleteMenu";
import { Roles } from "./role";
import { useSelector } from "react-redux";

export function Users({ isCompany }) {
  const [showSideBar, setSideBar] = useState(false);
  const [showCreateForm, setCreateForm] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showActions, setEditActions] = useState();
  const [usersData, setUsersData] = useState(user_info);
  const [usersDataIndi, setUsersDataIndi] = useState([]);
  const [usersRoles, setUsersRoles] = useState();
  const initialValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    nickName: "",
    user_title: "",
    login_id: "",
    email_id: "",
    cell_phone: "",
    timezone: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isNext, setIsNext] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const location = useLocation();
  const [label, setLabel] = useState("Add New User");
  const [openUserAccessForm, setUserAccessForm] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [disable, setDisable] = useState(false);
  const [accessData, setAccessData] = useState([]);
  const [allTimeZone, setAllTimeZone] = useState([]);
  const [openSidebar, setOpenSideBar] = useState(false);
  const [openUserAddForm, setUserAddForm] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const errors = {};

  const getTimeZone = () => {
    Api(API_types.GET, "TimeZone/GetTimeZone?isActive=true")
      .then((response) => {
        if (response.data) {
          setAllTimeZone(response.data);
        } else {
          setAllTimeZone([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleUserEdit = (anchor, open) => {
    setOpenSideBar({ ...openSidebar, [anchor]: open });
  };

  const toggleUserNext = (anchor, open) => {
    setIsNext({ ...isNext, [anchor]: open });
  };

  const toggleUserCreate = (anchor, open) => {
    setCreateForm({ ...showCreateForm, [anchor]: open });
  };

  const toggleUserAccess = (anchor, open) => {
    setUserAccessForm({ ...openUserAccessForm, [anchor]: open });
  };

  const handleNext = () => {
    setFormErrors(validate(formValues));
    if (Object.values(formErrors).length > 0) {
      return;
    }
    toggleUserEdit("right", false);
    toggleUserNext("right", true);
  };

  const handleCreate = () => {
    setFormErrors(validate(formValues));
    if (Object.values(formErrors).length > 0) {
      return;
    }
    toggleUserNext("right", false);
    toggleUserCreate("right", true);
  };

  const toggleUserAdd = (anchor, open) => {
    setUserAddForm({ ...openUserAddForm, [anchor]: open });
  };

  const getUserRoles = () => {
    Api(
      "GET",
      `RoleData/GetRoles?companyId=${companyData.companyId}&pageNumber=1&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        let userRoles = res.data.data.map(({ roleId, roleName }) => ({
          roleId,
          roleName,
        }));
        setUsersRoles(userRoles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    const params = {
      companyId: companyData.companyId,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      middleName: formValues.middleName,
      nickName: formValues.nickName,
      emails: [formValues.email_id],
      phoneNumbers: [formValues.cell_phone],
      loginID: formValues.login_id,
      title: formValues.user_title,
      timeZone: formValues.timeZone,
      roleId: formValues.roles,
      jobAccessLevel: {
        entityId: formValues.jobAccessCheck,
        entityType: formValues.jobAccess,
      },
    };

    setFormErrors(validate(formValues));
    if (Object.values(formErrors).length > 0) {
      return;
    }

    if (editMode) {
      delete params.emails;
      delete params.loginID;
      delete params.roleId;
      delete params.jobAccessLevel;
      params.userId = usersDataIndi.userId;
      params.emailNotification = formValues.emailNotification;
      params.smsNotification = formValues.smsNotification;

      // EditUser API call
      Api(API_types.PUT, `Users/EditPersonalInformation`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            setIsActive(false);
            getUsersData();
            toast.success(res.data.message);
            setIsActive(params.userId);
            setSideBar(false);
          } else {
            toast.error(res.data.message);
            setSideBar(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setDisable(true);
      // Add User API call
      Api(API_types.POST, `Users/AddUser`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            getUsersData();
            toast.success(res.data.message);
            setIsActive(params.userId);
            toggleUserAdd("right", false);
          } else {
            toast.error(res.data.message);
            setDisable(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleUserAccess = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.values(formErrors).length > 0) {
      return;
    }
    const params = {
      userId: usersDataIndi.userId,
      companyId: companyData.companyId,
      entType: formValues.jobAccess,
      entId: formValues.accessRecords,
      roleId: formValues.userRole,
    };

    if (editMode) {
      params.isNotify = formValues.isNotify;
      params.cardId = formValues.cardId;

      // EditJobManagement API call
      Api(API_types.PUT, `Users/EditAccessManagement`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            setIsActive(false);
            getUsersData();
            toast.success(res.data.message);
            setUserAccessForm(false);
            setIsActive(params.userId);
          } else {
            toast.error(res.data.message);
            setUserAccessForm(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      params.isNotify = true;
      // Add JobManagement API call
      Api(API_types.POST, `Users/AddAccessManagement`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            setIsActive(false);
            getUsersData();
            toast.success(res.data.message);
            setIsActive(params.userId);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setUserAccessForm(false);
  };

  const validate = (values) => {
    if (showSideBar) {
      if (!values.firstName) {
        errors.firstName = validations?.fname?.req;
      }
      if (!values.middleName) {
        errors.middleName = validations?.mname?.req;
      }
      if (!values.lastName) {
        errors.lastName = validations?.lname?.req;
      }
      if (!values.nickName) {
        errors.nickName = validations?.nname?.req;
      }
      if (!values.user_title) {
        errors.user_title = validations?.title?.req;
      }
      if (!values.login_id) {
        errors.login_id = validations?.loginid?.req;
      } else if (values.login_id.length < 4 || values.login_id.length > 5) {
        errors.login_id = validations?.loginid?.custom;
      }
      if (!values.email_id) {
        errors.email_id = validations?.email?.req;
      }
      if (!values.cell_phone) {
        errors.cell_phone = validations?.mobile?.req;
      } else if (isNaN(values.cell_phone)) {
        errors.cell_phone = validations?.mobile?.pattern.message;
      }
      if (!values.timeZone) {
        errors.timeZone = validations?.timezone?.req;
      }
    } else if (isNext) {
      if (values.roles.length == 0) {
        errors.roleName = validations?.urole?.req;
      }
      if (!values.jobAccess) {
        errors.jobAccess = validations?.job_level?.req;
      }
      if (values.jobAccessCheck.length == 0) {
        errors.jobAccessLevel = validations?.facilities?.req;
      }
    } else if (openUserAccessForm) {
      if (!values.jobAccess) {
        errors.jobAccess = validations?.job_level?.req;
      }
      if (!values.accessRecords) {
        errors.accessRecords = validations?.facilities?.req;
      }
      if (!values.userRole) {
        errors.userRole = validations?.urole?.req;
      }
    }

    return errors;
  };

  const setValueFromEdit = (id, type) => {
    if (type == "jobManagement") {
      const listAccess = usersDataIndi.accessManagementDetails.map((items) =>
        items.entityId.find((ent) => (ent.cardId === id ? ent : null))
      );
      let url, entId;
      switch (listAccess[0].entType) {
        case "department":
          url = `Department/GetDepartmentList?companyId=${companyData.companyId}`;
          entId = "departmentId";
          break;
        case "company":
          url = `Companies/GetCompany?parentCompanyId=${companyData.companyId}`;
          entId = "companyId";
          break;
        case "facility":
          url = `Facilities/GetFacilitiesList?companyId=${companyData.companyId}`;
          entId = "facilitiesId";
          break;
        case "job":
          url = "Jobs";
          entId = "jobId";
          break;
      }

      if (
        listAccess[0].entType === "job" ||
        listAccess[0].entType === "none" ||
        listAccess[0].entType === "Select"
      ) {
        setAccessData([]);
        return;
      }

      Api("GET", url + `&companyHirerchy=${companyData.companyHirerchy}`)
        .then((res) => {
          listAccess[0].entType === "department"
            ? setAccessData(
                res.data.map((res) => ({ ["id"]: res[entId], ...res }))
              )
            : setAccessData(
                res.data.data.map((res) => ({ ["id"]: res[entId], ...res }))
              );

          // This condition is for temporary basis untill the job module APIs get prepared
          // The condition for department module is due to the response variables
        })
        .catch((err) => {
          console.log(err);
        });
      setFormValues({
        ["userRole"]: listAccess[0].roleId,
        ["jobAccess"]: listAccess[0].entType,
        ["accessRecords"]: listAccess[0].entId,
        ["cardId"]: listAccess[0].cardId,
        ["entName"]: listAccess[0].entName,
        ["isNotify"]: listAccess[0].isNotify,
      });
    } else {
      setFormValues({
        ["firstName"]: usersDataIndi.firstName,
        ["middleName"]: usersDataIndi.middleName,
        ["nickName"]: usersDataIndi.nickName,
        ["lastName"]: usersDataIndi.lastName,
        ["email_id"]: usersDataIndi.emails[0],
        ["cell_phone"]: usersDataIndi.phoneNumbers[0],
        ["timeZone"]: usersDataIndi.timeZone,
        ["user_title"]: usersDataIndi.title,
        ["login_id"]: usersDataIndi.loginID,
        ["roleName"]: usersDataIndi.user_role,
        ["emailNotification"]: usersDataIndi.emailNotification,
        ["smsNotification"]: usersDataIndi.smsNotification,
      });
    }
    setFormErrors({});
  };

  let navigate = useNavigate();
  const manageJob = (userId) => {
    navigate("managejobs", {
      state: {
        companyId: companyData.companyId,
        companyHirerchy: companyData.companyHirerchy,
        userId: userId,
      },
    });
  };
  const manageNot = (userId) => {
    navigate("notifications", {
      state: {
        companyId: companyData.companyId,
        companyHirerchy: companyData.companyHirerchy,
        userId: userId,
      },
    });
  };

  const showItems = (userId) => {
    setIsActive(userId);
  };

  function editUserInfo(id) {
    setLabel("Edit User");
    toggleUserEdit("right", true);
    setEditActions(null);
    setEditMode(true);
    setValueFromEdit(id, "userInfo");
  }

  useEffect(() => {
    getTimeZone();
    getUsersData(true);
    getUserRoles();
  }, []);

  const getUsersData = (firstSelectDefault) => {
    setIsLoader(true);
    Api(
      API_types.GET,
      `Users/GetUserList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}&OrderbyDirection=desc&OrderBy=updatedAt&pageLimit=12&pageNumber=1`
    )
      .then((response) => {
        if (response.data.data.length) {
          setUsersData(response.data.data);
          if (firstSelectDefault) {
            setIsActive(response.data.data[0].userId);
          }
        } else {
          setUsersData([]);
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  };

  useEffect(() => {
    if (isActive) {
      getData();
    }
  }, [isActive]);

  const getData = async () => {
    try {
      setIsLoader(true);
      const res = await Api(
        API_types.GET,
        `Users/GetUserById?companyId=${companyData.companyId}&userId=${isActive}`
      );
      setUsersDataIndi(res.data);
      setIsLoader(false);
    } catch (e) {
      console.log(e);
    }
  };

  const isActiveUser = (id, setActive) => {
    const params = {
      userID: id,
      isActive: !setActive,
    };

    Api(
      "PATCH",
      `Users/UserActiveInActive?companyId=${companyData.companyId}&userId=${params.userID}&isActive=${params.isActive}`
    )
      .then((res) => {
        if (res.data.isSuccess == true) {
          toast.success(res.data.message);
          setIsActive(false);
          getUsersData();
          setIsActive(params.userID);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editJobAccess = (id) => {
    setLabel("Edit Job Management");
    toggleUserAccess("right", true);
    setEditActions(null);
    setEditMode(true);
    setValueFromEdit(id, "jobManagement");
  };

  const deleteJobAccess = (id) => {
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      Api(
        API_types.DELETE,
        `Users/DeleteAccessManagement?userId=${usersDataIndi.userId}&cardId=${id}`
      )
        .then((res) => {
          if (res.data.isSuccess) {
            setIsActive(false);
            getUsersData();
            toast.success(res.data.message);
            setIsActive(usersDataIndi.userId);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader inShow={showLoader} />

      <AddUser
        openSidebar={openUserAddForm}
        toggleDrawer={toggleUserAdd}
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        allTimeZone={allTimeZone}
        usersRoles={usersRoles}
        setFormErrors={setFormErrors}
        handleFormSubmit={handleSubmit}
      />

      <EditUserInfo
        formValues={formValues}
        setFormValues={setFormValues}
        openSidebar={openSidebar}
        handleNext={handleNext}
        formErrors={formErrors}
        editMode={editMode}
        handleSubmit={handleSubmit}
        label={label}
        toggleDrawer={toggleUserEdit}
        allTimeZone={allTimeZone}
      />

      {isNext ? (
        <UserNext
          formValues={formValues}
          setFormValues={setFormValues}
          openSidebar={isNext}
          formErrors={formErrors}
          handleCreate={handleCreate}
          usersRoles={usersRoles}
          companyData={companyData}
          label={label}
          toggleDrawer={toggleUserNext}
        />
      ) : null}

      {showCreateForm ? (
        <UserOverview
          formValues={formValues}
          toggleDrawer={toggleUserCreate}
          openSidebar={showCreateForm}
          handleSubmit={handleSubmit}
          label={label}
          usersRoles={usersRoles}
          disable={disable}
        />
      ) : null}

      {openUserAccessForm ? (
        <UserAccess
          toggleDrawer={toggleUserAccess}
          openSidebar={openUserAccessForm}
          selectedUserRoles={usersDataIndi.roleDetails}
          companyData={companyData}
          handleUserAccess={handleUserAccess}
          formValues={formValues}
          editMode={editMode}
          setFormValues={setFormValues}
          label={label}
          accessRecordsData={accessData}
          formErrors={formErrors}
        />
      ) : null}

      <div
        className={
          isCompany === true ? "user-container company" : "user-container"
        }
      >
        <div className="w-100 h-100 position-relative d-flex gap-20">
          <Loader inShow={isLoader} />
          <div className="user-list">
            <div className="inner-h">
              {usersData.length > 0 ? (
                usersData.map((users) => (
                  <div
                    className={
                      isActive === users.userId
                        ? "user-info active"
                        : "user-info"
                    }
                    key={users.userId}
                    onClick={() => {
                      showItems(users.userId);
                    }}
                  >
                    <div className="user-header">
                      {users.active ? (
                        <img
                          src={
                            require("../../../../assets/images/user-info-selected.svg")
                              .default
                          }
                          alt="nav-icon"
                          className="icon profile-icon"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../../assets/images/user-info-default.svg")
                              .default
                          }
                          alt="nav-icon"
                          className="icon profile-icon"
                        />
                      )}
                      <span>{users.firstName + " " + users.lastName}</span>
                    </div>
                    <p>
                      {users.roleDetails?.map((roles, i, row) => {
                        if (i + 1 === row.length) {
                          return roles.roleName;
                        } else {
                          return roles.roleName + ", ";
                        }
                      })}
                    </p>
                  </div>
                ))
              ) : (
                <NoRecordsFound />
              )}
            </div>
            <div className="bottom-btn">
              <ButtonUI
                p="12px"
                mt="20px"
                ml="8px"
                width="300px"
                leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
                onClick={() => (
                  setSideBar(true),
                  setLabel("Add New User"),
                  setFormErrors(initialValues),
                  setFormValues(initialValues),
                  toggleUserAdd("right", true),
                  setEditMode(false)
                )}
              >
                Add New User
              </ButtonUI>
            </div>
          </div>
          {isActive ? (
            <div className="user-detail position-relative">
              <div className="top-header-user">
                <span className="main-name">
                  {usersDataIndi.firstName + " " + usersDataIndi.lastName}
                </span>
                <span className="check-status">Active</span>
                <FormControlLabel
                  control={
                    <Android12Switch
                      checked={usersDataIndi.isActive ?? false}
                      onChange={() =>
                        isActiveUser(
                          usersDataIndi.userId,
                          usersDataIndi.isActive
                        )
                      }
                    />
                  }
                />
              </div>
              <div className="personal-info">
                <div className="d-flex justify-content-between mb-15">
                  <span className="main-head">Personal Information</span>
                  <div className="position-relative">
                    <EditDeleteMenu
                      id={usersDataIndi.userId}
                      onEdit={editUserInfo}
                      deleteOption={false}
                    />
                  </div>
                </div>
                <div className="personal-data">
                  <div className="p-entity">
                    <OutlinedEmailIcon
                      width="20"
                      height="16"
                      className="p-icon"
                    />
                    <div>
                      <p className="p-label">Email Address</p>
                      <p className="p-data">
                        {usersDataIndi?.emails.join(", ")}
                      </p>
                    </div>
                  </div>
                  <div className="p-entity">
                    <CellPhoneIcon width="14" height="22" className="p-icon" />
                    <div>
                      <p className="p-label">Cell Phone</p>
                      <p className="p-data">
                        {usersDataIndi?.phoneNumbers.join(", ")}
                      </p>
                    </div>
                  </div>
                  <div className="p-entity">
                    <img
                      src={
                        require("../../../../assets/images/personal-time.svg")
                          .default
                      }
                      alt="nav-icon"
                      className="p-icons"
                    />
                    <div>
                      <p className="p-label">Time Zone</p>
                      <p className="p-data">{usersDataIndi.timeZone}</p>
                    </div>
                  </div>
                  <div className="p-entity">
                    <img
                      src={
                        require("../../../../assets/images/User.svg").default
                      }
                      alt="nav-icon"
                      className="p-icons"
                    />
                    <div>
                      <p className="p-label">Title</p>
                      <p className="p-data">{usersDataIndi.title}</p>
                    </div>
                  </div>
                  <div className="p-entity">
                    <img
                      src={
                        require("../../../../assets/images/User.svg").default
                      }
                      alt="nav-icon"
                      className="p-icons"
                    />
                    <div>
                      <p className="p-label">Login ID</p>
                      <p className="p-data">{usersDataIndi.loginID}</p>
                    </div>
                  </div>
                  <div className="p-entity">
                    <img
                      src={
                        require("../../../../assets/images/User.svg").default
                      }
                      alt="nav-icon"
                      className="p-icons"
                    />
                    <div>
                      <p className="p-label">Nick Name</p>
                      <p className="p-data">{usersDataIndi.nickName}</p>
                    </div>
                  </div>
                </div>
              </div>
              <Roles
                getUsersData={getUsersData}
                usersRoles={usersRoles}
                setUsersRoles={setUsersRoles}
                usersDataIndi={usersDataIndi}
                setIsActive={setIsActive}
              />
              <div className="job-management">
                <div className="d-flex justify-content-between mb-15">
                  <span className="main-head">Job Management</span>
                  <img
                    src={
                      require("../../../../assets/images/add-user-roles.svg")
                        .default
                    }
                    alt=""
                    className="cursor-pointer"
                    onClick={() => (
                      toggleUserAccess("right", true),
                      setEditMode(false),
                      setLabel("Add Job Management"),
                      setFormErrors(initialValues),
                      setFormValues(initialValues)
                    )}
                  />
                </div>
                <div className="job-container overflow-unset">
                  <div className="w-100">
                    {usersDataIndi.accessManagementDetails?.map((items) =>
                      // no access management details found so its pending right now
                      items.entityId?.map((ent) => (
                        <div className="job-tabs w-100" key={ent.cardId}>
                          <img
                            src={localStorage.getItem("companyLogoPath")}
                            alt=""
                            className="cursor-pointer"
                          />
                          <div className="job-in-name w-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <p>{localStorage.getItem("companyName")}</p>
                              <EditDeleteMenu
                                id={ent.cardId}
                                onEdit={editJobAccess}
                                onDelete={deleteJobAccess}
                              />
                            </div>
                            <div className="job-content">
                              <span>
                                User Role: <strong>{ent.roleName}</strong>
                              </span>
                              <span>
                                Notify:{" "}
                                <strong>{ent.isNotify ? "Yes" : "No"}</strong>
                              </span>
                              <span>
                                Level: <strong>{ent.entType}</strong>
                              </span>
                              <span>
                                Name: <strong>{ent.entName}</strong>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className="add-btn manage-btn"
                  onClick={() => manageNot(usersDataIndi.userId)}
                >
                  Manage Notifications
                  <img
                    src={require("../../../../assets/images/Vector.png")}
                    alt="right-arrow"
                    className="right-arrow-btn"
                  />
                </button>
                <button
                  className="add-btn manage-btn"
                  onClick={() => manageJob(usersDataIndi.userId)}
                >
                  Manage Job Assignments
                  <img
                    src={require("../../../../assets/images/Vector.png")}
                    alt="right-arrow"
                    className="right-arrow-btn"
                  />
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
