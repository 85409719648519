import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import moment from "moment";
import { indigo } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";

export const AcceptPreHireForm = ({
  toggleForm,
  openForm,
  applicantId,
  selectedSlots,
  acceptPhysicalPPD,
}) => {
  const [slots, setSlots] = useState([]);
  const [value, setValue] = useState(selectedSlots[0]);

  useEffect(() => {
    setSlots(selectedSlots);
  }, [selectedSlots]);

  const handleSubmit = (e) => {
    e.preventDefault();
    acceptPhysicalPPD(value);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      title="Accept PreHire Form"
      handleSubmit={handleSubmit}
      applicantId={applicantId}
    >
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          className="ml-10 mt-10"
        >
          {slots.map((slot, index) => (
            <FormControlLabel
              key={index}
              style={{
                paddingTop: 10,
              }}
              value={slot.slots}
              control={
                <Radio
                  style={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                />
              }
              label={moment(slot.slots).format("h:mm a, MMM Do, YYYY")}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </AddEditCol>
  );
};
