import { useLocation, Navigate, Outlet } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";
import jwt_decode from "jwt-decode";

const RequireAuth = () => {
    // const { auth } = useAuth();
    const location = useLocation();
    const token = localStorage.getItem('token');
    const decodedToken = jwt_decode(token);
    const dateNow = new Date();
    let isValid = true;
    
    if(decodedToken.exp * 1000  < dateNow.getTime()){
        isValid = false;
        localStorage.clear();
    }else{
        isValid = true;
    }
        
    return (
            // auth?.user
            token && isValid
                ? <Outlet />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;