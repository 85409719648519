import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';

// pagination style
export const StyledPagination = styled(Pagination)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  minHeight: "48px",
  ul: {
    '& .MuiPaginationItem-root': {
       '&.Mui-selected': {
         background: '#3a54a6',
         color: '#fff',
        //  borderRadius: '50%',
       },
    },
  },
}));