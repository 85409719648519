export const LocationIcon = ({...props}) => {
    return (
        <svg {...props} viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M9 11.75C10.6569 11.75 12 10.4069 12 8.75C12 7.09315 10.6569 5.75 9 5.75C7.34315 5.75 6 7.09315 6 8.75C6 10.4069 7.34315 11.75 9 11.75Z" 
                stroke="#3954A5" 
                strokeWidth="1.5"
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M16.5 8.75C16.5 15.5 9 20.75 9 20.75C9 20.75 1.5 15.5 1.5 8.75C1.5 6.76088 2.29018 4.85322 3.6967 3.4467C5.10322 2.04018 7.01088 1.25 9 1.25C10.9891 1.25 12.8968 2.04018 14.3033 3.4467C15.7098 4.85322 16.5 6.76088 16.5 8.75Z" 
                stroke="#3954A5" 
                strokeWidth="1.5"
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    );
}
