export const MonitorIcon = ({ ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 20.25H9C8.80109 20.25 8.61032 20.329 8.46967 20.4697C8.32902 20.6103 8.25 20.8011 8.25 21C8.25 21.1989 8.32902 21.3897 8.46967 21.5303C8.61032 21.671 8.80109 21.75 9 21.75H15C15.1989 21.75 15.3897 21.671 15.5303 21.5303C15.671 21.3897 15.75 21.1989 15.75 21C15.75 20.8011 15.671 20.6103 15.5303 20.4697C15.3897 20.329 15.1989 20.25 15 20.25Z"
        fill="#783EFD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 3.75H19.5C20.7426 3.75 21.75 4.75736 21.75 6V16.5C21.75 17.7426 20.7426 18.75 19.5 18.75H4.5C3.25736 18.75 2.25 17.7426 2.25 16.5V6C2.25 4.75736 3.25736 3.75 4.5 3.75ZM15.1685 11.8246C14.9368 12.4705 14.5142 13.0186 13.9646 13.3861C14.9217 13.7081 15.7727 14.3144 16.4176 15.1339C16.4606 15.1886 16.4882 15.2552 16.497 15.326C16.5057 15.3968 16.4954 15.4687 16.4671 15.5334C16.4388 15.5981 16.3937 15.6529 16.3372 15.6913C16.2807 15.7296 16.2151 15.75 16.1482 15.75L8.60185 15.75C8.53485 15.75 8.46925 15.7296 8.41276 15.6912C8.35626 15.6529 8.31122 15.5981 8.28292 15.5334C8.25463 15.4687 8.24426 15.3967 8.25303 15.326C8.2618 15.2552 8.28935 15.1886 8.33245 15.1339C8.97734 14.3144 9.82833 13.7081 10.7854 13.3861C10.2358 13.0186 9.81317 12.4705 9.58147 11.8246C9.34977 11.1787 9.32151 10.47 9.50097 9.80551C9.68042 9.14099 10.0579 8.55669 10.5763 8.1408C11.0947 7.72492 11.7261 7.5 12.375 7.5C13.0239 7.5 13.6552 7.72492 14.1737 8.1408C14.6921 8.55669 15.0696 9.14099 15.249 9.80551C15.4285 10.47 15.4002 11.1787 15.1685 11.8246Z"
        fill="#783EFD"
      />
    </svg>
  );
};
