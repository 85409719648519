import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { ButtonUI } from "../../../../../../ui/button";
import { AddDocPackForm } from "./addDocPackForm";
import { SendOnboardingPackForm } from "./sendPackForm";
import { CancelDocPackForm } from "../PreHire/cancelDocPackForm";
import { AddOnboardDocForm } from "./addOnboardDocForm";
import { validations } from "../../../../../../../helper/validations";
import moment from "moment";
import fileDownload from "js-file-download";
import axios from "axios";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const OnBoarding = ({
  selectedSuitCase,
  applicantName,
  applicantId,
}) => {
  const [openOnboardForm, setOnboardForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [openSendDocPackForm, setSendDocPackForm] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [docPackList, setDocPackList] = useState([]);
  const [id, setId] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [openCancelForm, setCancelForm] = useState(false);
  const [openAddDocForm, setAddDocForm] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const formData = new FormData();
  const errors = {};

  useEffect(() => {
    getOnboardingDocPackList();
  }, []);

  const toggleOnboardForm = (open) => {
    setOnboardForm(open);
  };

  const getOnboardingDocPackList = () => {
    Api(
      "GET",
      `Job/OnboardingList?companyId=${companyData.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setDocPackList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleSendPackForm = (open) => {
    setSendDocPackForm(open);
  };

  const toggleCancelForm = (open) => {
    setCancelForm(open);
  };

  const handleSendPack = (comment) => {
    const params = {
      onboardingId: docPackList[0].onboardingId,
      messageToApplicant: comment,
    };

    Api("POST", `Job/SendOnboardingDocPack`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleSendPackForm(false);
          getOnboardingDocPackList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCommonIcon = (flag, comment) => {
    const params = {
      onboardingId: docPackList[0].onboardingId,
      documentId: documentId,
      comments: comment,
      flag: flag,
    };

    Api("POST", `Job/OnboardingDocPackIcons`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleCancelForm(false);
          getOnboardingDocPackList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveDocPack = (docPackList) => {
    const params = {
      companyId: companyData.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantId,
      documentPackId: "",
      onboardingDocumentList: docPackList,
    };

    Api("POST", `Job/SaveOnboardingDocument`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleOnboardForm(false);
          getOnboardingDocPackList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAcceptDoc = () => {
    var answer = window.confirm("Are you sure ?");
    if (answer) {
      handleCommonIcon(1);
    }
  };

  const handleDeleteDoc = () => {
    var answer = window.confirm(
      "Are you sure you want to delete this record ?"
    );
    if (answer) {
      handleCommonIcon(3);
    }
  };

  const cancleDocPack = () => {
    handleCommonIcon(2);
  };

  const toggleAddDocForm = (open) => {
    setAddDocForm(open);
  };

  const saveDocument = () => {
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    formData.append("onboardingId", docPackList[0].onboardingId);
    formData.append(
      "SelectedOnboardingDocList",
      formValues?.SelectedDocLists ?? ""
    );
    formData.append("UploadDocLists", formValues?.UploadDocLists ?? {});

    Api("POST", `Job/SaveExistingOnboardingDocument`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAddDocForm(false);
          getOnboardingDocPackList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Validations
  const validate = (values) => {
    if (!values.SelectedDocLists && !values.UploadDocLists) {
      errors.document = validations?.document?.req;
    }

    return errors;
  };

  const handleDownload = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "OB_" + url.split("/").at(-1).substring(24, 50));
      });
  };

  return (
    <div>
      <AddDocPackForm
        openForm={openOnboardForm}
        toggleForm={toggleOnboardForm}
        applicantId={applicantId}
        saveDocPack={saveDocPack}
      />

      <SendOnboardingPackForm
        openForm={openSendDocPackForm}
        toggleForm={toggleSendPackForm}
        applicantId={applicantId}
        handleSendPack={handleSendPack}
      />

      <CancelDocPackForm
        openForm={openCancelForm}
        toggleForm={toggleCancelForm}
        applicantId={applicantId}
        cancleDocPack={cancleDocPack}
      />

      <AddOnboardDocForm
        openForm={openAddDocForm}
        toggleForm={toggleAddDocForm}
        applicantId={applicantId}
        saveDocument={saveDocument}
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
      />

      {docPackList.length > 0 ? (
        <ButtonUI
          p="9px"
          mt="10px"
          ml="65px"
          mb="10px"
          onClick={() => toggleSendPackForm(true)}
        >
          Send Onboarding Pack
        </ButtonUI>
      ) : (
        <ButtonUI
          p="9px"
          mt="10px"
          ml="65px"
          mb="10px"
          onClick={() => toggleOnboardForm(true)}
        >
          Create Onboarding Pack
        </ButtonUI>
      )}
      <div className="profile-data mt-10">
        {docPackList[0]?.onboardingDocPackList?.map((doc, index) => (
          <div className="section border-bottom" key={index}>
            <div className="mt-5">
              <strong>
                {doc.documentUrl.split("/").at(-1).substring(24, 50)}
              </strong>
              <span className="status blue-completed">
                {doc?.documentStatus?.split("_")[0]}
              </span>
            </div>

            <span className="scheduled">
              <label>
                <strong>Last Viewed :</strong>{" "}
                {moment(doc.lastViewed).format("m/DD/YYYY @ h:MM A")}
              </label>
            </span>
            <span className="mt-5 d-flex mb-5">
              <ButtonUI
                variant="outlined"
                fontSize="12px"
                borderColor="#34AA44"
                color="#34AA44"
                mr="6px"
                pr="0px"
                onClick={() => (
                  handleAcceptDoc(),
                  setId(docPackList[0].preHireDocumentPackId),
                  setDocumentId(doc.documentId)
                )}
                rightIcon={<ChevronRightIcon fontSize="small" />}
              >
                Accept
              </ButtonUI>
              <ButtonUI
                variant="outlined"
                fontSize="12px"
                borderColor="#E6492D"
                color="#E6492D"
                mr="6px"
                pr="0px"
                onClick={() => (
                  toggleCancelForm(true),
                  setId(docPackList[0].preHireRequestId),
                  setDocumentId(doc.documentId)
                )}
                rightIcon={<ChevronRightIcon fontSize="small" />}
              >
                Cancel
              </ButtonUI>
              <ButtonUI
                variant="outlined"
                fontSize="12px"
                borderColor="#B80000"
                color="#B80000"
                mr="6px"
                pr="0px"
                onClick={() => (
                  handleDeleteDoc(),
                  setId(docPackList[0].preHireDocumentPackId),
                  setDocumentId(doc.documentId)
                )}
                rightIcon={<ChevronRightIcon fontSize="small" />}
              >
                Delete
              </ButtonUI>

              {doc?.documentStatus !== "ACCEPTED_PREHIRE" && (
                <ButtonUI
                  variant="outlined"
                  fontSize="12px"
                  color="#3954A5"
                  mr="6px"
                pr="0px"
                  onClick={() => handleDownload(doc.documentUrl)}
                  rightIcon={<ChevronRightIcon fontSize="small" />}
                >
                  Download
                </ButtonUI>
              )}
            </span>
          </div>
        ))}
        {docPackList.length > 0 && (
          <div className="text-center pointer">
            <ButtonUI
              p="7px"
              mt="10px"
              radius="6px"
              color="#1F68D1"
              borderColor="#1F68D1"
              variant="outlined"
              ml="80px"
              onClick={() => (
                toggleAddDocForm(true), setFormValues({}), setFormErrors({})
              )}
              rightIcon={<AddCircleOutlineOutlined fontSize="small" />}
            >
              Add Documents
            </ButtonUI>
          </div>
        )}
      </div>
    </div>
  );
};
