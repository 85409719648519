import NavigationBar from "../../../components/common/navigationBar/NavigationBar";
import { Sidebar } from "../../../components/common/sideBar/SideBar";

export function AdminLayout({ ...props }) {
  return (
    <div className="h-100">
      <NavigationBar />
      <Sidebar />
      <>{props.children}</>
    </div>
  );
}
