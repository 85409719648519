import { Applicant } from "../../views/company/applicant/index";
import { Hires } from "../../views/company/hires";
import { HotList } from "../../views/company/hotList";
import { CompanyApplicantDetails } from "../../views/company/applicant/ApplicantDetails";
import { Dashboard } from "../../views/company/dashboard";
import { Calendar } from "../../views/company/calendar";
import { CompanyJobs } from "../../views/company/jobs";
import { CompanyJobDetails } from "../../views/company/jobs/details";
import { CompanyMorePositions } from "../../views/company/applicant/morePositions";
import { CompanyUsers } from "../../views/company/users";
import { CompanyManageJobs } from "../../views/company/users/manageJobs";
import { CompanyManageNotifications } from "../../views/company/users/manageNotifications";
import { Reports } from "../../views/company/reports";
import { TimeToHire } from "../../views/company/reports/timeToHire";
import { HireReport } from "../../views/company/reports/hireReport";
import { JobReport } from "../../views/company/reports/jobReport";
import { ApplicantSourceReport } from "../../views/company/reports/applicantSourceReport";

export const companyRoutes = [
  { path: "/dashboard_new", element: <Dashboard /> },
  { path: "/calendar", element: <Calendar /> },

  { path: "/applicants", element: <Applicant /> },
  { path: "/applicants/applicant-details", element: <CompanyApplicantDetails />,},
  { path: "/applicants/more-positions", element: <CompanyMorePositions /> },

  { path: "/hires", element: <Hires /> },
  { path: "/hires/applicant-details", element: <CompanyApplicantDetails /> },
  { path: "/hires/more-positions", element: <CompanyMorePositions /> },

  { path: "/hot-list", element: <HotList /> },
  { path: "/hot-list/applicant-details", element: <CompanyApplicantDetails /> },
  { path: "/hot-list/more-positions", element: <CompanyMorePositions /> },

  { path: "/all-jobs", element: <CompanyJobs /> },
  { path: "/all-jobs/details", element: <CompanyJobDetails /> },
  { path: "/all-jobs/applicant-details", element: <CompanyApplicantDetails /> },
  { path: "/all-jobs/more-positions", element: <CompanyMorePositions /> },

  { path: "/all-users", element: <CompanyUsers /> },
  { path: "/all-users/managejobs", element: <CompanyManageJobs /> },
  { path: "/all-users/notifications", element: <CompanyManageNotifications /> },

  { path: "/reports", element: <Reports /> },
  { path: "/reports/time-to-hire", element: <TimeToHire /> },
  { path: "/reports/hire", element: <HireReport /> },
  { path: "/reports/job", element: <JobReport /> },
  { path: "/reports/applicant-source", element: <ApplicantSourceReport /> },
];
