import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { MultiStepCol } from "../../../../../addEditPopup/multiStepCol";
import { CheckboxList } from "../../../../CheckboxList";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

export const CreateAptForm = ({
  openForm,
  toggleForm,
  activeStep,
  setActiveStep,
  formValues,
  setFormValues,
  applicantId,
  jobId,
  savePreHireForm,
}) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [appoiontment, setAppointment] = useState({});
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [allParticipants, setAllParticipants] = useState([]);
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [participantsObj, setParticipantsObj] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const date = new Date();
  const tomorrow = new Date().setDate(date.getDate() + 1);
  const dayAfterTomorrow = new Date().setDate(date.getDate() + 2);
  const [locationName, setLocationName] = useState([]);
  const [participantNames, setParticipantNames] = useState([]);

  useEffect(() => {
    Api(
      "GET",
      `Appointments/GetAppointmentsList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        const data = [...res.data.data];
        const apt = data.find((rec) => rec.keyName === "Physical_PPD");
        setAppointment(apt);
      })
      .catch((err) => console.log(err));

    Api(
      "GET",
      `Facilities/GetFacilitiesList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        setFacilitiesList(res.data.data);
      })
      .catch((err) => console.log(err));

    Api("GET", `Job/GetParticipantUserList?companyId=${companyData.companyId}`)
      .then((res) => {
        const data = [...res.data];
        data.map((rec) => {
          rec.id = rec.guestUserId;
          rec.checked = false;
          rec.name = rec.fullName;
        });
        setAllParticipants(data);
      })
      .catch((err) => {
        console.log(err);
      });

    const params = {
      companyId: companyData.companyId,
      jobId: jobId,
      applicantId: applicantId,
      offset: new Date().getTimezoneOffset(),
    };
    Api("POST", `Job/GetPreHireSlot`, params)
      .then((res) => {
        setAllTimeSlots(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleNext = () => {
    // console.log(formValues);
    // console.log(activeStep);
    // console.log("handle clicked");
  };

  const handleSubmit = () => {
    savePreHireForm();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // On selecting the facility (location)
  const handleRadioChange = (e) => {
    const facility = facilitiesList.find(
      (rec) => rec.facilitiesId === e.target.value
    );
    setLocationName(facility.name);
    setFormValues({ ...formValues, ["facilities"]: [e.target.value] });
  };

  // On selecting the participants
  const handleCheckboxChange = (checkName, isChecked) => {
    const partiName = [];
    const data = [...allParticipants];
    data.map((rec) => {
      if (rec.id === checkName) {
        rec.checked = isChecked;
      }
    });
    setAllParticipants(data);
    const users = [];
    data.filter((d) => {
      if (d.checked === true) {
        users.push(d.id);
        partiName.push(d.name);
      }
    });
    setParticipantNames(partiName);
    setFormValues({ ...formValues, ["participants"]: users });
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const data = [...participantsObj];
    data.at(index)[name] = value;
    setParticipantsObj(data);
    setFormValues({ ...formValues, ["participantList"]: data });
  };

  const handleAddBox = (e) => {
    e.preventDefault();
    setParticipantsObj([
      ...participantsObj,
      {
        firstName: "",
        lastName: "",
        title: "",
        emailAddress: "",
      },
    ]);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...participantsObj];
    list.splice(index, 1);
    setParticipantsObj(list);
    setFormValues({ ...formValues, ["participantList"]: list });
  };

  function selectSlots(slots) {
    const data = [...selectedSlots];
    if (data.includes(slots)) {
      data.splice(data.indexOf(slots), 1);
    } else {
      data.push(slots);
    }
    setSelectedSlots(data);
    setFormValues({ ...formValues, ["suggestedSlots"]: data });
  }

  return (
    <MultiStepCol
      openForm={openForm}
      toggleForm={toggleForm}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleSubmit={handleSubmit}
      handleNext={handleNext}
      applicantId={applicantId}
      title="PreHire Form"
      steps="4"
    >
      {activeStep === 0 && (
        <>
          <div className="forms-input">
            <label>Appointment Type:</label>
            <select
              onChange={handleChange}
              name="appoinmentType"
              value={formValues.appointment}
            >
              <option value="">Select</option>
              <option value={appoiontment.appointmentId}>
                {appoiontment.name}
              </option>
            </select>
          </div>

          <div className="forms-input">
            <label>Duration(minutes) :</label>
            <input
              type="text"
              name="duration"
              placeholder="Duartion"
              onChange={handleChange}
              value={formValues.duration}
            />
          </div>

          <div className="forms-input">
            <label>Select Location:</label>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={formValues.location}
                onChange={handleRadioChange}
              >
                {facilitiesList.map((facility, index) => (
                  <FormControlLabel
                    value={facility.facilitiesId}
                    key={index}
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          "&.Mui-checked": {
                            color: indigo[600],
                          },
                        }}
                      />
                    }
                    label={facility.name}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>

          <div className="forms-input">
            <label>Notes/Instructions :</label>
            <textarea
              placeholder="Notes"
              onChange={handleChange}
              value={formValues.notes}
              name="instructions"
            />
          </div>
        </>
      )}

      {activeStep === 1 && (
        <>
          <h3>Select Participants</h3>
          <CheckboxList
            allData={allParticipants}
            setAllData={setAllParticipants}
            showSaveContainer={false}
            handleChange={handleCheckboxChange}
          />
          {participantsObj.map((participant, index) => (
            <div className="forms-input" key={index}>
              <div className="add-upload-container" key={1}>
                <div className="text-right">
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveClick(index)}
                  >
                    <CloseIcon color="primary" fontSize="small" />
                  </IconButton>
                </div>
                <div className="forms-input sections">
                  <div className="col-2">
                    <label>First Name:</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      id="firstName"
                      value={participant.firstName}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>

                  <div className="col-2">
                    <label>Last Name:</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      id="lastName"
                      value={participant.lastName}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>
                </div>

                <div className="forms-input sections">
                  <div className="col-2">
                    <label>Title:</label>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      id="title"
                      value={participant.title}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>

                  <div className="col-2">
                    <label>Email Address:</label>
                    <input
                      type="text"
                      placeholder="Email Address"
                      name="emailAddress"
                      id="emailAddress"
                      value={participant.email}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <span className="participants pointer" onClick={handleAddBox}>
            <AddIcon fontSize="small" /> Add Participant
          </span>
        </>
      )}

      {activeStep === 2 && (
        <>
          {allTimeSlots?.timeSlotsCurrent?.length > 0 &&
          allTimeSlots?.timeSlotTomorrow?.length > 0 &&
          allTimeSlots?.timeSlotDayAfterTomorrow?.length > 0 ? (
            <>
              <div className="time-slots">
                <span>Date: {moment(date).format("dddd, MMMM DD")}</span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotsCurrent?.map((slot) => (
                    <span
                      className={
                        slot.isDisabled === false
                          ? selectedSlots.includes(slot.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() => selectSlots(slot.timeSlot)}
                    >
                      {slot.isDisabled === false && (
                        <>{moment(slot.timeSlot).format("h:mm a")}</>
                      )}
                    </span>
                  ))}
                </div>
              </div>

              <div className="time-slots mt-20">
                <span>Date: {moment(tomorrow).format("dddd, MMMM DD")}</span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotTomorrow?.map((slot) => (
                    <span
                      className={
                        slot.isDisabled === false
                          ? selectedSlots.includes(slot.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() => selectSlots(slot.timeSlot)}
                    >
                      {moment(slot.timeSlot).format("h:mm a")}
                    </span>
                  ))}
                </div>
              </div>

              <div className="time-slots mt-20">
                <span>
                  Date: {moment(dayAfterTomorrow).format("dddd, MMMM DD")}
                </span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotDayAfterTomorrow?.map((slot) => (
                    <span
                      className={
                        slot.isDisabled === false
                          ? selectedSlots.includes(slot.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() => selectSlots(slot.timeSlot)}
                    >
                      {moment(slot.timeSlot).format("h:mm a")}
                    </span>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <span>
              No slots found ! Please check the system settings for slots
              availability !
            </span>
          )}
        </>
      )}

      {activeStep === 3 && (
        <>
          <div className="forms-inputfill">
            <label className="note-label">
              Review the following user information and click Create.
            </label>
          </div>
          <div className="forms-inputfill">
            <label className="company_label">
              {localStorage.getItem("companyName")}
            </label>
          </div>
          <div className="forms-inputfill">
            <label>Appointment Type:</label>
            <span>Physical / PPD</span>
          </div>
          <div className="forms-inputfill">
            <label>Duration:</label>
            <span>{formValues?.duration} minutes</span>
          </div>
          <div className="forms-inputfill">
            <label>Location:</label>
            <span>{locationName}</span>
          </div>
          <div className="forms-inputfill">
            <label>Notes/Instructions:</label>
            <span>{formValues?.notes}</span>
          </div>
          <div className="forms-inputfill">
            <label>Interviewers:</label>
            <span>{participantNames.join(", ")}</span>
          </div>
          <div className="forms-inputfill">
            <label>Selected dates and Times:</label>
            <span>{selectedSlots.join(", ")}</span>
          </div>
        </>
      )}
    </MultiStepCol>
  );
};
