import { IconButton } from "@mui/material";
import { ButtonUI } from "../../ui/button";
import CloseIcon from "@mui/icons-material/Close";
import { createPortal } from "react-dom";

export const AddEditCol = (props) => {
  const domNode = document?.getElementById(props.applicantId) || "";

  return (
    <>
      {domNode && props.openForm === true
        ? createPortal(
            <div
              className="col-form-container"
              id={"form_" + props.applicantId}
            >
              <header className="header">
                <span>{props.title}</span>
                <IconButton
                  onClick={() => props.toggleForm(false)}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </header>

              <form className="innerContainer">
                <div className="inForms">{props.children}</div>
              </form>
              <div className="btnContainer">
                <ButtonUI p="10px" onClick={props.handleSubmit}>
                  Submit
                </ButtonUI>
                <ButtonUI
                  p="10px"
                  variant="outlined"
                  className="header close"
                  onClick={() => props.toggleForm(false)}
                >
                  Cancel
                </ButtonUI>
              </div>
            </div>,
            domNode
          )
        : null}
    </>
  );
};
