import { ApplicantInfo } from "../../../components/common/layouts/applicantInfo";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const CompanyApplicantDetails = () => {
  const [breadCrumb, setBreadCrumb] = useState("");
  const [url, setUrl] = useState("");
  const { applicantData } = useSelector((state) => state.applicantDataReducer);
  const { companyData } = useSelector((state) => state.companyDataReducer);

  useEffect(() => {
    if (window.location.href.indexOf("job") > -1) {
      setBreadCrumb("Jobs");
      setUrl("/all-jobs");
    } else if (window.location.href.indexOf("hires") > -1) {
      setBreadCrumb("Hires");
      setUrl("/hires");
    } else if (window.location.href.indexOf("hot-list") > -1) {
      setBreadCrumb("Hot List");
      setUrl("/hot-list");
    } else if (window.location.href.indexOf("applicant") > -1) {
      setBreadCrumb("Applicants");
      setUrl("/applicants");
    }
  }, []);

  return (
    <div>
      <div className="company_content help-container applicants">
        <div className="top-url">
          <p>
            <Link to={url} state={companyData}>
              {breadCrumb}
            </Link>{" "}
            &gt;
            <strong>
              {" "}
              {applicantData.firstName + " " + applicantData.lastName}
            </strong>
          </p>
        </div>

        <div className="common-container users company">
          <div className="inner-container company">
            <ApplicantInfo isCompany={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
