export const JobIcon = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34949 1.51953C7.70646 1.51953 7.15942 1.98535 7.00586 2.6132H12.1692C12.0157 1.98535 11.4686 1.51953 10.8256 1.51953H8.34949ZM13.6282 2.61264H15.5285C17.5439 2.61264 19.1851 4.34428 19.1851 6.47085C19.1851 6.47085 19.1275 7.3711 19.1083 8.62477C19.1064 8.72401 19.0603 8.82122 18.9845 8.87996C18.5228 9.2354 18.1006 9.52907 18.0622 9.54932C16.469 10.6632 14.6177 11.447 12.6454 11.8369C12.5168 11.8632 12.3902 11.7934 12.3249 11.6739C11.7721 10.6754 10.7394 10.0253 9.58773 10.0253C8.44373 10.0253 7.40146 10.6683 6.83234 11.6678C6.76612 11.7853 6.64135 11.8531 6.51371 11.8278C4.55777 11.4369 2.70645 10.6541 1.12289 9.55945L0.201544 8.89109C0.124765 8.84046 0.0767786 8.74932 0.0767786 8.64806C0.0479866 8.13161 0 6.47085 0 6.47085C0 4.34428 1.64114 2.61264 3.65658 2.61264H5.54726C5.7296 1.1443 6.91008 0 8.34968 0H10.8258C12.2654 0 13.4459 1.1443 13.6282 2.61264ZM18.8585 10.8148L18.8201 10.8351C16.8814 12.192 14.5493 13.0933 12.102 13.468C11.7565 13.5186 11.411 13.2857 11.315 12.9211C11.1038 12.0908 10.4224 11.5439 9.60666 11.5439H9.59706H9.57786C8.76209 11.5439 8.08068 12.0908 7.86954 12.9211C7.77357 13.2857 7.42806 13.5186 7.08256 13.468C4.63524 13.0933 2.30309 12.192 0.364428 10.8351C0.354831 10.8249 0.258858 10.7642 0.182079 10.8148C0.0957031 10.8655 0.0957031 10.987 0.0957031 10.987L0.162884 16.1515C0.162884 18.2781 1.79443 19.9996 3.80987 19.9996H15.3651C17.3805 19.9996 19.012 18.2781 19.012 16.1515L19.0888 10.987C19.0888 10.987 19.0888 10.8655 19.0024 10.8148C18.9545 10.7844 18.8969 10.7946 18.8585 10.8148ZM10.3073 15.058C10.3073 15.4833 9.99056 15.8175 9.58748 15.8175C9.19398 15.8175 8.86768 15.4833 8.86768 15.058V13.7517C8.86768 13.3365 9.19398 12.9922 9.58748 12.9922C9.99056 12.9922 10.3073 13.3365 10.3073 13.7517V15.058Z"
        {...props}
      />
    </svg>
  );
};
