import React from "react";

export const CircleIcon = ({ ...props }) => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="5.79736" r="5" {...props} />
    </svg>
  );
};
