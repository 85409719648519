import axios from "axios";
import { baseUrl } from "./constants";

const Api = (method, urlData, params, contentType) => {

  const url     = `${baseUrl}${urlData}`;
  const token   = localStorage.getItem('token');
  const headers = {};

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  if(contentType === 'multipart'){
    headers["Content-Type"] = "multipart/form-data";
  }
  let axiosObj = {
    method: method,
    url: url,
    data: params,
    headers: headers,
  };

  return axios(axiosObj);
};

export default Api;