export const HelpIcon = ({ ...props }) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 -1 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0321 6.85421e-06C4.45798 0.0128356 -0.00640745 4.47723 6.90335e-06 10.0321C0.00642126 15.5099 4.51571 20.0128 9.98716 20C15.5356 19.9871 20.0192 15.5035 20.0064 9.98075C20 4.49006 15.4971 -0.0064075 10.0321 6.85421e-06ZM9.89095 18.4605C5.27261 18.4413 1.50738 14.5798 1.53945 9.92302C1.57152 5.28544 5.39448 1.5138 10.0321 1.53945C14.7274 1.56511 18.5183 5.37524 18.4669 10.0705C18.422 14.6055 14.8043 18.4862 9.89095 18.4605Z"
        {...props}
      />
      <path
        d="M9.98726 5.02241C8.3067 5.016 7.15211 5.87552 6.79291 7.25461C6.67745 7.6972 6.88271 8.10131 7.29964 8.22959C7.70375 8.35788 8.12068 8.13338 8.26821 7.70362C8.58251 6.80561 9.10208 6.46564 10.0386 6.57469C10.5902 6.63883 11.0392 6.88899 11.2445 7.43421C11.4433 7.96019 11.1739 8.34505 10.8211 8.69143C10.5517 8.95442 10.2759 9.21099 10.0065 9.47398C9.34582 10.1218 8.9353 10.8851 8.98662 11.8473C9.01228 12.3027 9.30092 12.6298 9.72427 12.6427C10.1669 12.6555 10.5325 12.354 10.5132 11.8922C10.4876 11.27 10.7891 10.8467 11.206 10.4554C11.5524 10.1282 11.9052 9.81394 12.2066 9.45473C12.8737 8.65294 13.0662 7.72927 12.63 6.76071C12.0719 5.55481 11.0456 5.08014 9.98726 5.02241Z"
        {...props}
      />
      <path
        d="M9.74321 13.4381C9.30703 13.4381 8.9799 13.7652 8.9799 14.195C8.9799 14.6248 9.30703 14.9519 9.73679 14.9583C10.1666 14.9647 10.5001 14.6376 10.5129 14.2142C10.5258 13.7781 10.1794 13.4381 9.74321 13.4381Z"
        {...props}
      />
    </svg>
  );
};
