import { createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import { ButtonUI } from "../../../components/ui/button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { StyledPagination } from "../../../components/ui/pagination/pagination";

export const ApplicantSourceReport = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNumber, setPage] = useState(1);

  const theme = createTheme({
    palette: {
      action: {
        hover: "#fff",
      },
    },
  });

  useEffect(() => {
    getTimeToHireReport();
  }, []);

  const getTimeToHireReport = () => {
    setIsLoader(true);

    const query = `companyId=${companyData.companyId}`;
    Api("GET", `Report/ApplicantSourceReport?${query}`)
      .then((res) => {
        setRows(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const handleSort = () => {};

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const Row = rows.map((val, key) => {
    return (
      <tr key={key}>
        <td className="td_name">{val.source}</td>
        <td>{val.applicants}</td>
        <td>{val.videos}</td>
        <td>{val.interviews}</td>
        <td>{val.offers}</td>
        <td>{val.hires}</td>
      </tr>
    );
  });

  return (
    <div>
      <div className="company_content">
        <div className="content_header">
          <div className="company_text mr-20">Applicant Source Report</div>
          {rows?.length > 0 && (
            <ButtonUI
              p="6px"
              leftIcon={<FileDownloadOutlinedIcon />}
              onClick={() => exportToCSV(rows, "Time_To_hire_Report")}
            >
              Download
            </ButtonUI>
          )}
        </div>

        {isLoader ? (
          <Loader inShow={true} />
        ) : rows?.length > 0 ? (
          <>
            <div className="company_table">
              <table>
                <thead>
                  <tr>
                    <th className="th_name">
                      Source
                      <button
                        onClick={() => handleSort("source")}
                        className="icon icon_button"
                      >
                        <img
                          src={
                            require("../../../assets/images/tabsort.svg")
                              .default
                          }
                          alt="sort-icon"
                          className="icon sort_icon"
                        />
                      </button>
                    </th>
                    <th>Applicants</th>
                    <th>Videos</th>
                    <th>Interviews</th>
                    <th>Offers</th>
                    <th>Hires</th>
                  </tr>
                </thead>
                <tbody>{Row}</tbody>
              </table>
            </div>
            {/* <StyledPagination
              count={totalPage}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            /> */}
          </>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </div>
  );
};
