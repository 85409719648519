import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import BackgroundLetterAvatars from "../../../components/common/layouts/BackgroundLetterAvatars";
import Api from "../../../helper/Api";
import "../../../assets/css/components/notification/_notification.css";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import { useSelector } from "react-redux";

export function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);

  useEffect(() => {
    setLoader(true);
    getNotificationList();
  }, []);

  const getNotificationList = () => {
    Api(
      "GET",
      `Notification/GetNotificationList?companyId=${
        companyData.companyId
      }&userId=${localStorage.getItem("user")}`
    )
      .then((res) => {
        setNotifications(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <React.Fragment>
      <div className="company_content help-container">
        <div className="content_header">
          <div className="company_text">Notification</div>
        </div>
        <div className="company_table">
          {isLoader ? (
            <Loader inShow={true} />
          ) : notifications.length > 0 ? (
            <div className="notification-container">
              {notifications.map((items, index) => (
                <div
                  className={
                    items?.read ? "cards-notifi active" : "cards-notifi"
                  }
                  key={index}
                >
                  <BackgroundLetterAvatars name={items.username} />
                  <div className="cards-name">
                    Company : {items.companyName}
                    <div
                      className="cards-heading"
                      dangerouslySetInnerHTML={{ __html: items.message }}
                    ></div>
                    <p className="cards-notifi-time">
                      {moment(items.notificationDate).fromNow()} by{" "}
                      {items.username}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoRecordsFound />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
