import "../../../assets/css/components/notification/_notification.css";
import "../../../assets/css/components/applicants/_applicants.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Api from "../../../helper/Api";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../redux/applicantData/actions";
import BackgroundLetterAvatars from "../../../components/common/layouts/BackgroundLetterAvatars";
import { EditDeleteMenu } from "../../../components/ui/editDeleteMenu";
import { AddApplicantForm } from "../../../components/common/layouts/jobInfo/content/addApplicant";
import { toast } from "react-toastify";
import moment from "moment";
import JobInfo from "../../../components/common/layouts/jobInfo";
import { ColoredLinearProgress } from "../../../components/common/coloredProgressBar";
import { ProfileCard } from "../../../components/common/cards/ProfileCard";
import { NoColDataFound } from "../../../components/common/NoColDataFound";
import { useSelector } from "react-redux";
import { ApplicantContent } from "../../../components/common/layouts/applicantInfo/content";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box } from "@mui/system";
import { CircularProgress, ListItemText } from "@mui/material";

export function HotList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const [hotList, setHotList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNumber, setPage] = useState(1);
  const [isLoader, setLoader] = useState(false);
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [ids, setIds] = useState({});
  const dispatch = useDispatch();
  const formData = new FormData();
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  useEffect(() => {
    if (searchInput === "") {
      setLoader(true);
      getHotApplicants("updatedAt", "desc");
    }
  }, [searchInput]);

  useEffect(() => {
    getHotApplicants("updatedAt", "DESC");
  }, [pageNumber]);

  const applicantDetails = (applicantData) => {
    applicantData.jobId = applicantData.applicantJobList[0].jobId;

    dispatch({
      type: APPLICANT_DATA_ACTIONS.SET_APPLICANT_DATA,
      payload: applicantData,
    });
    navigate("/hot-list/applicant-details");
  };

  const getHotApplicants = (columnName, direction) => {
    Api(
      "GET",
      `Job/GetHotandHireApplicantList?companyId=${location.state.companyId}&Type=HotList&pageLimit=10&searchText=${searchInput}&pageNumber=${pageNumber}`
    )
      .then((res) => {
        setLoader(false);
        setTotalPage(res.data.totalPage);

        const allData = [...hotList, ...res.data.data];
        setHotList(allData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handlePageChange = (e, value) => {
    setPage(value);
    getHotApplicants("updatedAt", "desc");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearchApplicant("updatedAt", "desc");
    }
  };

  const getSearchApplicant = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      getHotApplicants("updatedAt", "desc");
    }
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const editAction = (companyId, jobId, applicantId) => {
    Api(
      "GET",
      `Job/GetApplicantById?companyId=${companyId}&jobId=${jobId}&applicantId=${applicantId}`
    )
      .then((res) => {
        toggleDrawer("right", true);
        setIds({
          companyId: companyId,
          jobId: jobId,
          applicantId: applicantId,
        });
        setFormValues(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editApplicant = () => {
    formData.append("firstName", formValues?.firstName ?? "");
    formData.append("middleName", formValues?.middleName ?? "");
    formData.append("lastName", formValues?.lastName ?? "");
    formData.append("nickName", formValues?.nickName ?? "");
    formData.append("title", formValues?.title ?? "");
    formData.append("phoneNumber", formValues?.phoneNumber ?? "");
    formData.append("resume", formValues?.resume ?? "");
    formData.append("viewResume", formValues?.viewResume ?? "");
    formData.append("companyId", ids.companyId);
    formData.append("jobId", ids.jobId);
    formData.append("applicantId", ids.applicantId);
    formData.append(
      "address.addressLineOne",
      formValues?.address?.addressLineOne ?? ""
    );
    formData.append("address.addressLineTwo", "");
    formData.append("address.country", "");
    formData.append("address.city", formValues?.address?.city ?? "");
    formData.append("address.state", formValues?.address?.state ?? "");
    formData.append("address.zipCode", formValues?.address?.zipCode ?? "");
    formData.append("source", formValues?.source ?? "");

    Api("PUT", `Job/EditApplicant`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
          getHotApplicants("updatedAt", "desc");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openApplicantDetails = (applicantData) => {
    if (applicantData.applicantList[0].jobCycleStatus === "NEW_APPLICATION") {
      Api(
        "PUT",
        `Job/UpdateCurrentStatus?companyId=${applicantData.companyId}&jobId=${applicantData.applicantList[0].jobId}&applicantId=${applicantData.applicantId}&status=REVIEWING`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            applicantData.applicantList[0].jobCycleStatus = "REVIEWING";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const record = { ...applicantData };
    applicantData.applicantList.forEach((rec) => {
      if (rec.applicantId === applicantData.applicantId) {
        record.isHotList = rec.isHotList;
      }
    });

    const data = [...applicantsArray];
    data.push(record);

    dispatch({
      type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
      payload: data,
    });
  };

  const fetchMore = () => {
    setPage((prevState) => prevState + 1);
  };

  return (
    <div>
      {/* Edit Applicant form */}
      <AddApplicantForm
        openAddForm={openSidebar}
        toggleDrawer={toggleDrawer}
        handleSubmit={editApplicant}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <div className="company_content help-container applicants">
        <div className="common-container users company">
          <div className="inner-container company">
            <div className="user-container company">
              <div className="user-list">
                <div className="applicant-profile">
                  <div className="applicant-data">
                    <strong>Hot list</strong>
                  </div>
                </div>
                {isLoader === true ? (
                  <ColoredLinearProgress />
                ) : hotList.length > 0 ? (
                  <div className="inner-h applicant-list">
                    <div>
                      <ul className="job_template_option_menu pointer">
                        <InfiniteScroll
                          height={700}
                          dataLength={hotList?.length || 0}
                          next={fetchMore}
                          hasMore={hotList?.length !== hotList?.totalRecords}
                          loader={<h4>Loading...</h4>}
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          {hotList?.length === 0 && (
                            <ListItemText primary={"No Available Resource"} />
                          )}
                          {
                            <div>
                              {hotList?.length > 0 &&
                                hotList.map((applicant) => (
                                  <div key={applicant.applicantId}>
                                    <ProfileCard
                                      applicantId={applicant.applicantId}
                                      name={
                                        applicant.firstName +
                                        " " +
                                        applicant.lastName
                                      }
                                      jobName={
                                        applicant.applicantJobList[0].jobName
                                      }
                                      jobId={applicant.jobId}
                                      companyId={applicant.companyId}
                                      jobCode={
                                        applicant.applicantJobList[0].jobNo
                                      }
                                      morePositions={applicant.morePositions}
                                      createdAt={applicant.createdAt}
                                      openApplicantDetails={
                                        openApplicantDetails
                                      }
                                      checked={applicant.checked}
                                      latestActivityMessage={
                                        applicant?.latestActivityMessage
                                      }
                                      applicantList={hotList}
                                      setApplicantList={setHotList}
                                      setAllChecked={true}
                                      status={applicant.jobHireStatus}
                                    />
                                  </div>
                                ))}
                            </div>
                          }
                        </InfiniteScroll>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <NoColDataFound />
                )}
              </div>
              {applicantsArray?.map((data, index) => (
                <ApplicantContent data={data} index={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
