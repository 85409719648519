import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";

export const RescindedForm = ({
  toggleForm,
  openForm,
  formValues,
  setFormValues,
  handleSubmit,
  applicantId,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <AddEditCol
      toggleForm={toggleForm}
      openForm={openForm}
      handleSubmit={handleSubmit}
      title="Rescind this Offer Letter?"
      applicantId={applicantId}
    >
      <div className="forms-input">
        <label>Applicant Message :</label>
        <textarea
          placeholder="Message"
          name="applicantMessage"
          onChange={handleChange}
        />
      </div>
    </AddEditCol>
  );
};
