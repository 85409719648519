import React from "react";

export const NotePencilIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 32 32" fill="none">
      <path
        d="M16 20H12V16L24 4L28 8L16 20Z"
        stroke={!!props.fill ? props.fill : "#8898C9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 7L25 11"
        stroke={!!props.fill ? props.fill : "#8898C9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27 15V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H17"
        stroke={!!props.fill ? props.fill : "#8898C9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
