export const SettingsIcon = ({...props}) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path 
                d="M12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5Z" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M18.5069 7.56051C18.7287 7.88621 18.9258 8.22811 19.0963 8.58333L21.5288 9.93365C21.8251 11.2944 21.8261 12.7029 21.5318 14.0641L19.098 15.4151C18.9268 15.7701 18.7293 16.1117 18.5069 16.437L18.5537 19.2187C17.5234 20.1557 16.3042 20.8608 14.9782 21.2865L12.5912 19.8543C12.1983 19.8836 11.8037 19.8833 11.4107 19.8534L9.02508 21.2848C7.6985 20.861 6.47822 20.1576 5.44655 19.2222L5.4934 16.4389C5.27158 16.1132 5.07454 15.7713 4.90396 15.4161L2.4715 14.0658C2.17522 12.705 2.17421 11.2965 2.46852 9.93536L4.90233 8.5843C5.07348 8.22934 5.27105 7.88775 5.49339 7.56241L5.44657 4.78068C6.47688 3.84372 7.69614 3.1386 9.02211 2.71289L11.4091 4.1451C11.802 4.11585 12.1967 4.11616 12.5896 4.14604L14.9752 2.71462C16.3018 3.13841 17.5221 3.84176 18.5538 4.77723L18.5069 7.56051Z" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    );
}
