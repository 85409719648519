import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "../../../assets/css/common/_company-side-bar.css";
import { ToastAlert } from "../../../helper/constants";
import Api from '../../../helper/Api';
import { toast } from 'react-toastify';
import { DropzoneArea } from 'material-ui-dropzone';
import { AddEditPopup } from "../../common/addEditPopup";

const menu_options = [ 
  {id: 1, key: 'sources', name: 'Sources'},
  {id: 2, key: 'appointments', name: 'Appointments'},
  {id: 3, key: 'facilities', name: 'Facilities'},
  {id: 4, key: 'jobs', name: 'Jobs'},
  {id: 5, key: 'documents', name: 'Documents'},
  // {id: 6, key: 'job-applications', name: 'Job Applications'},
  {id: 7, key: 'job-boards', name: 'Job Boards'},
  {id: 8, key: 'job-templates', name: 'Job Templates'},
  {id: 9, key: 'messages', name: 'Messages'},
  {id: 10, key: 'quick-notes', name: 'Quick Notes'},
  {id: 11, key: 'stop-reasons', name: 'Stop Reasons'},
  {id: 12, key: 'sub-companies', name: 'Sub Companies'},
  {id: 13, key: 'system-settings', name: 'System Settings'},
  {id: 14, key: 'users', name: 'Users'},
  {id: 15, key: 'video-interviews', name: 'Video Interviews'},
];

export function CompanySideBar(props) {

  const [showSideBar, setSideBar] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState('Add New');
  const initialValues = { name: "", companyLogo: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [openToastBar, setOpenToastBar] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [editImageShow, setEditImageShow] = useState(false);
  const [companyId, setCompanyId] = useState(props.params.companyId);
  const [companyHirerchy, setCompanyHirerchy] = useState(props.params.companyHirerchy);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    getCompanyData();
    setIsLoader(true);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      Api("PUT", 'companies/editcompany', formValues, 'multipart')
        .then((response) => {
          if (response.data.isSuccess) {
            setIsLoader(false);
            toast.success(response.data.message);
            setSideBar(false);
            getCompanyData();
          } else {
            setIsLoader(false);
            toast.error(response.data.message);
          }
          setOpenToastBar(true);
        }).catch((error) => {
          console.log(error);
        })
    }
  }, [formErrors]);

  const errors = {};
  const validate = (values) => {
    if (!values.name) {
      errors.name = "Name is required.";
    }
    if (!values.companyImage && !values.companyLogo) {
      errors.companyImage = "Company Logo is required!";
    }
    return errors;
  };

  const formErrorClear = () => {
    formErrors.name = '';
    formErrors.companyLogo = '';
    formErrors.companyImage = '';
  };

  const getCompanyData = () => {
    if (props.params.companyId) {
      Api("GET", `Companies/GetCompanyById?companyId=${props.params.companyId}`)
        .then((response) => {
          if (response.data) {
            setCompanyData(response.data);
            localStorage.setItem('companyLogoPath', response.data.companyImage);
            localStorage.setItem('companyName', response.data.name);
            setIsLoader(false);
          } else {
            setCompanyData([]);
            setIsLoader(false);
          }
        }).catch((error) => {
          console.log(error);
        })
    }
  }
  const toggleDrawer = (anchor, open) => {
    setSideBar({ ...showSideBar, [anchor]: open });
  };

  return (
    <div className='parent-outer-main'>
      {/* side-bar html call */}

      {openToastBar ? <ToastAlert /> : null}
      {showSideBar ?
      // change sidebar code - Foram Mehta 
        <AddEditPopup
          title={showAddEditLabel}
          openSidebar={showSideBar}
          toggleDrawer={toggleDrawer}
          handleSubmit={handleSubmit}
        >
          <div className="forms-input">
            <label>Name</label>
            <input type="text" placeholder="Name" name="name" id="name" value={formValues.name} onChange={handleChange} />
            <span className="error_msg">{formErrors.name}</span>
          </div>
          <div className="forms-input">
            {editImageShow ?
              <div className="upload-file-shower">
                <img src={formValues.companyImage} alt="preview-img" className="show-img" />
                <button onClick={() => (setEditImageShow(false))} className='icon icon_button'>
                  <img src={require('../../../assets/images/close-upload.png')} alt="close" className="close-img" />
                </button>
              </div>
              : <div className="forms-input">
                <DropzoneArea
                  acceptedFiles={['image/*']}
                  dropzoneText={"Drag and drop an image here or click"}
                  onChange={(files) => formValues["companyLogo"] = files[0]}
                  maxFileSize={500000}
                  filesLimit={1}
                />
                <span className="error_msg">{formErrors.companyImage}</span>
              </div>
            }
          </div>
        </AddEditPopup>
        : null}

      <div className='sidebar-company'>
        <div className='sidebar-header'>
          <img src={companyData.companyImage ? companyData.companyImage : require('../../../assets/images/dummy-img.png')} alt="logo" className="company-logo" />
          <p className='company-name'>{companyData.name ? companyData.name : "N/A"}</p>
          <img className='company-edit' src={require('../../../assets/images/menu-edit.svg').default} alt="icon" onClick={() => (toggleDrawer("right", true), setAddEditLabel('Edit Company'), setFormValues(companyData), setEditImageShow(true))} />
        </div>
        <div className='sidebar-content'>
          {menu_options.map(function (menu) {
            if (!(companyData.parentCompanyId != null && menu.name == 'Sub Companies')) {
              return <div key={menu.id} className={props.params.componentName == menu.key ? 'dashboard active' : 'dashboard'}>
                <Link to={'/' + menu.key} state={{ companyId: companyId, companyHirerchy: companyHirerchy, componentName: menu.key }} >
                  <span className='dashboard_text icon'>{menu.name}</span>
                </Link>
              </div>
            }
          })}
        </div>
      </div>
    </div>
  );
}
