import React from "react";

export const DescriptionIcon = (props) => {
  return (
    <svg viewBox="0 0 22 26" fill="none" {...props}>
      <path
        d="M21.6987 7.28436L14.7072 0.292846C14.6971 0.282837 14.6863 0.274048 14.6759 0.264526C14.6624 0.252136 14.6493 0.239319 14.6351 0.227661C14.6257 0.219971 14.6157 0.213256 14.606 0.205871C14.5897 0.19336 14.5735 0.180664 14.5564 0.169129C14.5486 0.16394 14.5404 0.159608 14.5326 0.154663C14.5128 0.142151 14.4929 0.1297 14.4722 0.11853C14.4665 0.11554 14.4607 0.11322 14.4551 0.110351C14.4316 0.0982663 14.4079 0.0866701 14.3835 0.0764763C14.3797 0.0748888 14.3757 0.0737261 14.3718 0.0722036C14.3455 0.0616436 14.3188 0.0517562 14.2915 0.0434537C14.2875 0.0422324 14.2834 0.0414999 14.2795 0.0403399C14.2524 0.0324649 14.225 0.0252037 14.1969 0.0195899C14.188 0.0178199 14.1788 0.0170263 14.1698 0.0155001C14.1466 0.0114726 14.1235 0.00738252 14.0999 0.00506377C14.0667 0.0016918 14.0334 1.70236e-06 14 0H1.99902C1.46878 0.000606423 0.960421 0.211515 0.585479 0.586456C0.210538 0.961398 -0.00037014 1.46975 -0.000976562 2V24C-0.00037014 24.5302 0.210538 25.0386 0.585479 25.4135C0.960421 25.7885 1.46878 25.9994 1.99902 26H20C20.5302 25.9994 21.0386 25.7885 21.4135 25.4135C21.7885 25.0386 21.9994 24.5302 22 24V8.01935C22.0001 8.01282 22.001 8.00653 22.001 8C22.0011 7.86655 21.9744 7.73444 21.9224 7.61151C21.8705 7.48857 21.7944 7.37733 21.6987 7.28436ZM15 19H7C6.73478 19 6.48043 18.8946 6.29289 18.7071C6.10536 18.5196 6 18.2652 6 18C6 17.7348 6.10536 17.4804 6.29289 17.2929C6.48043 17.1054 6.73478 17 7 17H15C15.2652 17 15.5196 17.1054 15.7071 17.2929C15.8946 17.4804 16 17.7348 16 18C16 18.2652 15.8946 18.5196 15.7071 18.7071C15.5196 18.8946 15.2652 19 15 19ZM15 15H7C6.73478 15 6.48043 14.8946 6.29289 14.7071C6.10536 14.5196 6 14.2652 6 14C6 13.7348 6.10536 13.4804 6.29289 13.2929C6.48043 13.1054 6.73478 13 7 13H15C15.2652 13 15.5196 13.1054 15.7071 13.2929C15.8946 13.4804 16 13.7348 16 14C16 14.2652 15.8946 14.5196 15.7071 14.7071C15.5196 14.8946 15.2652 15 15 15ZM14 8V2.41419L19.5857 8H14Z"
        fill={!!props.fill ? props.fill : "#fff"}
      />
    </svg>
  );
};
