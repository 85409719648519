import React from "react";

export const ActivityIcon = (props) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M3.00049 16H7.00049L12.0005 5L20.0005 26L25.0005 16H29.0005"
        stroke={!!props.stroke ? props.stroke : "#8898C9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
