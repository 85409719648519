import React from "react";

export const CloseXIcon = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M18.75 5.25L5.25 18.75"
        stroke={!!props.fill ? props.fill : "#E6492D"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 18.75L5.25 5.25"
        stroke={!!props.fill ? props.fill : "#E6492D"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
