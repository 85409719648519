import { AddEditPopup } from "../../common/addEditPopup";
import { CheckboxWithBlueCheck } from "../../ui/checkbox/checkboxWithBlueTick";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { useLocation } from "react-router-dom";
import Api from "../../../helper/Api";
import { API_types } from "../../../helper/enum";
import { toast } from "react-toastify";

export const EditInterview = ({
  openSidebar,
  toggleDrawer,
  formValues,
  setFormValues,
  interviewLabel,
  setOpenToastBar,
  editMode,
  getData,
}) => {
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      videoInterviewId: "",
      companyId: location.state.companyId,
      name: formValues.name,
      isActive: formValues.isActive,
      allowRadorequest: formValues.allowRadorequest,
    };
    if (editMode) {
      params.videoInterviewId = formValues.videoInterviewId;
      Api(API_types.PUT, `VideoInterview/EditVideoInterview`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            toggleDrawer("right", false);
            toast.success(res.data.message);
            getData();
          } else {
            toast.error(res.data.message);
          }
          setOpenToastBar(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Api(API_types.POST, `VideoInterview/AddVideoInterview`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            toggleDrawer("right", false);
            toast.success(res.data.message);
            getData();
          } else {
            toast.error(res.data.message);
          }
          setOpenToastBar(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onCheckBoxChange = (checkName, isChecked) => {
    setFormValues({ ...formValues, [checkName]: isChecked });
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  return (
    <AddEditPopup
      title={interviewLabel}
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
    >
      <div className="forms-input">
        <label>Name:</label>
        <input
          type="text"
          placeholder="Name here"
          name="name"
          id="name"
          value={formValues.name}
          onChange={handleChange}
        />
      </div>
      <div className="checkbox-label">
        <FormControlLabel
          control={
            <CheckboxWithBlueCheck
              disableRipple
              checked={formValues.isActive}
              onChange={(e) =>
                onCheckBoxChange(e.target.name, e.target.checked)
              }
              value={formValues.isActive}
              name="isActive"
            />
          }
          label="Active"
        />
      </div>
      <div className="checkbox-label">
        <FormControlLabel
          control={
            <CheckboxWithBlueCheck
              disableRipple
              checked={formValues.allowRadorequest}
              onChange={(e) =>
                onCheckBoxChange(e.target.name, e.target.checked)
              }
              value={formValues.allowRadorequest}
              name="allowRadorequest"
            />
          }
          label="Allow Redo Request"
        />
      </div>
    </AddEditPopup>
  );
};
