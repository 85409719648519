import { AddEditPopup } from "../../../components/common/addEditPopup";

export const FilterForm = (props) => {
  return (
    <AddEditPopup
      title="Filter"
      toggleDrawer={props.toggleDrawer}
      openSidebar={props.openSidebar}
      formValues={props.formValues}
      setFormValues={props.setFormValues}
    >
      <div className="forms-input">
        <label>Status : </label>
        <select>
          <option value="active">Active</option>
          <option value="active">Active</option>
          <option value="active">Active</option>
          <option value="active">Active</option>
        </select>
      </div>

      <div className="forms-input">
        <label>Days Posted : </label>
        <select>
          <option value="active">Active</option>
          <option value="active">Active</option>
          <option value="active">Active</option>
          <option value="active">Active</option>
        </select>
      </div>

      <div className="forms-input">
        <label>Priority : </label>
        <select>
          <option value="active">Active</option>
          <option value="active">Active</option>
          <option value="active">Active</option>
          <option value="active">Active</option>
        </select>
      </div>

      <div className="forms-input">
        <label>Facility : </label>
        <select>
          <option value="active">Active</option>
          <option value="active">Active</option>
          <option value="active">Active</option>
          <option value="active">Active</option>
        </select>
      </div>

      <div className="forms-input">
        <label>Department : </label>
        <select>
          <option value="active">Active</option>
          <option value="active">Active</option>
          <option value="active">Active</option>
        </select>
      </div>

      <div className="forms-input">
        <label>Job Name/Number : </label>
        <input type="text" placeholder="Job Name/Number" />
      </div>
    </AddEditPopup>
  );
};
