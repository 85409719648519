import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, Link, useLocation } from "react-router-dom";
import "../../../assets/css/common/_side-bar.css";
import Api from "../../../helper/Api";
import { getInitials } from "../layouts/utils/getInitials";
import { userAsCompany } from "../../../helper/constants";
import {
  DashboardIcon,
  CompanyIcon,
  UsersIcon,
  ReportsIcon,
  SettingsIcon,
  HotlistIcon,
  HireIcon,
  CalendarIcon,
  OutlinedSuitcaseIcon,
} from "../../../icons";

export const Sidebar = () => {
  // Assigning location variable
  const location = useLocation();

  // Destructuring pathname from location
  const { pathname } = location;

  const [companyInfo, setCompanyInfo] = useState({});
  const { companyData } = useSelector((state) => state.companyDataReducer);

  // Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  useEffect(() => {
    {
      userAsCompany() &&
        Api(
          "GET",
          `Companies/GetCompanyById?companyId=${companyData.companyId}`
        )
          .then((response) => {
            if (response.data) {
              setCompanyInfo(response.data);
              localStorage.setItem(
                "companyLogoPath",
                response.data.companyImage
              );
              localStorage.setItem("companyName", response.data.name);
            } else {
              setCompanyInfo({});
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }, []);

  return (
    <div className={userAsCompany() ? "sidebar company-active" : "sidebar"}>
      {userAsCompany() ? (
        <div className="sidebar-header">
          <img
            src={
              companyInfo.companyImage
                ? companyInfo.companyImage
                : require("../../../assets/images/dummy-img.png")
            }
            alt="logo"
          />
          <p className="sidebar-heading">
            {companyInfo.name ? getInitials(companyInfo.name) : "N/A"}
          </p>
        </div>
      ) : null}

      <div className="sidebar-content">
        <div className="dashboard">
          {userAsCompany() ? (
            <NavLink
              to="/dashboard_new"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <DashboardIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Dashboard</span>
            </NavLink>
          ) : (
            <NavLink
              to="/dashboard"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <DashboardIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Dashboard</span>
            </NavLink>
          )}
        </div>

        {userAsCompany() ? (
          <div className="dashboard">
            <NavLink
              to="/all-jobs"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <OutlinedSuitcaseIcon
                width="24"
                height="24"
                className="sidebar-icon"
              />
              <span className="dashboard_text">Jobs (Desktop)</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany() ? (
          <div className="dashboard">
            <NavLink
              to="/applicants"
              state={companyData}
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <UsersIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Applicants</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany() ? (
          <div className="dashboard">
            <NavLink
              to="/hot-list"
              state={companyData}
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <HotlistIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Hot List</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany() ? (
          <div className="dashboard">
            <NavLink
              to="/calendar"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <CalendarIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Calendar</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany() ? (
          <div className="dashboard">
            <NavLink
              to="/hires"
              state={companyData}
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <HireIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Hires</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany() ? null : (
          <div className="dashboard">
            <NavLink
              to="/companylist"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <CompanyIcon
                width="24"
                height="24"
                fill="#C7C7D2"
                className="sidebar-icon"
              />
              <span className="dashboard_text">Companies</span>
            </NavLink>
          </div>
        )}
        {userAsCompany() ? (
          <div className="dashboard">
            <Link
              to="/all-users"
              state={companyData}
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <UsersIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Users</span>
            </Link>
          </div>
        ) : null}
        {/* Temporary hidden the Users option for Admin */}
        {/* {userAsCompany() ? null : (
          <div className="dashboard">
            <NavLink
              to="/usersdetail"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <UsersIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Users</span>
            </NavLink>
          </div>
        )} */}
        {userAsCompany() && (
          <div className="dashboard">
            <NavLink
              to="/reports"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <ReportsIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Reports</span>
            </NavLink>
          </div>
        )}
        {userAsCompany() ? null : (
          <div className="dashboard">
            <NavLink
              to="/settings"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <SettingsIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Settings</span>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};
