import { GreyCard } from "../../../../common/cards/GreyCard";
import { useSelector } from "react-redux";
import { StyledPagination } from "../../../../ui/pagination/pagination";
import { useEffect, useState } from "react";
import NoRecordsFound from "../../../../common/NoRecordsFound";

export const Activity = () => {
  const activities = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.activity
  );
  const perPage = 8;
  const [totalPage, setTotalPage] = useState(
    Math.ceil(activities.length / perPage)
  );
  const [activitiesData, setActivities] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    let data = [];
    let start = (page - 1) * perPage;
    let end = page * perPage;

    for (let i = start; i < end; i++) {
      if (activities[i] !== undefined) {
        data.push(activities[i]);
      }
    }

    setActivities(data);
  }, [activities, page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <div className="top-header-user">
        <span className="main-name">Activity</span>
      </div>
      {activities.length > 0 ? (
        <div>
          <div className="activity-container">
            {activitiesData?.map((activity) => (
              <GreyCard
                cardHeader1={activity.action}
                cardHeader2={activity.message}
                contentSpan={activity.loginUserName}
                contTimeStamp={activity.createdAt}
                type="activity"
                key={activity.activityLogId}
              />
            ))}
          </div>
          <StyledPagination
            count={totalPage}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
      ) : (
        <NoRecordsFound />
      )}
    </div>
  );
};
