import { AddEditPopup } from "../../common/addEditPopup";
import Api from "../../../helper/Api";
import { API_types } from "../../../helper/enum";
import { toast } from "react-toastify";

export const EditQuestion = ({
  questionLabel,
  openSidebar,
  toggleDrawer,
  setFormValues,
  formValues,
  editMode,
  getData,
}) => {
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      videoInterviewId: formValues.videoInterviewId,
      companyId: formValues.companyId,
      questionsId: "",
      question: formValues.question,
      prepTime: formValues.prepTime,
      responseTime: formValues.responseTime,
    };
    if (editMode) {
      params.questionsId = formValues.questionsId;
      Api(API_types.PUT, `VideoInterview/EditQuestion`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            toggleDrawer("right", false);
            toast.success(res.data.message);
            getData();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Api(API_types.POST, `VideoInterview/AddQuestion`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            toggleDrawer("right", false);
            toast.success(res.data.message);
            getData();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <AddEditPopup
      title={questionLabel}
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
    >
      <div className="forms-input">
        <label>Question: </label>
        <textarea
          name="question"
          id="question"
          cols="20"
          rows="4"
          placeholder="Question here"
          value={formValues.question}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="forms-input">
        <label>Prep Time: </label>
        <input
          type="text"
          id="prepTime"
          name="prepTime"
          alt="prepTime"
          value={formValues.prepTime}
          placeholder="Prep Time here"
          onChange={handleChange}
        />
      </div>
      <div className="forms-input">
        <label>Response Time: </label>
        <input
          type="text"
          id="responseTime"
          name="responseTime"
          alt="responseTime"
          value={formValues.responseTime}
          placeholder="Response Time here"
          onChange={handleChange}
        />
      </div>
    </AddEditPopup>
  );
};
