import { CheckboxWithBlueCheck } from "../../../../../ui/checkbox/checkboxWithBlueTick";
import { FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import Api from "../../../../../../helper/Api";
import { AddEditCol } from "../../../../addEditPopup/AddEditCol";
import { createPortal } from "react-dom";

export const JobSelectForm = ({
  applicantId,
  companyId,
  openForm,
  toggleForm,
}) => {
  const [allData, setAllData] = useState([]);
  const domNode = document?.getElementById(applicantId) || "";

  useEffect(() => {
    Api(
      "GET",
      `Job/GetJobList?companyId=${companyId}&OrderBy=createdAt&pageLimit=50`
    )
      .then((res) => {
        let data = [...res.data.data];
        const responseData = data.map((d) => {
          return { ...d, checked: false };
        });
        setAllData(responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [openForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(allData);
    // props.handleJobSelect();
  };

  const onCheckBoxChange = (checkName, isChecked) => {
    const checkList = allData.map((data) => {
      if (data.jobId === checkName) {
        data.checked = isChecked;
      }
      return data;
    });

    setAllData(checkList);
  };

  return (
    <AddEditCol
      applicantId={applicantId}
      openForm={openForm}
      title="Job Select Form"
      toggleForm={toggleForm}
    >
      {allData.map((rec) => (
        <div className="checkbox-label job-list" key={rec.id}>
          <div>
            <FormControlLabel
              control={
                <CheckboxWithBlueCheck
                  disableRipple
                  checked={rec.checked}
                  onChange={(e) => onCheckBoxChange(rec.id, e.target.checked)}
                  value={rec.id}
                  key={rec.id}
                />
              }
              label={rec.name + " (" + rec.jobNo + ")"}
            />
          </div>
          <label className="company-name">{rec.companyName}</label>
          <label className="company-address">
            {rec?.description?.substring(0, 50)}
          </label>
        </div>
      ))}
    </AddEditCol>
  );
};
