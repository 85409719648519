export const PreHireIcon = ({ ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 10.75C23 10.8485 22.9806 10.946 22.943 11.037C22.9053 11.128 22.85 11.2107 22.7804 11.2803C22.7107 11.35 22.6281 11.4052 22.5371 11.4429C22.4461 11.4806 22.3485 11.5 22.25 11.5H20.75V13C20.75 13.1989 20.671 13.3897 20.5304 13.5303C20.3897 13.671 20.199 13.75 20 13.75C19.8011 13.75 19.6104 13.671 19.4697 13.5303C19.3291 13.3897 19.25 13.1989 19.25 13V11.5H17.75C17.5511 11.5 17.3604 11.421 17.2197 11.2803C17.0791 11.1397 17 10.9489 17 10.75C17 10.5511 17.0791 10.3603 17.2197 10.2196C17.3604 10.079 17.5511 9.99998 17.75 9.99998H19.25V8.49998C19.25 8.30106 19.3291 8.1103 19.4697 7.96965C19.6104 7.82899 19.8011 7.74998 20 7.74998C20.199 7.74998 20.3897 7.82899 20.5304 7.96965C20.671 8.1103 20.75 8.30106 20.75 8.49998V9.99998H22.25C22.3485 9.99997 22.4461 10.0194 22.5371 10.0571C22.6281 10.0948 22.7107 10.15 22.7804 10.2196C22.85 10.2893 22.9053 10.372 22.943 10.463C22.9806 10.554 23 10.6515 23 10.75ZM12.5134 12.771C13.6851 12.0358 14.5859 10.9394 15.0798 9.64731C15.5737 8.3552 15.634 6.9375 15.2514 5.60815C14.8689 4.27881 14.0644 3.10996 12.9592 2.27799C11.8541 1.44603 10.5083 0.996094 9.12504 0.996094C7.74175 0.996094 6.39599 1.44603 5.29086 2.27799C4.18572 3.10996 3.38118 4.27881 2.99865 5.60815C2.61611 6.9375 2.67635 8.3552 3.17025 9.64731C3.66416 10.9394 4.56494 12.0358 5.73666 12.771C3.69646 13.4151 1.88245 14.628 0.507777 16.2674C0.415921 16.3767 0.357191 16.51 0.33849 16.6516C0.31979 16.7931 0.341896 16.9371 0.402211 17.0666C0.462526 17.196 0.558542 17.3055 0.678971 17.3823C0.7994 17.459 0.939237 17.4998 1.08204 17.4998L17.1681 17.4998C17.3109 17.4998 17.4507 17.4591 17.5712 17.3823C17.6916 17.3056 17.7876 17.196 17.8479 17.0666C17.9082 16.9372 17.9303 16.7932 17.9116 16.6516C17.8929 16.51 17.8342 16.3767 17.7423 16.2674C16.3677 14.628 14.5536 13.4151 12.5134 12.771Z"
        fill="#F6AB2F"
      />
    </svg>
  );
};
