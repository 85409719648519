export const LeftArrowIcon = ({ ...props }) => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="22"
        height="22"
        transform="matrix(-1 0 0 1 22 0)"
        fill="white"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25439 10.0787C8.08254 10.2506 7.986 10.4837 7.986 10.7268C7.986 10.9699 8.08254 11.203 8.25439 11.3749L12.4646 15.5851C12.6354 15.7525 12.8653 15.8457 13.1044 15.8445C13.3435 15.8433 13.5725 15.7478 13.7415 15.5787C13.9106 15.4096 14.0061 15.1807 14.0073 14.9416C14.0085 14.7025 13.9153 14.4726 13.748 14.3018L10.173 10.7268L13.748 7.15182C13.9153 6.98105 14.0085 6.75114 14.0073 6.51205C14.0061 6.27295 13.9106 6.044 13.7415 5.87493C13.5725 5.70586 13.3435 5.61035 13.1044 5.60914C12.8653 5.60793 12.6354 5.70113 12.4646 5.86848L8.25439 10.0787Z"
        fill="#9EA0A5"
      />
    </svg>
  );
};
