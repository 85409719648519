import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AccordianList } from "../../common/layouts/AccordianList";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { EditInterview } from "./editInterview";
import Api from "../../../helper/Api";
import { API_types } from "../../../helper/enum";
import { toast } from "react-toastify";
import { reasonPageLimit } from "../../../helper/constants";
import Loader from "../../common/Loader";
import NoRecordsFound from "../../common/NoRecordsFound";
import { ButtonUI } from "../../ui/button";
import AddIcon from "@mui/icons-material/Add";
import { TableHead, TableRow } from "@mui/material";
import { StyledTableCell } from "../../ui/table/table";

export const VideoInterviews = () => {
  const initialValues = { name: "", status: false, redoRequest: false };
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [interviewLabel, setInterviewLabel] = useState("");
  const [videoInterviewList, setVideoInterviewList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");

  const setUpHeight = {
    height: "calc(100% - 0px)",
    backgroundColor: "transparent",
  };

  useEffect(() => {
    if (searchInput === "" && page) {
      getVideoInterviews();
    } else {
      setIsLoader(false);
    }
  }, [searchInput, page]);

  const getVideoInterviews = () => {
    setIsLoader(true);
    Api(
      API_types.GET,
      `VideoInterview/GetVideoInterviewList?companyId=${
        location.state.companyId
      }&companyHirerchy=${
        location.state.companyHirerchy
      }&pageLimit=${reasonPageLimit}&pageNumber=${page}&OrderbyDirection=${"desc"}&OrderBy=${"updatedAt"}&searchText=${searchInput}`
    )
      .then((res) => {
        setVideoInterviewList(res.data.data);
        setTotalPage(res.data.totalPage);
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // On every page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // On every company search
  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearchCompany();
    }
  };

  // Return searched company records
  const getSearchCompany = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      getVideoInterviews();
      setIsLoader(true);
    }
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const editInterview = (id) => {
    setInterviewLabel("Edit Video Interview");
    setEditMode(true);
    toggleDrawer("right", true);

    const obj = videoInterviewList.find((item) => {
      return item.videoInterviewId === id;
    });
    setFormValues(obj);
  };

  const deleteInterview = (id) => {
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      // DELETE API call
      Api(
        API_types.DELETE,
        `VideoInterview/DeleteVideoInterview?videoInterviewId=${id}&companyId=${location.state.companyId}`
      )
        .then((res) => {
          if (res.data.isSuccess) {
            toast.success(res.data.message);
            getVideoInterviews();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="common-container users">
      <EditInterview
        openSidebar={openSidebar}
        toggleDrawer={toggleDrawer}
        formValues={formValues}
        setFormValues={setFormValues}
        interviewLabel={interviewLabel}
        editMode={editMode}
        getData={getVideoInterviews}
      />
      <div className="inner-container">
        <div className="top-url">
          <p>
            <Link to="/companylist"> Companies &gt; </Link>
            <strong>Video Interviews </strong>
          </p>
        </div>
        <div className="manage-container jobs">
          <div className="manage-bg active">
            <div className="parent-container-d">
              <div className="top-search">
                <div className="company_search">
                  <img
                    src={require("../../../assets/images/search_icon.png")}
                    alt="search-icon"
                    className="search_img"
                  />
                  <input
                    type="text"
                    placeholder="Search here"
                    className="search_input appointments"
                    name="search_input"
                    value={searchInput}
                    onChange={handleSearch}
                    onKeyPress={handleKeyPress}
                  />
                  <button className="search-btn" onClick={getSearchCompany}>
                    {" "}
                    Search{" "}
                  </button>
                </div>
                <ButtonUI
                  p="12px"
                  leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
                  onClick={() => (
                    toggleDrawer("right", true),
                    setInterviewLabel("Add Video Interview"),
                    setEditMode(false),
                    setFormValues(initialValues)
                  )}
                >
                  Add
                </ButtonUI>
              </div>
              <div className="manage-checkboxes-none position-relative">
                <Loader inShow={isLoader} />
                {videoInterviewList.length > 0 ? (
                  <TableContainer
                    component={Paper}
                    className="box-shadow-none t-bg"
                  >
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Video Interview Name{" "}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Allow Redo Request{" "}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Status{" "}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                          <StyledTableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {videoInterviewList.map((item) => (
                          <AccordianList
                            key={item.videoInterviewId}
                            items={item}
                            editInterview={editInterview}
                            deleteInterview={deleteInterview}
                            getData={getVideoInterviews}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <NoRecordsFound />
                )}
              </div>
              {/* <StyledPagination count={totalPage} page={page} variant="outlined" shape="rounded" onChange={handlePageChange} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
