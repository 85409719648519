import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "@fontsource/inter";
import "../../../assets/css/components/login/_login.css";
import { ToastAlert, userAsCompany } from "../../../helper/constants";
import Api from "../../../helper/Api";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validations } from "../../../helper/validations";

export function Otp() {
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get("userId");

  const [errorMessages, setErrorMessages] = useState({});
  const initialValues = { otp: "", userId: userId };
  const [formValues, setFormValues] = useState(initialValues);
  const [isLoader, setIsLoader] = useState(false);
  const [openToastBar, setOpenToastBar] = useState(false);
  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();
    if (!formValues.otp) {
      setErrorMessages({ name: "otp", message: validations?.otp?.req });
    } else {
      setIsLoader(true);
      setErrorMessages({});
      Api("POST", "account/verifyotp", formValues)
        .then((response) => {
          if (response.data.isSuccess) {
            const token = response.data.data.token;
            setIsLoader(false);
            toast.success(response.data.message);
            setOpenToastBar(true);
            setTimeout(() => {
              navigate("/resetpassword?userId=" + userId + "&token=" + token);
            }, 2000);
          } else {
            setIsLoader(false);
            toast.error(response.data.message);
            setOpenToastBar(true);
            if (response.data.message != "Your OTP is invalid.") {
              setTimeout(() => {
                navigate("/forgot-password", { replace: true });
              }, 2000);
            }
          }
        })
        .catch((error) => {
          if (!error?.response) {
            toast.error(error.response.data.message);
            setOpenToastBar(true);
          }
          setIsLoader(false);
        });
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <span className="error_msg">{errorMessages.message}</span>
    );

  // JSX code for login form
  return (
    <div className="main-container">
      <Loader inShow={isLoader} />
      {openToastBar ? <ToastAlert /> : null}
      <div className="inner-container">
        <div className="rec4">
          <div className="left_form">
            <div className="forgotkey_img">
              <img
                src={require("../../../assets/images/Frame.png")}
                alt="left-img"
                className=""
              />
            </div>
            <div className="login_title otp-title">
              <h2 className="title_1 center">OTP Verification</h2>
              <p className="title_2 center">
                Please check your mobile for 6 digit OTP
              </p>
            </div>
            <div className="login_form">
              <form onSubmit={handleSubmit}>
                <div className="email_box">
                  <label className="email_label" htmlFor="otp">
                    Enter OTP{" "}
                  </label>
                  <input
                    className="email_input"
                    type="text"
                    name="otp"
                    id="otp"
                    placeholder="Enter OTP"
                    onChange={handleChange}
                  />
                  {renderErrorMessage("otp")}
                </div>
                <div className="submit_box">
                  <input
                    type="submit"
                    className="login-submit-btn"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
            <div className="back_to_login">
              <img
                src={require("../../../assets/images/Vector.png")}
                alt="vector"
                className="vector_img"
              />
              <Link to="/login" className="login_link">
                Back to log in
              </Link>
            </div>
          </div>
        </div>
        <div className={userAsCompany() ? "rec5 active" : "rec5"}></div>
      </div>
    </div>
  );
}
