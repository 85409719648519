import { DateSelector } from "../../../../../../ui/pickers/date";
import { AddEditPopup } from "../../../../../addEditPopup";
import { useEffect, useState } from "react";
import Api from "../../../../../../../helper/Api";
import { useSelector } from "react-redux";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";

export const RequestVideoInterviewForm = (props) => {
  const [value, setValue] = useState(new Date());
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [videoInterviewList, setVideoInterviewList] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleRequestVideInterview();
  };

  const handleDateChange = (newValue) => {
    setValue(newValue.toISOString());
    props.setFormValues({
      ...props.formValues,
      ["expDate"]: newValue.toISOString(),
    });
  };

  const handleChange = (e) => {
    props.setFormValues({
      ...props.formValues,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    Api(
      "GET",
      `Job/GetVideoIntDropdownList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        setVideoInterviewList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <AddEditCol
      title={
        props.resendForm === true
          ? "Resend notification"
          : props.resendInterviewForm === true
          ? "Resend interview notification"
          : "Request Video Interview"
      }
      handleSubmit={handleSubmit}
      openForm={props.openForm}
      toggleForm={props.toggleForm}
      applicantId={props.applicantId}
    >
      {props.resendForm === false &&
        props.resendInterviewForm === false &&
        props.edit === false && (
          <div className="forms-input">
            <label>Select Video Interview:</label>
            <select
              onChange={handleChange}
              name="videoInterviewId"
              value={props.formValues.videoInterviewId}
            >
              <option value="">Select</option>
              {videoInterviewList.map((interview) => (
                <option value={interview.videoInterviewId}>
                  {interview.name}
                </option>
              ))}
            </select>
            <span className="error_msg">
              {props.formErrors.videoInterviewId}
            </span>
          </div>
        )}

      {props.edit === false && (
        <div className="forms-input">
          <label>Message to Applicant:</label>
          <textarea
            placeholder="Message Here"
            onChange={handleChange}
            name="description"
            value={props.formValues.description}
          />
        </div>
      )}

      {props.resendInterviewForm === false && (
        <div className="forms-input">
          <label>Expires:</label>
          <DateSelector
            value={value}
            setValue={setValue}
            disablePast={true}
            handleDateChange={handleDateChange}
          />
        </div>
      )}
    </AddEditCol>
  );
};
