import { useState } from "react";
import { ButtonUI } from "../../../../../../ui/button";
import { AcceptPreHireForm } from "./acceptPreHireForm";
import { CancelPreHireForm } from "./cancelPreHireForm";
import { CreateAptForm } from "./createAptForm";
import { CreatePreHirePack } from "./createPreHirePack";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { CancelDocPackForm } from "./cancelDocPackForm";
import { SendPackForm } from "./sendPackForm";
import { AddDocumentForm } from "./addDocumentForm";
import fileDownload from "js-file-download";
import axios from "axios";
import moment from "moment";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const PreHire = ({ selectedSuitCase, applicantName, applicantId }) => {
  const [openAptForm, setOpenAptForm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [openCancelForm, setCancelForm] = useState(false);
  const [openAcceptForm, setAcceptFrom] = useState(false);
  const [openPreHirePack, setOpenPreHirePack] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [physicalPpdList, setPhysicalPpdList] = useState([]);
  const [docPackList, setDocPackList] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [openPackCancelForm, setPackCancelForm] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [id, setId] = useState("");
  const [openSendDocPackForm, setSendDocPackForm] = useState(false);
  const [openAddDocForm, setAddDocForm] = useState(false);
  const formData = new FormData();

  useEffect(() => {
    getPreHireList();
    getPreHireDocPackList();
  }, []);

  const toggleAptForm = (open) => {
    setOpenAptForm(open);
  };

  const toggleCancelForm = (open) => {
    setCancelForm(open);
  };

  const toggleAcceptForm = (open) => {
    setAcceptFrom(open);
  };

  const togglePreHirePack = (open) => {
    setOpenPreHirePack(open);
  };

  const togglePackCancelForm = (open) => {
    setPackCancelForm(open);
  };

  const toggleAddDocForm = (open) => {
    setAddDocForm(open);
  };

  // Request Physical / PPD list
  function getPreHireList() {
    Api(
      "GET",
      `Job/GetPhysicalPPDRequestList?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setPhysicalPpdList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Pre Hire Document pack list
  function getPreHireDocPackList() {
    Api(
      "GET",
      `Job/GetPreHireDocumentPackList?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setDocPackList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Cancel PreHire Physical / PPD
  const cancelPhysicalPpd = (comment) => {
    const params = {
      preHireRequestId: id,
      preHireRequestComment: comment,
    };

    Api("POST", `Job/CancelPhysicalPPDRequest`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleCancelForm(false);
          getPreHireList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Create PreHire Physical / PPD form
  const savePreHireForm = () => {
    const params = { ...formValues };
    params.companyId = selectedSuitCase.companyId;
    params.jobId = selectedSuitCase.jobId;
    params.applicantId = applicantId;
    params.duration = +formValues.duration;
    params.participants = formValues?.participants ?? [];
    params.participantList = !formValues?.participantList
      ? []
      : formValues?.participantList;

    Api("POST", `Job/SavePhysicalPPDRequest`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAptForm(false);
          getPreHireList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Accept PreHire Physical / PPD request
  const acceptPhysicalPPD = (slots) => {
    const params = {
      preHireRequestId: id,
      acceptedSlot: slots,
    };

    Api("POST", `Job/AcceptPhysicalPPDRequest`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAcceptForm(false);
          getPreHireList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // PreHire Pack accept icon
  const handleAcceptDoc = () => {
    var ans = window.confirm("Are you sure you want to Accept this document ?");
    if (ans) {
      handleDocPackIcons(1);
    }
  };

  // PreHire Pack cancel icon
  const cancleDocPack = (comment) => {
    handleDocPackIcons(2, comment);
  };

  // PreHire Pack delete icon
  const handleDeleteDoc = () => {
    var ans = window.confirm("Are you sure you want to delete this document ?");
    if (ans) {
      handleDocPackIcons(3);
    }
  };

  /**
   * For submitting the icons API call
   * @param {*} flag
   *
   * 1 for Accept, 2 for reject / cancel and 3 for delete
   *
   */
  const handleDocPackIcons = (flag, comment = "") => {
    const params = {
      preHireDocumentPackId: id,
      documentId: documentId,
      comments: comment,
      flag: flag,
    };

    Api("POST", `Job/PreHireDocumentPackIcons`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          togglePackCancelForm(false);
          getPreHireDocPackList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Create PreHire Document Pack
  const saveDocPack = (docPackList) => {
    const params = {
      companyId: selectedSuitCase.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantId,
      documentPackId: "",
      documentPackList: docPackList,
    };

    Api("POST", `Job/PreHireDocumentPackSave`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          togglePreHirePack(false);
          getPreHireDocPackList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleSendPackForm = (open) => {
    setSendDocPackForm(open);
  };

  const handleSendPack = (comment) => {
    const params = {
      preHireDocumentPackId: docPackList[0].preHireDocumentPackId,
      messageToApplicant: comment,
    };

    Api("POST", `Job/SendDocumentPackPreHire`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleSendPackForm(false);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveDocument = () => {
    formData.append(
      "preHireDocumentPackId",
      docPackList[0].preHireDocumentPackId
    );
    formData.append("SelectedDocLists", formValues?.SelectedDocLists ?? "");
    formData.append("UploadDocLists", formValues?.UploadDocLists ?? {});

    Api("POST", `Job/SaveExistingPreHireDocument`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAddDocForm(false);
          getPreHireDocPackList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Download the document
  const downloadDocument = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "document_" + url.split("/").at(-1));
      });
  };

  return (
    <div>
      <CreateAptForm
        toggleForm={toggleAptForm}
        openForm={openAptForm}
        formValues={formValues}
        setFormValues={setFormValues}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        applicantId={applicantId}
        jobId={selectedSuitCase.jobId}
        savePreHireForm={savePreHireForm}
      />

      <CancelPreHireForm
        toggleForm={toggleCancelForm}
        openForm={openCancelForm}
        applicantId={applicantId}
        cancelPhysicalPpd={cancelPhysicalPpd}
      />

      <AcceptPreHireForm
        toggleForm={toggleAcceptForm}
        openForm={openAcceptForm}
        applicantId={applicantId}
        selectedSlots={selectedSlots}
        acceptPhysicalPPD={acceptPhysicalPPD}
      />

      <CreatePreHirePack
        toggleForm={togglePreHirePack}
        openForm={openPreHirePack}
        applicantId={applicantId}
        saveDocPack={saveDocPack}
        companyId={selectedSuitCase.companyId}
      />

      <CancelDocPackForm
        toggleForm={togglePackCancelForm}
        openForm={openPackCancelForm}
        applicantId={applicantId}
        cancleDocPack={cancleDocPack}
      />

      <SendPackForm
        openForm={openSendDocPackForm}
        toggleForm={toggleSendPackForm}
        applicantId={applicantId}
        handleSendPack={handleSendPack}
      />

      <AddDocumentForm
        openForm={openAddDocForm}
        toggleForm={toggleAddDocForm}
        applicantId={applicantId}
        saveDocument={saveDocument}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <div>
        <ButtonUI p="9px" ml="70px" onClick={() => toggleAptForm(true)}>
          Request Physical/PPD
        </ButtonUI>
        <div className="profile-data mt-16">
          {physicalPpdList.map((rec, bindex) => (
            <div className="section border-bottom" key={bindex}>
              <div>
                <strong>{rec.appoinmentTypeName}</strong>
                <span className="status blue-completed ml-20">
                  {rec.preHireRequestStatus}
                </span>
              </div>
              <span className="scheduled">
                <label>
                  <strong>Participant :</strong>
                  {rec.participantsLists.map((participant) => (
                    <label> {participant.participantFullName}, </label>
                  ))}
                </label>
              </span>
              <span className="d-flex mt-5">
                <ButtonUI
                  variant="outlined"
                  fontSize="12px"
                  borderColor="#34AA44"
                  color="#34AA44"
                  mr="6px"
                  pr="0px"
                  onClick={() => (
                    toggleAcceptForm(true),
                    setSelectedSlots(rec.suggestedSlots),
                    setId(rec.preHireRequestId)
                  )}
                  rightIcon={<ChevronRightIcon fontSize="small" />}
                >
                  Accept
                </ButtonUI>

                {rec.preHireRequestStatus != "Cancelled" && (
                  <ButtonUI
                    variant="outlined"
                    fontSize="12px"
                    borderColor="#B80000"
                    color="#B80000"
                    mr="6px"
                    pr="0px"
                    onClick={() => (
                      toggleCancelForm(true), setId(rec.preHireRequestId)
                    )}
                    rightIcon={<ChevronRightIcon fontSize="small" />}
                  >
                    Cancel
                  </ButtonUI>
                )}
              </span>
            </div>
          ))}
        </div>
        {docPackList.length === 0 ? (
          <ButtonUI
            p="9px"
            mt="10px"
            ml="75px"
            mb="10px"
            onClick={() => togglePreHirePack(true)}
          >
            Create PreHire Pack
          </ButtonUI>
        ) : (
          <ButtonUI
            p="9px"
            mt="10px"
            ml="70px"
            onClick={() => toggleSendPackForm(true)}
          >
            Send Document Pack
          </ButtonUI>
        )}
        <div className="profile-data mt-10">
          {docPackList.map((docs) => (
            <>
              {docs.documentPackLists.map((doc, index) => (
                <div className="section border-bottom" key={index}>
                  <div>
                    <strong>{doc.displayFileName}</strong>
                    <span className="status blue-completed">
                      {doc?.documentStatus?.split("_")[0]}
                    </span>
                  </div>

                  <span className="scheduled">
                    <label>
                      <strong>Last Viewed :</strong>{" "}
                      {moment(doc.lastViewed).format("YYYY/MM/DD @ h:MM A")}
                    </label>
                  </span>
                  <span className="d-flex">
                    {/* Accept option */}
                    {doc.documentStatus === "SIGNED_PREHIRE" ||
                      doc.documentStatus === "DECLINED_PREHIRE" ||
                      (doc.documentStatus === "REJECTED_PREHIRE" && (
                        <ButtonUI
                          variant="outlined"
                          fontSize="12px"
                          borderColor="#34AA44"
                          color="#34AA44"
                          mr="6px"
                          pr="0px"
                          onClick={() => (
                            handleAcceptDoc(),
                            setId(docs.preHireDocumentPackId),
                            setDocumentId(doc.documentId)
                          )}
                          rightIcon={<ChevronRightIcon fontSize="small" />}
                        >
                          Accept
                        </ButtonUI>
                      ))}

                    {/* Cancel option */}
                    {doc.documentStatus === "SIGNED_PREHIRE" ||
                      (doc.documentStatus === "ACCEPTED_PREHIRE" && (
                        <ButtonUI
                          variant="outlined"
                          fontSize="12px"
                          borderColor="#B80000"
                          color="#B80000"
                          mr="6px"
                          pr="0px"
                          onClick={() => (
                            togglePackCancelForm(true),
                            setId(docs.preHireDocumentPackId),
                            setDocumentId(doc.documentId)
                          )}
                          rightIcon={<ChevronRightIcon fontSize="small" />}
                        >
                          Cancel
                        </ButtonUI>
                      ))}

                    {/* Delete option */}
                    {doc.documentStatus !== "DELIVERED_PREHIRE" && (
                      <ButtonUI
                        variant="outlined"
                        fontSize="12px"
                        borderColor="#E6492D"
                        color="#E6492D"
                        mr="6px"
                        pr="0px"
                        onClick={() => (
                          handleDeleteDoc(),
                          setId(docs.preHireDocumentPackId),
                          setDocumentId(doc.documentId)
                        )}
                        rightIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        Delete
                      </ButtonUI>
                    )}

                    {/* Download option */}
                    {doc.documentStatus === "SIGNED_PREHIRE" ||
                      (doc.documentStatus === "ACCEPTED_PREHIRE" && (
                        <ButtonUI
                          variant="outlined"
                          fontSize="12px"
                          color="#3954A5"
                          mr="6px"
                          pr="0px"
                          onClick={() => downloadDocument(doc.documentUrl)}
                          rightIcon={<ChevronRightIcon fontSize="small" />}
                        >
                          Download
                        </ButtonUI>
                      ))}
                  </span>
                </div>
              ))}
            </>
          ))}
          {docPackList.length > 0 && (
            <div className="text-center pointer">
              <ButtonUI
                p="7px"
                mt="10px"
                radius="6px"
                color="#1F68D1"
                borderColor="#1F68D1"
                variant="outlined"
                ml="80px"
                onClick={() => toggleAddDocForm(true)}
                rightIcon={<AddCircleOutlineOutlined fontSize="small" />}
              >
                Add Documents
              </ButtonUI>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
