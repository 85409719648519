import { useEffect, useState } from "react";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { indigo } from "@mui/material/colors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import moment from "moment";

export const AcceptInterviewForm = (props) => {
  const [slots, setSlots] = useState([]);
  const [value, setValue] = useState(props.availableSlots[0]);

  useEffect(() => {
    setSlots(props.availableSlots);
  }, [props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(props.interScheduleId, value);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <AddEditCol
      openForm={props.openForm}
      toggleForm={props.toggleForm}
      title="Accept Interview"
      handleSubmit={handleSubmit}
      applicantId={props.applicantId}
    >
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          className="ml-10 mt-10"
        >
          {slots.map((slot, index) => (
            <FormControlLabel
              key={index}
              style={{
                paddingTop: 10,
              }}
              value={slot.slots}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                />
              }
              label={moment(slot.slots).format("h:mm a, MMM Do, YYYY")}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </AddEditCol>
  );
};
