import React from "react";
import moment from "moment";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { useState, useEffect } from "react";
import Api from "../../../../../../../helper/Api";
import { toast } from "react-toastify";
import { ColoredLinearProgress } from "../../../../../coloredProgressBar";
import { NoColDataFound } from "../../../../../NoColDataFound";

export const ActivityTab = ({ applicantData, selectedSuitCase }) => {
  const [results, setResults] = useState([]);
  const [isLoader, setLoader] = useState(false);

  const getApplicantActivityLogsData = () => {
    setLoader(true);
    const params = {
      applicantId: applicantData.applicantId,
      userId: localStorage.getItem("user"),
      jobId: selectedSuitCase.jobId,
      jobApplicantId: selectedSuitCase.jobApplicantId,
    };

    Api("POST", `Job/GetApplicantActivityLogs`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setResults(res.data.data.lstActivityLogsDetails);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getApplicantActivityLogsData();
  }, []);

  return (
    <>
      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : results.length > 0 ? (
        <div className="tab-container p-16">
          <div className="notes-header-container">
            <span className="heading-label font-blue">
              Activity: ({results.length})
            </span>
          </div>
          <div className="notes-cards-container">
            {results.map((activity) => (
              <div className="notes-card" key={activity.step}>
                <div className="card-header">
                  <span className="name-label">{activity.status}</span>
                </div>
                {activity.step.split(" ").at(-1) ===
                activity.step.split(" ").at(-1).toUpperCase() ? (
                  <>
                    <div className="notes-desc">
                      {activity.step.replace(
                        activity.step.split(" ").at(-1),
                        activity.step.split(" ").at(-1)[0] +
                          activity.step
                            .split(" ")
                            .at(-1)
                            .slice(1)
                            .toLowerCase()
                            .replace("_", " ")
                      )}
                    </div>
                    <div className="header-detail">
                      <span className="header-labels">
                        {moment(new Date(activity.createdAt)).fromNow()}
                      </span>
                      <div className="dot-div" />
                      <span className="header-labels">
                        {moment(activity.createdAt).format("LT")}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="notes-desc">{activity.step}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoColDataFound />
      )}
    </>
  );
};
