import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { JobCard } from "../../../common/cards/JobCard";
import { ButtonUI } from "../../../ui/button";
import Api from "../../../../helper/Api";
import { useDispatch } from "react-redux";
import { JOB_DATA_ACTIONS } from "../../../../redux/jobData/actions";
import { AddEditJob } from "../../addEditPopup/AddEditJob";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ProfileCard } from "../../cards/ProfileCard";
import { APPLICANT_DATA_ACTIONS } from "../../../../redux/applicantData/actions";
import { Tooltip } from "@mui/material";
import { NoColDataFound } from "../../NoColDataFound";
import { ColoredLinearProgress } from "../../coloredProgressBar";
import { AddApplicantForm } from "./content/addApplicant";
import AddIcon from "@mui/icons-material/Add";

export const JobSidebar = () => {
  const { jobData } = useSelector((state) => state.jobDataReducer);
  const [isLoader, setIsLoader] = useState(false);
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  const location = useLocation();
  const [openTextbox, setOpenTextbox] = useState(false);
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [color, setColor] = useState("");
  const [jobProgressStatus, setJobProgressStatus] = useState("");
  const [count, setCount] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("NEW_APPLICATION");
  const [openAddForm, setAddForm] = useState(false);
  const formData = new FormData();
  const [isSubmitting, setSubmitting] = useState(false);
  const [jobStatusDescription, setJobStatusDescription] = useState(
    jobData.jobProgressStatusDescription
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: JOB_DATA_ACTIONS.SET_JOB_CYCLE_STATUS,
      payload: "NEW_APPLICATION",
    });

    if (jobData.jobProgressStatus === "") {
      setColor("");
    } else if (jobData.jobProgressStatus === "COMPLETE") {
      setColor("green");
    } else if (jobData.jobProgressStatus === "IN_PROGRESS") {
      setColor("yellow");
    } else if (jobData.jobProgressStatus === "NOT_STARTED") {
      setColor("pink");
    }

    getCount();
    getAllApplicants("createdAt", "desc", "NEW_APPLICATION");
  }, []);

  const handlejobApplicantStatus = (status) => {
    setSelectedStatus(status);
    getAllApplicants("createdAt", "desc", status);
  };

  const getAllApplicants = (
    columnName,
    direction,
    status,
    isAligable = false
  ) => {
    setIsLoader(true);
    Api(
      "GET",
      `Job/GetApplicantListForJob?companyId=${location.state.companyId}&jobId=${
        jobData.jobId
      }&jobCycleStatus=${status}&pageLimit=10&pageNumber=${1}&OrderBy=${columnName}&OrderByDirection=${direction}&isAligable=${isAligable}`
    )
      .then((res) => {
        let list = [...res?.data?.data];
        list.map((rec) => {
          rec.checked = false;
        });

        setApplicantList(list);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getCount = () => {
    Api(
      "GET",
      `Job/GetCount?jobId=${jobData.jobId}&companyId=${location.state.companyId}`
    )
      .then((res) => {
        setCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveProgressStatus = () => {
    const params = {
      jobId: jobData.jobId,
      companyId: location.state.companyId,
      jobProgressStatus: jobProgressStatus,
      jobProgressStatusDescription: jobStatusDescription,
    };

    Api("PUT", "Job/UpdateJobProgress", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setOpenTextbox(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const handleSubmit = (editor) => {
    setSubmitting(true);
    if (editor === undefined) {
      editor = formValues.description;
    }

    const params = {
      jobCode: jobData.jobCode,
      jobId: jobData.jobId,
      name: formValues.name,
      description: editor,
      positions: formValues.positions,
      createdAt: jobData.createdAt,
      jobStatus: formValues.jobStatus,
      priority: formValues.priority,
    };

    dispatch({
      type: JOB_DATA_ACTIONS.SET_JOB_DATA,
      payload: params,
    });

    formValues.description = editor;
    Api("PUT", "Job/EditJob", formValues)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  const showTextbox = (color) => {
    setColor(color);
    setOpenTextbox(true);
  };

  const handleChange = (e) => {
    setJobStatusDescription(e.target.value);
  };

  const editAction = (jobId) => {
    Api("GET", `Job/GetJobById?jobId=${jobId}`)
      .then((res) => {
        setFormValues(res.data);
        toggleDrawer("right", true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openApplicantDetails = (applicantData) => {
    if (applicantData.applicantList[0].jobCycleStatus === "NEW_APPLICATION") {
      Api(
        "PUT",
        `Job/UpdateCurrentStatus?companyId=${applicantData.companyId}&jobId=${applicantData.applicantList[0].jobId}&applicantId=${applicantData.applicantId}&status=REVIEWING`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            applicantData.applicantList[0].jobCycleStatus = "REVIEWING";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const record = { ...applicantData };
    applicantData.applicantList.forEach((rec) => {
      if (rec.applicantId === applicantData.applicantId) {
        record.isHotList = rec.isHotList;
      }
    });

    const data = [...applicantsArray];
    data.push(record);

    dispatch({
      type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
      payload: data,
    });
  };

  const toggleAddForm = (anchor, open) => {
    setAddForm({ ...openAddForm, [anchor]: open });
  };

  const handleAddApplicant = () => {
    formData.append("firstName", formValues?.firstName ?? "");
    formData.append("middleName", formValues?.middleName ?? "");
    formData.append("lastName", formValues?.lastName ?? "");
    formData.append("nickName", formValues?.nickName ?? "");
    formData.append("title", formValues?.title ?? "");
    formData.append("emailAddress", formValues?.emailAddress ?? "");
    formData.append("phoneNumber", formValues?.phoneNumber ?? "");
    formData.append("resume", formValues?.resume ?? "");
    formData.append("companyId", location.state.companyId);
    formData.append("jobId", jobData.jobId);
    formData.append("address.addressLineOne", formValues?.addressLineOne ?? "");
    formData.append("address.addressLineTwo", "");
    formData.append("address.country", "");
    formData.append("address.city", formValues?.city ?? "");
    formData.append("address.state", formValues?.state ?? "");
    formData.append("address.zipCode", formValues?.zipCode ?? "");
    formData.append("address.source", formValues?.source ?? "");

    Api("POST", `Job/AddApplicant`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAddForm("right", false);
          getAllApplicants("createdAt", "desc");
          getCount();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="user-list">
      <AddApplicantForm
        openAddForm={openAddForm}
        toggleDrawer={toggleAddForm}
        handleAddApplicant={handleAddApplicant}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <AddEditJob
        title="Edit Job"
        openSidebar={openSidebar}
        toggleDrawer={toggleDrawer}
        formValues={formValues}
        setFormValues={setFormValues}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setSubmitting={setSubmitting}
      />

      <div className="j-progress-status">
        <div className="j-status">
          <div
            className={
              color === "pink"
                ? "light-pink pointer b-border"
                : "light-pink pointer"
            }
            role="button"
            onClick={() => (
              showTextbox("pink"), setJobProgressStatus("NOT_STARTED")
            )}
          ></div>
          <div
            className={
              color === "yellow"
                ? "light-yellow pointer b-border"
                : "light-yellow pointer"
            }
            role="button"
            onClick={() => (
              showTextbox("yellow"), setJobProgressStatus("IN_PROGRESS")
            )}
          ></div>
          <div
            className={
              color === "green"
                ? "light-green pointer b-border"
                : "light-green pointer"
            }
            role="button"
            onClick={() => (
              showTextbox("green"), setJobProgressStatus("COMPLETE")
            )}
          ></div>
        </div>
        <div>
          <ButtonUI
            p="8px"
            pr="16px"
            leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
            onClick={() => (toggleAddForm("right", true), setFormValues({}))}
          >
            Add
          </ButtonUI>
        </div>
        {openTextbox ? (
          <div className="note-input">
            <div className="forms-input">
              <textarea
                type="text"
                id="note"
                placeholder="Note here.."
                value={jobStatusDescription}
                onChange={handleChange}
              />
            </div>
            <div className="btn-container">
              <ButtonUI
                p="8px"
                className="mr-10"
                variant="outlined"
                onClick={() => setOpenTextbox(false)}
              >
                Cancel
              </ButtonUI>
              <ButtonUI
                p="8px"
                pl="18px"
                onClick={saveProgressStatus}
                pr="18px"
              >
                Save
              </ButtonUI>
            </div>
          </div>
        ) : null}
      </div>

      <div className="js-card">
        <JobCard
          jobCode={jobData.jobCode}
          name={jobData.name}
          description={jobData.description}
          jobStatus={jobData.jobStatus}
          priority={jobData.priority}
          positions={jobData.positions}
          jobId={jobData.jobId}
          createdAt={jobData.createdAt}
          editAction={editAction}
          type="sidebar"
        />
        <li className="item mb-10" style={{ padding: 0, width: "100%" }}>
          <Tooltip placement="top" title="New Application">
            <span
              className={
                selectedStatus === "NEW_APPLICATION"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("NEW_APPLICATION")}
              style={{ marginRight: 5 }}
            >
              {count["NEW_APPLICATION"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Reviewing">
            <span
              className={
                selectedStatus === "REVIEWING"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("REVIEWING")}
              style={{ marginRight: 5 }}
            >
              {count["REVIEWING"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Video Interview">
            <span
              className={
                selectedStatus === "VIDEO_INTERVIEW"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("VIDEO_INTERVIEW")}
              style={{ marginRight: 5 }}
            >
              {count["VIDEO_INTERVIEW"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Interviewing">
            <span
              className={
                selectedStatus === "INTERVIEWING"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("INTERVIEWING")}
              style={{ marginRight: 5 }}
            >
              {count["INTERVIEWING"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Offering">
            <span
              className={
                selectedStatus === "OFFERING"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("OFFERING")}
              style={{ marginRight: 5 }}
            >
              {count["OFFERING"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Offer Letter">
            <span
              className={
                selectedStatus === "OFFER_LETTER"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("OFFER_LETTER")}
              style={{ marginRight: 5 }}
            >
              {count["OFFER_LETTER"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="PreHire">
            <span
              className={
                selectedStatus === "PREHIRE"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("PREHIRE")}
              style={{ marginRight: 5 }}
            >
              {count["PREHIRE"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="On Boarding">
            <span
              className={
                selectedStatus === "ONBOARDING"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("ONBOARDING")}
              style={{ marginRight: 5 }}
            >
              {count["ONBOARDING"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Hire">
            <span
              className={
                selectedStatus === "HIRE"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("HIRE")}
              style={{ marginRight: 5 }}
            >
              {count["HIRE"]}
            </span>
          </Tooltip>
        </li>
      </div>
      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : applicantList.length > 0 ? (
        <div className="inner-h job-sidebar">
          <div>
            <ul className="job_template_option_menu pointer">
              <div>
                {applicantList.map((applicant) => (
                  <div key={applicant.applicantId}>
                    <ProfileCard
                      applicantId={applicant.applicantId}
                      name={applicant.firstName + " " + applicant.lastName}
                      jobName={applicant.jobName}
                      jobId={applicant.jobId}
                      companyId={applicant.companyId}
                      jobCode={applicant.jobNo}
                      morePositions={applicant.morePositions}
                      createdAt={applicant.createdAt}
                      openApplicantDetails={openApplicantDetails}
                      checked={applicant.checked}
                      latestActivityMessage={applicant.latestActivityMessage}
                      applicantList={applicantList}
                      setApplicantList={setApplicantList}
                      setAllChecked={true}
                      status={applicant.jobHireStatus}
                    />
                  </div>
                ))}
              </div>
            </ul>
          </div>
        </div>
      ) : (
        <NoColDataFound />
      )}
    </div>
  );
};
