import { ApplicantSidebar } from "./sidebar";

export const ApplicantInfo = ({ isCompany }) => {
  return (
    <div
      className={
        isCompany === true ? "user-container company" : "user-container"
      }
    >
      <ApplicantSidebar />
    </div>
  );
};
