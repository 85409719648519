import "../../../assets/css/components/select-comp/_selectCompany.css";
import * as React from "react";
import { Login } from "@mui/icons-material";
import { ButtonUI } from "../../../components/ui/button";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { COMPANY_DATA_ACTIONS } from "../../../redux/companyData/actions";

export default function SelectCompany() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyList = [...location.state.companyList];

  const openCompany = (companyId, companyHirerchy) => {
    const params = {
      companyId: companyId,
      companyHirerchy: companyHirerchy,
    };

    dispatch({
      type: COMPANY_DATA_ACTIONS.SET_COMP_DATA,
      payload: params,
    });

    setTimeout(() => {
      navigate("/dashboard_new", { replace: true });
    }, 600);
  };

  return (
    <div>
      <div className="company-content">
        <div className="container">
          <img
            src={require("../../../assets/images/logo.png")}
            width={120}
            height={30}
          />
          <span className="choose-title">Choose your company to login</span>
          <div className="mb-15">
            {companyList.map((rec) => (
              <div className="company-row">
                <ButtonUI
                  p="10px"
                  rightIcon={<Login fontSize="small" />}
                  width={200}
                  onClick={() =>
                    openCompany(rec.companyId, rec.companyHirerchy)
                  }
                >
                  {rec.companyName}
                </ButtonUI>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
