import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box } from "@material-ui/core";
import { useState } from "react";
import { ButtonUI } from "../../ui/button";
import { CKEditor } from "ckeditor4-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OpenEditor({ formValues, setFormValues }) {
  const [open, setOpen] = useState(false);
  const [editor, setEditor] = useState("");

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOfferLetter = () => {
    setOpen(false);
  };

  const handleEditor = (e) => {
    formValues.docContent = e.editor.getData();
    setFormValues({ ...formValues, ["docContent"]: e.editor.getData() });
  };

  const config = {
    extraPlugins: "print,smiley,placeholder,timestamp,custom_options,justify",
    removePlugins: "pastefromword, pastetext,about",
  };

  return (
    <div>
      <ButtonUI onClick={handleClickOpen} p="9px" ml="110px">
        Open Editor
      </ButtonUI>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Document Content
            </Typography>
            <Button autoFocus color="inherit" onClick={handleEditOfferLetter}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Box style={{ height: "200%" }}>
          <CKEditor
            initData={editor}
            config={config}
            onChange={handleEditor}
            onReady={console.log("in e:  " + editor)}
            onBeforeLoad={(CKEDITOR) => {
              if (
                !CKEDITOR.plugins.registered["timestamp"] &&
                !CKEDITOR.plugins.registered["custom_options"]
              ) {
                CKEDITOR.plugins.add("timestamp", {
                  init: function (editor) {
                    editor.addCommand("insertTimestamp", {
                      exec: function (editor) {
                        var now = new Date();
                        editor.insertHtml(now.toString());
                      },
                    });
                    editor.ui.addButton("Timestamp", {
                      label: "Insert Timestamp",
                      command: "insertTimestamp",
                      toolbar: "insert",
                      icon: "https://cdn4.iconfinder.com/data/icons/24x24-free-pixel-icons/24/Clock.png",
                    });
                  },
                });
                CKEDITOR.plugins.add("custom_options", {
                  init: function (editor) {
                    editor.ui.addRichCombo("custom_options", {
                      label: "Customize",
                      toolbar: "basicstyles,0",
                      extraAllowedContent: "img[src,alt,width,height]",
                      icon: "https://cdn4.iconfinder.com/data/icons/basic-user-interface-elements/700/document-text-file-sheet-doc-512.png",

                      panel: {
                        css: [CKEDITOR.skin.getPath("editor")].concat(
                          config.contentsCss
                        ),
                        multiSelect: false,
                        attributes: {
                          "aria-label": "custom options",
                        },
                      },

                      init: function () {
                        this.startGroup("My custom options");
                        this.add(
                          "[Applicant_First_Name]",
                          "Applicant_First_Name"
                        );
                        this.add(
                          "[Applicant_Last_Name]",
                          "Applicant_Last_Name"
                        );
                        this.add(
                          "[Applicant_Full_Name]",
                          "Applicant_Full_Name"
                        );
                        this.add("[Company_Logo]", "Company_Logo");
                        this.add("[Company_Name]", "Company_Name");
                        this.add("[Current_Date]", "Current_Date");
                        this.add("[Department_Name]", "Department_Name");
                        this.add("[Facility_Name]", "Facility_Name");
                        this.add("[Job_Name]", "Job_Name");
                        this.add("[Pay_Rate]", "Pay_Rate");
                        this.add("[Start_Date]", "Start_Date");
                        this.add("[Supervisor_Names]", "Supervisor_Names");
                      },

                      onClick: function (value) {
                        editor.focus();
                        if (value == "[Company_Logo]") {
                          editor.insertHtml(
                            "<img src=" +
                              localStorage.getItem("companyLogoPath") +
                              ">"
                          );
                        } else {
                          editor.insertHtml(value);
                        }
                      },
                    });
                  },
                });
              }
            }}
          />
        </Box>
      </Dialog>
    </div>
  );
}
