import Checkbox from '@material-ui/core/Checkbox';
import { indigo } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

export const BlueCheckbox = withStyles({
    root: {
      '&$checked': {
        color: indigo[600],
      },
    },
    checked: {},
}) ((props) => <Checkbox color="default" {...props} />);
