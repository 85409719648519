import React from "react";

export const UserHotListIcon = (props) => {
  return (
    <svg viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M10.3025 13.6341C10.4481 12.8523 10.7967 12.1889 11.4702 11.7253C11.6834 11.5764 11.9203 11.4748 12.1809 11.5019C12.4482 11.529 12.6005 11.4207 12.7088 11.1838C12.8408 10.8893 12.7833 10.6998 12.4753 10.5847C12.164 10.4697 11.8526 10.3546 11.5345 10.2598C10.3939 9.91462 9.22967 9.70479 8.03836 9.72848C5.88588 9.76909 3.85523 10.3207 1.94642 11.3192C1.27969 11.6677 0.772033 12.1653 0.440361 12.8421C0.16284 13.4039 0.128995 14.0064 0.112073 14.6189C0.0849981 15.57 0.799109 16.2299 1.68921 16.2096C3.0362 16.1792 4.3832 16.2029 5.73358 16.2029C7.15503 16.2029 8.57648 16.2029 9.99793 16.2029C10.3161 16.2029 10.4785 16.0032 10.3871 15.7087C10.1739 15.0251 10.1705 14.3313 10.3025 13.6341Z"
        fill={!!props.fill ? props.fill : "#fff"}
      />
      <path
        d="M8.035 8.9873C10.4244 9.00084 12.3738 7.06495 12.384 4.66541C12.3941 2.29294 10.4616 0.350284 8.079 0.333362C5.69637 0.31644 3.74695 2.24555 3.7368 4.63833C3.72326 7.02773 5.64561 8.97376 8.035 8.9873Z"
        fill={!!props.fill ? props.fill : "#fff"}
      />
      <path
        d="M17.4267 14.3245C17.3624 13.3329 16.9224 12.4733 16.2286 11.7558C15.765 11.2752 15.4841 10.7167 15.413 10.0534C15.3825 9.76573 15.105 9.6202 14.8681 9.78265C14.0051 10.3682 13.406 11.1533 13.159 12.172C13.0676 12.5511 13.0371 12.9471 12.9762 13.36C12.7393 13.1535 12.5972 12.8997 12.5295 12.5985C12.4516 12.2363 12.1741 12.1179 11.8797 12.348C11.6935 12.4936 11.5142 12.6729 11.3923 12.876C10.932 13.6443 10.8373 14.4836 11.0776 15.3365C11.7375 17.6785 14.3605 18.1794 15.9511 17.1776C16.9868 16.521 17.5012 15.5463 17.4267 14.3245Z"
        fill={!!props.fill ? props.fill : "#fff"}
      />
    </svg>
  );
};
