import { JobSidebar } from "./sidebar";
import { ApplicantContent } from "../applicantInfo/content";
import { useSelector } from "react-redux";
import { memo } from "react";

export const JobInfo = (props) => {
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  return (
    <div
      className={
        props?.isCompany === true ? "user-container company" : "user-container"
      }
    >
      <JobSidebar />
      {applicantsArray.map((data, index) => (
        <ApplicantContent data={data} index={index} />
      ))}
    </div>
  );
};

export default memo(JobInfo);
