import React from "react";

export const EyeIcon = ({ ...props }) => {
  return (
    <svg viewBox="0 0 24 16" fill="#475467" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path 
          d="M23.1853 7.69516C23.1524 7.6211 22.3585 5.85989 20.5928 4.09412C18.2412 1.74222 15.2698 0.499023 12 0.499023C8.73017 0.499023 5.75874 1.74222 3.40711 4.09412C1.64142 5.8599 0.847573 7.62109 0.814613 7.69516C0.772012 7.79106 0.75 7.89482 0.75 7.99976C0.75 8.10469 0.772012 8.20846 0.814613 8.30435C0.847573 8.37851 1.64152 10.1393 3.4072 11.9048C5.75883 14.2561 8.73017 15.499 12 15.499C15.2698 15.499 18.2411 14.2561 20.5928 11.9048C22.3584 10.1393 23.1524 8.37851 23.1853 8.30436C23.2279 8.20846 23.25 8.10469 23.25 7.99976C23.25 7.89482 23.2279 7.79106 23.1853 7.69516ZM12 4.62485C12.6675 4.62485 13.32 4.82279 13.875 5.19364C14.43 5.56449 14.8626 6.09159 15.1181 6.70829C15.3735 7.32499 15.4404 8.00359 15.3101 8.65828C15.1799 9.31296 14.8585 9.91433 14.3865 10.3863C13.9145 10.8583 13.3131 11.1798 12.6584 11.31C12.0037 11.4402 11.3251 11.3734 10.7084 11.1179C10.0917 10.8625 9.56462 10.4299 9.19377 9.8749C8.82292 9.31988 8.62498 8.66736 8.62498 7.99985C8.62598 7.10505 8.98189 6.24719 9.6146 5.61448C10.2473 4.98176 11.1052 4.62585 12 4.62485Z" 
        />
    </svg>    
  );
};
