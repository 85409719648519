import { GreyCard } from "../../../../../common/cards/GreyCard";
import { useState } from "react";
import { EditAppointment } from "./editAppointment";
import { useSelector } from "react-redux";
import { validations } from "../../../../../../helper/validations";
import Api from "../../../../../../helper/Api";
import { toast } from "react-toastify";
import NoRecordsFound from "../../../../../common/NoRecordsFound";

export const JobTempAppointments = () => {
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const errors = {};
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const appointments = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.appointments
  );
  const count = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.countDetails
  );

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
    setFormValues([]);
    setFormErrors({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      jobTemplateAppointmentId: formValues.jobTemplateAppointmentId,
      companyId: companyData.companyId,
      jobTemplateId: companyData.data.jobTemplateId,
      appointmentId: formValues.appointmentId,
      appointmentName: formValues.appointmentName,
      duration: +formValues.duration,
      timeBetween: +formValues.timeBetween,
      slotClose: +formValues.slotClose,
    };

    Api("PUT", `JobTemplate/EditJobTemplateAppointment`, params)
      .then((res) => {
        if (res.data.isSuccess == true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);

          let index = appointments
            .map((object) => object.jobTemplateAppointmentId)
            .indexOf(params.jobTemplateAppointmentId);
          appointments[index] = params;
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEdit = (id) => {
    let appointmentData = appointments.find((apt) => apt.appointmentId == id);
    toggleDrawer("right", true);
    setFormValues(appointmentData);
  };

  const handleDelete = (id) => {
    let appointmentData = appointments.find((apt) => apt.appointmentId == id);
    // DELETE action code
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      // DELETE API call
      Api(
        "DELETE",
        `JobTemplate/DeleteJobTemplateAppointment?companyId=${companyData.companyId}&jobTemplateId=${companyData.data.jobTemplateId}&jobTemplateAppointmentId=${appointmentData.jobTemplateAppointmentId}&appointmentId=${appointmentData.appointmentId}`
      )
        .then((res) => {
          if (res.data.isSuccess == true) {
            toast.success(res.data.message);
            toggleDrawer("right", false);

            let index = appointments.indexOf(appointmentData);
            appointments.splice(index, 1);
            count.appointments = appointments.length;
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const validate = (values) => {
    if (!values.duration) {
      errors.duration = validations?.duartion?.req;
    }

    if (!values.timeBetween) {
      errors.timeBetween = validations?.timeBetween?.req;
    }

    if (!values.slotClose) {
      errors.slotClose = validations?.slotClose?.req;
    }

    return errors;
  };

  return (
    <div>
      <EditAppointment
        openSidebar={openSidebar}
        toggleDrawer={toggleDrawer}
        formValues={formValues}
        setFormValues={setFormValues}
        handleSubmit={handleSubmit}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
      <div className="top-header-user">
        <span className="main-name">Appointments ({appointments.length})</span>
      </div>
      <div>
        {appointments.length > 0 ? (
          <div className="g-card-container">
            {appointments.map((appointment) => (
              <GreyCard
                darkTitle={appointment.appointmentName}
                id={appointment.appointmentId}
                duration={appointment.duration}
                timeBetween={appointment.timeBetween}
                slotHrs={appointment.slotClose}
                key={appointment.appointmentId}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </div>
  );
};
