import { LinearProgress } from "@mui/material";

export const ColoredLinearProgress = () => {
  return (
    <LinearProgress
      sx={{
        backgroundColor: "#3954A5",
        "& .MuiLinearProgress-bar": {
          backgroundColor: "#DEEBFF",
        },
      }}
    />
  );
};
