export const CompanyIcon = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 19.498H21.7495V9.74805C21.749 9.35036 21.5908 8.9691 21.3096 8.68789C21.0284 8.40668 20.6471 8.2485 20.2495 8.24805H14.2495V3.74805C14.249 3.35036 14.0908 2.9691 13.8096 2.68789C13.5284 2.40668 13.1471 2.2485 12.7495 2.24805H3.74945C3.35177 2.2485 2.9705 2.40668 2.68929 2.68789C2.40809 2.9691 2.24991 3.35036 2.24945 3.74805V19.498H1.5C1.30109 19.498 1.11032 19.5771 0.96967 19.7177C0.829018 19.8584 0.75 20.0491 0.75 20.248C0.75 20.447 0.829018 20.6377 0.96967 20.7784C1.11032 20.919 1.30109 20.998 1.5 20.998H22.5C22.6989 20.998 22.8897 20.919 23.0303 20.7784C23.171 20.6377 23.25 20.447 23.25 20.248C23.25 20.0491 23.171 19.8584 23.0303 19.7177C22.8897 19.5771 22.6989 19.498 22.5 19.498ZM11.2495 12.748C11.2494 12.947 11.1704 13.1377 11.0298 13.2784C10.8891 13.419 10.6984 13.498 10.4995 13.498H7.49945C7.30054 13.498 7.10977 13.419 6.96912 13.2784C6.82847 13.1377 6.74945 12.947 6.74945 12.748C6.74945 12.5491 6.82847 12.3584 6.96912 12.2177C7.10977 12.0771 7.30054 11.998 7.49945 11.998H10.4995C10.6984 11.998 10.8891 12.0771 11.0298 12.2177C11.1704 12.3584 11.2494 12.5491 11.2495 12.748ZM5.99945 5.99805H8.99945C9.19836 5.99805 9.38913 6.07706 9.52978 6.21772C9.67043 6.35837 9.74945 6.54913 9.74945 6.74805C9.74945 6.94696 9.67043 7.13772 9.52978 7.27838C9.38913 7.41903 9.19836 7.49805 8.99945 7.49805H5.99945C5.80054 7.49805 5.60977 7.41903 5.46912 7.27838C5.32847 7.13772 5.24945 6.94696 5.24945 6.74805C5.24945 6.54913 5.32847 6.35837 5.46912 6.21772C5.60977 6.07706 5.80054 5.99805 5.99945 5.99805ZM5.99945 15.748H8.99945C9.19836 15.748 9.38913 15.8271 9.52978 15.9677C9.67043 16.1084 9.74945 16.2991 9.74945 16.498C9.74945 16.697 9.67043 16.8877 9.52978 17.0284C9.38913 17.169 9.19836 17.248 8.99945 17.248H5.99945C5.80054 17.248 5.60977 17.169 5.46912 17.0284C5.32847 16.8877 5.24945 16.697 5.24945 16.498C5.24945 16.2991 5.32847 16.1084 5.46912 15.9677C5.60977 15.8271 5.80054 15.748 5.99945 15.748ZM14.2495 9.74805H20.2495V19.498H14.2495V9.74805Z"
        {...props}
      />
      <path
        d="M17.9995 15.748H16.4995C16.3006 15.748 16.1098 15.8271 15.9692 15.9677C15.8285 16.1084 15.7495 16.2991 15.7495 16.498C15.7495 16.697 15.8285 16.8877 15.9692 17.0284C16.1098 17.169 16.3006 17.248 16.4995 17.248H17.9995C18.1984 17.248 18.3892 17.169 18.5298 17.0284C18.6705 16.8877 18.7495 16.697 18.7495 16.498C18.7495 16.2991 18.6705 16.1084 18.5298 15.9677C18.3892 15.8271 18.1984 15.748 17.9995 15.748Z"
        {...props}
      />
      <path
        d="M16.4995 13.498H17.9995C18.1984 13.498 18.3892 13.419 18.5298 13.2784C18.6705 13.1377 18.7495 12.947 18.7495 12.748C18.7495 12.5491 18.6705 12.3584 18.5298 12.2177C18.3892 12.0771 18.1984 11.998 17.9995 11.998H16.4995C16.3006 11.998 16.1098 12.0771 15.9692 12.2177C15.8285 12.3584 15.7495 12.5491 15.7495 12.748C15.7495 12.947 15.8285 13.1377 15.9692 13.2784C16.1098 13.419 16.3006 13.498 16.4995 13.498Z"
        {...props}
      />
    </svg>
  );
};
