import React from "react";
import { Link } from "react-router-dom";
import { HelpIcon, SearchIcon } from "../../../icons";
import "../../../assets/css/components/navigation-bar/_navigation-bar.css";
import ProfileMenu from "../../ui/profileMenu";
import { userAsCompany } from "../../../helper/constants";
import { IconButton, Tooltip } from "@mui/material";
import { NotificationMenu } from "../../ui/notificationMenu";

export const NavigationBar = () => {
  const authToken = localStorage.getItem("token");

  return (
    <div className={userAsCompany() ? "navbar company-active" : "navbar"}>
      {userAsCompany() ? (
        <div className="w-100 d-flex align-items-center">
          <img src={require("../../../assets/images/menu.png")} alt="icon" />
          <p className="menu-company mr-auto">Menu</p>
        </div>
      ) : (
        <img
          src={require("../../../assets/images/logo_white.png")}
          alt="nav-logo"
          className="whitelogo_img"
        />
      )}
      {authToken ? (
        <div className="navbar_options">
          <div className="user_options">
            <Link to="/search">
              <Tooltip title="Search">
                <IconButton size="small">
                  <SearchIcon
                    fill={userAsCompany() ? "#3954A5" : "#fff"}
                    width="22"
                    height="22"
                    className="icon search-icon"
                  />
                </IconButton>
              </Tooltip>
            </Link>
            <NotificationMenu />
            {/* <CSSTransition
                in={isActive}
                classNames="item"
                timeout={500}
                unmountOnExit
              >
                {isActive ? (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setIsActive(false);
                    }}
                  >
                    <div className="notifi-popup-nav">
                      <p>Notification</p>
                      {notifications.map((items, i) =>
                        i < notificationLength ? (
                          <div className="cards-notifi" key={items.id}>
                            <img
                              src={require("../../../" + items.img)}
                              alt="profile"
                              className="profile-notification"
                            />
                            <div className="cards-name">
                              <div
                                className="cards-heading"
                                dangerouslySetInnerHTML={{
                                  __html: items.notification,
                                }}
                              ></div>
                              <p className="timing">{items.time + " Min"}</p>
                              <span
                                className={items.read ? "read" : "read active"}
                              ></span>
                            </div>
                          </div>
                        ) : null
                      )}
                      <Link to="/notification">
                        <button className="btn-notifi">
                          See all notifications
                        </button>
                      </Link>
                    </div>
                  </OutsideClickHandler>
                ) : (
                  <div></div>
                )}
              </CSSTransition> */}
            <Link to="/help">
              <Tooltip title="Help">
                <IconButton size="small">
                  <HelpIcon
                    fill={userAsCompany() ? "#3954A5" : "#fff"}
                    width="22"
                    height="22"
                    className="icon search-icon"
                  />
                </IconButton>
              </Tooltip>
            </Link>
            <div
              className={
                userAsCompany() ? "divider-line company-active" : "divider-line"
              }
            ></div>
            <div className="profile">
              <ProfileMenu />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NavigationBar;
