import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { JOB_TEMPLATE_ACTIONS } from "../../../../redux/jobTemplate/actions";
import { EditJobTemplate } from "../editJobTemplate";
import { validations } from "../../../../helper/validations";
import Api from "../../../../helper/Api";
import { toast } from "react-toastify";
import { EditDeleteMenu } from "../../../ui/editDeleteMenu";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const [openSidebar, setSidebar] = useState(true);
  const description = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.description
  );
  const [formValues, setFormValues] = useState(description);
  const [formErrors, setFormErrors] = useState([]);
  const location = useLocation();
  const currentMenu = useSelector(
    (state) => state.jobTemplateReducer.currentMenu
  );
  const [jobTemplateData, setjobTempData] = useState(location.state.data);
  const countDetails = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.countDetails
  );
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const navigate = useNavigate();
  const errors = {};

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const openDetails = (viewName) => {
    dispatch({
      type: JOB_TEMPLATE_ACTIONS.SET_MENU,
      payload: viewName,
    });
  };

  const getJobTemplateData = () => {
    Api(
      "GET",
      `JobTemplate/GetJobTemplateById?companyId=${companyData.companyId}&jobTemplateId=${companyData.data.jobTemplateId}`
    )
      .then((res) => {
        dispatch({
          type: JOB_TEMPLATE_ACTIONS.SET_JOB_TEMP_DATA,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    Api("PUT", "JobTemplate/EditJobTemplate", formValues)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getJobTemplateData();
          toggleDrawer("right", false);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validate = (values) => {
    if (!values.companyId) {
      errors.companyId = validations?.companyId?.req;
    }

    if (!values.name) {
      errors.name = validations?.name?.req;
    }

    if (!values.description) {
      errors.description = validations?.description?.req;
    }

    return errors;
  };

  const deleteJobTemplate = () => {
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      // Delete Job Template api call
      Api(
        "DELETE",
        `JobTemplate/DeleteJobTemplate?companyId=${companyData.companyId}&jobTemplateId=${companyData.data.jobTemplateId}`
      )
        .then((res) => {
          if (res.data.isSuccess == true) {
            toast.success(res.data.message);
            navigate("/job-templates", { state: { ...companyData } });
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleEdit = () => {
    toggleDrawer("right", true);
  };

  return (
    <div className="user-list">
      <EditJobTemplate
        openSidebar={openSidebar}
        toggleDrawer={toggleDrawer}
        formValues={formValues}
        setFormValues={setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        handleSubmit={handleSubmit}
        label="Edit Job Template"
      />

      <div className="inner-h">
        <div className="job-t-h-1">
          <span>{jobTemplateData.jobTemplateNo}</span>
          <EditDeleteMenu
            id={jobTemplateData.jobTemplateId}
            onEdit={handleEdit}
            onDelete={deleteJobTemplate}
          />
        </div>

        <div className="job-t-h-2">
          <span>{jobTemplateData.name}</span>
          <p>{jobTemplateData.description}</p>
          <span
            className={
              jobTemplateData.isActive == true
                ? "status-label blue"
                : "status-label grey"
            }
          >
            {jobTemplateData.isActive == true ? "ACTIVE" : "INACTIVE"}
          </span>
        </div>

        <div>
          <ul className="job_template_option_menu pointer">
            <li
              className={
                currentMenu == "description" ? "item selected" : "item"
              }
              onClick={() => openDetails("description")}
            >
              <span>Description</span>
              <span className="description">
                <img src={require("../../../../assets/images/arrow.png")} />
              </span>
            </li>
            <li
              className={currentMenu == "activity" ? "item selected" : "item"}
              onClick={() => openDetails("activity")}
            >
              <span>Activity</span>
              <span
                className={countDetails?.activity > 0 ? "count" : "count zero"}
              >
                {countDetails?.activity}
              </span>
            </li>
            <li
              className={
                currentMenu == "appointments" ? "item selected" : "item"
              }
              onClick={() => openDetails("appointments")}
            >
              <span>Appointments</span>
              <span
                className={
                  countDetails?.appointments > 0 ? "count" : "count zero"
                }
              >
                {countDetails?.appointments}
              </span>
            </li>
            <li
              className={currentMenu == "benefits" ? "item selected" : "item"}
              onClick={() => openDetails("benefits")}
            >
              <span>Benefits</span>
              <span
                className={countDetails?.benefits > 0 ? "count" : "count zero"}
              >
                {countDetails?.benefits}
              </span>
            </li>
            <li
              className={
                currentMenu == "checklist-items" ? "item selected" : "item"
              }
              onClick={() => openDetails("checklist-items")}
            >
              <span>Checklist Items</span>
              <span
                className={
                  countDetails?.checklistItems > 0 ? "count" : "count zero"
                }
              >
                {countDetails?.checklistItems}
              </span>
            </li>
            <li
              className={currentMenu == "documents" ? "item selected" : "item"}
              onClick={() => openDetails("documents")}
            >
              <span>Documents</span>
              <span
                className={countDetails?.documents > 0 ? "count" : "count zero"}
              >
                {countDetails?.documents}
              </span>
            </li>
            <li
              className={
                currentMenu == "document-packs" ? "item selected" : "item"
              }
              onClick={() => openDetails("document-packs")}
            >
              <span>Document Packs</span>
              <span
                className={
                  countDetails?.documentPacks > 0 ? "count" : "count zero"
                }
              >
                {countDetails?.documentPacks}
              </span>
            </li>
            <li
              className={currentMenu == "job-boards" ? "item selected" : "item"}
              onClick={() => openDetails("job-boards")}
            >
              <span>Job Boards</span>
              <span
                className={countDetails?.jobBoards > 0 ? "count" : "count zero"}
              >
                {countDetails?.jobBoards}
              </span>
            </li>
            <li
              className={currentMenu == "notes" ? "item selected" : "item"}
              onClick={() => openDetails("notes")}
            >
              <span>Notes</span>
              <span
                className={countDetails?.notes > 0 ? "count" : "count zero"}
              >
                {countDetails?.notes}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
