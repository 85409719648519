import { TextareaAutosize } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { UserHotListIcon } from "../../../../../../../icons";
import { ButtonUI } from "../../../../../../ui/button";
import Api from "../../../../../../../helper/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../../../../../redux/applicantData/actions";

export const HotApplicantTab = ({
  applicantData,
  setIsHotList,
  selectedSuitCase,
}) => {
  const [comment, setComment] = useState("");
  const [hotListComment, setShowHotListComment] = useState(false);
  const [applicantDetails, setApplicantDetails] = useState({});
  const dispatch = useDispatch();
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  useEffect(() => {
    const applicants = [...applicantData.applicantList];
    const applicant = applicants.find(
      (applicant) => applicant.applicantId === applicantData.applicantId
    );
    setApplicantDetails(applicant);
  }, []);

  function addToHotlist() {
    var answer = window.confirm("Are you sure ?");
    if (answer) {
      Api(
        "PUT",
        `Job/AddToHotList?companyId=${applicantData.companyId}&applicantId=${
          applicantData.applicantId
        }&jobId=${selectedSuitCase.jobId}&isHotList=${true}`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            setIsHotList(true);
            setShowHotListComment(true);
            const data = [...applicantsArray];
            data.map((rec) => {
              if (rec.applicantId === applicantDetails.applicantId) {
                rec.isHotList = true;
              }
            });
            dispatch({
              type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
              payload: data,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function addHotListComment() {
    const params = {
      companyId: applicantData.companyId,
      applicantId: applicantData.applicantId,
      jobId: selectedSuitCase.jobId,
      hotListComment: comment,
    };

    Api("POST", `Job/AddHotListComment`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setComment("");
          setShowHotListComment(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="tab-container p-0-16 mt-15">
      <div className="notes-header-container">
        <span className="heading-label font-blue">Hot Applicant</span>
        <ButtonUI
          variant="outlined"
          p="4px"
          borderColor="#EBEBEB"
          leftIcon={
            <div className="hot-list-icon">
              <UserHotListIcon width="15px" height="15px" />
            </div>
          }
          onClick={() => addToHotlist()}
        >
          {applicantDetails.isHotList ? "Hot listed" : "Add to Hot List"}
        </ButtonUI>
      </div>
      {hotListComment && (
        <div className="hot-applicant-note-container">
          <div className="text-area-container">
            <TextareaAutosize
              aria-label="minimum height"
              minRows={4}
              maxRows={4}
              placeholder="Note Here"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              name="hotListComment"
              style={{
                resize: "none",
                width: "276px",
                border: "none",
                outline: "none",
                fontFamily: "Inter",
              }}
            />
          </div>
          <div className="applicant-btn-container">
            <ButtonUI
              mr="16px"
              p="8px"
              width={"80px"}
              onClick={addHotListComment}
            >
              Save
            </ButtonUI>
            <ButtonUI
              variant="outlined"
              p="8px"
              width="80px"
              onClick={() => setShowHotListComment(false)}
            >
              Cancel
            </ButtonUI>
          </div>
        </div>
      )}
    </div>
  );
};
