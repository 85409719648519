import React from 'react';
import NavigationBar from '../../common/navigationBar/NavigationBar';
import { CompanySideBar } from '../../common/sideBar/CompanySideBar';
import { useLocation } from 'react-router-dom';

export function CompanyDetailLayout({ ...props }) {

    const location = useLocation();

    const params = {
        componentName: location.pathname.split('/')[1],
        companyId: location.state.companyId,
        companyHirerchy: location.state.companyHirerchy
    };

    return (
        <div className='h-100'>
            <NavigationBar />
            <CompanySideBar params={params}/>
            <>{props.children}</>
        </div>
    );
}
