export const BlueAddIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="41"
        height="41"
        rx="7.5"
        fill="#F2F5FF"
        stroke="#3954A5"
      />
      <path
        d="M21.5781 19.6562H28.0156V21.7812H21.5781V28.4062H19.4375V21.7812H13V19.6562H19.4375V13H21.5781V19.6562Z"
        fill="#3954A5"
      />
    </svg>
  );
};
