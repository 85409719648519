import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { CheckboxList } from "../../../../CheckboxList";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useRef } from "react";

export const AddDocumentForm = ({
  openForm,
  toggleForm,
  applicantId,
  saveDocument,
  formValues,
  setFormValues,
}) => {
  const [allData, setAllData] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const uploadDoc = useRef();

  useEffect(() => {
    Api(
      "GET",
      `Job/GetExistingPreHireDocumentList?companyId=${companyData.companyId}`
    )
      .then((res) => {
        const response = res.data.map((d) => {
          return { ...d, id: d.documentId, name: d.internalName };
        });

        setAllData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [openForm]);

  const handleChange = (checkName, isChecked) => {
    const checkList = allData.map((data) => {
      if (data.id === checkName) {
        data.checked = isChecked;
      }
      return data;
    });

    const data = [...checkList];
    const checkedDocs = [];
    data.forEach((rec) => {
      if (rec.checked === true) {
        checkedDocs.push(rec.documentId);
      }
    });

    setFormValues({ ...formValues, ["SelectedDocLists"]: checkedDocs });
    setAllData(checkList);
  };

  const openAttachment = () => {
    uploadDoc.current.click();
  };

  const handleSelectedFile = (e, index) => {
    const data = [...allData];
    setFormValues({ ...formValues, ["UploadDocLists"]: e.target.files[0] });
    data.push({ id: 1, checked: true, name: e.target.files[0].name });
    setAllData(data);
  };

  return (
    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      title="Add Document"
      handleSubmit={saveDocument}
    >
      <input
        type="file"
        ref={uploadDoc}
        hidden
        className="pointer"
        onChange={(e) => handleSelectedFile(e, 0)}
      />
      <CheckboxList
        allData={allData}
        setAllData={setAllData}
        showSaveContainer={false}
        handleChange={handleChange}
      />
      <div className="forms-input">
        <div
          className="d-flex mt-10 justify-content-center pointer"
          role="button"
          onClick={openAttachment}
        >
          <FileUploadOutlinedIcon color="action" />
          <label>Upload new documents</label>
        </div>
        <span className="success_msg">
          *Allowed file types are jpg, png, pdf, doc, docs only
        </span>
      </div>
    </AddEditCol>
  );
};
