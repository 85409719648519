import { GreyCard } from "../../../../../common/cards/GreyCard";
import { useState } from "react";
import { AddNote } from "./addNote";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { validations } from "../../../../../../helper/validations";
import Api from "../../../../../../helper/Api";
import NoRecordsFound from "../../../../../common/NoRecordsFound";
import AddIcon from "@mui/icons-material/Add";
import { JOB_TEMPLATE_ACTIONS } from "../../../../../../redux/jobTemplate/actions";
import { ButtonUI } from "../../../../../ui/button";

export const Notes = () => {
  const [openSidebar, setSidebar] = useState(true);
  const [formValues, setFormValues] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const notes = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.notes
  );
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const errors = {};
  const dispatch = useDispatch();

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
    setFormValues([]);
  };

  const getJobTemplateData = () => {
    Api(
      "GET",
      `JobTemplate/GetJobTemplateById?companyId=${companyData.companyId}&jobTemplateId=${companyData.data.jobTemplateId}`
    )
      .then((res) => {
        dispatch({
          type: JOB_TEMPLATE_ACTIONS.SET_JOB_TEMP_DATA,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      companyId: companyData.companyId,
      jobTemplateId: companyData.data.jobTemplateId,
      description: formValues.note,
    };

    Api("POST", `JobTemplate/AddJobTemplateNotes`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
          getJobTemplateData();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validate = (values) => {
    if (!values.note) {
      errors.note = validations?.note?.req;
    }

    return errors;
  };

  return (
    <div>
      <AddNote
        openSidebar={openSidebar}
        toggleDrawer={toggleDrawer}
        formValues={formValues}
        setFormValues={setFormValues}
        handleSubmit={handleSubmit}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
      <div className="top-header-user notes">
        <span className="main-name">Notes</span>
        <ButtonUI
          p="12px"
          pr="16px"
          leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
          onClick={() => toggleDrawer("right", true)}
        >
          Add
        </ButtonUI>
      </div>
      {notes.length > 0 ? (
        <div className="g-card-container">
          {notes.map((note) => (
            <GreyCard
              cardHeader1={note.loginUserName}
              cardHeader2={note.description}
              headerTimeStamp={note.createdAt}
              key={note.jobTemplateNotesId}
            />
          ))}
        </div>
      ) : (
        <NoRecordsFound />
      )}
    </div>
  );
};
