import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.css";
import "froala-editor/js/plugins.pkgd.min.js";
import Froala from "react-froala-wysiwyg";
import React from "react";
import FroalaEditor from "froala-editor";
import { useEffect } from "react";
import { loadCSS } from "fg-loadcss";

const CustomFroalaEditor = ({ content, setContent }) => {
  useEffect(() => {
    //add font awesome 
    const node = loadCSS(
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css",
      // Inject before JSS
      document.querySelector("#font-awesome-css") || document.head.firstChild
    );
    FroalaEditor.DefineIconTemplate('font_awesome_radio', '<i class="fa-regular fa-[FA5NAME]" aria-hidden="true"></i>');
    // Insert custom Radio button
    FroalaEditor.DefineIcon('insert-radio', { FA5NAME: 'circle-dot', template: 'font_awesome_radio', ALT: "Insert Radio" });
    //FroalaEditor.DefineIcon("insert-radio", { NAME: "plus", SVG_KEY: "add" });
    FroalaEditor.RegisterCommand("insert-radio", {
      title: "Insert Radio",
      icon: "insert-radio",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        this.html.insert(`<input type="radio"/>`);
      },
    });

    // Custom variables options
    FroalaEditor.DefineIcon("customize", {
      NAME: "file-text-o",
      SVG_KEY: "cogs",
    });
    FroalaEditor.RegisterCommand("customize", {
      title: "Customize",
      type: "dropdown",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      options: {
        "[Applicant_First_Name]": "Applicant_First_Name",
        "[Applicant_Last_Name]": "Applicant_Last_Name",
        "[Applicant_Full_Name]": "Applicant_Full_Name",
        "[Company_Logo]": "Company_Logo",
        "[Company_Name]": "Company_Name",
        "[Job_Name]": "Job_Name",
        "[Job_Code]": "Job_Code",
        "[Job_Name_Code]": "Job_Name_Code",
        "[LoginID]": "LoginID",
        "[UserName]": "UserName",
        "[CurrentUserName]": "CurrentUserName",
        "[Password]": "Password",
        "[Department_Name]": "Department_Name",
        "[Facility_Name]": "Facility_Name",
        "[Current_Date]": "Current_Date",
        "[Start_Date]": "Start_Date",
        "[End_Date]": "End_Date",
        "[Expire_Date]": "Expire_Date",
        "[Supervisor_Names]": "Supervisor_Names",
        "[Interviewer_Names]": "Interviewer_Names",
        "[Participent_Names]": "Participent_Names",
        "[Pay_Rate]": "Pay_Rate",
        "[Applicant_Current_Stage]": "Applicant_Current_Stage",
        "[Applicant_Next_Stage]": "Applicant_Next_Stage",
        "[DownloadLink]": "DownloadLink",
        "[Chat_Representative_Link]": "Chat_Representative_Link",
        "[Video_Interview_Link]": "Video_Interview_Link",
        "[ApplicantLink]": "ApplicantLink",
        "[ReferenceLink]": "ReferenceLink",
        "[OfferInfoLink]": "OfferInfoLink",
        "[ReviewLink]": "ReviewLink",
        "[OfferLetterLink]": "OfferLetterLink",
        "[InterviewInfoLink]": "InterviewInfoLink",
        "[MessageLink]": "MessageLink",
        "[DocPackLink]": "DocPackLink",
        "[NotifyMessage]": "NotifyMessage",
        "[Notes_Message]": "Notes_Message",
        "[ScheduledApplicant]": "ScheduledApplicant",
        "[TypeName]": "TypeName",
        "[Sequence]": "Sequence",
        "[ChatPrompt]": "ChatPrompt",
      },

      // Here, insert html or image depends on the type
      callback: function (cmd, val) {
        this.html.insert(val);
      },
    });

    // Insert custom checkbox
    // FroalaEditor.DefineIcon("buttonIcon", {
    //   NAME: "plus",
    //   SVG_KEY: "add",
    // });
    FroalaEditor.DefineIconTemplate('font_awesome_checkbox', '<i class="fa-solid fa-[FA5NAME]" aria-hidden="true"></i>');
    FroalaEditor.DefineIcon('insert-checkbox', { FA5NAME: 'square-check', template: 'font_awesome_checkbox', ALT: "Insert Checkbox" });
    FroalaEditor.RegisterCommand("insert-checkbox", {
      title: "Insert Checkbox",
      icon: "insert-checkbox",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        this.html.insert(`<input type="checkbox"/>`);
      },
    });

    // Insert custom textarea
    FroalaEditor.DefineIconTemplate('font_awesome_textarea', '<i class="fa-solid fa-[FA5NAME]" aria-hidden="true"></i>');
    FroalaEditor.DefineIcon('insert-textarea', { FA5NAME: 't', template: 'font_awesome_textarea', ALT: "Insert Textarea" });

    // FroalaEditor.DefineIcon("insert-textarea", {
    //   NAME: "plus",
    //   SVG_KEY: "add",
    // });
    FroalaEditor.RegisterCommand("insert-textarea", {
      title: "Insert Textarea",
      icon: "insert-textarea",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        this.html.insert(`<textarea></textarea>`);
      },
    });

    // Insert signature box
    FroalaEditor.DefineIconTemplate('font_awesome_5s', '<i class="fa-solid fa-[FA5NAME]" aria-hidden="true"></i>');
    FroalaEditor.DefineIcon('form-signature', { FA5NAME: 'edit', template: 'font_awesome_5s', ALT: "Signature" });
    // FroalaEditor.DefineIcon("form-signature", {
    //   NAME: "plus",
    //   //SVG_KEY: "atIcon",
    //   ALT: "Signature",
    //   icon: 'smartphoneIcon',
    // });
    FroalaEditor.RegisterCommand("form-signature", {
      title: "Signature",
      icon: 'form-signature',
      undo: false,
      focus: false,
      callback: function () {
        console.log("asdhgjasgjasgdjasdgasjdg");

        this.html.insert(
          `<label class="sign-label">Sign : </label><input type="text" class="signature_field" id="doc_sign" name="doc_sign" required>`
        );
      },
    });
  }, []);

  const onModelChange = (model: any) => {
    setContent(model);
  };

  return (
    <div className="App">
      <Froala
        model={content}
        onModelChange={(model) => onModelChange(model)}
        config={{
          enter: FroalaEditor.ENTER_BR,
          tableStyles: {
            "no-border": "No border",
          },
          useClasses: false,
          attribution: false,
          toolbarSticky: false,
          charCounterCount: false,
          fontFamilySelection: true,
          fontSizeSelection: true,
          paragraphFormatSelection: true,
          heightMin: 720,
          heightMax: 1000,
          linkInsertButtons: [],
          toolbarButtons: [
            [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
              "paragraphFormat",
              "lineHeight",
              "align",
              "formatOL",
              "formatUL",
              "outdent",
              "indent",
              "leftToRight",
              "rightToLeft",
              "insertLink",
              "insertImage",
              "insertTable",
              "emoticons",
              "personalize",
              "insertButton",
              "clearFormatting",
              "selectAll",
              "insertHR",
              "undo",
              "redo",
              "fullscreen",
              "html",
              "insert-radio",
              "insert-checkbox",
              "insert-textarea",
              "customize",
              "form-signature",
              "smartphoneButton",
              "fontAwesome",
            ],
          ],
        }}
      />
    </div>
  );
};

export default CustomFroalaEditor;
