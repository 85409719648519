import { Fragment, useEffect, useState } from "react";
import { StyledTableCell } from "../../ui/table/table";
import { StyledTableRow } from "../../ui/table/table";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";

export const AccordianRecord = (props) => {
  const [open, setOpen] = useState(false);
  const [statusClass, setStatusClass] = useState("status-label");
  const [priorityClass, setPriorityClass] = useState("status-label");
  const date_1 = new Date(props.createdAt);
  const date_2 = new Date();
  const days = Math.ceil(
    (date_2.getTime() - date_1.getTime()) / (1000 * 3600 * 24)
  );

  useEffect(() => {
    switch (props.jobStatus) {
      case "ON_HOLD":
        setStatusClass("status-label red");
        break;
      case "ACTIVE":
        setStatusClass("status-label active");
        break;
      case "CANCELLED":
        setStatusClass("status-label");
        break;
      case "FILLED":
        setStatusClass("status-label brown");
        break;
      case "HIRING":
        setStatusClass("status-label lblue");
        break;
      case "PENDING":
        setStatusClass("status-label yellow");
        break;
      default:
        break;
    }

    let label =
      props.priority === "MEDIUM"
        ? "status-label yellow"
        : props.priority === "NORMAL"
        ? "status-label"
        : "status-label red";
    setPriorityClass(label);
  }, [props]);

  return (
    <Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell
          component="th"
          scope="row"
          align="left"
          onClick={() => props.cardClickEvent(props)}
        >
          {props.name}
        </StyledTableCell>
        <StyledTableCell
          scope="row"
          align="center"
          width="70px"
          onClick={() => props.cardClickEvent(props)}
        >
          {props.jobStatus ? (
            <span className={"mr-10 " + statusClass}>
              {props.jobStatus[0] +
                props.jobStatus.slice(1).toLowerCase().replace("_", " ")}
            </span>
          ) : null}
          {props.priority ? (
            <span className={priorityClass}>
              {props.priority[0] + props.priority.slice(1).toLowerCase()}
            </span>
          ) : null}
        </StyledTableCell>
        <StyledTableCell
          scope="row"
          align="center"
          width="70px"
          onClick={() => props.cardClickEvent(props)}
        >
          {props.positions}
        </StyledTableCell>
        <StyledTableCell
          scope="row"
          align="center"
          width="70px"
          onClick={() => props.cardClickEvent(props)}
        >
          {days}
        </StyledTableCell>
        <StyledTableCell scope="row" align="right" width="50px">
          <EditDeleteMenu
            id={props.jobId}
            deleteOption={false}
            onEdit={props.editAction}
          />
        </StyledTableCell>
        <StyledTableCell align="right" width="50px">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon color="primary" />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div>
                <div
                  className="content-table"
                  dangerouslySetInnerHTML={{
                    __html: decodeURIComponent(
                      props.description.substring(0, 250)
                    ),
                  }}
                />
              </div>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </Fragment>
  );
};
