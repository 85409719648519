import { useEffect, useState } from "react";
import { ButtonUI } from "../../../../../../ui/button";
import { OfferForm } from "./offerForm";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import moment from "moment";
import { ResendOfferingForm } from "./resendNotificationForm";
import { EditOfferForm } from "./editOfferForm";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

export const OfferingStatus = ({ selectedSuitCase, applicantId }) => {
  const [openOfferingForm, setOfferingForm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [offeringList, setOfferingList] = useState([]);
  const [openResendForm, setResendForm] = useState(false);
  const [flag, setFlag] = useState("");
  const [offerScheduleId, setOfferScheduleId] = useState("");
  const [openEditForm, setEditOfferForm] = useState(false);

  useEffect(() => {
    getOfferingList();
  }, []);

  const getOfferingList = () => {
    Api(
      "GET",
      `Job/GetOfferingList?companyId=${companyData.companyId}&applicantId=${applicantId}`
    )
      .then((res) => {
        setOfferingList(res.data);
      })
      .catch((err) => console.log(err));
  };

  const toggleOfferForm = (open) => {
    setOfferingForm(open);
  };

  const toggleResendForm = (open) => {
    setResendForm(open);
  };

  const toggleEditOffer = (open) => {
    setEditOfferForm(open);
  };

  const acceptOffer = (id) => {
    var ans = window.confirm("Are you sure you want to Accept this offer ?");
    if (ans) {
      const params = {
        applicantOfferingId: id,
      };

      Api("POST", `Job/AcceptOfferLetter`, params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleOfferForm(false);
            getOfferingList();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSaveForm = () => {
    const params = { ...formValues };
    params.companyId = companyData.companyId;
    params.jobId = selectedSuitCase.jobId;
    params.applicantId = applicantId;

    Api("POST", "Job/SaveOfferingForm", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleOfferForm(false);
          getOfferingList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNotification = (description, id) => {
    const params = {
      companyId: companyData.companyId,
      jobId: selectedSuitCase.jobId,
      applicantOfferingId: id,
      applicantId: applicantId,
      description: description,
    };

    Api("PUT", `Job/ResendNotificationForOffering`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleResendForm(false);
          getOfferingList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleCancelInterview = (description, id) => {
    const params = {
      applicantOfferingId: id,
      applicantMessage: description,
    };

    Api("POST", `Job/RescindedJobOffer`, params)
      .then((res) => {
        getOfferingList();
      })
      .catch((err) => console.log(err));
  };

  const editAction = (offering) => {
    setActiveStep(0);
    setFormValues(offering);
    if (offering.offerStatus === "Accepted") {
      toggleEditOffer(true);
    } else {
      toggleOfferForm(true);
    }
  };

  const handleEditOfferDate = () => {
    const params = {
      startDate: formValues.startDate,
      applicantId: applicantId,
      jobId: selectedSuitCase.jobId,
      companyId: companyData.companyId,
      applicantOfferingId: formValues.applicantOfferingId,
    };

    Api("PUT", `Job/EditOfferingForm`, params)
      .then((res) => {
        console.log(res);
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleEditOffer(false);
          getOfferingList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <OfferForm
        openForm={openOfferingForm}
        toggleForm={toggleOfferForm}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        formValues={formValues}
        setFormValues={setFormValues}
        handleSaveForm={handleSaveForm}
        applicantId={applicantId}
      />

      <ResendOfferingForm
        handleSubmit={handleNotification}
        toggleForm={toggleResendForm}
        openForm={openResendForm}
        flag={flag}
        handleCancelInterview={handleCancelInterview}
        offerScheduleId={offerScheduleId}
        applicantId={applicantId}
      />

      <EditOfferForm
        openForm={openEditForm}
        handleSubmit={handleEditOfferDate}
        toggleForm={toggleEditOffer}
        applicantId={applicantId}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      {offeringList[0]?.offerStatus === "Rescinded" ||
      offeringList[0]?.offerStatus === "Declined" ||
      offeringList.length === 0 ? (
        <ButtonUI
          p="8px"
          variant="outlined"
          ml="96px"
          onClick={() => toggleOfferForm(true)}
        >
          Extend Offer
        </ButtonUI>
      ) : null}

      <div className="profile-data mt-10">
        <div className="inter-container">
          {offeringList?.map((offering, index) => (
            <fieldset className="status-border mt-5">
              <legend
                className={
                  offering?.offerStatus === "Accepted"
                    ? "status green-active border-label"
                    : offering?.offerStatus === "Draft"
                    ? "status blue-completed border-label"
                    : "status border-label"
                }
              >
                {offering?.offerStatus}
              </legend>
              <div className="section" key={index}>
                <div>
                  <strong>Offering</strong>
                </div>
                <div className="mt-5">
                  <div className="flex-column">
                    <div className="j-field">
                      <label>Rate:</label>
                      <strong>
                        {offering?.rate ? "$" : ""}
                        {offering?.rate + " (" + offering?.rateType + ")"}
                      </strong>
                    </div>

                    <div className="j-field">
                      <label>Shift Type:</label>
                      <strong>{offering?.shiftType}</strong>
                    </div>

                    <div className="j-field">
                      <label>Created:</label>
                      <strong>
                        {moment(offering?.created).format("MM/DD/Y")}
                      </strong>
                    </div>

                    <div className="j-field">
                      <label>Start Date:</label>
                      <strong>
                        {moment(offering?.startDate).format("MM/DD/Y")}
                      </strong>
                    </div>

                    <div className="j-field">
                      <label>Expires:</label>
                      <strong>
                        {moment(offering?.expireDate).format("MM/DD/Y")}
                      </strong>
                    </div>

                    <div className="j-field">
                      <label>Employment Type:</label>
                      <strong>{offering?.employeementType}</strong>
                    </div>

                    <div className="j-field">
                      <label>Exempt from overtime:</label>
                      <strong>
                        {offering?.isExemptfromovertime == true ? "Yes" : "No"}
                      </strong>
                    </div>

                    {/* <div className="j-field">
                    <label>Supervisor(s):</label>
                    <strong>{offering?.supervisors}</strong>
                  </div> */}

                    <div className="j-field">
                      <label>Schedule Type:</label>
                      <strong>{offering?.scheduleType}</strong>
                    </div>

                    <div className="j-field">
                      <label>Last viewed:</label>
                      <strong>{offering?.lastViewed}</strong>
                    </div>
                  </div>
                </div>
                <span className="d-flex flex-row mt-5">
                  {offering?.offerStatus === "Pending" && (
                    <ButtonUI
                      variant="outlined"
                      fontSize="11px"
                      borderColor="#34AA44"
                      color="#34AA44"
                      mr="4px"
                      onClick={() => acceptOffer(offering.applicantOfferingId)}
                      rightIcon={<ArrowForwardOutlinedIcon fontSize="small" />}
                    >
                      Accept
                    </ButtonUI>
                  )}

                  {offering?.offerStatus === "Pending" ||
                  offering?.offerStatus === "Accepted" ? (
                    <ButtonUI
                      variant="outlined"
                      fontSize="11px"
                      borderColor="#E6492D"
                      color="#E6492D"
                      mr="4px"
                      onClick={() => (
                        toggleResendForm(true),
                        setFlag("send"),
                        setOfferScheduleId(offering.applicantOfferingId)
                      )}
                      rightIcon={<ArrowForwardOutlinedIcon fontSize="small" />}
                    >
                      Send
                    </ButtonUI>
                  ) : null}

                  {offering?.offerStatus !== "Declined" &&
                  offering?.offerStatus !== "Rescinded" ? (
                    <ButtonUI
                      variant="outlined"
                      fontSize="11px"
                      borderColor="#B80000"
                      color="#B80000"
                      mr="4px"
                      onClick={() => (
                        toggleResendForm(true),
                        setFlag("cancel"),
                        setOfferScheduleId(offering.applicantOfferingId)
                      )}
                      rightIcon={<ArrowForwardOutlinedIcon fontSize="small" />}
                    >
                      Cancel
                    </ButtonUI>
                  ) : null}

                  {offering?.offerStatus === "Draft" ||
                  offering?.offerStatus === "Accepted" ? (
                    <ButtonUI
                      variant="outlined"
                      fontSize="11px"
                      color="#3954A5"
                      mr="4px"
                      onClick={() => editAction(offering)}
                      rightIcon={<ArrowForwardOutlinedIcon fontSize="small" />}
                    >
                      Edit
                    </ButtonUI>
                  ) : null}
                </span>
              </div>
            </fieldset>
          ))}
        </div>
      </div>
    </div>
  );
};
