import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PublicNavigationBar } from "../../../components/common/navigationBar/public";
import Api from "../../../helper/Api";
import "../../../assets/css/common/_public.css";

export const ViewJob = () => {
  const params = useParams();
  const [jobData, setJobData] = useState({});

  useEffect(() => {
    let data = window.atob(params.token);
    let values = data.split("&");
    let urlParams = [];

    values.map((d) => {
      let t = d.split("=");
      urlParams.push(t[1]);
    });

    Api("GET", `Job/ViewJob?jobId=${urlParams[0]}&companyId=${urlParams[1]}`)
      .then((res) => {
        setJobData(res.data.data.jobDetails);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <PublicNavigationBar />
      <div className="job-data-container">
        <div className="header">
          <img
            src={
              jobData.companyLogo
                ? jobData.companyLogo
                : require("../../../assets/images/dummy-img.png")
            }
            alt="logo"
            width={200}
            height={200}
            className="company-logo"
          />
          <strong>{jobData.companyName}</strong>
          <span>
            {jobData.jobName} ({jobData.jobNo})
          </span>
        </div>
        <div className="j-content">
          <label>{jobData.companyName}</label>
          <div>
            <label>Position Title :</label> {jobData.jobName}
          </div>
          <div className="mt-20">
            <label>Job Description : </label>
            <div
              dangerouslySetInnerHTML={{
                __html: decodeURIComponent(jobData.jobDescription),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
