import { AddEditPopup } from "../../../../../addEditPopup";
import { ButtonUI } from "./../../../../../../ui/button/index";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useRef, useState } from "react";
import { FilesIcon, CloseXIcon } from "../../../../../../../icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Api from "../../../../../../../helper/Api";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";

export const AddDocument = (props) => {
  const attachRef = useRef(null);
  const [documents, setDocuments] = useState([]);
  // const [attachment, setAttachment] = useState([]);
  const [docData, setDocData] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const initialValues = [
    { notes: "", documentTypeId: "", document: "", docName: "" },
  ];
  const [selectedDocumentsLists, setSelectedDocumentsLists] =
    useState(initialValues);
  const [openSidebar, setSidebar] = useState(false);
  const { applicantData } = useSelector((state) => state.applicantDataReducer);
  const formData = new FormData();

  useEffect(() => {
    setDocuments([]);
    getDocs();
    toggleDrawer("right", true);
  }, [props.openSidebar]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedDocumentsLists);
    console.log("akfjsdfgsdhfgsdjfgjsdhfgsdjfg");
    const data = [...selectedDocumentsLists];
    const params = {
      companyId: applicantData.companyId,
      jobId: applicantData.jobId,
      applicantId: applicantData.applicantId,
      selectedDocumentsLists: [...data],
    };
    console.log(params);
    // formData.append("companyId", applicantData.companyId);
    // formData.append("jobId", applicantData.jobId);
    // formData.append("applicantId", applicantData.applicantId);
    // formData.append("selectedDocumentsLists", selectedDocumentsLists);

    // Api("POST", "Job/AddApplicantDocuments", params, "multipart")
    //   .then((res) => {
    //     if (res.data.isSuccess == true) {
    //       console.log("success");
    //     } else {

    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    // props.handleSubmit();
    // console.log("in handle submit");
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const documentsList = [...selectedDocumentsLists];
    documentsList[index][name] = value;
    setSelectedDocumentsLists(documentsList);
  };

  const handleSelectedFile = (e, index) => {
    console.log(index);
    console.log(selectedDocumentsLists);
    setDocData({ ...docData, ["document"]: e.target.files[0] });
    const documentsList = [...selectedDocumentsLists];
    documentsList[index].document = e.target.files[0];
    documentsList[index].docName = e.target.files[0].name;
    setSelectedDocumentsLists(documentsList);
  };

  const openAttachment = () => {
    attachRef.current.click();
  };

  const addDocument = () => {
    setDocuments([
      ...documents,
      { document: "", documentTypeId: "", notes: "", docName: "" },
    ]);
    setSelectedDocumentsLists([
      ...selectedDocumentsLists,
      { document: "", documentTypeId: "", notes: "", docName: "" },
    ]);
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
    if (!open) {
      setSelectedDocumentsLists(initialValues);
    }
  };

  //get all docs dropdown api
  const getDocs = () => {
    Api("GET", "Document/GetDocumentType?type=doctype")
      .then((res) => {
        if (res.data) {
          setAllDocs(res.data);
        } else {
          setAllDocs([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AddEditPopup
      title="Add Document"
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
    >
      <div className="job-document">
        <label>Documents({documents.length + 1})</label>
        <ButtonUI
          p="12px"
          leftIcon={<AddIcon sx={{ height: "16px", width: "16px" }} />}
          onClick={() => addDocument()}
        >
          Add
        </ButtonUI>
      </div>

      {/* <div>
        <div className="forms-input">
          <input
            type="file"
            ref={attachRef}
            hidden
            onChange={(e) => handleSelectedFile(e, 0)}
            key={attachment}
          />

          {attachment.length > 0 ? (
            <div className="doucments-card">
              <div className="doc-detail">
                <FilesIcon
                  stroke="#9EA0A5"
                  line="#fff"
                  width="32px"
                  height="32px"
                />
                <div className="file-name-label">{attachment}</div>
              </div>
              <div
                className="doc-detail"
                role="button"
                onClick={() => setAttachment("")}
              >
                <div className="pointer">
                  <CloseXIcon width="28px" height="28px" />
                </div>
              </div>
            </div>
          ) : (
            <div
              role="button"
              className="document-section pointer"
              onClick={() => openAttachment()}
            >
              <div className="documents-upload">
                <span>Upload Document</span>
                <CloudUploadOutlinedIcon width="32" height="32" />
              </div>
            </div>
          )}
        </div>

        <div className="forms-input">
          <label>Select Document Type</label>
          <select onChange={(e) => handleChange(e, 0)} name="documentTypeId">
            <option value="none">Select</option>
            {allDocs.map((docs) => (
              <option key={docs.documentTypeId} value={docs.documentTypeId}>
                {docs.name}
              </option>
            ))}
          </select>
        </div>

        <div className="forms-input">
          <label>Notes</label>
          <textarea
            placeholder="Enter a description..."
            id="notes"
            name="notes"
            onChange={(e) => handleChange(e, 0)}
          />
        </div>
      </div> */}

      {selectedDocumentsLists.map((doc, i) => (
        <div key={i}>
          <div className="forms-input">
            <input
              type="file"
              ref={attachRef}
              hidden
              onChange={(e) => handleSelectedFile(e, i)}
              key={i}
            />

            {doc.docName ? (
              <div className="doucments-card">
                <div className="doc-detail">
                  <FilesIcon
                    stroke="#9EA0A5"
                    line="#fff"
                    width="32px"
                    height="32px"
                  />
                  <div className="file-name-label">{doc.docName}</div>
                </div>
                <div className="doc-detail" role="button">
                  <IconButton size="small">
                    <CloseXIcon width="28px" height="28px" />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div
                role="button"
                className="document-section pointer"
                onClick={() => openAttachment()}
              >
                <div className="documents-upload">
                  <span>Upload Document</span>
                  <CloudUploadOutlinedIcon width="32" height="32" />
                </div>
              </div>
            )}
          </div>

          <div className="forms-input">
            <label>Select Document Type</label>
            <select
              name="documentTypeId"
              onChange={(e) => handleChange(e, i)}
            >
              <option value="none">Select</option>
              {allDocs.map((docs) => (
                <option key={docs.documentTypeId} value={docs.documentTypeId}>
                  {docs.name}
                </option>
              ))}
            </select>
          </div>

          <div className="forms-input">
            <label>Notes</label>
            <textarea
              name="notes"
              placeholder="Enter a description..."
              id="notes"
              onChange={(e) => handleChange(e, i)}
            />
          </div>
        </div>
      ))}
    </AddEditPopup>
  );
};
