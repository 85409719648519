import "../../../assets/css/common/_cards.css";
import { Fragment } from "react";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";

export const GreyCard = (props) => {
  const {
    darkTitle,
    cardHeader1,
    cardHeader2,
    headerTimeStamp,
    contentSpan,
    contTimeStamp,
    duration,
    timeBetween,
    slotHrs,
    handleEdit,
    handleDelete,
    id,
    type,
  } = props;
  const contentClass =
    contentSpan === undefined ? "g-card-content" : "g-card-content mt-10";

  let contTime = new Date(contTimeStamp);
  let headerTime = new Date(headerTimeStamp);

  return (
    <Fragment>
      <div className={type === "activity" ? "g-card activity-card" : "g-card"}>
        <div className="g-card-dark-header">
          <span>{darkTitle}</span>
          {darkTitle ? (
            <EditDeleteMenu
              id={id}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ) : null}
        </div>
        <div className="g-card-header">
          {cardHeader1}
          <span>
            {headerTimeStamp
              ? headerTime.getMonth() +
                1 +
                "/" +
                headerTime.getDate() +
                "/" +
                headerTime.getFullYear() +
                " • " +
                headerTime.toLocaleTimeString("en-US")
              : ""}
          </span>
        </div>
        <div className="g-card-header-2 mt-10">{cardHeader2}</div>
        <div className={contentClass}>
          <span>{contentSpan} </span>{" "}
          {contTimeStamp
            ? contTime.getMonth() +
              1 +
              "/" +
              contTime.getDate() +
              "/" +
              contTime.getFullYear() +
              " • " +
              contTime.toLocaleTimeString("en-US")
            : ""}
        </div>
        {darkTitle ? (
          <div className="g-card-content-sections">
            <div>
              <span>Duration(min): </span>
              {duration}
            </div>
            <div>
              <span>Time Between Interviews(min): </span>
              {timeBetween}
            </div>
            <div>
              <span>Slot Close(hours): </span>
              {slotHrs}
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};
