import { JOB_DATA_ACTIONS } from './actions';

const initialState = {
    jobData: [],
    jobAllStatus: [],
    jobCycleStatus: 'NEW_APPLICATION',
    jobApplicantStatusCount: {}
}

export const jobDataReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case JOB_DATA_ACTIONS.SET_JOB_DATA:
            return {...state, jobData: payload};
            
        case JOB_DATA_ACTIONS.SET_ALL_JOB_STATUS:
            return {...state, jobAllStatus: payload};

        case JOB_DATA_ACTIONS.SET_JOB_CYCLE_STATUS:
            return {...state, jobCycleStatus: payload};

        case JOB_DATA_ACTIONS.SET_APPLICANT_COUNT:
            return {...state, jobApplicantStatusCount: payload};

        default:
            return state;
    }
}
