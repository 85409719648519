import React from "react";

import "../../../assets/css/components/ui/_button.css";

export const ButtonUI = ({
  variant = "contained",
  bgColor = "#3954A5",
  height,
  width,
  p,
  px,
  py,
  my,
  m,
  mx,
  ml,
  mr,
  pl,
  pr,
  pt,
  pb,
  mb,
  mt,
  fontSize = "14px",
  fontWeight = 500,
  radius = "8px",
  color = variant === "contained" ? "#fff" : "#000",
  borderColor = variant === "outlined" && "#3954A5",
  border = variant === "outlined" ? `1px solid ${borderColor}` : "none",
  leftIcon,
  rightIcon,
  mlicon,
  ...rest
}) => {
  return (
    <button
      style={{
        backgroundColor: variant === "contained" ? bgColor : "transparent",
        height: height,
        width: width,
        fontSize: fontSize,
        fontWeight: fontWeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingInline: !!px && px,
        paddingBlock: !!py && py,
        margin: !!m && m,
        marginInline: !!mx && mx,
        marginBlock: !!my && my,
        marginLeft: !!ml && ml,
        marginRight: !!mr && mr,
        marginTop: !!mt && mt,
        marginBottom: !!mb && mb,
        paddingLeft: !!pl && pl,
        paddingRight: !!pr && pr,
        paddingTop: !!pt && pt,
        paddingBottom: !!pb && pb,
        padding: !!p && p,
        borderRadius: radius,
        borderColor: borderColor,
        color: color,
        border: border,
        cursor: "pointer",
      }}
      {...rest}
    >
      <div className="button-container">
        {!!leftIcon && leftIcon}
        <span
          style={{
            marginLeft: !!leftIcon && mlicon ? mlicon : "4px",
            marginRight: !!rightIcon && "4px",
          }}
          className="button-text"
        >
          {rest.children}
        </span>
        {!!rightIcon && rightIcon}
      </div>
    </button>
  );
};
