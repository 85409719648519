export const CVDocumentIcon = ({ ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 225 225"
      enable-background="new 0 0 225 225"
      {...props}
    >
      <path
        fill="#DEEBFF"
        opacity="1.000000"
        stroke="none"
        d="M195.000000,226.000000 
            C172.979111,226.000000 150.958206,226.000000 128.767166,225.796051 
            C140.786804,222.563354 147.063538,214.570572 147.074326,202.017838 
            C147.086746,187.547211 147.123993,173.076370 147.062851,158.605988 
            C147.009583,145.997528 137.348282,136.214996 124.749596,136.022980 
            C117.266525,135.908920 109.778999,135.913330 102.295769,136.021942 
            C89.648453,136.205490 79.986572,145.933075 79.936958,158.528641 
            C79.877998,173.498016 79.903252,188.467896 79.948959,203.437378 
            C79.954460,205.239212 80.132210,207.082672 80.541382,208.834106 
            C82.732193,218.211868 89.489418,223.012894 98.000000,226.000000 
            C78.645775,226.000000 59.291546,226.000000 39.641785,225.649139 
            C52.158901,224.127197 64.320457,214.695938 66.747986,201.535797 
            C68.651138,191.218399 68.209328,180.403198 68.188675,169.810379 
            C68.150925,150.442795 53.363949,136.059189 33.928997,136.007843 
            C22.952734,135.978851 11.976336,136.000931 1.000000,136.000000 
            C1.000000,128.645752 1.000000,121.291496 1.455241,113.588440 
            C8.987369,113.239639 16.064257,113.239639 23.267078,113.239639 
            C23.267078,97.746002 23.267078,82.940689 23.267078,68.135376 
            C23.267078,53.243256 23.267078,38.351139 23.267078,23.564659 
            C61.310627,23.564659 98.525986,23.564659 136.067383,23.564659 
            C136.067383,46.105900 136.067383,68.350868 136.067383,91.111679 
            C158.741089,91.111679 181.094513,91.111679 203.816193,91.111679 
            C203.816193,98.707115 203.816193,105.793114 203.816193,113.000000 
            C211.460999,113.000000 218.730499,113.000000 226.000000,113.000000 
            C226.000000,120.354248 226.000000,127.708496 225.531342,135.532043 
            C217.912277,136.001358 210.761810,135.982132 203.611481,136.004990 
            C181.558517,136.075500 161.976120,152.750793 159.017731,173.960754 
            C155.819839,196.887909 169.189209,217.711044 191.203659,224.176010 
            C192.533005,224.566422 193.737442,225.382172 195.000000,226.000000 z"
      />
      <path
        fill="#0052CC"
        opacity="1.000000"
        stroke="none"
        d="M226.000000,112.531342 
            C218.730499,113.000000 211.460999,113.000000 203.816193,113.000000 
            C203.816193,105.793114 203.816193,98.707115 203.816193,91.111679 
            C181.094513,91.111679 158.741089,91.111679 136.067383,91.111679 
            C136.067383,68.350868 136.067383,46.105900 136.067383,23.564659 
            C98.525986,23.564659 61.310627,23.564659 23.267078,23.564659 
            C23.267078,38.351139 23.267078,53.243256 23.267078,68.135376 
            C23.267078,82.940689 23.267078,97.746002 23.267078,113.239639 
            C16.064257,113.239639 8.987369,113.239639 1.455241,113.119820 
            C1.000000,75.727249 1.000000,38.454494 1.000000,1.000000 
            C53.354088,1.000000 105.708382,1.000000 158.466934,1.344573 
            C169.621078,13.215702 180.327942,24.782793 191.132599,36.257786 
            C202.707077,48.550377 214.373627,60.756271 226.000000,73.000000 
            C226.000000,86.020897 226.000000,99.041786 226.000000,112.531342 z"
      />
      <path
        fill="#0052CC"
        opacity="1.000000"
        stroke="none"
        d="M98.468658,226.000000 
            C89.489418,223.012894 82.732193,218.211868 80.541382,208.834106 
            C80.132210,207.082672 79.954460,205.239212 79.948959,203.437378 
            C79.903252,188.467896 79.877998,173.498016 79.936958,158.528641 
            C79.986572,145.933075 89.648453,136.205490 102.295769,136.021942 
            C109.778999,135.913330 117.266525,135.908920 124.749596,136.022980 
            C137.348282,136.214996 147.009583,145.997528 147.062851,158.605988 
            C147.123993,173.076370 147.086746,187.547211 147.074326,202.017838 
            C147.063538,214.570572 140.786804,222.563354 128.298508,225.796051 
            C118.312439,226.000000 108.624878,226.000000 98.468658,226.000000 M102.073311,169.990204 
            C102.073296,176.819412 102.041878,183.648788 102.081573,190.477768 
            C102.126556,198.214630 106.786316,203.625885 113.383682,203.691299 
            C119.958847,203.756500 124.855171,198.281631 124.916130,190.694580 
            C124.966980,184.365463 124.956032,178.035477 124.919380,171.706177 
            C124.875145,164.066147 120.779945,158.970078 114.274757,158.371368 
            C108.423180,157.832794 103.758286,161.942688 102.073311,169.990204 z"
      />
      <path
        fill="#0052CC"
        opacity="1.000000"
        stroke="none"
        d="M1.000000,136.468658 
            C11.976336,136.000931 22.952734,135.978851 33.928997,136.007843 
            C53.363949,136.059189 68.150925,150.442795 68.188675,169.810379 
            C68.209328,180.403198 68.651138,191.218399 66.747986,201.535797 
            C64.320457,214.695938 52.158901,224.127197 39.173126,225.649139 
            C26.376780,226.000000 13.753559,226.000000 1.000000,226.000000 
            C1.000000,196.312561 1.000000,166.624939 1.000000,136.468658 
        M25.900824,203.748367 
            C29.062880,203.737015 32.241016,203.925369 35.383606,203.672302 
            C41.707653,203.163025 45.865314,199.364288 45.971329,193.882843 
            C46.122566,186.063187 46.129883,178.235580 45.960266,170.416550 
            C45.829113,164.370514 41.640591,159.293411 35.868206,158.566040 
            C31.843105,158.058838 27.702499,158.468292 23.253885,158.468292 
            C23.253885,173.026062 23.224617,187.144302 23.328131,201.261566 
            C23.334206,202.090088 24.411951,202.910751 25.900824,203.748367 z"
      />
      <path
        fill="#0052CC"
        opacity="1.000000"
        stroke="none"
        d="M195.468658,226.000000 
            C193.737442,225.382172 192.533005,224.566422 191.203659,224.176010 
            C169.189209,217.711044 155.819839,196.887909 159.017731,173.960754 
            C161.976120,152.750793 181.558517,136.075500 203.611481,136.004990 
            C210.761810,135.982132 217.912277,136.001358 225.531342,136.000671 
            C226.000000,143.020950 226.000000,150.041901 225.544556,157.650482 
            C218.099091,158.247482 211.105560,158.118927 204.119781,158.295761 
            C190.813629,158.632645 181.023651,168.213409 180.995682,180.720154 
            C180.966660,193.697571 190.549652,203.315979 204.062576,203.711456 
            C211.371613,203.925369 218.687302,203.911240 226.000000,204.000000 
            C226.000000,211.272247 226.000000,218.544495 226.000000,226.000000 
            C215.979706,226.000000 205.958511,226.000000 195.468658,226.000000 z"
      />
      <path
        fill="#DEEBFF"
        opacity="1.000000"
        stroke="none"
        d="M226.000000,72.531342 
            C214.373627,60.756271 202.707077,48.550377 191.132599,36.257786 
            C180.327942,24.782793 169.621078,13.215702 158.935593,1.344573 
            C181.262817,1.000000 203.525635,1.000000 226.000000,1.000000 
            C226.000000,24.687141 226.000000,48.374912 226.000000,72.531342 z"
      />
      <path
        fill="#DEEBFF"
        opacity="1.000000"
        stroke="none"
        d="M226.000000,203.531342 
            C218.687302,203.911240 211.371613,203.925369 204.062576,203.711456 
            C190.549652,203.315979 180.966660,193.697571 180.995682,180.720154 
            C181.023651,168.213409 190.813629,158.632645 204.119781,158.295761 
            C211.105560,158.118927 218.099091,158.247482 225.544556,158.119049 
            C226.000000,173.020889 226.000000,188.041794 226.000000,203.531342 z"
      />
      <path
        fill="#DEEBFF"
        opacity="1.000000"
        stroke="none"
        d="M102.080505,169.549316 
            C103.758286,161.942688 108.423180,157.832794 114.274757,158.371368 
            C120.779945,158.970078 124.875145,164.066147 124.919380,171.706177 
            C124.956032,178.035477 124.966980,184.365463 124.916130,190.694580 
            C124.855171,198.281631 119.958847,203.756500 113.383682,203.691299 
            C106.786316,203.625885 102.126556,198.214630 102.081573,190.477768 
            C102.041878,183.648788 102.073296,176.819412 102.080505,169.549316 z"
      />
      <path
        fill="#DEEBFF"
        opacity="1.000000"
        stroke="none"
        d="M25.445904,203.741714 
            C24.411951,202.910751 23.334206,202.090088 23.328131,201.261566 
            C23.224617,187.144302 23.253885,173.026062 23.253885,158.468292 
            C27.702499,158.468292 31.843105,158.058838 35.868206,158.566040 
            C41.640591,159.293411 45.829113,164.370514 45.960266,170.416550 
            C46.129883,178.235580 46.122566,186.063187 45.971329,193.882843 
            C45.865314,199.364288 41.707653,203.163025 35.383606,203.672302 
            C32.241016,203.925369 29.062880,203.737015 25.445904,203.741714 z"
      />
    </svg>
  );
};
