import moment from "moment";
import React from "react";
import { ButtonUI } from "../../../components/ui/button";
import { getTagColor } from "../../../helper/constants";

const Upcoming = ({ borderColor = "#000", heading = "heading", data = [] }) => {
  return (
    <div className="charts_grid upcoming_box">
      <p style={{ borderColor }}>{heading}</p>
      <div className="upcoming_container">
        <div>
          {data.length ? (
            data.map((iter) => (
              <div className="interview_box" key={iter.applicantId}>
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    {iter?.fNameAndlName
                      ? iter.fNameAndlName
                      : iter.name
                      ? iter.name
                      : iter.firstName + " " + iter?.lastName}
                  </span>
                  <span className="date_span">
                    {moment(iter.createdAt).format("DD/MM/YYYY")}
                  </span>
                </div>
                <span className="job_span">
                  {iter?.time && iter?.scheduledDate + " - " + iter?.time}
                  {iter?.jobNameAndNo && iter.jobNameAndNo}
                  {iter?.jobName && iter?.jobName}{" "}
                  {iter.jobNo && "(" + iter.jobNo + ")"}
                  {iter.more > 0 && (
                    <p style={{ margin: 0, color: "#000", fontWeight: 500 }}>
                      +{iter.more} more
                    </p>
                  )}
                </span>
                {/* && iter.more > 1 */}
                {iter.jobHireStatus && (
                  <span
                    className={`custom_tag active_span ${getTagColor(
                      iter.jobHireStatus
                    )}`}
                  >
                    {iter.jobHireStatus}
                  </span>
                )}
              </div>
            ))
          ) : (
            <div
              style={{
                textAlign: "center",
                marginBlock: "20px 10px",
                padding: "20px 10px",
                color: "#202020a6",
                fontWeight: "500",
              }}
            >
              No data available.
            </div>
          )}
        </div>
        {data.length ? (
          <ButtonUI
            color="#006AFF"
            variant="contained"
            bgColor="#f2f5ff"
            height={"48px"}
            width={"100%"}
            fontWeight={600}
          >
            View All
          </ButtonUI>
        ) : null}
      </div>
    </div>
  );
};

export default Upcoming;
