import { CheckboxWithBlueCheck } from "../../../../../../ui/checkbox/checkboxWithBlueTick";
import { FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { CheckboxList } from "../../../../../layouts/CheckboxList";
import Api from "../../../../../../../helper/Api";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { MultiStepCol } from "../../../../../addEditPopup/multiStepCol";

export const InterviewForm = ({
  openForm,
  toggleForm,
  activeStep,
  setActiveStep,
  formValues,
  setFormValues,
  handleFormSubmit,
  applicantId,
  jobId,
}) => {
  const [allFacilities, setFacilities] = useState();
  const [allInterviewers, setAllInterviewers] = useState([]);
  const [interviewType, setInterviewType] = useState("");
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [interviewTypes, setInterviewTypes] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [facilitiesCheckData, setFacilitiesCheckData] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [interviewTypeKey, setInetrviewTypeKey] = useState("");
  const [interviewersName, setInterviewerName] = useState([]);
  const [locationName, setLocationName] = useState([]);
  const date = new Date();
  const tomorrow = new Date().setDate(date.getDate() + 1);
  const dayAfterTomorrow = new Date().setDate(date.getDate() + 2);

  // Location checkbox change - 1st screen
  function onCheckBoxChange(checkName, isChecked) {
    const location = [];
    const data = [...allFacilities];
    const facilityName = [];
    data.map((rec) => {
      if (rec.id === checkName) {
        rec.checked = isChecked;
      }
    });

    setFacilitiesCheckData(data);
    const facilities = data.filter((d) => {
      if (d.checked === true) {
        location.push(d.id);
        facilityName.push(d.name);
      }
    });

    setLocationName(facilityName);
    setFormValues({ ...formValues, ["facilities"]: location });
  }

  function handleCheckboxChange(checkName, isChecked) {
    const users = [];
    const data = [...allInterviewers];
    data.map((rec) => {
      if (rec.id === checkName) {
        rec.checked = isChecked;
      }
    });

    setAllInterviewers(data);
    const interName = [];
    const selectedInterviewers = data.filter((d) => {
      if (d.checked === true) {
        users.push(d.id);
        interName.push(d.userFirstName + " " + d.userLastName);
      }
    });
    setInterviewerName(interName);
    setFormValues({ ...formValues, ["interviewers"]: users });
  }

  function selectSlots(slots) {
    const data = [...selectedSlots];
    if (data.includes(slots)) {
      data.splice(data.indexOf(slots), 1);
    } else {
      data.push(slots);
    }
    setSelectedSlots(data);
    setFormValues({ ...formValues, ["selectedSlots"]: data });
  }

  const recordThisMeet = (isChecked) => {
    setFormValues({ ...formValues, ["isRecordMeeting"]: isChecked });
  };

  const handleSubmit = () => {
    handleFormSubmit();
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    console.log(formValues);
    console.log(activeStep);
    console.log(locationName);
    console.log("handle clicked");
    // if (activeStep === 0) {
    //   return true;
    // }
  };

  useEffect(() => {
    getAllInterviewers();
    getFacilities();
    getInterviewSlots();
    getAllAppointments();
  }, []);

  const getAllInterviewers = () => {
    Api(
      "GET",
      `Job/GetRoleBaseUserList?companyId=${companyData.companyId}&type=0`
    )
      .then((res) => {
        res.data.map((user) => {
          user.name = user.userFirstName + " " + user.userLastName;
          user.id = user.userId;
          user.checked = false;
        });
        setAllInterviewers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFacilities = () => {
    Api(
      "GET",
      `Facilities/GetFacilitiesList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        const data = [];
        res.data.data.map((facility) => {
          facility.id = facility.facilitiesId;
          data.push({
            id: facility.facilitiesId,
            checked: false,
          });
        });

        setFacilitiesCheckData(data);
        setFacilities(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getInterviewSlots = () => {
    const params = {
      companyId: companyData.companyId,
      jobId: jobId,
      applicantId: applicantId,
      offset: new Date().getTimezoneOffset(),
    };

    Api("POST", `Job/GetInterviewSlot`, params)
      .then((res) => {
        const response = { ...res.data.data };
        setAllTimeSlots(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllAppointments = () => {
    Api(
      "GET",
      `Appointments/GetAppointmentsList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        const data = [];
        res.data.data.filter((rec) => {
          if (rec.type === "interview") {
            data.push(rec);
          }
        });
        setInterviewTypes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeInterviewType = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const selectedOptionKey = el.getAttribute("id");

    setInterviewType(e.target.value);
    setInetrviewTypeKey(selectedOptionKey);
    setFormValues({ ...formValues, ["interviewType"]: e.target.value });
  };

  return (
    <MultiStepCol
      openForm={openForm}
      toggleForm={toggleForm}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      title="Interview Form"
      handleSubmit={handleSubmit}
      steps="4"
      handleNext={handleNext}
      applicantId={applicantId}
    >
      {activeStep === 0 && (
        <>
          <div className="forms-input">
            <label>Interview Type</label>
            <select
              onChange={(e) => changeInterviewType(e)}
              value={interviewType}
            >
              <option value="">Select</option>
              {interviewTypes.map((app) => (
                <option value={app.appointmentId} id={app.keyName}>
                  {app.name}
                </option>
              ))}
            </select>
          </div>

          {(interviewTypeKey === "In_Person_Interview" ||
            interviewTypeKey === "Live_Virtual_Interview" ||
            interviewTypeKey === "Phone_Interview") && (
            <div className="forms-input">
              <label>Duration(Minutes)</label>
              <input
                type="text"
                placeholder="Duration"
                name="duration"
                key="duration"
                onChange={handleChange}
                value={formValues?.duration}
              />
            </div>
          )}

          {interviewTypeKey === "In_Person_Interview" && (
            <>
              <div className="forms-input">
                <label>Location</label>
                {allFacilities.map((rec, index) => (
                  <div className="checkbox-label job-list" key={index}>
                    <div>
                      <FormControlLabel
                        control={
                          <CheckboxWithBlueCheck
                            disableRipple
                            checked={rec.checked}
                            onChange={(e) =>
                              onCheckBoxChange(rec.id, e.target.checked)
                            }
                            value={rec.id}
                            key={rec.id}
                          />
                        }
                        label={rec.name}
                      />
                    </div>
                    <label className="company-name">{rec.address}</label>
                    <label className="company-address">380015</label>
                  </div>
                ))}
              </div>
            </>
          )}

          {(interviewTypeKey === "In_Person_Interview" ||
            interviewTypeKey === "Live_Virtual_Interview" ||
            interviewTypeKey === "Phone_Interview") && (
            <div className="forms-input">
              <label>Notes/Instructions</label>
              <textarea
                placeholder="Notes/Instructions"
                name="notes"
                onChange={handleChange}
                value={formValues?.notes}
              />
            </div>
          )}

          {interviewTypeKey === "Live_Virtual_Interview" && (
            <FormControlLabel
              control={
                <CheckboxWithBlueCheck
                  disableRipple
                  onChange={(e) => recordThisMeet(e.target.checked)}
                />
              }
              label="Record this Meeting"
            />
          )}
        </>
      )}

      {activeStep === 1 && (
        <>
          <h3>Select Interviewers</h3>
          <CheckboxList
            allData={allInterviewers}
            setAllData={setAllInterviewers}
            handleSubmit={handleNext}
            showSaveContainer={false}
            handleChange={handleCheckboxChange}
            showSaveContainer={false}
          />
        </>
      )}

      {activeStep === 2 && (
        <>
          {allTimeSlots?.timeSlotsCurrent?.length > 0 &&
          allTimeSlots?.timeSlotTomorrow?.length > 0 &&
          allTimeSlots?.timeSlotDayAfterTomorrow?.length > 0 ? (
            <>
              <div className="time-slots">
                <span>Date: {moment(date).format("dddd, MMMM DD")}</span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotsCurrent?.map((slot) => (
                    <span
                      className={
                        slot.isDisabled === false
                          ? selectedSlots.includes(slot.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() => selectSlots(slot.timeSlot)}
                    >
                      {slot.isDisabled === false && (
                        <>{moment(slot.timeSlot).format("h:mm a")}</>
                      )}
                    </span>
                  ))}
                </div>
              </div>

              <div className="time-slots mt-20">
                <span>Date: {moment(tomorrow).format("dddd, MMMM DD")}</span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotTomorrow?.map((slot) => (
                    <span
                      className={
                        slot.isDisabled === false
                          ? selectedSlots.includes(slot.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() => selectSlots(slot.timeSlot)}
                    >
                      {moment(slot.timeSlot).format("h:mm a")}
                    </span>
                  ))}
                </div>
              </div>

              <div className="time-slots mt-20">
                <span>
                  Date: {moment(dayAfterTomorrow).format("dddd, MMMM DD")}
                </span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotDayAfterTomorrow?.map((slot) => (
                    <span
                      className={
                        slot.isDisabled === false
                          ? selectedSlots.includes(slot.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() => selectSlots(slot.timeSlot)}
                    >
                      {moment(slot.timeSlot).format("h:mm a")}
                    </span>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <span>
              No slots found ! Please check the system settings for slots
              availability !
            </span>
          )}
        </>
      )}

      {activeStep === 3 && (
        <>
          <div className="forms-inputfill">
            <label className="note-label">
              Review the following user information and click Create.
            </label>
          </div>
          <div className="forms-inputfill">
            <label className="company_label">
              {localStorage.getItem("companyName")}
            </label>
          </div>
          <div className="forms-inputfill">
            <label>Interview Type:</label>
            <span>{interviewTypeKey.replace("_", " ")}</span>
          </div>
          <div className="forms-inputfill">
            <label>Duration:</label>
            <span>{formValues?.duration}</span>
          </div>
          <div className="forms-inputfill">
            <label>Location:</label>
            <span>{locationName.join(", ")}</span>
          </div>
          <div className="forms-inputfill">
            <label>Notes/Instructions:</label>
            <span>{formValues?.notes}</span>
          </div>
          <div className="forms-inputfill">
            <label>Interviewers:</label>
            <span>{interviewersName.join(", ")}</span>
          </div>
          <div className="forms-inputfill">
            <label>Selected dates and Times:</label>
            <span>{selectedSlots.join(", ")}</span>
          </div>
        </>
      )}
    </MultiStepCol>
  );
};
