import { ManageJobs } from "../../common/layouts/users/ManageJobs";
import { Link, useLocation } from "react-router-dom";

export const CompanyManageJobsForAdmin = () => {
  const location = useLocation();
  return (
    <div>
      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/dashboard"> Dashboard &gt; </Link>
              <Link to="/companylist"> Companies &gt; </Link>
              <Link
                to="/users"
                state={{
                  companyId: location.state.companyId,
                  companyHirerchy: location.state.companyHirerchy,
                }}
              >
                {" "}
                Users &gt;{" "}
              </Link>
              <strong>Manage Job Assignments</strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <ManageJobs />
          </div>
        </div>
      </div>
    </div>
  );
};
