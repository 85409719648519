import { useEffect } from "react";

export const ViewIframe = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <html>
      <body>
        <iframe
          width="100%"
          height="420"
          src="https://www.youtube.com/embed/JzPfMbG1vrE"
          title="30 Second Explainer Videos"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>

        <ins
          class="adsbygoogle"
          style={{ display: "block", width: "300px", height: "250px" }}
          data-ad-client="ca-pub-2687290035748737"
          data-ad-slot="3100919444"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </body>
    </html>
  );
};
