import { useState } from "react";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";

export const ResendOfferingForm = ({
  toggleForm,
  handleSubmit,
  openForm,
  offerScheduleId,
  flag,
  handleCancelInterview,
  applicantId,
}) => {
  const [description, setDescription] = useState("");

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (flag === "send") {
      handleSubmit(description, offerScheduleId);
    } else {
      handleCancelInterview(description, offerScheduleId);
    }
  };

  return (
    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      handleSubmit={handleSave}
      title={
        flag === "send" ? "Resend Offering Notification" : "Cancel this Offer ?"
      }
    >
      <div className="forms-input">
        <label>Message to Applicant:</label>
        <textarea
          placeholder="Message"
          value={description}
          name="description"
          onChange={handleChange}
        />
      </div>
    </AddEditCol>
  );
};
