import { LeftArrowIcon } from "./../../../../../icons";
import "./../../../../../assets/css/components/company-detail/_morePositions.css";
import { FormControlLabel } from "@mui/material";
import { indigo } from "@mui/material/colors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { CheckboxWithBlueCheck } from "../../../../ui/checkbox/checkboxWithBlueTick";
import { ButtonUI } from "../../../../ui/button";
import { useState, useEffect } from "react";
import Api from "../../../../../helper/Api";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const MorePositions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [jobListWithApplicant, setJobListWithApplicant] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showMoreLabel, setShowMoreLabel] = useState("Show More");
  const { applicantData } = useSelector((state) => state.applicantDataReducer);
  const [value, setValue] = useState(applicantData.jobId);

  const backToProfile = () => {
    navigate(location.pathname.replace(/[^/]*$/, "applicant-details"), {
      state: { ...location.state },
    });
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
    showMore === true
      ? setShowMoreLabel("Show More")
      : setShowMoreLabel("less");
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    Api(
      "GET",
      `Job/GetApplicantAllJob?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId}`
    )
      .then((res) => {
        setJobListWithApplicant(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const changePrimaryJob = () => {
    Api(
      "PATCH",
      `Job/ChangePrimaryJob?companyId=${applicantData.companyId}&jobId=${value}&applicantId=${applicantData.applicantId}`
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onRadioChecked = (checkName,isChecked) => {
    console.log(checkName);
    const checkList = jobListWithApplicant.map((data) => {
      if (data.jobId === checkName) {
        data.isPrimaryJob = isChecked;
      }else {
        data.isPrimaryJob = false;
      }
      return data;
    });
    setJobListWithApplicant(checkList);
  };
  return (
    <div className="manage-bg active">
      <div
        className="back pointer"
        role="button"
        onClick={() => backToProfile()}
      >
        <span>
          <LeftArrowIcon width="25" height="20" />
        </span>
        <span>Back to Profile</span>
      </div>
      <div className="parent-container-d">
        <div className="position-title">
          <span style={{ width: "85px" }}>PRIMARY</span>
          <label style={{ width: "calc(100% - 100px)" }}>POSITION</label>
          {/* <span style={{ width: "150px" }}>COMBINE DUPLICATE</span> */}
        </div>
        <div className="w-card-container">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            {jobListWithApplicant.map((job) => (
              <div className="w-card" key={job.jobId}>
                <div className="primary-job">
                  <FormControlLabel
                    value={job.jobId}
                    control={
                      <Radio
                        sx={{
                          color: indigo[800],
                          "&.Mui-checked": {
                            color: indigo[600],
                          },
                          
                        }}
                        checked={job.isPrimaryJob}
                        onChange={(e) =>
                          onRadioChecked(job.jobId, e.target.checked)
                        }
                      />
                    }
                  />
                </div>

                <div className="position-data">
                  <strong>{job.jobName}</strong>
                  <span className="mt-10">
                    <div
                      className="show-more"
                      dangerouslySetInnerHTML={{
                        __html: job.jobDiscription.substring(0, 100),
                      }}
                    />
                    {/* {showMore ? (
                      <div
                        className="show-more"
                        dangerouslySetInnerHTML={{
                          __html: job.jobDiscription.substring(0, 250),
                        }}
                      />
                    ) : (
                      <div
                        className="show-more"
                        dangerouslySetInnerHTML={{
                          __html: job.jobDiscription.substring(0, 150),
                        }}
                      />
                    )}
                    {job.jobDiscription.length > 150 ? (
                      <label
                        type="button"
                        className="blue-text pointer"
                        onClick={toggleShowMore}
                      >
                        {showMoreLabel}
                      </label>
                    ) : null} */}
                  </span>
                </div>

                {/* <div style={{ width: "45px" }}>
                      <CheckboxWithBlueCheck />
                    </div> */}
              </div>
            ))}
          </RadioGroup>
        </div>

        <div className="save-positions">
          <ButtonUI
            variant="outlined"
            p="12px"
            width="80px"
            onClick={() => backToProfile()}
          >
            Cancel
          </ButtonUI>
          <ButtonUI
            width={"80px"}
            ml={"42px"}
            p={"12px"}
            onClick={() => changePrimaryJob()}
          >
            Save
          </ButtonUI>
        </div>
      </div>
    </div>
  );
};
