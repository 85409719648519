import { combineReducers } from 'redux';
import { userReducer } from './users/reducer';
import { jobTemplateReducer } from './jobTemplate/reducer';
import { companyDataReducer } from './companyData/reducer';
import { jobDataReducer } from './jobData/reducer';
import { applicantDataReducer } from './applicantData/reducer';

export default combineReducers ({
    userReducer,
    jobTemplateReducer,
    companyDataReducer,
    jobDataReducer,
    applicantDataReducer,
});
