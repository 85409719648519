import React from "react";

export const CheckBoxIcon = (props) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M21.5 13L14.1666 20L10.5 16.5"
        stroke={!!props.stroke ? props.stroke : "#8898C9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 5H6C5.44772 5 5 5.44772 5 6V26C5 26.5523 5.44772 27 6 27H26C26.5523 27 27 26.5523 27 26V6C27 5.44772 26.5523 5 26 5Z"
        stroke={!!props.stroke ? props.stroke : "#8898C9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
