import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { JobCard } from "../../common/cards/JobCard";
import "../../../assets/css/components/company-detail/_job.css";
import { AccordianRecord } from "../../common/layouts/AccordianRecord";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell } from "../../ui/table/table";
import { AddEditJob } from "../../common/addEditPopup/AddEditJob";
import Api from "../../../helper/Api";
import { JOB_DATA_ACTIONS } from "../../../redux/jobData/actions";
import { useDispatch } from "react-redux";
import { SortIcon } from "../../../icons";
import { StyledPagination } from "../../ui/pagination/pagination";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import NoRecordsFound from "../../common/NoRecordsFound";
import { ButtonUI } from "../../ui/button";
import AddIcon from "@mui/icons-material/Add";
import { APPLICANT_DATA_ACTIONS } from "../../../redux/applicantData/actions";

export const Jobs = () => {
  const [searchInput, setSearchInput] = useState("");
  const [formValues, setFormValues] = useState({});
  const [openSidebar, setSidebar] = useState(false);
  const [pageNumber, setPage] = useState(1);
  const [jobList, setJobList] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [view, setView] = useState("grid");
  const [totalRecord, setTotalRecord] = useState(0);
  const [jobFormLabel, setLabel] = useState("Add Job");
  const [sortType, setSorted] = useState("desc");
  const [totalPage, setTotalPage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [byBrand, setByBrand] = useState("");
  const [jobStatus, setJobStatusText] = useState("");

  useEffect(() => {
    getAllJobs("updatedAt", "DESC");
  }, [pageNumber]);

  useEffect(() => {
    getJobStatus();
    getFacilities();
  }, []);

  useEffect(() => {
    if (searchInput == "") {
      getAllJobs("updatedAt", "desc");
    }
  }, [searchInput]);

  const getAllJobs = (
    orderBy,
    orderbyDirection,
    searchText = searchInput,
    byBrandText = byBrand,
    jobStatusText = jobStatus
  ) => {
    setIsLoader(true);
    Api(
      "GET",
      `Job/GetJobList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&pageLimit=12&pageNumber=${pageNumber}&searchText=${searchText}&OrderBy=${orderBy}&OrderbyDirection=${orderbyDirection}&ByBrand=${byBrandText}&JobStatus=${jobStatusText}`
    )
      .then((res) => {
        setJobList(res.data.data);
        setTotalRecord(res.data.totalRecord);
        setTotalPage(res.data.totalPage);
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobStatus = () => {
    Api("GET", `Job/GetPriorityAndStatus`)
      .then((res) => {
        const groupByCategory = res.data.reduce((group, status) => {
          const { type } = status;
          group[type] = group[type] ?? [];
          group[type].push(status);
          return group;
        }, {});
        setStatusOptions(groupByCategory.STATUS);
        dispatch({
          type: JOB_DATA_ACTIONS.SET_ALL_JOB_STATUS,
          payload: groupByCategory,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFacilities = () => {
    Api(
      "GET",
      `Facilities/GetFacilitiesList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}`
    )
      .then((res) => {
        setFacilityOptions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSubmit = (editor) => {
    formValues.description = editor;
    if (editMode === true) {
      // Edit job
      Api("PUT", "Job/EditJob", formValues)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleDrawer("right", false);
            setEditMode(false);
            getAllJobs("createdAt", "desc");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Add job
      Api("POST", `Job/AddJob`, formValues)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleDrawer("right", false);
            getAllJobs("createdAt", "desc");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key == "Enter") {
      getAllJobs("updatedAt", "desc");
    }
  };

  const getSearchJob = () => {
    if (searchInput.length === 0) {
      alert("Please enter search value!");
    } else {
      getAllJobs("updatedAt", "desc");
    }
  };

  const displayData = (displayForm) => {
    setView(displayForm);
  };

  const handleSort = (sortColumn) => {
    const sortOrder = sortType === "asc" ? "desc" : "asc";
    setSorted(sortOrder);
    getAllJobs(sortColumn, sortType);
  };

  const cardClickEvent = (jobData) => {
    dispatch({
      type: JOB_DATA_ACTIONS.SET_JOB_DATA,
      payload: jobData,
    });

    dispatch({
      type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
      payload: [],
    });

    navigate("/jobs/details", { state: { ...location.state } });
  };

  const handleByBrandChange = (e) => {
    getAllJobs("updatedAt", "desc", searchInput, e.target.value, jobStatus);
    setByBrand(e.target.value);
  };

  const handleJobStatusChange = (e) => {
    getAllJobs("updatedAt", "desc", searchInput, byBrand, e.target.value);
    setJobStatusText(e.target.value);
  };

  const editAction = (jobId) => {
    setEditMode(true);
    Api("GET", `JOb/GetJobById?jobId=${jobId}`)
      .then((res) => {
        setFormValues(res.data);
        setLabel("Edit Job");
        toggleDrawer("right", true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {openSidebar ? (
        <AddEditJob
          title={jobFormLabel}
          openSidebar={openSidebar}
          toggleDrawer={toggleDrawer}
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleSubmit}
        />
      ) : null}

      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url job">
            <div>
              <p>
                <Link to="/companylist"> Companies &gt; </Link>
                <strong>Jobs ({totalRecord})</strong>
              </p>
            </div>
            <div className="header-container-right">
              <div className="forms-input brand facility">
                <label>By Brand</label>
                <select
                  name="facility"
                  onChange={handleByBrandChange}
                  value={byBrand}
                >
                  <option value="">Select</option>
                  {facilityOptions.map((facility) => (
                    <option
                      value={facility.facilitiesId}
                      key={facility.facilitiesId}
                    >
                      {facility.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="forms-input brand status-container">
                <label>Job Status</label>
                <select
                  name="jobStatus"
                  onChange={handleJobStatusChange}
                  value={jobStatus}
                >
                  <option value="">Select</option>
                  {statusOptions.map((status) => (
                    <option value={status.id} key={status.id}>
                      {status.description}
                    </option>
                  ))}
                </select>
              </div>
              <div className="company_view">
                <button
                  type="submit"
                  onClick={() => (
                    getAllJobs("desc", "updatedAt", ""),
                    setSearchInput(""),
                    setByBrand(""),
                    setJobStatusText("")
                  )}
                  className="icon icon_button"
                >
                  <img
                    src={require("../../../assets/images/refresh.png")}
                    alt="sync-icon"
                    className="icon"
                  />
                </button>
                <button
                  type="submit"
                  onClick={() => displayData("grid")}
                  className="icon icon_button"
                >
                  {view === "grid" ? (
                    <img
                      src={require("../../../assets/images/select_grid_view.png")}
                      alt="grid-icon"
                    />
                  ) : (
                    <img
                      src={require("../../../assets/images/grid_view.png")}
                      alt="grid-icon"
                    />
                  )}
                </button>
                <button
                  type="submit"
                  onClick={() => displayData("list")}
                  className="icon icon_button"
                >
                  {view === "list" ? (
                    <img
                      src={require("../../../assets/images/select_list_view.png")}
                      alt="list-icon"
                    />
                  ) : (
                    <img
                      src={require("../../../assets/images/list_view.png")}
                      alt="list-icon"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div
                  className={
                    view === "grid" ? "content_header_reasons" : "top-search"
                  }
                >
                  <div className="company_search">
                    <img
                      src={require("../../../assets/images/search_icon.png")}
                      alt="search-icon"
                      className="search_img"
                    />
                    <input
                      type="text"
                      placeholder="Search Job"
                      className="search_input appointments"
                      name="search_input"
                      value={searchInput}
                      onChange={handleSearch}
                      onKeyPress={handleKeyPress}
                    />
                    <button className="search-btn" onClick={getSearchJob}>
                      Search
                    </button>
                  </div>
                  <ButtonUI
                    p="12px"
                    pr="16px"
                    onClick={() => {
                      toggleDrawer("right", true);
                      setLabel("Add Job");
                      setFormValues({});
                    }}
                    leftIcon={
                      <AddIcon sx={{ height: "16px", width: "16px" }} />
                    }
                  >
                    Add
                  </ButtonUI>
                </div>
                {isLoader ? (
                  <Loader inShow={true} />
                ) : jobList.length > 0 ? (
                  view === "grid" ? (
                    <div className="j-card-container">
                      {jobList.map((job) => (
                        <div className="j-card pointer" key={job.jobId}>
                          <JobCard
                            jobCode={job.jobNo}
                            name={job.name}
                            description={job.description}
                            jobStatus={job.jobStatus}
                            priority={job.priority}
                            positions={job.positions}
                            jobId={job.jobId}
                            createdAt={job.createdAt}
                            jobProgressStatus={job.jobProgressStatus}
                            jobProgressStatusDescription={
                              job.jobProgressStatusDescription
                            }
                            type="card"
                            cardClickEvent={cardClickEvent}
                            editAction={editAction}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="manage-checkboxes-none specific-facilities">
                      <TableContainer
                        component={Paper}
                        className="box-shadow-none t-bg"
                      >
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">
                                Job Name:
                                <SortIcon
                                  width="7"
                                  height="10"
                                  onClick={() => handleSort("name")}
                                  className="pointer"
                                />
                              </StyledTableCell>
                              <StyledTableCell scope="row" align="center">
                                Status:
                                <SortIcon
                                  width="7"
                                  height="10"
                                  onClick={() => handleSort("jobStatus")}
                                  className="pointer"
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Positions:
                                <SortIcon
                                  width="7"
                                  height="10"
                                  onClick={() => handleSort("positions")}
                                  className="pointer"
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center" width="120px">
                                Days Posted:
                                <SortIcon
                                  width="7"
                                  height="10"
                                  onClick={() => handleSort("createdAt")}
                                  className="pointer"
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Action
                              </StyledTableCell>
                              <StyledTableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {jobList.map((jobRecord) => (
                              <AccordianRecord
                                editAction={editAction}
                                jobCode={jobRecord.jobNo}
                                name={jobRecord.name}
                                description={jobRecord.description}
                                jobStatus={jobRecord.jobStatus}
                                priority={jobRecord.priority}
                                positions={jobRecord.positions}
                                jobId={jobRecord.jobId}
                                createdAt={jobRecord.createdAt}
                                cardClickEvent={cardClickEvent}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )
                ) : (
                  <NoRecordsFound />
                )}
                {jobList.length > 0 ? (
                  <StyledPagination
                    count={totalPage}
                    page={pageNumber}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePageChange}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
