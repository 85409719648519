import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Edit } from "@material-ui/icons";
import CustomFroalaEditor from "../../../../../../ui/froala";
import { Box } from "@material-ui/core";
import Api from "../../../../../../../helper/Api";
import { useState } from "react";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditOfferLetter({ id }) {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = useState("");

  React.useEffect(() => {
    const params = {
      applicantOfferLetterId: id,
    };

    Api("POST", `Job/GetOfferLetterDocumentDetails`, params)
      .then((res) => {
        setContent(res.data.data.lstOfferLetterDetails[0].offerLetterContent);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditOfferLetter = () => {
    const params = {
      applicantOfferLetterId: id,
      offerLetterContent: content,
    };
    Api("POST", "Job/EditOfferLetter", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          setOpen(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} size="small">
        <Edit fontSize="small" />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Offer Letter
            </Typography>
            <Button autoFocus color="inherit" onClick={handleEditOfferLetter}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Box style={{ height: "100%" }}>
          <CustomFroalaEditor content={content} setContent={setContent} />
        </Box>
      </Dialog>
    </div>
  );
}
