import { useState, useEffect } from "react";
import { CheckboxList } from "../../../../common/layouts/CheckboxList";
import { useSelector } from "react-redux";
import Api from "../../../../../helper/Api";
import { toast } from "react-toastify";

export const DocumentPacks = ({ getJobTempById }) => {
  const [allData, setAllData] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [isCheckedAll, setAllChecked] = useState(true);
  const hasSelectAll = true;
  const selectedData = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.checklistItems
  );

  useEffect(() => {
    Api(
      "GET",
      `DocumentPack/GetDocumentPackList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        res.data.data.map((d) => {
          d.checked = selectedData.includes(d.documentPackId) ? true : false;
          d.id = d.documentPackId;
        });
        setAllData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const selectAll = () => {
    setAllChecked(!isCheckedAll);
    allData.map((d) => {
      d.checked = isCheckedAll;
    });
  };

  const handleSubmit = (data) => {
    let selected = [];
    data.filter(function (record) {
      if (record.checked === true) {
        selected.push(record.documentPackId);
      }
    });

    const params = {
      companyId: companyData.companyId,
      jobTemplateId: companyData.data.jobTemplateId,
      ids: selected,
      enumSourceType: 4, // Document Packs
    };

    Api("POST", "JobTemplate/SaveJobTemplateDetails", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getJobTempById();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="top-header-user">
        <span className="main-name">Document Packs </span>
        <span className="blue-t pointer" onClick={() => selectAll()}>
          Select all
        </span>
      </div>
      <div className="main_content">
        <CheckboxList
          allData={allData}
          setAllData={setAllData}
          handleSubmit={handleSubmit}
          hasSelectAll={hasSelectAll}
          setAllChecked={setAllChecked}
        />
      </div>
    </div>
  );
};
