import NavigationBar from "../../../components/common/navigationBar/NavigationBar";
import { Sidebar } from "../../../components/common/sideBar/SideBar";

export const CompanyLayout = ({ ...props }) => {
  return (
    <div className="h-100">
      <NavigationBar />
      <Sidebar />
      <>{props.children}</>
    </div>
  );
};
