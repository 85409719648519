import React, { useEffect, useState } from "react";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import Api from "../../../../../../../helper/Api";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { indigo } from "@material-ui/core/colors";

export const AddDocPackForm = ({
  toggleForm,
  openForm,
  applicantId,
  saveDocPack,
}) => {
  const [docPackList, setDocPackList] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);

  useEffect(() => {
    Api("GET", `Job/ListOnboardingDocument?companyId=${companyData.companyId}`)
      .then((res) => {
        const data = [...res.data.onboardingDocumentPacksLists];
        data.forEach((rec) => {
          rec.checked = false;
          rec.documentPackLists.forEach((dl) => {
            dl.checked = false;
          });
        });
        setDocPackList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = [...docPackList];
    const records = [];
    data.forEach((rec) => {
      rec.documentPackLists.forEach((r) => {
        if (r.checked === true) {
          records.push({
            documentId: r.documentId,
            documentUrl: r.documentUrl,
            documentName: r.documentName,
          });
        }
      });
    });
    saveDocPack(records);
  };

  const handleDocChange = (e, i, ci) => {
    const list = [...docPackList];

    if (ci === undefined || ci === null) {
      list[i]["documentPackLists"].map(
        (items) => (items.checked = e.target.checked)
      );
      list[i].checked = e.target.checked;
    } else {
      list[i]["documentPackLists"][ci].checked = e.target.checked;
      if (e.target.checked === false) {
        list[i].checked = false;
      }
    }

    setDocPackList(list);
  };

  return (
    <AddEditCol
      toggleForm={toggleForm}
      openForm={openForm}
      applicantId={applicantId}
      title="Create Onborading Pack"
      handleSubmit={handleSubmit}
    >
      {docPackList.map((docPack, index) => (
        <div className="forms-checkbox mt-5" key={index}>
          <Accordion square={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    value={docPack.documentPackId}
                    onChange={(e) => handleDocChange(e, index)}
                    style={{
                      color: indigo[600],
                    }}
                    checked={docPack.checked}
                  />
                }
                label={docPack.documentPackName}
              />
            </AccordionSummary>
            {docPack.documentPackLists.map((items, ci) => (
              <AccordionDetails key={items.documentName}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      style={{
                        color: indigo[600],
                      }}
                      onChange={(e) => handleDocChange(e, index, ci)}
                      checked={items.checked}
                      value={items.documentName}
                    />
                  }
                  label={items.documentName}
                />
              </AccordionDetails>
            ))}
          </Accordion>
        </div>
      ))}
    </AddEditCol>
  );
};
