import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastAlert } from "../../helper/constants";
import { StyledPagination } from "../ui/pagination/pagination";
import Api from "../../helper/Api";
import Loader from "../common/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/inter";
import "../../assets/css/components/add-edit-popup/_add-edit-popup.css";
import OutsideClickHandler from "react-outside-click-handler";
import "../../assets/css/components/company-view/_company-view.css";
import { AddEditCompany } from "./AddEditCompany";
import { useDispatch } from "react-redux";
import { COMPANY_DATA_ACTIONS } from "../../redux/companyData/actions";
import { ButtonUI } from "../ui/button";
import AddIcon from "@mui/icons-material/Add";

export function CompanyList() {
  const [isLoader, setIsLoader] = useState(false);
  const [openToastBar, setOpenToastBar] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [allTimeZone, setAllTimeZone] = useState([]);
  const [sortType, setSorted] = useState("asc");
  const [searchInput, setSearchInput] = useState("");
  const [disable, setDisable] = useState(false);
  const [showActions, setEditActions] = useState();
  const [showSideBar, setShowSideBar] = useState(false);
  const dispatch = useDispatch();
  const [showAddEditLabel, setAddEditLabel] = useState("Add New");
  const [showPopup, setPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const initialValues = {
    name: "",
    jobPrefix: "",
    timeZone: "",
    totalJob: "",
    perJobBoard: "",
    companyLogo: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editImageShow, setEditImageShow] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [view, setView] = useState("list");
  const pageLimit = 12;
  let navigate = useNavigate();
  const errors = {};

  useEffect(() => {
    getTimeZone();
    setIsLoader(true);
  }, []);

  useEffect(() => {
    if (searchInput == "" && page) {
      setIsLoader(true);
      getCompanyData("desc", "updatedAt", "");
    } else {
      setIsLoader(false);
    }
  }, [searchInput, page]);

  const getCompanyData = (sortType, sortColumnName, searchText) => {
    Api(
      "GET",
      `companies/getcompany?pageLimit=${pageLimit}&pageNumber=${page}&OrderbyDirection=${sortType}&OrderBy=${sortColumnName}&searchText=${searchText}`
    )
      .then((response) => {
        if (response.data.data.length) {
          setCompanyData(response.data.data);
          setTotalPage(response.data.totalPage);
        } else {
          setCompanyData([]);
          toast.error("Companies not found");
          setOpenToastBar(true);
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTimeZone = () => {
    Api("GET", "TimeZone/GetTimeZone?isActive=true")
      .then((response) => {
        if (response.data) {
          setAllTimeZone(response.data);
        } else {
          setAllTimeZone([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // On every page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // On every company search
  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearchCompany();
    }
  };

  // Return searched company records
  const getSearchCompany = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      getCompanyData("desc", "updatedAt", searchInput);
      setIsLoader(true);
    }
  };

  // Sort company data
  const handleSort = () => {
    const sortOrder = sortType === "asc" ? "desc" : "asc";
    setSorted(sortOrder);
    getCompanyData(sortType, "name", "");
  };

  // Perticular card wise actions box open
  const handleClick = (id) => {
    setEditActions(id !== showActions ? id : null);
  };

  // Open Add form
  const addDataTable = () => {
    setEditImageShow(false);
    setEditMode(false);
    setFormValues({});
    setAddEditLabel("Add New Company");
    setShowSideBar(true);
  };

  // on click of actions buttons returns individual id
  function getActionId(type, compId) {
    setPopup(false);
    if (type === "edit") {
      // Edit button clicked in - popup
      setEditMode(true);
      setAddEditLabel("Edit Company");
      setEditImageShow(true);

      const listItemValue = companyData.filter((items) => {
        return items.companyId === compId ? items : null;
      });

      setFormValues({
        ["name"]: listItemValue[0].name,
        ["jobPrefix"]: listItemValue[0].jobPrefix,
        ["emailAddress"]: listItemValue[0].emailAddress,
        ["timeZone"]: listItemValue[0].timeZone,
        ["totalJob"]: listItemValue[0].totalJob,
        ["perJobBoard"]: listItemValue[0].perJobBoard,
        ["companyLogo"]: listItemValue[0].companyImage,
        ["companyId"]: compId,
      });
      setShowSideBar(true);
    } else if (type === "delete") {
      // Delete data of row table - popup clicked delte
      var answer = window.confirm("Are you sure you want to delete?");
      if (answer) {
        setIsLoader(true);
        // Delete comapny api call
        Api("DELETE", `Companies/DeleteCompany?companyId=${compId}`)
          .then((res) => {
            res.data.isSuccess == true
              ? toast.success(res.data.message)
              : toast.error(res.data.message);
            setOpenToastBar(true);
            getCompanyData("desc", "updatedAt", "");
          })
          .catch((err) => {
            setIsLoader(false);
          });
      }
    }
  }

  // On submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setDisable(true);
      setIsLoader(true);
      if (editMode) {
        Api("PUT", "companies/editcompany", formValues, "multipart")
          .then((response) => {
            if (response.data.isSuccess) {
              toast.success(response.data.message);
              getCompanyData("desc", "updatedAt", "");
              setShowSideBar(false);
              setDisable(false);
            } else {
              toast.error(response.data.message);
              setShowSideBar(true);
            }
            setOpenToastBar(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Api("POST", "companies/addcompany", formValues, "multipart")
          .then((response) => {
            if (response.data.isSuccess) {
              toast.success(response.data.message);
              getCompanyData("desc", "updatedAt", "");
              setShowSideBar(false);
              setDisable(false);
            } else {
              toast.error(response.data.message);
              setShowSideBar(true);
            }
            setOpenToastBar(true);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setIsLoader(false);
    }
  }, [formErrors]);

  // Validations
  const validate = (values) => {
    if (!values.name) {
      errors.name = "Name is required!";
    }

    if (!values.jobPrefix) {
      errors.jobPrefix = "Job Number Prefix is required!";
    } else if (!isNaN(values.jobPrefix)) {
      errors.jobPrefix = "Job Number Prefix must be text!";
    }

    if (!editMode) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!values.emailAddress) {
        errors.emailAddress = "Email id is required!";
      } else if (!values.emailAddress.match(validRegex)) {
        errors.emailAddress = "Please enter a valid email address!";
      }
    }

    if (!values.timeZone) {
      errors.timeZone = "Timezone is required!";
    }

    if (!values.totalJob) {
      errors.totalJob = "Total is required!";
    } else if (isNaN(values.totalJob)) {
      errors.totalJob = "Total must be number!";
    }

    if (!values.perJobBoard) {
      errors.perJobBoard = "Per Job Board is required!";
    }

    if (!values.companyImage && !values.companyLogo) {
      errors.companyImage = "Company Logo is required!";
    }

    return errors;
  };

  const onDetailSources = (id, companyHirerchy) => {
    navigate("/sources", {
      state: { companyId: id, companyHirerchy: companyHirerchy },
    });
    dispatch({
      type: COMPANY_DATA_ACTIONS.SET_COMP_DATA,
      payload: { companyId: id, companyHirerchy: companyHirerchy },
    });
  };

  const handleCellClick = (compId, compHierarchy) => {
    navigate("/sources", {
      state: { companyId: compId, companyHirerchy: compHierarchy },
    });
    dispatch({
      type: COMPANY_DATA_ACTIONS.SET_COMP_DATA,
      payload: { companyId: compId, companyHirerchy: compHierarchy },
    });
  };

  const displayData = (method) => {
    setView(method);
  };

  const CompaniesCard = companyData.map((val, key) => {
    return (
      <div key={key} className="company_grid">
        <div className="grid_1">
          <img
            src={val.companyImage}
            alt="logo"
            className="company_list_logo"
            width="80px"
            height="70px"
          />
          <button type="submit" className="icon icon_button option_menu">
            <img
              src={require("../../assets/images/option_menu.png")}
              alt="company-logo"
              className=""
              onClick={() => (
                handleClick(val.companyId),
                setPopup(true),
                showPopup ? setPopup(false) : setPopup(true)
              )}
            />
            {/* edit actions popup html */}
            {showActions === val.companyId && showPopup ? (
              <OutsideClickHandler
                onOutsideClick={() => {
                  setPopup(false);
                }}
              >
                <div className="edit-popup">
                  <ul>
                    <li
                      key={val.companyId}
                      onClick={() => getActionId("edit", val.companyId)}
                    >
                      <img
                        src={require("../../assets/images/edit.png")}
                        className="actionIcon"
                      />
                      <span>Edit</span>
                    </li>
                    <li
                      key={val.name}
                      onClick={() => getActionId("delete", val.companyId)}
                    >
                      <img
                        src={require("../../assets/images/delete.png")}
                        alt="delete"
                        className="actionIcon"
                      />
                      <span>Delete</span>
                    </li>
                  </ul>
                </div>
              </OutsideClickHandler>
            ) : null}
          </button>
        </div>
        <div
          className="grid_2 td_name"
          onClick={() => handleCellClick(val.companyId, val.companyHirerchy)}
        >
          {val.name}
        </div>
        <div className="grid_3 grid_values">
          {val.jobPrefix}
          <br />
          <span className="grid_keys">Job Number Prefix</span>
        </div>
        <div className="grid_4 grid_values">
          {val.timeZoneOffSet}
          <br />
          <span className="grid_keys">Time Zone</span>
        </div>
        <div className="grid_5 grid_values">
          {val.perJobBoard}
          <br />
          <span className="grid_keys">Per Job Board</span>
        </div>
        <div className="grid_6 grid_values">
          {val.totalJob}
          <br />
          <span className="grid_keys">Total</span>
        </div>
      </div>
    );
  });

  const CompaniesList = companyData.map((val, key) => {
    return (
      <tr key={key}>
        <td
          className="td_name"
          onClick={() => onDetailSources(val.companyId, val.companyHirerchy)}
        >
          {val.companyImage ? (
            <img
              src={val.companyImage}
              alt="logo"
              className="company_list_logo"
              width="80px"
              height="70px"
            />
          ) : (
            <img
              alt="logo"
              className="company_list_logo"
              width="80px"
              height="70px"
              src={require("../../assets/images/no-img.png")}
            />
          )}
          {val.name}
        </td>
        <td>{val.jobPrefix}</td>
        <td>{val.timeZoneOffSet}</td>
        <td>{val.perJobBoard}</td>
        <td>{val.totalJob}</td>
        <td>
          <img
            src={require("../../assets/images/edit.png")}
            alt="edit-icon"
            className="icon edit_icon"
            onClick={() => getActionId("edit", val.companyId)}
          />
          <img
            src={require("../../assets/images/delete.png")}
            alt="delete-icon"
            className="icon"
            onClick={() => getActionId("delete", val.companyId)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div className="">
      {showSideBar ? (
        <AddEditCompany
          formValues={formValues}
          setFormValues={setFormValues}
          setSideBar={setShowSideBar}
          formErrors={formErrors}
          editMode={editMode}
          handleSubmit={handleSubmit}
          label={showAddEditLabel}
          disable={disable}
          editImageShow={editImageShow}
          setEditImageShow={setEditImageShow}
          allTimeZone={allTimeZone}
        />
      ) : null}
      {openToastBar ? <ToastAlert /> : null}
      <div className="company_content">
        <div className="content_header">
          <div className="company_text mr-20">Companies</div>
          <div className="company_search">
            <img
              src={require("../../assets/images/search_icon.png")}
              alt="search-icon"
              className="search_img"
            />
            <input
              type="text"
              placeholder="Search Companies"
              className="search_input"
              name="search_input"
              value={searchInput}
              onChange={handleSearch}
              onKeyPress={handleKeyPress}
            />
            <button onClick={getSearchCompany} className="search-btn">
              Search
            </button>
          </div>
          <Loader inShow={isLoader} />
          {view === "grid" ? (
            <div className="company_sort">
              <div className="sort_text">
                <span className="text_light"> SORT:</span> A-Z
              </div>
              <button onClick={handleSort} className="icon icon_button">
                <img
                  src={require("../../assets/images/drop_down.png")}
                  alt="sort-icon"
                  className="icon sort_img"
                />
              </button>
            </div>
          ) : null}
          <div className="company_view">
            <button
              type="submit"
              onClick={() => displayData("grid")}
              className="icon icon_button"
            >
              {view === "grid" ? (
                <img
                  src={require("../../assets/images/select_grid_view.png")}
                  alt="grid-icon"
                />
              ) : (
                <img
                  src={require("../../assets/images/grid_view.png")}
                  alt="grid-icon"
                />
              )}
            </button>
            <button
              type="submit"
              onClick={() => displayData("list")}
              className="icon icon_button"
            >
              {view === "list" ? (
                <img
                  src={require("../../assets/images/select_list_view.png")}
                  alt="list-icon"
                />
              ) : (
                <img
                  src={require("../../assets/images/list_view.png")}
                  alt="list-icon"
                />
              )}
            </button>
            <button
              type="submit"
              onClick={() => (
                getCompanyData("desc", "updatedAt", ""), setSearchInput("")
              )}
              className="icon icon_button"
            >
              <img
                src={require("../../assets/images/refresh.png")}
                alt="sync-icon"
                className="icon"
              />
            </button>
            <ButtonUI
              p="12px"
              ml="5px"
              type="submit"
              onClick={() => addDataTable()}
              leftIcon={<AddIcon sx={{ height: "16px", width: "16px" }} />}
            >
              Add
            </ButtonUI>
          </div>
        </div>
        {view === "list" ? (
          <div className="company_table">
            <table>
              <thead>
                <tr>
                  <th className="th_name">
                    Companies Name
                    <button onClick={handleSort} className="icon icon_button">
                      <img
                        src={require("../../assets/images/tabsort.png")}
                        alt="sort-icon"
                        className="icon sort_icon"
                      />
                    </button>
                  </th>
                  <th>Job Prefix</th>
                  <th>Time Zone</th>
                  <th>Per Job Board</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{CompaniesList}</tbody>
            </table>
          </div>
        ) : (
          <div className="company_card">{CompaniesCard}</div>
        )}
        <StyledPagination
          count={totalPage}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}
