import React from 'react';

const NoRecordsFound = () => {
    // No records found

    const mainStyle = {
      height: "85%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }
    const labelP = {
      marginTop: "10px"
    }


  return (
    <div style={mainStyle}>
        <img src={require('../../assets/images/no_records_found_1.png')} width="240px" alt="" />
        <p style={labelP} className='text-center w-100'>No Records Found..!</p>
    </div>
  )
}

export default NoRecordsFound;