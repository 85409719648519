import {
  createTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import { ButtonUI } from "../../../components/ui/button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export const JobReport = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageNumber, setPage] = useState(1);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [totalPage, setTotalPage] = useState(1);

  const theme = createTheme({
    palette: {
      action: {
        hover: "#fff",
      },
    },
  });

  useEffect(() => {
    getJobReport();
  }, [pageNumber]);

  const getJobReport = () => {
    setIsLoader(true);

    const query = `companyId=${companyData.companyId}&pageLimit=10&pageNumber=${pageNumber}&orderBy=desc&orderByDirection=createdAt`;
    Api("GET", `Report/JobsReport?${query}`)
      .then((res) => {
        setRows(res.data.data);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // function Row(props) {
  //   const { row } = props;

  //   return (
  //     <React.Fragment>
  //       <StyledTableRow
  //         sx={{ "& > *": { borderBottom: "unset" } }}
  //         theme={theme}
  //       >
  //         <StyledTableCell
  //           component="th"
  //           scope="row"
  //           align="left"
  //           sx={{ cursor: "pointer" }}
  //           onClick={(e) => handleCellClick(row.companyId, row.companyHirerchy)}
  //         >
  //           {row.jobNo}
  //         </StyledTableCell>
  //         <StyledTableCell align="center">{row.jobTitle}</StyledTableCell>
  //         <StyledTableCell align="center">{row.facility}</StyledTableCell>
  //         <StyledTableCell align="center">{row.location}</StyledTableCell>
  //         <StyledTableCell align="center">{row.schedule}</StyledTableCell>
  //         <StyledTableCell align="center">{row.opened}</StyledTableCell>
  //         <StyledTableCell align="center">{row.daysPosted}</StyledTableCell>
  //         <StyledTableCell align="center">{row.currentStage}</StyledTableCell>
  //         <StyledTableCell align="center">{row.replacing}</StyledTableCell>
  //         <StyledTableCell align="center">
  //           {row.newHire.join(", ")}
  //         </StyledTableCell>
  //         <StyledTableCell align="center">
  //           {row.startDate.join(", ")}
  //         </StyledTableCell>
  //       </StyledTableRow>
  //       <StyledTableRow></StyledTableRow>
  //     </React.Fragment>
  //   );
  // }

  const Row = rows.map((val, key) => {
    return (
      <tr key={key}>
        <td className="td_name">{val.jobNo}</td>
        <td>{val.jobTitle}</td>
        <td>{val.facility}</td>
        <td>{val.location}</td>
        <td>{val.schedule}</td>
        <td>{val.opened}</td>
        <td>{val.daysPosted}</td>
        <td>{val.currentStage}</td>
        <td>{val.replacing}</td>
        <td>{val.newHire.join(", ")}</td>
        <td>{val.startDate}</td>
      </tr>
    );
  });

  return (
    <div>
      <div className="company_content">
        <div className="content_header">
          <div className="company_text mr-20">Job Report</div>
          {rows.length > 0 && (
            <ButtonUI
              p="6px"
              leftIcon={<FileDownloadOutlinedIcon />}
              onClick={() => exportToCSV(rows, "report")}
            >
              Download
            </ButtonUI>
          )}
        </div>

        {isLoader ? (
          <Loader inShow={true} />
        ) : rows?.length > 0 ? (
          <>
            <div className="company_table">
              <table>
                <thead>
                  <tr>
                    <th className="th_name">Job No.</th>
                    <th>Job Title</th>
                    <th>Facility</th>
                    <th>Location</th>
                    <th>Scheduled</th>
                    <th>Opened</th>
                    <th>Days Posted</th>
                    <th>Current Stage</th>
                    <th>Replacing</th>
                    <th>New Hire</th>
                    <th>Start Date</th>
                  </tr>
                </thead>
                <tbody>{Row}</tbody>
              </table>
            </div>
            <StyledPagination
              count={totalPage}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </div>
  );
};
