import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import styled from "@emotion/styled";
import "@fullcalendar/common/main.css";

// Cal style
const CalStyleWrapper = styled.div`
  .fc {
    padding: 16px;
  }
  .fc .fc-toolbar-title {
    font-size: 16px;
    margin: 0px 30px;
  }
  .fc-daygrid-day.fc-day.fc-day-today
    .fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
    border: 2px solid #34aa44 !important;
  }
  .fc-daygrid-day.fc-day.fc-day-today {
    background: none !important;
  }
  .fc-day-today .fc-daygrid-day-number {
    background: #34aa44;
    color: white;
    border-radius: 20px;
  }
  .fc-daygrid-day-number {
    margin: 8px;
    padding: 8px;
    width: 30px;
  }
  .fc td {
    color: #344054;
    font-weight: 600;
    font-size: 16px;
  }
  .fc-button-primary {
    color: #667085;
    background-color: #f0f6ff;
    border: none;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
  }
  .fc-button-primary: hover {
    color: #ffffff;
    background-color: #006aff;
    box-shadow: none !important;
  }
  .fc-button-active {
    color: #ffffff !important;
    background-color: #006aff !important;
    border-radius: 0.25em !important;
  }
  .fc-button-active: focus {
    box-shadow: none !important;
  }
  .fc-button: focus {
    box-shadow: none !important;
  }
  .fc-toolbar-chunk div {
    display: flex;
    align-items: center;
  }
  table,
  tr,
  td,
  th {
    border: none !important;
    opacity: unset;
  }
  th {
    font-weight: 600;
    color: #667085;
    text-transform: none;
  }
  .fc-col-header-cell.fc-day {
    padding: 12px 24px 4px 4px;
    text-align: right;
  }
  .fc-scrollgrid-section.fc-scrollgrid-section-header {
    background: #f0f6ff;
  }
  .fc .fc-view-harness-active > .fc-view {
    border: 1px solid #f0f0fe;
    max-height: 80%;
    border-radius: 8px;
  }
  .fc .fc-daygrid-day-frame {
    border: 1px solid #f0f0fe;
  }
  .fc-daygrid-bg-harness .fc-highlight {
    border: 2px solid #3954a5;
    background: #deebff;
  }
`;

export const FullCal = ({ selectedDate, setSelectedDate, monthlyTasks }) => {
  const onDateSelection = (e) => {
    setSelectedDate(e.dateStr);
  };

  return (
    <CalStyleWrapper>
      <FullCalendar
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        defaultView="dayGridMonth"
        headerToolbar={{
          left: "prev,title,next",
          center: "",
          right: "",
        }}
        weekends={true}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          resourceTimeGridPlugin,
        ]}
        events={monthlyTasks}
        eventColor="#34AA44"
        displayEventTime={false}
        dayMaxEventRows={true}
        nowIndicator={true}
        selectable={true}
        unselectAuto={false}
        dateClick={(e) => {
          onDateSelection(e);
        }}
      />
    </CalStyleWrapper>
  );
};
