export const ActiveUserIcon = ({ ...props }) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6986 15.5223C13.8479 14.7872 14.7315 13.6911 15.216 12.3993C15.7004 11.1074 15.7595 9.69006 15.3843 8.36101C15.0091 7.03197 14.2199 5.86338 13.1359 5.03161C12.0519 4.19983 10.7318 3.75 9.37498 3.75C8.01812 3.75 6.69808 4.19983 5.61407 5.03161C4.53005 5.86338 3.74088 7.03197 3.36566 8.36101C2.99044 9.69006 3.04952 11.1074 3.53399 12.3993C4.01846 13.6911 4.90202 14.7872 6.05135 15.5223C4.05014 16.1662 2.27079 17.3788 0.922387 19.0178C0.832287 19.1271 0.774679 19.2604 0.756336 19.4019C0.737992 19.5435 0.759677 19.6874 0.818839 19.8168C0.878001 19.9462 0.972182 20.0557 1.09031 20.1325C1.20844 20.2092 1.3456 20.25 1.48568 20.25L17.2643 20.25C17.4044 20.25 17.5416 20.2092 17.6597 20.1325C17.7778 20.0558 17.872 19.9463 17.9312 19.8169C17.9903 19.6874 18.012 19.5435 17.9937 19.402C17.9753 19.2604 17.9177 19.1272 17.8276 19.0178C16.4792 17.3789 14.6998 16.1662 12.6986 15.5223Z"
        fill="#3954A5"
      />
      <path
        d="M23.0184 11.7933L19.0179 15.5433C18.8784 15.6764 18.693 15.7506 18.5001 15.7506C18.3073 15.7506 18.1219 15.6764 17.9823 15.5433L15.9828 13.6683C15.9114 13.6003 15.8541 13.519 15.8142 13.4288C15.7743 13.3387 15.7526 13.2416 15.7502 13.1431C15.7479 13.0445 15.7649 12.9465 15.8005 12.8546C15.836 12.7626 15.8894 12.6786 15.9574 12.6073C16.0254 12.536 16.1069 12.4788 16.197 12.439C16.2872 12.3991 16.3843 12.3775 16.4829 12.3752C16.5814 12.3729 16.6794 12.3901 16.7713 12.4257C16.8632 12.4614 16.9472 12.5148 17.0184 12.5829L18.5001 13.9635L21.9828 10.7079C22.054 10.6398 22.138 10.5864 22.2299 10.5507C22.3218 10.5151 22.4198 10.4979 22.5184 10.5002C22.6169 10.5025 22.714 10.5241 22.8042 10.564C22.8944 10.6038 22.9758 10.661 23.0438 10.7323C23.1119 10.8036 23.1652 10.8876 23.2007 10.9796C23.2363 11.0715 23.2534 11.1695 23.251 11.2681C23.2487 11.3666 23.2269 11.4637 23.187 11.5538C23.1471 11.644 23.0898 11.7253 23.0184 11.7933Z"
        fill="#3954A5"
      />
    </svg>
  );
};
