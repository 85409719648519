import React, { useState } from "react";
import "../../../assets/css/admin/_settings.css";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Android12Switch } from "../../../components/ui/android/android2switch";
import { UserProfile } from "./UserProfile";
import { ManageSession } from "./ManageSession";
import { ChangePassword } from "./ChangePassword";
import { API_types } from "../../../helper/enum";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";

export const Settings = () => {
  //seperate file
  const CustomTab = styled(Tab)`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: normal;
    text-transform: capitalize;

    &.Mui-selected {
      font-weight: 700;
      color: #333;
    }
  `;

  // Tab Value
  const [value, setValue] = useState(0);
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [desktopNotificationValues, setDesktopNotificationValues] = useState(false);
  const [emailNotificationValues, setEmailNotificationValues] = useState(false);
  const userId = localStorage.getItem("user");
  
  //seperate file
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div className="h-100">{children}</div>}
      </div>
    );
  }

  //seperate file
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  //seperate file
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const getUserName = (firstName, lastName, desktopNotification, emailNotification) => {
    // set name
    setFname(firstName);
    setLname(lastName);
    setDesktopNotificationValues(desktopNotification);
    setEmailNotificationValues(emailNotification);
  };

  const handleChange = (e, checked) => {
    e.preventDefault();
    var desktop = desktopNotificationValues;
    var email = emailNotificationValues;
    if (e.target.name == "desktopNotification") {
      desktop = checked;
      setDesktopNotificationValues(desktop);
    } 

    if (e.target.name == "emailNotification") {
      email = checked;
      setEmailNotificationValues(email);
    }   

    const params = {
      userId: userId,
      isDesktopNotification: desktop,
      isEmailNotification: email,
    };

    Api(API_types.PATCH, `Users/UserNotificationSetting`, params)
      .then((response) => {
        if (response.data) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="company_content">
      <div className="content_header_settings">
        {/* seperate file */}
        <img
          src={require("../../../assets/images/John.svg").default}
          alt="nav-icon"
          className="icon settings-icon"
        />

        <div className="company_text">{fname + " " + lname}</div>
      </div>
      <div className="d-flex justify-content-center">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} aria-label="Tab" onChange={handleChangeTab}>
            <CustomTab label="Profile" {...a11yProps(0)} />
            <CustomTab label="Password" {...a11yProps(1)} />
            <CustomTab label="Notifications" {...a11yProps(2)} />
            <CustomTab label="Session" {...a11yProps(3)} />
          </Tabs>
        </Box>
      </div>
      <TabPanel value={value} index={0} className="tab-panel-settings">
        <UserProfile getUserName={getUserName} />
      </TabPanel>
      <TabPanel value={value} index={1} className="tab-panel-settings">
        <ChangePassword />
      </TabPanel>
      <TabPanel value={value} index={2} className="tab-panel-settings">
        <div className="personal-settings password-tab">
          <div className="personal-settings-title">
            <span className="td_name"> Manage your notification settings </span>
            <p className="title_2">
              {" "}
              We may still send you important notifications about your account
              and content outside of your preferred notification settings.{" "}
            </p>
          </div>
          <div className="d-flex w-100 notify-tabs">
            <div className="job-in-name w-100">
              <div className="d-flex justify-content-between notify-card">
                <span> Desktop Notifications </span>
                  <div className="position-relative">
                    <FormControlLabel
                      control={<Android12Switch />}
                      name="desktopNotification"
                      checked={desktopNotificationValues}
                      onChange={(e) =>
                        handleChange(e, e.target.checked)
                      }
                    />
                  </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="sub-desc title_3">
                  {" "}
                  Receive notifications directly on my Windows{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 notify-tabs">
            <div className="job-in-name w-100">
              <div className="d-flex justify-content-between">
                <span> Email Notifications </span>
                <div className="position-relative">
                  <FormControlLabel
                    control={<Android12Switch />}
                    name="emailNotification"
                    checked={emailNotificationValues}
                    onChange={(e) =>
                      handleChange(e, e.target.checked)
                    }
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="sub-desc title_3">
                  {" "}
                  Receive notifications directly on my Windows{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={3} className="tab-panel-settings">
        <ManageSession />
      </TabPanel>
    </div>
  );
};
