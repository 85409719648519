import React, { useState } from "react";
import ApplicantInfoTabs from "./applicantInfoTabs";
import { ApplicantJobData } from "./applicantJobData";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../../assets/css/common/_col-form.css";
import { IconButton, Rating, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../../../redux/applicantData/actions";
import BackgroundLetterAvatars from "../../BackgroundLetterAvatars";
import { useEffect } from "react";
import Api from "../../../../../helper/Api";
import {
  BlueAddIcon,
  DarkSuitCaseIcon,
  LightSuitCaseIcon,
} from "../../../../../icons";
import EastIcon from "@mui/icons-material/East";
import EmailIcon from "@mui/icons-material/Email";
import LoginIcon from "@mui/icons-material/Login";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export const ApplicantContent = ({ data, index }) => {
  const dispatch = useDispatch();
  const domNode = document?.getElementById(data.applicantId) || "";
  const [selectedSuitCase, setSelectedSuitCase] = useState({});
  const [applicantInfo, setApplicantInfo] = useState([]);
  const [value, setValue] = useState(0);
  const [avgRating, setAvgRating] = useState(0);
  const [memberNo, setMemberNo] = useState(0);
  const [isShow, setShow] = useState(false);
  const [appDetails, setAppDetails] = useState({});
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  useEffect(() => {
    getApplicantRating();
    getApplicantJobData();
    showInfo();
  }, []);

  const getApplicantRating = () => {
    Api(
      "GET",
      `Job/GetApplicantRatingList?applicantId=${data.applicantId}&companyId=${data.companyId}`
    )
      .then((res) => {
        const data = [...res.data];
        var el = 0;

        data.forEach((rec) => {
          if (rec.userId == localStorage.getItem("user")) {
            setValue(rec.rating);
          }
          el = el + rec.rating;
        });
        setMemberNo(data.length);

        const avg = el / data.length;
        setAvgRating(avg);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeApplicantTab = () => {
    const dataArr = [...applicantsArray];

    dataArr.forEach((rec, index) => {
      if (rec.applicantId === data.applicantId) {
        dataArr.splice(index, 1);
      }
    });

    dispatch({
      type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
      payload: dataArr,
    });
  };

  const saveApplicantRating = (e, value) => {
    const params = {
      companyId: data.companyId,
      applicantId: data.applicantId,
      rating: value,
    };

    Api("POST", `Job/AddAverageRateingUserWise`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getApplicantRating();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getApplicantJobData = () => {
    Api(
      "GET",
      `Job/ViewApplicantData?companyId=${data.companyId}&applicantId=${data.applicantId}`
    )
      .then((res) => {
        setApplicantInfo(res.data);
        setSelectedSuitCase(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDetails = () => {
    setShow(!isShow);
  };

  const showInfo = () => {
    Api(
      "GET",
      `Job/GetApplicantById?applicantId=${data.applicantId}&companyId=${data.companyId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setAppDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="user-list applicant-h" id={data.applicantId}>
      <div className="applicant-header">
        <div className="d-flex align-items-center">
          <BackgroundLetterAvatars name={data.name} width={32} height={32} />
          <strong>{data.name}</strong>
        </div>
        <IconButton size="small" onClick={closeApplicantTab}>
          <CloseIcon color="action" fontSize="small" />
        </IconButton>
      </div>

      <div className="column-content">
        <ApplicantInfoTabs
          applicantData={data}
          selectedSuitCase={selectedSuitCase}
        />

        <div className="border-bottom">
          <div className="show-details">
            <strong className="pointer" onClick={() => toggleDetails()}>
              {isShow ? "Hide" : "Show"} Applicant Details
            </strong>
            <Tooltip title="Send Applicant to User">
              <IconButton size="small">
                <EastIcon />
              </IconButton>
            </Tooltip>
          </div>
          {isShow && (
            <div className="applicant-show-details">
              <div className="show-tab">
                <Tooltip title="Email">
                  <EmailIcon color="action" fontSize="small" />
                </Tooltip>
                <span>{appDetails?.emailAddress}</span>
              </div>
              <div className="show-tab">
                <Tooltip title="Source">
                  <LoginIcon color="action" fontSize="small" />
                </Tooltip>
                <span>{appDetails.sourceName}</span>
              </div>
              <div className="show-tab">
                <Tooltip title="TimeZone">
                  <AccessTimeIcon color="action" fontSize="small" />
                </Tooltip>
                <span>{appDetails.timeZone}</span>
              </div>
              <div className="show-tab">
                <strong>Title:</strong>
                <span>{appDetails.title}</span>
              </div>
            </div>
          )}
        </div>

        {/* Rating start */}
        <div className="border-bottom">
          <div className="p-16 font-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <strong>Average Rating :</strong>
                <Rating
                  name="read-only"
                  value={avgRating}
                  readOnly
                  precision={0.5}
                />
              </div>
              <span>({memberNo})</span>
            </div>
            <div className="d-flex align-items-center mt-5">
              <strong>Your Rating : </strong>
              <Rating
                name="simple-controlled"
                value={value}
                precision={0.5}
                onChange={saveApplicantRating}
              />
            </div>
          </div>
        </div>
        {/* Rating end */}

        {/* Suit case start */}
        <div className="p-16 p-case-content">
          <div>
            {applicantInfo?.map((job) => (
              <>
                {Object.entries(selectedSuitCase).toString() ===
                Object.entries(job).toString() ? (
                  <DarkSuitCaseIcon
                    width="30"
                    height="30"
                    className="mr-10 pointer"
                    onClick={() => setSelectedSuitCase(job)}
                  />
                ) : (
                  <LightSuitCaseIcon
                    width="30"
                    height="30"
                    className="mr-10 pointer"
                    onClick={() => setSelectedSuitCase(job)}
                  />
                )}
              </>
            ))}
            <BlueAddIcon width="30" height="30" className="pointer" />
          </div>
        </div>
        {/* Suit case end */}

        {/* Job stages start */}
        <div
          className={
            domNode ? "user-details-tab applicants" : "user-details-tab"
          }
        >
          <ApplicantJobData
            applicantData={data}
            selectedSuitCase={selectedSuitCase}
            applicantInfo={applicantInfo}
            getApplicantJobData={getApplicantJobData}
          />
        </div>
        {/* Job stages end */}
      </div>
    </div>
  );
};
