export const LightSuitCaseIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="8" fill="#F2F5FF" />
      <path
        d="M30.625 14.875H11.375C10.8918 14.875 10.5 15.2668 10.5 15.75V29.75C10.5 30.2332 10.8918 30.625 11.375 30.625H30.625C31.1082 30.625 31.5 30.2332 31.5 29.75V15.75C31.5 15.2668 31.1082 14.875 30.625 14.875Z"
        stroke="#3954A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.375 30.625V13.125C25.375 12.6609 25.1906 12.2158 24.8624 11.8876C24.5342 11.5594 24.0891 11.375 23.625 11.375H18.375C17.9109 11.375 17.4658 11.5594 17.1376 11.8876C16.8094 12.2158 16.625 12.6609 16.625 13.125V30.625"
        stroke="#3954A5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
