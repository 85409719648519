import { CancelOutlined, Send } from "@material-ui/icons";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { InterviewForm } from "./interviewForm";
import { AcceptInterviewForm } from "./acceptInterviewForm";
import { ResendInterviewingForm } from "./resendNotificationForm";
import moment from "moment";
import { IconButton, Tooltip } from "@material-ui/core";
import { red } from "@mui/material/colors";
import { ButtonUI } from "../../../../../../ui/button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const InterviewingStatus = ({ selectedSuitCase, applicantId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [openInterviewForm, setInterviewForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [interviewList, setInterviewList] = useState([]);
  const [openAcceptInterviewForm, setAcceptInterviewForm] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [openResendForm, setResendForm] = useState(false);
  const [interScheduleId, setInterScheduleId] = useState("");
  const [flag, setFlag] = useState(""); // To identify the send and cancel action

  const toggleInterviewForm = (open) => {
    setInterviewForm(open);
  };

  const toggleAcceptForm = (open) => {
    setAcceptInterviewForm(open);
  };

  const toggleResendForm = (open) => {
    setResendForm(open);
  };

  useEffect(() => {
    getInterviewList();
  }, []);

  const handleNotification = (description, id) => {
    const params = {
      description: description,
      companyId: companyData.companyId,
      jobId: selectedSuitCase.jobId,
      applicantInterviewScheduleId: id,
      applicantId: applicantId,
    };

    Api("PUT", `Job/ResendNotificationForRequestInterview`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleResendForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAcceptInetrview = (id, slot) => {
    const params = {
      applicantInterviewScheduleId: id,
      acceptedSlot: slot,
    };

    Api("POST", `Job/SubmitAcceptInterview`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAcceptForm(false);
          getInterviewList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getInterviewList = () => {
    Api(
      "GET",
      `Job/GetRequestInterviewList?companyId=${companyData.companyId}&applicantId=${applicantId}`
    )
      .then((res) => {
        setInterviewList(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleCancelInterview = (description, id) => {
    Api(
      "DELETE",
      `Job/DeleteRequestInterview?companyId=${companyData.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}&applicantInterviewScheduleId=${id}&comment=${description}`
    )
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleResendForm(false);
          toast.success(res.data.message);
          getInterviewList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleFormSubmit = () => {
    const params = {
      companyId: companyData.companyId,
      applicantId: applicantId,
      jobId: selectedSuitCase.jobId,
      interviewType: formValues.interviewType,
      duration: formValues.duration,
      facilities: formValues.facilities ? formValues.facilities : [],
      instructions: formValues.notes,
      isRecordMeeting: true,
      interviewers: formValues.interviewers,
      selectedSlots: formValues.selectedSlots,
    };

    Api("POST", `Job/SaveInterviewForm`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleInterviewForm(false);
          toast.success(res.data.message);
          getInterviewList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <InterviewForm
        openForm={openInterviewForm}
        toggleForm={toggleInterviewForm}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        formValues={formValues}
        setFormValues={setFormValues}
        handleFormSubmit={handleFormSubmit}
        jobId={selectedSuitCase.jobId}
        applicantId={applicantId}
      />

      <AcceptInterviewForm
        openForm={openAcceptInterviewForm}
        toggleForm={toggleAcceptForm}
        availableSlots={availableSlots}
        handleSubmit={handleAcceptInetrview}
        interScheduleId={interScheduleId}
        applicantId={applicantId}
      />

      <ResendInterviewingForm
        handleSubmit={handleNotification}
        toggleForm={toggleResendForm}
        openForm={openResendForm}
        interScheduleId={interScheduleId}
        flag={flag}
        handleCancelInterview={handleCancelInterview}
        applicantId={applicantId}
      />

      <button
        className="add-btn manage-btn job video"
        onClick={() => (setActiveStep(0), toggleInterviewForm(true))}
      >
        Request Interview
        <img
          src={require("../../../../../../../assets/images/Vector.png")}
          alt="right-arrow"
          className="right-arrow-btn"
        />
      </button>
      <div className="profile-data mt-15">
        <div className="inter-container">
          {interviewList?.map((interview, index) => (
            <div className="section mb-10 interview-section" key={index}>
              <div>
                <strong>{interview.interviewType}</strong>
                <span
                  className={
                    interview.interviewScheduleStatus === "Accept"
                      ? "status green-active"
                      : interview.interviewScheduleStatus === "Requested"
                      ? "status blue-completed"
                      : "status"
                  }
                >
                  {interview.interviewScheduleStatus}
                </span>
              </div>
              <span className="scheduled">
                <label>Scheduled : </label>
                {moment(interview.createdAt).format("M/DD/YYYY @ h:mm a")}
              </span>
              <span className="d-flex">
                {interview.interviewScheduleStatus === "Requested" &&
                  interview.interviewScheduleStatus !== "Canceled" && (
                    <Tooltip title="Accept Interview">
                      <ButtonUI
                        variant="outlined"
                        fontSize="12px"
                        borderColor="#34AA44"
                        color="#34AA44"
                        mr="6px"
                        pr="0px"
                        onClick={() => (
                          toggleAcceptForm(true),
                          setAvailableSlots(interview.interviewSlots),
                          setInterScheduleId(
                            interview.applicantInterviewScheduleId
                          )
                        )}
                        rightIcon={<ChevronRightIcon fontSize="small" />}
                      >
                        Accept
                      </ButtonUI>
                    </Tooltip>
                  )}

                {interview.interviewScheduleStatus !== "Canceled" && (
                  <Tooltip title="Send Message">
                    <ButtonUI
                      variant="outlined"
                      fontSize="12px"
                      borderColor="#E6492D"
                      color="#E6492D"
                      mr="6px"
                      pr="0px"
                      onClick={() => (
                        toggleResendForm(true),
                        setInterScheduleId(
                          interview.applicantInterviewScheduleId
                        ),
                        setFlag("send")
                      )}
                      rightIcon={<ChevronRightIcon fontSize="small" />}
                    >
                      Send
                    </ButtonUI>
                  </Tooltip>
                )}

                {interview.interviewScheduleStatus !== "Canceled" && (
                  <Tooltip title="Cancel Inetrview">
                    <ButtonUI
                      variant="outlined"
                      fontSize="12px"
                      borderColor="#B80000"
                      color="#B80000"
                      mr="6px"
                      pr="0px"
                      onClick={() => (
                        toggleResendForm(true),
                        setInterScheduleId(
                          interview.applicantInterviewScheduleId
                        ),
                        setFlag("cancel")
                      )}
                      rightIcon={<ChevronRightIcon fontSize="small" />}
                    >
                      Delete
                    </ButtonUI>
                  </Tooltip>
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
