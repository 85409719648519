import { useLocation, Link } from "react-router-dom";
import { ApplicantInfo } from "../../../common/layouts/applicantInfo";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { COMPANY_DATA_ACTIONS } from "../../../../redux/companyData/actions";
import { useSelector } from "react-redux";

export const ApplicantDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { jobData } = useSelector((state) => state.jobDataReducer);
  const { applicantData } = useSelector((state) => state.applicantDataReducer);

  useEffect(() => {
    // dispatch({
    //   type: COMPANY_DATA_ACTIONS.SET_COMP_DATA,
    //   payload: location.state,
    // });
  }, [location]);

  return (
    <div className="common-container users">
      <div className="inner-container">
        <div className="top-url">
          <p>
            <Link to="/dashboard"> Dashboard &gt; </Link>
            <Link to="/companylist"> Companies &gt; </Link>
            <Link to="/jobs" state={location.state}>
              Jobs &gt;
            </Link>
            <Link to="/jobs/details" state={location.state}>
              {jobData.name} &gt;
            </Link>
            <strong>
              {applicantData.firstName + " " + applicantData.lastName}
            </strong>
          </p>
        </div>
        <ApplicantInfo />
      </div>
    </div>
  );
};
