import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../../../../../helper/Api";
import { PublicNavigationBar } from "../../../../../navigationBar/public";

export const ViewOffer = () => {
  const location = useLocation();
  const offerLetterId = new URLSearchParams(location.search).get("id");
  const [content, setContent] = useState("");

  useEffect(() => {
    Api("GET", `Job/getOfferLetterDocumentContent?id=${offerLetterId}`)
      .then((res) => {
        setContent(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <PublicNavigationBar />
      <div className="job-data-container">
        <div className="header">
          <span>Offer Letter</span>
        </div>
        <div className="j-content">
          <div className="mt-20">
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
