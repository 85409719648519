export const DashboardIcon = ({...props}) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path 
                d="M10.5 4.5H4.5V10.5H10.5V4.5Z" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M19.5 4.5H13.5V10.5H19.5V4.5Z" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M10.5 13.5H4.5V19.5H10.5V13.5Z" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M19.5 13.5H13.5V19.5H19.5V13.5Z" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    )
}
