import { Link, useLocation } from "react-router-dom";
import { ManageNotification } from "../../common/layouts/users/ManageNotification";

export const CompanyManageNotificationsForAdmin = () => {
  const location = useLocation();
  return (
    <div className="common-container users">
      <div className="inner-container">
        <div className="top-url">
          <p>
            <Link to="/dashboard"> Dashboard &gt; </Link>
            <Link to="/companylist"> Companies &gt; </Link>
            <Link
              to="/users"
              state={{
                companyId: location.state.companyId,
                companyHirerchy: location.state.companyHirerchy,
              }}
            >
              {" "}
              Users &gt;{" "}
            </Link>
            <strong>Manage Notifications</strong>
          </p>
        </div>
        <div className="manage-container">
          <ManageNotification />
        </div>
      </div>
    </div>
  );
};
