import React from "react";
import Accordion from "@mui/material/Accordion";
import { makeStyles } from "@material-ui/core/styles";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MUIListItem from '@material-ui/core/ListItem';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../../assets/css/components/company-detail/_users.css";
import { useEffect, useState } from "react";
import Api from "../../../../helper/Api";
import Loader from "../../Loader";

const useStyles = makeStyles((theme) => ({
  acc: {
    boxShadow: "none",
  },
  detailRoot: {
    display: "table-row",
  },
}));

export function ManageNotification() {
  const classes = useStyles();
  const location = useLocation();
  let navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [isLoader, setisLoader] = useState(false);
  const [allData, setAllData] = useState(results);
  const [notifyManagerId, setNotifyManagerId] = useState("");

  const handleCancel = () => {
    navigate("/users", {
      state: {
        companyId: location.state.companyId,
        companyHirerchy: location.state.companyHirerchy,
      },
    });
  };

  useEffect(() => {
    setisLoader(true);
    getNotificationMasterData();
  }, []);

  const getNotificationMasterData = () => {
    const params = {
      userId: location.state.userId,
    };
    Api("POST", `Job/GetNotificationMaster`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setisLoader(false);
          setNotifyManagerId(res.data.data.notificationManagerId);
          setResults(res.data.data.manageNotificationList);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCheckBoxChange = (
    notificationManagerMasterId,
    checkId,
    isChecked,
    field
  ) => {
    const checkList = results.map((data) => {
      data.subNotification.map((d) => {
        if (
          data.notificationManagerMasterId === notificationManagerMasterId &&
          d.entId === checkId
        ) {
          d[field] = isChecked;
        }
        return data;
      });
    });
    setAllData(checkList);
  };

  const handleSubmit = () => {
    const params = {
      userId: location.state.userId,
      notificationManagerId: notifyManagerId,
      manageNotificationList: [...results],
    };

    Api("POST", `Job/SaveManageNotification`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
        } else {
          toast.err(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div class="user-notification-manage-content">
      <Loader inShow={isLoader} />
      <div className="manage-bg">
        <div className="manage-title">
          <p>Manage Notifications</p>
        </div>
        <div className="manage-content">
          {results.map((notify) => (
            <Accordion key={notify.id} className={classes.acc}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="panel1a-header"
              >
                <Typography>{notify.notification_name}</Typography>
              </AccordionSummary>
              {notify.subNotification.map((sub) => (
                <AccordionDetails
                  key={sub.entId}
                  className={classes.detailsRoot}
                >
                  <div className="d-flex checks-container">
                    <div className="left-content">
                      <span>{sub.entName}</span>
                    </div>
                    <div className="right-checks">
                      <div className="d-flex align-items-center justify-content-center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={sub.isAlarm}
                              checked={sub.isAlarm}
                              onChange={(e) =>
                                onCheckBoxChange(
                                  notify.notificationManagerMasterId,
                                  sub.entId,
                                  e.target.checked,
                                  "isAlarm"
                                )
                              }
                            />
                          }
                          label="Alarm"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={sub.isEmail}
                              checked={sub.isEmail}
                              onChange={(e) =>
                                onCheckBoxChange(
                                  notify.notificationManagerMasterId,
                                  sub.entId,
                                  e.target.checked,
                                  "isEmail"
                                )
                              }
                            />
                          }
                          label="Email"
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={sub.isText}
                              checked={sub.isText}
                              onChange={(e) =>
                                onCheckBoxChange(
                                  notify.notificationManagerMasterId,
                                  sub.entId,
                                  e.target.checked,
                                  "isText"
                                )
                              }
                            />
                          }
                          label="Text"
                        />
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              ))}
            </Accordion>
          ))}
          <div className="manage-title">
            <button
              className="cancel-btn save-btn"
              type="reset"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <input
              type="submit"
              className="add-btn save-btn"
              value="Save"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
