import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import Api from "../../../../../../../helper/Api";
import { useState } from "react";

export const OfferLetterForm = ({
  openForm,
  toggleForm,
  handleSubmit,
  applicantId,
}) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [offerLetters, setOfferLetters] = useState([]);
  const [documentId, setDocumentId] = useState("");

  useEffect(() => {
    Api(
      "GET",
      `Job/GetOfferLetterDocumentsDropdown?companyId=${companyData.companyId}`
    )
      .then((res) => {
        setOfferLetters(res.data.data.lstDdl);
      })
      .catch((err) => console.log(err));
  }, []);

  const saveForm = (e) => {
    e.preventDefault();
    handleSubmit(documentId);
  };

  const handleChange = (e) => {
    setDocumentId(e.target.value);
  };

  return (
    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      title="Create Offer Letter"
      handleSubmit={saveForm}
      applicantId={applicantId}
    >
      <div className="forms-input">
        <label>Offer letter</label>
        <select onChange={handleChange}>
          <option>Select</option>
          {offerLetters?.map((offer) => (
            <option value={offer.documentId}>
              {offer.internalName.substring(25, 50)}
            </option>
          ))}
        </select>
      </div>
    </AddEditCol>
  );
};
