import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { CancelOutlined, Edit, Send } from "@material-ui/icons";
import { Box } from "@mui/system";
import "../../../assets/css/common/_video-dialog-box.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Carousel from "./carousel";
import BackgroundLetterAvatars from "../layouts/BackgroundLetterAvatars";
import { useSelector } from "react-redux";
import { RatingIcon } from "../../../icons/rating";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { ReviewerForm } from "../layouts/applicantInfo/content/applicantJobData/VideoInterview/reviewerForm";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";

export default function VideoDialogBox({ interview, setEdit, name, jobId }) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const [openReviewerForm, setReviewerForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const errors = {};
  const [id, setId] = React.useState(
    interview?.videoQuestionsList[0]?.videoQuestionId
  );
  const [questionVideo, setQuestionVideo] = React.useState(
    interview?.videoQuestionsList[0]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const moveBehind = () => {
    value === -100 * 100 ? setValue(0) : setValue(value - 100);
  };

  const moveAhead = () => {
    value === 0 ? setValue(value - 100) : setValue(value + 100);
  };

  const toggleSelectReviewer = (open) => {
    setReviewerForm(open);
  };

  const validate = (values) => {
    if (!values.reviewerListIds) {
      errors.reviewerListIds = "Please select a reviewer !";
    }

    return errors;
  };

  const saveReviewer = () => {
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      jobApplicantVideoInterviewId: interview.jobApplicantVideoInterviewId,
      reviewerListIds: formValues.reviewerListIds,
    };

    Api("POST", `Job/SaveVideoInterviewReviewer`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleSelectReviewer(false);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {/* Select reviewers form */}
      <ReviewerForm
        openForm={openReviewerForm}
        toggleForm={toggleSelectReviewer}
        applicantId={interview.applicantId}
        formValues={formValues}
        setFormValues={setFormValues}
        saveReviewer={saveReviewer}
        formErrors={formErrors}
      />

      <div className="profile-data pointer mt-10 flex-row align-items-center">
        <BackgroundLetterAvatars
          name={name}
          width={50}
          height={50}
          shape="rounded"
        />
        <div className="v-rating" onClick={handleClickOpen}>
          <span className="status blue-completed">
            {interview?.videoInterviewStatus}
          </span>
          {/* <strong>{interview?.jobName}</strong> */}
          <span className="req-date">
            <label>Requested : </label>
            {moment(interview?.requestedDate).format("l")} &nbsp;
            {interview?.completedDate !== null && (
              <div>
                <label>Completed : </label>
                {moment(interview?.completedDate).format("l")}
              </div>
            )}
          </span>
          {/* <span>
            <Edit
              className="mr-10"
              color="action"
              fontSize="small"
              onClick={() => (toggleDrawer("right", true), setEdit(true))}
            />
            <Send
              className="mr-10 pointer"
              color="action"
              fontSize="small"
              onClick={() => (
                toggleDrawer("right", true, true), setEdit(false)
              )}
            />
            <CancelOutlined color="action" fontSize="small" />
          </span> */}
        </div>
        {interview.videoInterviewStatus === "Complete" && (
          <Tooltip title="Ratings">
            <IconButton onClick={() => toggleSelectReviewer(true)}>
              <RatingIcon width="26" height="26" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Video Interview </DialogTitle>
        <DialogContent>
          <Box>
            <div className="glider">
              {interview.videoQuestionsList.map((video, index) => (
                <div
                  key={index}
                  className="glide"
                  style={{ transform: `translateX(${value}%)` }}
                >
                  <img
                    src={
                      require("../../../assets/images/video-interview.svg")
                        .default
                    }
                    height={120}
                    width={160}
                    className={
                      video.videoQuestionId === id
                        ? "green-border"
                        : "grey-border"
                    }
                    onClick={() => (
                      setId(video.videoQuestionId), setQuestionVideo(video)
                    )}
                  />
                  <div className="question-center">
                    {video.videoQuestionName?.substring(0, 50)}
                  </div>
                </div>
              ))}
            </div>
          </Box>
          <div className="mt-20"></div>
          {/* <div>
            <ArrowBackIosIcon id="moveBehind" onClick={moveAhead} />
            <ArrowForwardIosIcon id="moveAhead" onClick={moveBehind} />
          </div> */}
          {id && (
            <Carousel
              className="bodyDiv"
              video={questionVideo}
              applicantId={interview.applicantId}
              jobId={jobId}
              jobApplicantVideoInterviewId={
                interview.jobApplicantVideoInterviewId
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
