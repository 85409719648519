import React, { useEffect, useState } from "react";
import { AddEditPopup } from "../../../components/common/addEditPopup";
import { ButtonUI } from "../../../components/ui/button";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { FormControlLabel, MenuItem } from "@mui/material";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import moment from "moment";

export const EditAvailability = ({
  openSidebar,
  toggleDrawer,
  formValues,
  setFormValues,
  label,
  saveAvailability,
  editMode,
  getData,
}) => {
  const [uploadReqList, setUploadReqList] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    getCalenderSlot();
    getFacilities();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editMode === true) {
      //
    } else {
      const params = {
        uploadReqList: uploadReqList,
        location: formValues.location,
      };

      saveAvailability(params);
    }
  };

  const getCalenderSlot = () => {
    const params = {
      companyId: companyData.companyId,
      offset: new Date().getTimezoneOffset(),
    };

    Api("POST", `Job/GetCalendarSlot`, params)
      .then((res) => {
        setSlots(res.data.data.timeSlots);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFacilities = () => {
    Api(
      "GET",
      `Facilities/GetFacilitiesList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}&pageLimit=25`
    )
      .then((res) => {
        setFacilities(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...uploadReqList];
    list.splice(index, 1);
    setUploadReqList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setUploadReqList([
      ...uploadReqList,
      {
        startTime: "",
        endTime: "",
        perInterview: "",
      },
    ]);
  };

  const handleSlot = (e, index) => {
    const { name, value } = e.target;
    const data = [...uploadReqList];
    data[index][name] = value;
    setUploadReqList(data);
  };

  return (
    <AddEditPopup
      title={label}
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
    >
      <div className="forms-input">
        <label>Location:</label>
        <select name="location" onChange={handleChange}>
          <option value="">Select Facility</option>
          {facilities.map((rec) => (
            <option value={rec.facilitiesId}>
              {rec.address.substring(0, 40)}
            </option>
          ))}
        </select>
      </div>
      {uploadReqList.map((req, i) => (
        <div className="add-upload-container" key={i}>
          <div className="justify-content-between d-flex">
            <label>Slot {i + 1}</label>
            <img
              src={
                require("../../../assets/images/add-upload-close.svg").default
              }
              alt="close-img"
              className="close-img"
              onClick={handleRemoveClick}
            />
          </div>
          <div className="forms-input sections mt-15">
            <div className="col-2">
              <label>Start Time:</label>
              <FormControl size="small">
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  defaultValue={1}
                  name="startTime"
                  onChange={(e) => handleSlot(e, i)}
                >
                  <MenuItem value="">
                    <em>Select Time</em>
                  </MenuItem>
                  {slots.map((slot) => (
                    <MenuItem value={slot.timeSlot}>
                      <em>{moment(slot.timeSlot).format("h:mm a")}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-2">
              <label>End Time:</label>
              <FormControl size="small">
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  defaultValue={1}
                  name="endTime"
                  onChange={(e) => handleSlot(e, i)}
                >
                  <MenuItem value="">
                    <em>Select Time</em>
                  </MenuItem>
                  {slots.map((slot) => (
                    <MenuItem value={slot.timeSlot}>
                      <em>{moment(slot.timeSlot).format("h:mm a")}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="forms-input">
            <label>Per Interview:</label>
            <FormControl size="small">
              <Select
                inputProps={{ "aria-label": "Without label" }}
                defaultValue={1}
                name="perInterview"
                onChange={(e) => handleSlot(e, i)}
              >
                <MenuItem value="">
                  <em>Select User</em>
                </MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      ))}
      <ButtonUI
        color="#006AFF"
        bgColor="#F2F5FF"
        mt="10px"
        p="8px"
        radius="4px"
        width="299px"
        onClick={handleAddClick}
      >
        + Add Time Slot
      </ButtonUI>
    </AddEditPopup>
  );
};
