import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import "../../../assets/css/components/add-edit-popup/_add-edit-popup.css";
import { ButtonUI } from "../../ui/button";

const styles = {
  header: {
    height: "64px",
    padding: "0 25px",
    background: "#5E6367",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
  },
  img: {
    cursor: "pointer",
  },
  span: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },
  innerContainer: {
    height: "calc(100% - 124px)",
    overflow: "auto",
    padding: "30px 25px 26px 30px",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  jobTemp: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "10px",
  },
  cancel: {
    background: "#fff",
    color: "#101323",
    border: "1px solid #2E90FA",
    padding: "11px",
    borderRadius: "7px",
    cursor: "pointer",
    lineHeight: "24px",
    fontSize: "16px",
  },
  inForms: {
    height: "calc(100% - 50px)",
    paddingRight: "10px",
    overflow: "auto",
  },
  fullHeight: {
    height: "100%",
  },
  subBtn: {
    background: "#2E90FA",
    color: "#fff",
  },
  fatSubBtn: {
    background: "#2E90FA",
    color: "#fff",
    marginTop: "0px",
    width: "20%",
  },
  fatCancel: {
    background: "#fff",
    color: "#101323",
    border: "1px solid #2E90FA",
    padding: "11px",
    borderRadius: "7px",
    cursor: "pointer",
    lineHeight: "24px",
    fontSize: "16px",
    width: "20%",
  },
  inFatForms: {
    height: "calc(100% - 60px)",
    paddingRight: "10px",
    overflow: "auto",
  },
};

export const AddEditPopup = (props) => {
  const list = (anchor) => (
    <Box
      sx={{ width: props.type === "jobTemplate" ? 650 : 372 }}
      style={styles.fullHeight}
      role="presentation"
    >
      <header style={styles.header}>
        <span style={styles.span}>{props.title}</span>
        <img
          src={require("../../../assets/images/close.svg").default}
          style={styles.img}
          onClick={() => props.toggleDrawer("right", false)}
        />
      </header>

      <div style={styles.innerContainer}>
        <div
          style={
            props.type === "jobTemplate" ? styles.inFatForms : styles.inForms
          }
        >
          {props.children}
        </div>
        <div style={styles.jobTemp}>
          <ButtonUI
            variant="outlined"
            p="10px"
            onClick={() => props.toggleDrawer("right", false)}
          >
            Cancel
          </ButtonUI>
          <ButtonUI p="10px" onClick={props.handleSubmit} width="80px">
            {props?.isSubmitting ? <div className="dot-pulse"></div> : "Submit"}
          </ButtonUI>
        </div>
      </div>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer anchor="right" open={props.openSidebar["right"]}>
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
};
