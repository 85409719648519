import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import "../../../../assets/css/components/company-detail/_users.css";
import Api from "../../../../helper/Api";
import Loader from "../../Loader";

export function ManageJobs() {
  let navigate = useNavigate();
  const location = useLocation();
  const [results, setResults] = useState([]);
  const [isLoader, setisLoader] = useState(false);
  const [allData, setAllData] = useState(results);
  const [jobAssignmentId, setJobAssignmentId] = useState("");

  const handleCancel = () => {
    navigate("/users", {
      state: {
        companyId: location.state.companyId,
        companyHirerchy: location.state.companyHirerchy,
      },
    });
  };

  useEffect(() => {
    setisLoader(true);
    getGetManageJobListData();
  }, []);

  const getGetManageJobListData = () => {
    const params = {
      userId: location.state.userId,
    };

    Api("POST", `Job/GetManageJobList`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setisLoader(false);
          setJobAssignmentId(res.data.data.manageJobAssignmentId);
          setResults(res.data.data.manageJobAssignmentList);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCheckBoxChange = (checkId, isChecked, field) => {
    const checkList = results.map((data) => {
      if (data.jobid === checkId) {
        data[field] = isChecked;
      }
      return data;
    });
    setAllData(checkList);
  };

  const params = {
    userId: location.state.userId,
    manageJobAssignmentId: jobAssignmentId,
    manageJobChecklists: [...results],
  };

  const handleSubmit = () => {
    Api("POST", `Job/SaveManageJobAssignment`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
        } else {
          toast.err(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Loader inShow={isLoader} />
      <div className="manage-bg active">
        <div className="parent-container-d active">
          <div className="manage-checkboxes">
            {results.map((users) => (
              <div className="job-info" key={users.jobid}>
                <Checkbox
                  disableRipple
                  onChange={(e) =>
                    onCheckBoxChange(users.jobid, e.target.checked, "isChecked")
                  }
                  checked={users.isChecked}
                />
                <div className="facilities_desc">
                  <label>{users.jobname}</label>
                  <span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: users.desc.substring(0, 250),
                      }}
                    ></div>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="btns-container">
          <button
            className="cancel-btn save-btn"
            type="reset"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <input
            type="submit"
            className="add-btn save-btn"
            value="Save"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}
