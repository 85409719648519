import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { CheckboxList } from "../../../../CheckboxList";

export const ReviewerForm = ({
  openForm,
  toggleForm,
  applicantId,
  formValues,
  setFormValues,
  saveReviewer,
  formErrors,
}) => {
  const [allData, setAllData] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);

  useEffect(() => {
    Api("GET", `Job/GetReviewerList?companyId=${companyData.companyId}`)
      .then((res) => {
        const data = [...res.data];
        data.forEach((rec) => {
          rec.id = rec.userId;
          rec.name = rec.userFirstName + " " + rec.userLastName;
          rec.checked = false;
        });

        setAllData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (checkName, isChecked) => {
    const checkList = allData.map((data) => {
      if (data.id === checkName) {
        data.checked = isChecked;
      }
      return data;
    });

    const data = [...checkList];
    const checkedDocs = [];
    data.forEach((rec) => {
      if (rec.checked === true) {
        checkedDocs.push(rec.userId);
      }
    });

    setFormValues({ ...formValues, ["reviewerListIds"]: checkedDocs });
    setAllData(checkList);
  };

  return (
    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      title="Select Reviewers"
      handleSubmit={saveReviewer}
    >
      <div className="forms-input">
        <CheckboxList
          allData={allData}
          setAllData={setAllData}
          showSaveContainer={false}
          handleChange={handleChange}
        />
        <span className="error_msg">{formErrors.reviewerListIds}</span>
      </div>

      {allData.length === 0 && (
        <div className="forms-input">
          <span>Please add reviewers in your company</span>
        </div>
      )}
    </AddEditCol>
  );
};
