import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { JobCard } from "../../../components/common/cards/JobCard";
import { ButtonUI } from "../../../components/ui/button";
import { AddEditJob } from "../../../components/common/addEditPopup/AddEditJob";
import { EditJobTemplate } from "../../../components/companyDetail/jobTemplates/editJobTemplate";
import { FilterForm } from "./filterForm";
import { useEffect } from "react";
import Api from "../../../helper/Api";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { JOB_DATA_ACTIONS } from "../../../redux/jobData/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import NoRecordsFound from "../../../components/common/NoRecordsFound";

export const CompanyJobs = () => {
  const [openSidebar, setSidebar] = useState(false);
  const [openJobTempSidebar, setJobTempSidebar] = useState(false);
  const [jobFormLabel, setLabel] = useState("Add Job");
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [sortType, setSorted] = useState("desc");
  const [jobList, setJobList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [openFilterForm, setFilterForm] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNumber, setPage] = useState(1);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllJobs = (orderBy, orderbyDirection, searchText = searchInput) => {
    Api(
      "GET",
      `Job/GetJobList?companyId=${companyData.companyId}&pageLimit=12&pageNumber=${pageNumber}&searchText=${searchText}&OrderBy=${orderBy}&OrderbyDirection=${orderbyDirection}`
    )
      .then((res) => {
        setJobList(res.data.data);
        setTotalRecord(res.data.totalRecord);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (searchInput == "") {
      getAllJobs("updatedAt", "DESC");
    }
  }, [searchInput]);

  useEffect(() => {
    getAllJobs("updatedAt", "DESC");
  }, [pageNumber]);

  const handleSubmit = () => {
    Api("POST", `Job/AddJob`, formValues)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
          getAllJobs("updatedAt", "desc");
        } else {
          toast.err(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key == "Enter") {
      getSearchJob();
    }
  };

  // Sort data
  function handleSort(columnType) {
    const sortOrder = sortType === "asc" ? "desc" : "asc";
    setSorted(sortOrder);
    getAllJobs(columnType, sortType);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
    getAllJobs("updatedAt", "DESC");
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const toggleJobTempDrawer = (anchor, open) => {
    setJobTempSidebar({ ...openJobTempSidebar, [anchor]: open });
  };

  const getSearchJob = () => {
    if (searchInput.length === 0) {
      alert("Please enter search value!");
    } else {
      getAllJobs("updatedAt", "desc");
    }
  };

  const toggleFilterForm = (anchor, open) => {
    setFilterForm({ ...openFilterForm, [anchor]: open });
  };

  // Navigate on card click
  const cardClickEvent = (jobData) => {
    dispatch({
      type: JOB_DATA_ACTIONS.SET_JOB_DATA,
      payload: jobData,
    });
    navigate("details", { state: { ...companyData } });
  };

  const editAction = () => {
    console.log("in edit job");
  };

  return (
    <div>
      {openFilterForm ? (
        <FilterForm
          toggleDrawer={toggleFilterForm}
          openSidebar={openFilterForm}
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleSubmit}
        />
      ) : null}

      {openSidebar ? (
        <AddEditJob
          title={jobFormLabel}
          openSidebar={openSidebar}
          toggleDrawer={toggleDrawer}
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleSubmit}
        />
      ) : null}

      {openJobTempSidebar ? (
        <EditJobTemplate
          openSidebar={openJobTempSidebar}
          toggleDrawer={toggleJobTempDrawer}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          handleSubmit={handleSubmit}
          label="Add Job Template"
        />
      ) : null}

      <div className="company_content help-container applicants">
        <div className="content_header">
          <div className="company_text mr-auto">Jobs</div>
          {jobList.length > 0 && (
            <div className="company_search company">
              <img
                src={require("../../../assets/images/search_icon.png")}
                alt="search-icon"
                className="search_img"
              />
              <input
                type="text"
                placeholder="Search Job"
                className="search_input appointments"
                name="search_input"
                value={searchInput}
                onChange={handleSearch}
                onKeyPress={handleKeyPress}
              />
              <button className="search-btn" onClick={getSearchJob}>
                Search
              </button>
            </div>
          )}
          {jobList.length > 0 && (
            <div className="company_sort">
              <div
                className="sort_text pointer"
                role="button"
                onClick={() => handleSort("name")}
              >
                <span className="text_light"> SORT :</span> A-Z
              </div>

              <button className="icon icon_button">
                <img
                  src={require("../../../assets/images/drop_down.png")}
                  alt="sort-icon"
                  className="icon sort_img"
                />
              </button>
            </div>
          )}
          <img
            src={require("../../../assets/images/refresh.png")}
            alt="refresh"
            className="applicants-icon pointer"
            onClick={() => (
              getAllJobs("desc", "updatedAt", ""), setSearchInput("")
            )}
          />
          <ButtonUI
            mr="16px"
            p="12px"
            leftIcon={<AddIcon sx={{ height: "16px", width: "16px" }} />}
            onClick={() => toggleJobTempDrawer("right", true)}
          >
            New Template
          </ButtonUI>
          <ButtonUI
            mr="16px"
            p="12px"
            leftIcon={<AddIcon sx={{ height: "16px", width: "16px" }} />}
            onClick={() => toggleDrawer("right", true)}
          >
            Add
          </ButtonUI>
        </div>
        <div className="j-card-container">
          {jobList.map((job) => (
            <div className="j-card pointer" key={job.jobId}>
              <JobCard
                jobCode={job.jobNo}
                name={job.name}
                description={job.description}
                jobStatus={job.jobStatus}
                priority={job.priority}
                positions={job.positions}
                jobId={job.jobId}
                createdAt={job.createdAt}
                cardClickEvent={cardClickEvent}
                editAction={editAction}
                type="card"
              />
            </div>
          ))}
        </div>
        <StyledPagination
          count={totalPage}
          page={pageNumber}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};
