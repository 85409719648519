import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import Api from "../../../../../../../helper/Api";
import { EditReviewForm } from "./editReviewForm";
import EditIcon from "@mui/icons-material/Edit";

export const ReviewingStatus = ({
  selectedSuitCase,
  getApplicantJobData,
  applicantId,
  companyId,
}) => {
  const [openReviewEditForm, setReviewEditForm] = useState(false);
  const [formValues, setFormValues] = useState({
    rate: selectedSuitCase.rate,
    workEligible: selectedSuitCase.isWorkEligible,
    canInterview: selectedSuitCase.canInterview,
    canStart: new Date(selectedSuitCase.canStart),
    isRelocatable: selectedSuitCase.isRelocatable,
    empHistory: selectedSuitCase.empHistory,
  });

  const toggleEditReviewForm = (open) => {
    setReviewEditForm(open);
  };

  function handleSubmit(e) {
    e.preventDefault();

    if (!formValues.workEligible) {
      setFormValues({ ...formValues, ["workEligible"]: false });
    }

    if (!formValues.isRelocatable) {
      setFormValues({ ...formValues, ["isRelocatable"]: true });
    }

    const params = {
      companyId: companyId,
      applicantId: applicantId,
      jobId: selectedSuitCase.jobId,
      workEligible: formValues.workEligible,
      rate: formValues.rate,
      canInterview: formValues.canInterview,
      canStart: formValues.canStart,
      isRelocatable: formValues.isRelocatable,
      empHistory: formValues.employee_history,
    };

    Api("PUT", "Job/EditReviewingData", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleEditReviewForm(false);
          getApplicantJobData();
        } else {
          toggleEditReviewForm(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      {/* When the job cycle status is REVIEWING */}
      {openReviewEditForm && (
        <EditReviewForm
          openForm={openReviewEditForm}
          toggleForm={toggleEditReviewForm}
          handleSubmit={handleSubmit}
          formValues={formValues}
          setFormValues={setFormValues}
          applicantId={applicantId}
        />
      )}

      <div className="review-edit mt-10">
        <strong className="font-blue">Reviewing:</strong>
        <Tooltip title="Edit Reviewing details">
          <IconButton size="small" onClick={() => toggleEditReviewForm(true)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="job-info">
        <div>
          <div className="j-field">
            <label>Work Eligible:</label>
            <strong>{selectedSuitCase?.isWorkEligible ? "Yes" : "No"}</strong>
          </div>
          <div className="j-field">
            <label>Rate:</label>
            <strong>
              {selectedSuitCase?.rate ? "$" + selectedSuitCase?.rate : "--"}
            </strong>
          </div>
          <div className="j-field">
            <label>Can Interview:</label>
            <strong>
              {selectedSuitCase?.canInterview
                ? selectedSuitCase?.canInterview
                : "--"}
            </strong>
          </div>
        </div>

        <div>
          <div className="j-field">
            <label>Can start:</label>
            <strong>
              {selectedSuitCase?.canStart
                ? moment(selectedSuitCase?.canStart).format("MM/DD/YYYY")
                : "--"}
            </strong>
          </div>
          <div className="j-field">
            <label>Can Relocate:</label>
            <strong>{selectedSuitCase?.isRelocatable ? "Yes" : "No"}</strong>
          </div>
          <div className="j-field">
            <label>Emp. History:</label>
            <strong>{selectedSuitCase?.empHistory}</strong>
          </div>
        </div>
      </div>
    </div>
  );
};
