import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";

export const AddChecklistItem = (props) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistId, setChecklistId] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    props.setFormValues({ ...props.formValues, ["checklistid"]: checklistId });
    props.handleSubmit(e, checklistId);
    if (!props.formErrors.newItem || !props.formErrors.checklisttId) {
      setChecklistId("");
    }
  };

  const handleChange = (e) => {
    props.setFormValues({
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeExistingItem = (e) => {
    const selectItem = checklistItems.map((data) => {
      if (data.checklisttId === e.target.value) {
        return e.target.value;
      }
    });
    setChecklistId(e.target.value);
  };

  useEffect(() => {
    Api(
      "GET",
      `Checklist/GetCheckList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        setChecklistItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <AddEditCol
      title="Add New Checklist"
      openForm={props.openForm}
      toggleForm={props.toggleForm}
      applicantId={props.applicantId}
      handleSubmit={handleSubmit}
    >
      <div className="forms-input">
        <label>Existing Item:</label>
        <select
          id="checklisttId"
          name="checklisttId"
          onChange={handleChangeExistingItem}
          value={props.formValues.checklisttId}
        >
          <option value="" label="Select">
            Select
          </option>
          {checklistItems.map((item) => (
            <option key={item.checklisttId} value={item.checklisttId}>
              {item.name}
            </option>
          ))}
        </select>
        <span className="error_msg">{props.formErrors.checklisttId}</span>
      </div>

      <div className="forms-input">
        <label>New Item</label>
        <input
          type="text"
          placeholder="Name here"
          id="newItem"
          name="newItem"
          value={props.formValues.newItem}
          onChange={handleChange}
        />
        <span className="error_msg">{props.formErrors.newItem}</span>
      </div>
    </AddEditCol>
  );
};
