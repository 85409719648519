import "../../../assets/css/components/navigation-bar/_navigation-bar.css";

export const PublicNavigationBar = () => {
  return (
    <div className="navbar">
      <img
        src={require("../../../assets/images/logo_white.png")}
        alt="nav-logo"
        className="whitelogo_img"
      />
    </div>
  );
};
