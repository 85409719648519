import React, { memo, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import {
  ActivityIcon,
  ChatWithBadgeIcon,
  CheckBoxIcon,
  DescriptionIcon,
  FilesIcon,
  NotePencilIcon,
  ThumbsUpIcon,
} from "../../../../../../icons";
import "../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { ResumeTab } from "./resume";
import { NotesTab } from "./notes";
import { DocumentsTab } from "./documents";
import { ActivityTab } from "./activity";
import { CheckListTab } from "./checklist";
import { HotApplicantTab } from "./hotApplicant";
import { ChatTab } from "./chat";

const ApplicantInfoTabs = ({ applicantData, selectedSuitCase }) => {
  const [value, setValue] = useState(0);
  const [isHotList, setIsHotList] = useState(false);
  const domNode = document?.getElementById(applicantData.applicantId) || "";

  useEffect(() => {
    setIsHotList(applicantData.isHotList);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
      </div>
    );
  }

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    display: "flex",
    flex: 1,
    "& .MuiTabs-flexContainer": {
      display: "flex",
      flex: 1,
    },
    "& .MuiTabs-indicator": {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 20,
      minWidth: 50,
      width: "100%",
      backgroundColor: "#3954A5",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      color: "#3954A5",
      display: "flex",
      flex: 1,
      padding: 2,
      maxWidth: 20,
      minWidth: 50,
      "&.Mui-selected": {
        color: "#3954A5",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <div className="user-details-tab">
      <div style={{ borderBottom: "1px solid rgba(57, 84, 165, 0.2)" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab
            icon={
              <div
                style={{
                  background: value === 0 && "#3954A5",
                }}
                className="tabs"
              >
                <DescriptionIcon
                  fill={value === 0 ? "#fff" : "#8898C9"}
                  width="18px"
                  height="18px"
                />
              </div>
            }
            {...a11yProps(0)}
          />
          <StyledTab
            icon={
              <div
                style={{
                  background: value === 1 && "#3954A5",
                }}
                className="tabs"
              >
                <NotePencilIcon
                  fill={value === 1 ? "#fff" : "#8898C9"}
                  width="18px"
                  height="18px"
                />
              </div>
            }
            {...a11yProps(1)}
          />
          <StyledTab
            icon={
              <div
                style={{
                  background: value === 2 && "#3954A5",
                }}
                className="tabs"
              >
                <ThumbsUpIcon
                  stroke={
                    isHotList ? "#E6492D" : value === 2 ? "#FFF" : "#8898C9"
                  }
                  fill={
                    isHotList ? "#FFDFDF" : value === 2 ? "#3954A5" : "#ffff"
                  }
                  width="18px"
                  height="18px"
                />
              </div>
            }
            {...a11yProps(2)}
          />
          <StyledTab
            icon={
              <div
                style={{
                  background: value === 3 && "#3954A5",
                }}
                className="tabs"
              >
                <ChatWithBadgeIcon
                  stroke={value === 3 ? "#fff" : "#8898C9"}
                  width="18px"
                  height="18px"
                />
              </div>
            }
            {...a11yProps(3)}
          />
          <StyledTab
            icon={
              <div
                style={{
                  background: value === 4 && "#3954A5",
                }}
                className="tabs"
              >
                <CheckBoxIcon
                  stroke={value === 4 ? "#fff" : "#8898C9"}
                  width="18px"
                  height="18px"
                />
              </div>
            }
            {...a11yProps(4)}
          />
          <StyledTab
            icon={
              <div
                style={{
                  background: value === 5 && "#3954A5",
                }}
                className="tabs"
              >
                <FilesIcon
                  stroke={value === 5 ? "#fff" : "#8898C9"}
                  line={value === 5 ? "#8898C9" : "#fff"}
                  width="18px"
                  height="18px"
                />
              </div>
            }
            {...a11yProps(5)}
          />
          <StyledTab
            icon={
              <div
                style={{
                  background: value === 6 && "#3954A5",
                }}
                className="tabs"
              >
                <ActivityIcon
                  stroke={value === 6 ? "#fff" : "#8898C9"}
                  width="18px"
                  height="18px"
                />
              </div>
            }
            {...a11yProps(6)}
          />
        </StyledTabs>
      </div>
      <TabPanel value={value} index={0}>
        <ResumeTab
          applicantData={applicantData}
          selectedSuitCase={selectedSuitCase}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NotesTab
          applicantData={applicantData}
          selectedSuitCase={selectedSuitCase}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <HotApplicantTab
          applicantData={applicantData}
          setIsHotList={setIsHotList}
          selectedSuitCase={selectedSuitCase}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ChatTab
          applicantData={applicantData}
          selectedSuitCase={selectedSuitCase}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CheckListTab
          applicantData={applicantData}
          selectedSuitCase={selectedSuitCase}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <DocumentsTab
          applicantData={applicantData}
          selectedSuitCase={selectedSuitCase}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ActivityTab
          applicantData={applicantData}
          selectedSuitCase={selectedSuitCase}
        />
      </TabPanel>
    </div>
  );
};

export default memo(ApplicantInfoTabs);
