import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControl, FormControlLabel } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { DateSelector } from "../../../../../../ui/pickers/date";
import { useState } from "react";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";

export const EditReviewForm = (props) => {
  const [value, setValue] = useState(props.formValues.canStart);

  function handleChange(e) {
    const { name, value } = e.target;
    props.setFormValues({ ...props.formValues, [name]: value });
  }

  function handleDateChange(newValue) {
    setValue(newValue.toISOString());
    props.setFormValues({
      ...props.formValues,
      ["canStart"]: newValue.toISOString(),
    });
  }

  return (
    <AddEditCol
      applicantId={props.applicantId}
      openForm={props.openForm}
      title="Reviewing edit Form"
      toggleForm={props.toggleForm}
      handleSubmit={props.handleSubmit}
    >
      <div className="forms-input multiple ml-10">
        <label>Work Eligible: </label>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="workEligible"
            onChange={handleChange}
            defaultValue={props.formValues.workEligible}
          >
            <FormControlLabel
              value={true}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              value={false}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                />
              }
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="forms-input">
        <label>Rate:</label>
        <input
          type="text"
          placeholder="Rate here"
          onChange={handleChange}
          name="rate"
          value={props.formValues.rate}
        />
      </div>

      <div className="forms-input">
        <label>Can Interview:</label>
        <textarea
          placeholder="Can Interview"
          onChange={handleChange}
          name="canInterview"
          value={props.formValues.canInterview}
        />
      </div>

      <div className="forms-input">
        <label>Can start:</label>
        <DateSelector
          value={value}
          setValue={setValue}
          disablePast={true}
          handleDateChange={handleDateChange}
        />
      </div>

      <div className="forms-input multiple ml-10">
        <label>Can Relocate : </label>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="isRelocatable"
            onChange={handleChange}
            defaultValue={true}
          >
            <FormControlLabel
              value={true}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              value={false}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                />
              }
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="forms-input">
        <label>Employee History:</label>
        <textarea
          placeholder="Employee History"
          onChange={handleChange}
          name="employee_history"
          value={props?.formValues?.employee_history}
        />
      </div>
    </AddEditCol>
  );
};
