import React from "react";
import '../../../assets/css/common/_cards.css';
import { getRandomColor, createImageFromInitials } from './utils';

export const ProfileImage = (props) => {
	let name = props.name;
	let imgSrc = "";

	return (
		<div className={props.class}>
			<img
				className={props.class}
				id='preview'
				src = {
					imgSrc.length <= 0 ? createImageFromInitials(500, name, getRandomColor()) : imgSrc
				}
				alt='profile-pic'
			/>
		</div>
	);
}
