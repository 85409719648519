import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckboxWithBlueCheck } from "../../ui/checkbox/checkboxWithBlueTick";
import NoRecordsFound from "../NoRecordsFound";

export const CheckboxList = ({
  allData,
  setAllData,
  handleSubmit,
  hasSelectAll,
  setAllChecked,
  showSaveContainer = true,
  handleChange,
}) => {
  const onCheckBoxChange = (checkName, isChecked) => {
    if (showSaveContainer === false) {
      handleChange(checkName, isChecked);
      return;
    }

    const checkList = allData.map((data) => {
      if (data.id === checkName) {
        data.checked = isChecked;
      }
      return data;
    });

    setAllData(checkList);
    if (hasSelectAll) {
      checkList.findIndex((item) => item.checked === false) === -1
        ? setAllChecked(false)
        : setAllChecked(true);
    }
  };

  return (
    <div>
      <FormGroup>
        {allData.length > 0 ? (
          <div>
            {allData.map((data) => (
              <div className="checkbox-label" key={data.id}>
                <FormControlLabel
                  control={
                    <CheckboxWithBlueCheck
                      disableRipple
                      checked={!!data.checked}
                      onChange={(e) =>
                        onCheckBoxChange(data.id, e.target.checked)
                      }
                      value={data.id}
                      key={data.id}
                    />
                  }
                  label={data.name}
                />
              </div>
            ))}
          </div>
        ) : (
          <NoRecordsFound />
        )}
      </FormGroup>

      {showSaveContainer ? (
        <div className="save_settings">
          <input
            className="save_btn"
            type="submit"
            value="Save"
            onClick={() => {
              handleSubmit(allData);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
