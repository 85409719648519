import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { MorePositions } from "../../../common/layouts/applicantInfo/morePositions";

export const AdminMorePositions = () => {
  const location = useLocation();
  const { applicantData } = useSelector((state) => state.applicantDataReducer);

  return (
    <div className="common-container users">
      <div className="inner-container">
        <div className="top-url">
          <p>
            <Link to="/dashboard"> Dashboard &gt; </Link>
            <Link to="/companylist"> Companies &gt; </Link>
            <Link to="/jobs" state={location.state}>
              {" "}
              Jobs &gt;{" "}
            </Link>
            <Link to="/jobs/details" state={location.state}>
              {" "}
              Resident Manager &gt;{" "}
            </Link>
            <strong>
              {applicantData.firstName + " " + applicantData.lastName}
            </strong>
          </p>
        </div>
        <div className="manage-container jobs">
          <MorePositions />
        </div>
      </div>
    </div>
  );
};
