import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "../../../../assets/css/components/company-detail/_jobTemplate.css";
import { JobTemplateContent } from "./content";
import { Sidebar } from "./Sidebar";
import { COMPANY_DATA_ACTIONS } from "../../../../redux/companyData/actions";
import { JOB_TEMPLATE_ACTIONS } from "../../../../redux/jobTemplate/actions";
import Api from "../../../../helper/Api";

export const JobTemplateDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: COMPANY_DATA_ACTIONS.SET_COMP_DATA,
      payload: location.state,
    });

    Api(
      "GET",
      `JobTemplate/GetJobTemplateById?companyId=${location.state.companyId}&jobTemplateId=${location.state.data.jobTemplateId}`
    )
      .then((res) => {
        dispatch({
          type: JOB_TEMPLATE_ACTIONS.SET_JOB_TEMP_DATA,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location]);

  return (
    <div className="common-container users">
      <div className="inner-container">
        <div className="top-url">
          <p>
            <Link to="/dashboard"> Dashboard &gt; </Link>
            <Link to="/companylist"> Companies &gt; </Link>
            <Link to="/job-templates" state={location.state}>
              {" "}
              Job Template &gt;{" "}
            </Link>
            <strong>Job Template Details</strong>
          </p>
        </div>
        <div className="user-container">
          <Sidebar />
          <JobTemplateContent />
        </div>
      </div>
    </div>
  );
};
