import {
  ApplicantIcon,
  CompanyIcon,
  ActiveUserIcon,
  ScheduleCalIcon,
  MonitorIcon,
  HandshakeIcon,
  PreHireIcon,
  JobIcon,
} from "../../../icons";
import Chart from "react-apexcharts";

import React, { useEffect, useState } from "react";

import Api from "../../../helper/Api";
import moment from "moment";
import Loader from "../../../components/common/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "./DatePicker";

const components = {
  c: CompanyIcon,
  a: ApplicantIcon,
  j: JobIcon,
  p: PreHireIcon,
  au: ActiveUserIcon,
  jo: HandshakeIcon,
  si: ScheduleCalIcon,
  vi: MonitorIcon,
};

export const Dashboard = () => {
  const [cardData, setCardData] = useState([]);

  // Job Chart variables
  const [jobCount, setJobCount] = useState(0);
  const [jobDates, setJobDates] = useState([]);
  const [jobValues, setJobValues] = useState([]);

  // Company chart variables
  const [companyCount, setCompanyCount] = useState(0);
  const [companyDates, setCompanyDates] = useState([]);
  const [companyValues, setCompanyValues] = useState([]);

  // User chart variables
  const [userCount, setUserCount] = useState(0);
  const [userDates, setUserDates] = useState([]);
  const [userValues, setUserValues] = useState([]);

  // Applicant chart variables
  const [appCount, setAppCount] = useState(0);
  const [appDates, setAppDates] = useState([]);
  const [appValues, setAppValues] = useState([]);

  const [isLoader, setLoader] = useState(true);

  const MyComponent = ({ ...props }) => {
    const TagName = components[props.tag];
    return (
      <TagName fill={props.fill} width={props.width} height={props.height} />
    );
  };

  useEffect(() => {
    setLoader(true);
    const params = {
      userId: localStorage.getItem("user"),
    };

    // Count API
    Api("POST", `Dashboard/GetAdminDashboardCounts`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          setCardData(res.data.data.dashboardCardDetails);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    Promise.all([
      getJobChart(),
      getCompanyChart(),
      getUserChart(),
      getApplicantChart(),
    ])
      .then(() => setLoader(false))
      .catch(() => setLoader(false));
  }, []);

  const jobChartProp = {
    series: [
      {
        name: "series1",
        data: jobValues,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#E6492D"],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: jobDates,
      },
      tooltip: {
        x: {
          format: "dd",
        },
      },
      fill: {
        colors: ["#E6492D"],
      },
    },
  };

  const companyChartProp = {
    series: [
      {
        name: "series1",
        data: companyValues,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#5B93FF"],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: companyDates,
      },
      tooltip: {
        x: {
          format: "dd",
        },
      },
      fill: {
        colors: ["#B7CFFE"],
      },
    },
  };

  const userChartProp = {
    series: [
      {
        name: "series1",
        data: userValues,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#FFD66B"],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: userDates,
      },
      tooltip: {
        x: {
          format: "dd",
        },
      },
      fill: {
        colors: ["#FFD66B"],
      },
    },
  };

  const applicantChartProp = {
    series: [
      {
        name: "series1",
        data: appValues,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#61BD6E"],
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: false,
        },
        categories: appDates,
      },
      tooltip: {
        x: {
          format: "dd",
        },
      },
      fill: {
        colors: ["#b6dbbb"],
      },
    },
  };

  // Admin Job Chart
  const getJobChart = (
    fromDate = moment().subtract(1, "months").format("YYYY-MM-DD"),
    toDate = moment().format("YYYY-MM-DD")
  ) => {
    const adminParams = {
      userId: localStorage.getItem("user"),
      fromDate: fromDate,
      toDate: toDate,
    };

    return new Promise((resolve, reject) => {
      Api("POST", `Dashboard/GetAdminJobCharts`, adminParams)
        .then((res) => {
          if (res.data.isSuccess === true) {
            const date = [];
            const values = [];
            const chartData = [...res.data.data.chartDetails];

            chartData.map((rec) => {
              date.push(rec.date);
              values.push(rec.value);
            });

            setJobDates(date);
            setJobValues(values);
            setJobCount(res.data.data.count);
            resolve();
          }
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  // Admin Company Chart
  const getCompanyChart = (
    fromDate = moment().subtract(1, "months").format("YYYY-MM-DD"),
    toDate = moment().format("YYYY-MM-DD")
  ) => {
    const adminParams = {
      userId: localStorage.getItem("user"),
      fromDate: fromDate,
      toDate: toDate,
    };

    return new Promise((resolve, reject) => {
      Api("POST", `Dashboard/GetCompanyAdminCharts`, adminParams)
        .then((res) => {
          if (res.data.isSuccess === true) {
            const date = [];
            const values = [];
            const chartData = [...res.data.data.chartDetails];

            chartData.map((rec) => {
              date.push(rec.date);
              values.push(rec.value);
            });

            setCompanyDates(date);
            setCompanyValues(values);
            setCompanyCount(res.data.data.count);
            resolve();
          }
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  // Admin User Chart
  const getUserChart = (
    fromDate = moment().subtract(1, "months").format("YYYY-MM-DD"),
    toDate = moment().format("YYYY-MM-DD")
  ) => {
    const adminParams = {
      userId: localStorage.getItem("user"),
      fromDate: fromDate,
      toDate: toDate,
    };

    return new Promise((resolve, reject) => {
      Api("POST", `Dashboard/GetAdminUserCharts`, adminParams)
        .then((res) => {
          if (res.data.isSuccess === true) {
            const date = [];
            const values = [];
            const chartData = [...res.data.data.chartDetails];

            chartData.map((rec) => {
              date.push(rec.date);
              values.push(rec.value);
            });

            setUserDates(date);
            setUserValues(values);
            setUserCount(res.data.data.count);
            resolve();
          }
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  // Admin Applicant Chart
  const getApplicantChart = (
    fromDate = moment().subtract(1, "months").format("YYYY-MM-DD"),
    toDate = moment().format("YYYY-MM-DD")
  ) => {
    const adminParams = {
      userId: localStorage.getItem("user"),
      fromDate: fromDate,
      toDate: toDate,
    };

    return new Promise((resolve, reject) => {
      Api("POST", `Dashboard/GetAdminApplicantCharts`, adminParams)
        .then((res) => {
          if (res.data.isSuccess === true) {
            const date = [];
            const values = [];
            const chartData = [...res.data.data.chartDetails];

            chartData.map((rec) => {
              date.push(rec.date);
              values.push(rec.value);
            });

            setAppDates(date);
            setAppValues(values);
            setAppCount(res.data.data.count);
            resolve();
          }
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  return (
    <>
      <div className="company_content">
        {isLoader ? (
          <Loader inShow={true} />
        ) : (
          <div className="company_card dashboard_content">
            {cardData.map((val) => (
              <div className="company_grid dashboard_grid" key={val.a}>
                <div
                  className="grid_1"
                  style={{ backgroundColor: val.fill + "26" }}
                >
                  <MyComponent
                    tag={val.tag}
                    width="24"
                    height="24"
                    fill={val.fill}
                  />
                </div>
                <div className="grid_2 content-table">{val.a}</div>
                <div className="grid_3 company_text">{val.b}</div>
                <div className="grid_4 grid_keys green_text">{val.c}</div>
              </div>
            ))}
            <div className="grid_container">
              {/* Company chart start */}
              <div className="charts_grid">
                <div className="charts_header">
                  <div className="charts_title">
                    <div>
                      <div className="svg-bg">
                        <MyComponent
                          tag="c"
                          width="43"
                          height="35"
                          fill="#3954A5"
                        />
                      </div>
                    </div>
                    <div className="heading-label grid_keys">
                      Companies
                      <br />
                      <span className="company_text">{companyCount}</span>
                    </div>
                  </div>
                  <div className="year_dropdown">
                    <DatePicker handleChange={getCompanyChart} />
                  </div>
                </div>

                <Chart
                  options={companyChartProp.options}
                  series={companyChartProp.series}
                  type="area"
                  width={"100%"}
                  height={"270px"}
                />
              </div>
              {/* Company chart end */}

              {/* Job chart start */}
              <div className="charts_grid">
                <div className="charts_header">
                  <div className="charts_title">
                    <div>
                      <div className="svg-bg">
                        <FontAwesomeIcon
                          icon={faBriefcase}
                          fontSize={"32px"}
                          color={"#3954A5"}
                        />
                      </div>
                    </div>
                    <div className="heading-label grid_keys">
                      Jobs
                      <br />
                      <span className="company_text">{jobCount}</span>
                    </div>
                  </div>
                  <div className="year_dropdown">
                    <DatePicker handleChange={getJobChart} />
                  </div>
                </div>

                <Chart
                  options={jobChartProp.options}
                  series={jobChartProp.series}
                  type="area"
                  width={"100%"}
                  height={"270px"}
                />
              </div>
              {/* Job chart end  */}

              {/* User chart start */}
              <div className="charts_grid">
                <div className="charts_header">
                  <div className="charts_title">
                    <div>
                      <div className="svg-bg">
                        <MyComponent
                          tag="a"
                          width="43"
                          height="35"
                          fill="#3954A5"
                        />
                      </div>
                    </div>
                    <div className="heading-label grid_keys">
                      User
                      <br />
                      <span className="company_text">{userCount}</span>
                    </div>
                  </div>
                  <div className="year_dropdown">
                    <DatePicker handleChange={getUserChart} />
                  </div>
                </div>

                <Chart
                  options={userChartProp.options}
                  series={userChartProp.series}
                  type="area"
                  width={"100%"}
                  height={"270px"}
                />
              </div>
              {/* User chart end  */}

              {/* Applicant chart start */}
              <div className="charts_grid">
                <div className="charts_header">
                  <div className="charts_title">
                    <div>
                      <div className="svg-bg">
                        <MyComponent
                          tag="a"
                          width="43"
                          height="35"
                          fill="#3954A5"
                        />
                      </div>
                    </div>
                    <div className="heading-label grid_keys">
                      Applicants
                      <br />
                      <span className="company_text">{appCount}</span>
                    </div>
                  </div>
                  <div className="year_dropdown">
                    <DatePicker handleChange={getApplicantChart} />
                  </div>
                </div>

                <Chart
                  options={applicantChartProp.options}
                  series={applicantChartProp.series}
                  type="area"
                  width={"100%"}
                  height={"270px"}
                />
              </div>
              {/* Applicant chart end  */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
