import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../../../assets/css/components/company-detail/_sources.css";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { source_tabs, user_roles } from "../../../helper/constants";
import { getRoles } from "@testing-library/react";
import { Android12Switch } from "../../ui/android/android2switch";
import { ButtonUI } from "../../ui/button";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";
import { AddEditSource } from "./addEditSource";
import { ColoredLinearProgress } from "../../common/coloredProgressBar";

export function Sources({ ...props }) {
  const [isLoader, setIsLoader] = useState(false);
  const [allDocTypes, setAllDocTypes] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [sourcesData, setSourcesData] = useState([]);
  const [sources, setSources] = useState([]);
  const [sourceTabs, setSourceTabs] = useState(source_tabs);
  const companyData = useLocation();
  const [showActions, setEditActions] = useState({});
  const [userroles, setUserroles] = useState([]);
  const [checklists, setChecklists] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [currSourceType, setSourceType] = useState("");
  const [rolesPer, setRolesPer] = useState([]);

  // Toggle Add sidebar
  const [showSideBar, setSideBar] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("Add New");
  const [showInputLabel, setShowInputLabel] = useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [jobPrefixValue, setJobPrefixValue] = React.useState("");
  const [editMode, setEditMode] = React.useState(false);
  const initialValues = { roleName: "", jobPrefix: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [openSidebar, setOpenSideBar] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkedState, setCheckedState] = useState(
    new Array(user_roles.length).fill(false)
  );

  useEffect(() => {
    setIsLoader(true);
    getSources();
    setSourceTabs(source_tabs);
  }, []);

  const toggleDrawer = (anchor, open) => {
    setOpenSideBar({ ...openSidebar, [anchor]: open });
  };

  const getRolesPer = () => {
    const params = {
      companyId: companyData.state.companyId,
      roleType: "",
    };

    Api("GET", `RoleData/GetRolePermissions?roleType=${params.roleType}`)
      .then((res) => {
        setRolesPer(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Sources listing
  const getSources = () => {
    const params = {
      companyId: companyData.state.companyId,
      type: "all",
      roleType: "",
    };

    Api(
      "GET",
      `Sources/GetSourceList?companyId=${params.companyId}&type=${params.type}&companyHirerchy=${companyData.state.companyHirerchy}`
    )
      .then((res) => {
        let response = Object.values(res.data);
        let result = response.reduce(function (r, a) {
          r[a.type] = r[a.type] || [];
          r[a.type]["listItems"] = r[a.type]["listItems"] || [];

          r[a.type]["listItems"].push(a);

          return r;
        }, Object.create(null));

        let data = [];
        sourceTabs.map((tab) => {
          if (result[tab.type] == undefined) {
            tab.listItems = [];
          } else {
            tab.listItems = result[tab.type].listItems;
          }
          data[tab.type] = tab;
        });

        setSources(data);
        setSourcesData(Object.values(sourceTabs));
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoader(false);
  };

  const getCheckListItems = () => {
    setIsLoader(true);
    const params = {
      companyId: companyData.state.companyId,
      type: "checklistitems",
      roleType: "",
    };

    Api(
      "GET",
      `Checklist/GetCheckList?companyId=${params.companyId}&companyHirerchy=${companyData.state.companyHirerchy}`
    )
      .then((res) => {
        setChecklists(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getDocs();
    getDocTypes();
    setIsLoader(false);
  };

  if (Object.keys(sources).length !== 0) {
    sources["departments"]["listItems"] = [...departments];
    sources["userroles"]["listItems"] = userroles;
    sources["checklistitems"]["listItems"] = [...checklists];
  }

  // Delete sources common API integration
  const deleteSource = (sourceId) => {
    const data = sourceId.split(",");
    let id = data[0];
    let sourceType = data[1];
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      const params = {
        companyId: companyData.state.companyId,
        id: id,
      };

      //delete sourcetype wise
      if (sourceType == "departments") {
        Api(
          "DELETE",
          `Department/DeleteDepartment?companyId=${params.companyId}&departmentId=${params.id}`
        )
          .then((res) => {
            if (res.data.isSuccess === true) {
              getSources();
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
            return;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (sourceType == "userroles") {
        //userrole dlt api
      } else if (sourceType == "checklistitems") {
        //checklistitems dlt api
        Api(
          "DELETE",
          `Checklist/DeleteCheckList?companyId=${params.companyId}&checklisttId=${params.id}`
        )
          .then((res) => {
            if (res.data.isSuccess === true) {
              getSources();
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Api(
          "DELETE",
          `Sources/DeleteSource?companyId=${params.companyId}&sourceId=${params.id}`
        )
          .then((res) => {
            if (res.data.isSuccess === true) {
              getSources();
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  // Get data to edit
  const updateSource = (id) => {
    const data = id.split(",");
    let sourceId = data[0];
    let sourceType = data[1];

    toggleDrawer("right", true);
    const temp = sourcesData.filter((item) => {
      return item.type === sourceType;
    });

    let listItemValue = [];
    setEditMode(true);
    if (sourceType == "departments") {
      listItemValue = temp[0].listItems.filter((items) => {
        return items.departmentId === sourceId ? items.name : null;
      });
      setSourceType("departments");
    } else if (sourceType == "checklistitems") {
      listItemValue = temp[0].listItems.filter((items) => {
        return items.checklisttId === sourceId ? items.name : null;
      });
      setSourceType("checklistitems");
    } else {
      listItemValue = temp[0].listItems.filter((items) => {
        return items.sourceId === sourceId ? items.name : null;
      });
    }
    setSideBar(sourceId);
    setAddEditLabel("Edit " + temp[0].name);
    setShowInputLabel(temp[0].name.slice(0, -1) + " Name");
    setFormValues({
      ["roleName"]: listItemValue[0].name,
      ["jobPrefix"]: listItemValue[0].jobNumberPrefix,
      ["doc"]: listItemValue[0].documentId,
      ["docType"]: listItemValue[0].documentPackId,
    });
    setEditActions(false);
  };

  const isActiveRole = (id, setActive) => {
    const params = {
      roleId: id,
      isActive: !setActive,
    };
    Api(
      "PATCH",
      `RoleData/ActiveDeactiveRole?roleId=${params.roleId}&isActive=${params.isActive}`
    )
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          getSources();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocs = () => {
    Api(
      "GET",
      `Checklist/GetDocuments?companyId=${companyData.state.companyId}`
    )
      .then((res) => {
        if (res.data) {
          setAllDocs(res.data.data);
        } else {
          setAllDocs([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDocTypes = () => {
    Api("GET", "Document/GetDocumentType?type=docgroup")
      .then((res) => {
        if (res.data) {
          setAllDocTypes(res.data);
          setIsLoader(false);
        } else {
          setAllDocTypes([]);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChecked = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
    setFormValues({ ...formValues, checkedState });
  };

  // Add and Update source API integration
  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      companyId: companyData.state.companyId,
      type:
        showAddEditLabel.replace("Add ", "").split(" ").join("").toLowerCase() +
        "s",
      isActive: true,
      name: formValues.roleName,
    };

    if (editMode === true) {
      // Edit department API integration
      params.type = showAddEditLabel
        .replace("Edit ", "")
        .split(" ")
        .join("")
        .toLowerCase();

      if (params.type == "departments") {
        params.jobNumberPrefix = formValues.jobPrefix;
        params.departmentId = showSideBar;
        Api("PUT", "Department/EditDepartment", params)
          .then((res) => {
            if (res.data.isSuccess === true) {
              getSources();
              getDepartments();
              toast.success(res.data.message);
              toggleDrawer("right", false);
            } else {
              toast.error(res.data.message);
            }
            setEditMode(false);
            return;
          })
          .catch(() => {
            return;
          });
      }
      // Edit checklistitems API integration
      else if (params.type === "checklistitems") {
        params.checklisttId = showSideBar;
        params.documentId = formValues.doc;
        params.documentPackId = formValues.docType;

        Api("PUT", "Checklist/EditCheckList", params)
          .then((res) => {
            if (res.data.isSuccess === true) {
              getSources();
              getCheckListItems();
              toast.success(res.data.message);
              toggleDrawer("right", false);
            } else {
              toast.error(res.data.message);
            }
            setEditMode(false);
            return;
          })
          .catch(() => {
            return;
          });
      } else {
        // Edit source API integration
        params.sourceId = showSideBar;
        Api("PUT", "Sources/EditSource", params)
          .then((res) => {
            if (res.data.isSuccess === true) {
              getSources();
              toast.success(res.data.message);
              toggleDrawer("right", false);
            } else {
              toast.error(res.data.message);
            }
            setEditMode(false);
            return;
          })
          .catch(() => {
            return;
          });
      }
    } else {
      // Add source API integration type wise

      if (params.type === "departments") {
        params.jobNumberPrefix = formValues.jobPrefix;
        delete params.type;
        delete params.isActive;
        Api("POST", "Department/AddDepartment", params)
          .then((res) => {
            if (res.data.isSuccess === true) {
              getSources();

              toast.success(res.data.message);
              toggleDrawer("right", false);
            } else {
              toast.error(res.data.message);
            }
            setEditMode(false);
            return;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (params.type === "userroles") {
        params.permissions = rolesPer;
        params.roleName = params.name;
        delete params.name;
        delete params.type;
        Api("POST", "RoleData/AddRole", params)
          .then((res) => {
            if (res.data.isSuccess === true) {
              getRoles();
              getRolesPer();
              getSources();
              toast.success(res.data.message);
              toggleDrawer("right", false);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (params.type === "checklistitems") {
        params.documentId = formValues.doc;
        params.documentPackId = formValues.docType;
        delete params.type;
        Api("POST", "Checklist/AddCheckList", params)
          .then((res) => {
            if (res.data.isSuccess === true) {
              getCheckListItems();
              toast.success(res.data.message);
              toggleDrawer("right", false);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Api("POST", "Sources/AddSource", params)
          .then((res) => {
            if (res.data.isSuccess === true) {
              getSources();
              toast.success(res.data.message);
              toggleDrawer("right", false);
            } else {
              toast.error(res.data.message);
            }
            setEditMode(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    setSideBar(false);
    setFormValues("");
    setSourceType("");
    setSourcesData(sourcesData);
    setIsSubmit(true);
  };

  const errors = {};
  const validate = (values) => {
    if (!values.roleName) {
      errors.roleName = "Name is required.";
    } else if (!isNaN(values.roleName)) {
      errors.roleName = "Name must be a text!";
    }

    if (currSourceType === "departments" && !values.jobPrefix) {
      errors.jobPrefix = "Job Number is required.";
    } else if (currSourceType === "departments" && isNaN(values.jobPrefix)) {
      errors.jobPrefix = "Job must be a number!";
    }

    return errors;
  };

  // perticular card wise actions box open
  const handleClick = (p_id, id) => {
    setEditActions({ ...showActions, p_id, id });
  };

  const getDepartments = () => {
    const params = {
      companyId: companyData.state.companyId,
      type: "departments",
      roleType: "",
    };
    Api(
      "GET",
      `Department/GetDepartmentList?companyId=${params.companyId}&companyHirerchy=${companyData.state.companyHirerchy}`
    )
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showItems = (type) => {
    const params = {
      companyId: companyData.state.companyId,
      type: type,
      roleType: "",
    };

    if (type === "departments") {
      getDepartments();
    }

    if (type === "checklistitems") {
      getCheckListItems();
    }

    if (type === "userroles") {
      Api(
        "GET",
        `RoleData/GetRoles?companyId=${params.companyId}&companyHirerchy=${companyData.state.companyHirerchy}`
      )
        .then((res) => {
          setUserroles(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

      getRolesPer();
    }

    let tabsData = sourceTabs.map((item) =>
      item.type === type ? { ...item, active: true } : item
    );
    sourcesData.map(function (item) {
      if (item.type === type) {
        item.active = true;
      }
    });
    setSourceTabs(tabsData);
    setSourcesData(sourcesData);
  };

  const closeItems = (type) => {
    let data = sourcesData.map((item) =>
      item.type === type ? { ...item, active: false } : item
    );
    let tabsData = sourceTabs.map((item) =>
      item.type === type ? { ...item, active: false } : item
    );
    setSourceTabs(tabsData);
    setSourcesData(data);
  };

  return (
    <div>
      <AddEditSource
        formErrors={setFormErrors}
        formValues={formValues}
        showAddEditLabel={showAddEditLabel}
        showInputLabel={showInputLabel}
        setFormValues={setFormValues}
        openSidebar={openSidebar}
        toggleDrawer={toggleDrawer}
        allDocs={allDocs}
        handleSubmit={handleSubmit}
        rolesPer={rolesPer}
        setRolesPer={setRolesPer}
        allDocTypes={allDocTypes}
        currSourceType={currSourceType}
        setFormErrors={setFormErrors}
      />

      <div className="sources">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <Link to="/Sources">
                <strong>
                  Sources {"(" + Object.keys(sourcesData).length + ")"}
                </strong>{" "}
              </Link>
            </p>
          </div>
          <div className="top-bar">
            {sourceTabs.map((sources) => (
              <div
                key={sources.type}
                className={
                  sources.active === true ? "small-box active" : "small-box"
                }
                onClick={() =>
                  sources.active === false ? showItems(sources.type) : null
                }
              >
                <span>{sources.name}</span>
                <img
                  src={require("../../../assets/images/close-bar.svg").default}
                  alt="close"
                  className="close-bar"
                  onClick={() => closeItems(sources.type)}
                />
              </div>
            ))}
          </div>
          <div className="add-types">
            {sourcesData.map((sources) =>
              sources.active ? (
                <div className="parent-types" key={sources.type}>
                  <div
                    className="add-types-box"
                    style={{ borderColor: sources.color }}
                  >
                    <p>{sources.name}</p>
                    {sources.listItems ? (
                      <span className="label-length">
                        {sources.listItems.length}
                      </span>
                    ) : null}
                    <img
                      src={
                        require("../../../assets/images/add-types.svg").default
                      }
                      alt="close"
                      className="close-bar"
                      onClick={() => (
                        setSideBar(sources.type),
                        toggleDrawer("right", true),
                        setSourceType(sources.type),
                        setShowInputLabel(sources.name.slice(0, -1) + " Name"),
                        setFormValues({}),
                        setFormErrors({}),
                        setAddEditLabel(
                          "Add " + sources.name.slice(0, -1),
                          setSourceType(sources.type)
                        )
                      )}
                    />
                  </div>
                  <div className="adding-items">
                    {isLoader ? (
                      <ColoredLinearProgress />
                    ) : (
                      <>
                        {sources.listItems.length > 0 ? (
                          sources.listItems.map((lists) => (
                            <div
                              key={lists.name ? lists.name : lists.roleName}
                              className="items position-relative"
                            >
                              <div className="d-flex justify-content-between align-items-center w-100">
                                {sources.type === "userroles" ? (
                                  <span>{lists.roleName}</span>
                                ) : (
                                  <span>{lists.name}</span>
                                )}
                                {/* For active/Inactive user role */}
                                {sources.id == 9 &&
                                lists.companyId ==
                                  companyData.state.companyId ? (
                                  <FormControlLabel
                                    control={
                                      <Android12Switch
                                        checked={lists.isActive}
                                        onChange={() =>
                                          isActiveRole(
                                            lists.roleId,
                                            lists.isActive
                                          )
                                        }
                                      />
                                    }
                                  />
                                ) : null}
                                {sources.id == 4 && (
                                  <EditDeleteMenu
                                    id={lists.departmentId + "," + sources.type}
                                    onEdit={updateSource}
                                    onDelete={deleteSource}
                                  />
                                )}
                                {sources.id == 3 && (
                                  <EditDeleteMenu
                                    id={lists.checklisttId + "," + sources.type}
                                    onEdit={updateSource}
                                    onDelete={deleteSource}
                                  />
                                )}
                                {sources.id != 4 &&
                                  sources.id != 3 &&
                                  sources.id != 9 && (
                                    <EditDeleteMenu
                                      id={lists.sourceId + "," + sources.type}
                                      onEdit={updateSource}
                                      onDelete={deleteSource}
                                    />
                                  )}
                              </div>
                              {sources.type === "departments" ? (
                                <div className="d-flex justify-content-between w-100 job-prefix">
                                  {/* for Departments job prefix span added */}
                                  <span>Job Number Prefix:</span>
                                  <span className="mr-none">
                                    {lists.jobNumberPrefix}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          ))
                        ) : (
                          <div>
                            <i>No items please add it.</i>
                          </div>
                        )}
                      </>
                    )}

                    <ButtonUI
                      p="12px"
                      mt="16px"
                      width="268px"
                      onClick={() => (
                        toggleDrawer("right", true),
                        setSourceType(sources.type),
                        setSideBar(sources.type),
                        setShowInputLabel(sources.name.slice(0, -1) + " Name"),
                        setInputValue(""),
                        setJobPrefixValue(""),
                        setFormValues({}),
                        setFormErrors({}),
                        setAddEditLabel(
                          "Add " + sources.name.slice(0, -1),
                          setSourceType(sources.type)
                        )
                      )}
                    >
                      Add {sources.name.slice(0, -1)}
                    </ButtonUI>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
