import { useEffect, useState } from "react";
import Api from "../../../../../../../helper/Api";
import VideoDialogBox from "../../../../../dialogBox/videoDialogBox";
import { RequestVideoInterviewForm } from "./requestVideoInterviewForm";
import { toast } from "react-toastify";
import { validations } from "../../../../../../../helper/validations";

export const VideoInterviewStatus = ({
  setResendInterviewForm,
  formValues,
  setFormValues,
  selectedSuitCase,
  resendForm,
  resendInterviewForm,
  applicantData,
}) => {
  const [videoInterviewList, setVideoInterviewList] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [requestInterview, setRequestInterview] = useState(false);
  const errors = {};
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    getVideoInterviewList();
  }, []);

  const toggleDrawer = (open) => {
    setRequestInterview(open);
  };

  const getVideoInterviewList = () => {
    Api(
      "GET",
      `Job/GetRequestVideoInterviewList?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setVideoInterviewList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleRequestVideInterview() {
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      applicantId: applicantData.applicantId,
      companyId: applicantData.companyId,
      jobId: selectedSuitCase.jobId,
      videoInterviewId: formValues.videoInterviewId,
      description: formValues.description,
      expDate: formValues.expDate,
    };

    Api("POST", "Job/RequestVideoInterview", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer(false);
          getVideoInterviewList();
        } else {
          toast.error(res.data.message);
          toggleDrawer(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Validations
  const validate = (values) => {
    if (!values.videoInterviewId) {
      errors.videoInterviewId = validations?.videoInterviewId?.req;
    }

    return errors;
  };

  return (
    <>
      {requestInterview === true && (
        <RequestVideoInterviewForm
          openForm={requestInterview}
          toggleForm={toggleDrawer}
          resendForm={resendForm}
          resendInterviewForm={resendInterviewForm}
          edit={edit}
          handleRequestVideInterview={handleRequestVideInterview}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          applicantId={applicantData.applicantId}
        />
      )}
      <div>
        <p style={{ fontWeight: 600, marginBottom: 10, fontSize: 14 }}>
          Video Interview :
        </p>
      </div>
      <button
        className="add-btn manage-btn job video"
        onClick={() => (
          setResendInterviewForm(false),
          setEdit(false),
          toggleDrawer(true),
          setFormValues({})
        )}
      >
        Request Video Interview
        <img
          src={require("../../../../../../../assets/images/Vector.png")}
          alt="right-arrow"
          className="right-arrow-btn"
        />
      </button>

      {videoInterviewList.map((interview) => (
        <>
          <VideoDialogBox
            interview={interview}
            setEdit={setEdit}
            name={applicantData.name}
            jobId={selectedSuitCase.jobId}
          />

          {/* <span className="icon-border-right mr-10"></span>
          <UserProfileIcon width="40" height="38" /> */}
        </>
      ))}
    </>
  );
};
