import { ToastContainer } from "react-toastify";

// export const baseUrl = "https://talentats-api.apps.openxcell.dev/api/";
export const baseUrl = process.env.REACT_APP_BASE_URL;

export const ToastAlert = () => (
  <ToastContainer
    position="top-right"
    autoClose={3000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
  />
);

export const config = {
  extraPlugins: "print,smiley,placeholder,timestamp,custom_options,justify",
  removePlugins: "pastefromword, pastetext,about",
};

export const present_year = new Date().getFullYear();

export const pageLimit = 5;

export const reasonPageLimit = 10;

export const source_tabs = [
  {
    id: 1,
    type: "applicantsources",
    name: "Applicant Sources",
    active: true,
    color: "#257AFD",
  },
  {
    id: 2,
    type: "benefits",
    name: "Benefits",
    active: false,
    color: "#E6492D",
  },
  {
    id: 3,
    type: "checklistitems",
    name: "Checklist Items",
    active: false,
    color: "#34AA44",
  },
  {
    id: 4,
    type: "departments",
    name: "Departments",
    active: false,
    color: "#1665D8",
  },
  {
    id: 5,
    type: "employmenttypes",
    name: "Employment Types",
    active: false,
    color: "#F6AB2F",
  },
  {
    id: 6,
    type: "ratetypes",
    name: "Rate Types",
    active: false,
    color: "#34AA44",
  },
  {
    id: 7,
    type: "scheduletypes",
    name: "Schedule Types",
    active: false,
    color: "#E6492D",
  },
  {
    id: 8,
    type: "shifttypes",
    name: "Shift Types",
    active: false,
    color: "#34AA44",
  },
  {
    id: 9,
    type: "userroles",
    name: "User Roles",
    active: false,
    color: "#F6AB2F",
  },
];

export const user_roles = [
  {
    id: 1,
    name: "Sources",
    listItems: [
      { id: 1, name: "Add", checked: true },
      { id: 2, name: "Edit", checked: false },
      { id: 3, name: "Delete", checked: false },
      { id: 4, name: "List View", checked: false },
      { id: 5, name: "Details View", checked: false },
    ],
    checked: true,
  },
  {
    id: 2,
    name: "Company",
    listItems: [
      { id: 1, name: "Add", checked: false },
      { id: 2, name: "Edit", checked: false },
      { id: 3, name: "Delete", checked: false },
      { id: 4, name: "List View", checked: false },
      { id: 5, name: "Details View", checked: false },
    ],
    checked: true,
  },
  {
    id: 3,
    name: "Sources",
    listItems: [
      { id: 1, name: "Add", checked: false },
      { id: 2, name: "Edit", checked: false },
      { id: 3, name: "Delete", checked: false },
      { id: 4, name: "List View", checked: false },
      { id: 5, name: "Details View", checked: false },
    ],
    checked: true,
  },
];

export const job_access = [
  { id: 1, name: "Company" },
  { id: 2, name: "Facility" },
  { id: 3, name: "Department" },
  { id: 4, name: "Job" },
  { id: 5, name: "None" },
];

export const user_info = [];

export const user_info_individual = [{}];

export const errors = {
  email: "Please enter valid Email.",
  echeck: "Entered email is not registered.",
  pass: "Please enter valid Password.",
  ereq: "Email is required.",
  preq: "Password is required.",
  npass: "New Password should be 6 characters long.",
  npreq: "New Password is required.",
  cpreq: "Confirm Password is required.",
  pmatch: "New and Confirm Passwords do not match.",
  evalid: "Please enter valid Email or Mobile.",
  emreq: "Email or Mobile is required.",
  oreq: "OTP is required.",
};

export const session_tabs = [
  {
    id: 1,
    location: "Ahmedabad, Gujarat, IN 180.211.110.146",
    device: "Windows Chrome",
    usage: "Mar 10, 2022 04:26 PM just now",
  },
  {
    id: 2,
    location: "Ahmedabad, Gujarat, IN 180.211.110.146",
    device: "Mac Safari",
    usage: "Mar 10, 2022 04:26 PM just now",
  },
];

export const notifications = [
  {
    id: 1,
    read: false,
    img: "assets/images/profile-1.png",
    notification:
      "<p>Janeta Ethel <strong>@janetaethel</strong> commented your post : “That’s really coll dude”</p>",
    time: 5,
  },
  {
    id: 2,
    read: false,
    img: "assets/images/profile-2.png",
    notification:
      "<p>Grigoriy Kozhukhov <strong>@grigoriyk</strong> “Mobile Apps UI Designer is required for Tech…”</p>",
    time: 6,
  },
  {
    id: 3,
    read: false,
    img: "assets/images/profile-3.png",
    notification:
      "<p>Janeta Ethel <strong>@janetaethel</strong> commented your post : “That’s really coll dude”</p>",
    time: 9,
  },
  {
    id: 4,
    read: true,
    img: "assets/images/profile-4.png",
    notification:
      "<p>Grigoriy Kozhukhov <strong>@grigoriyk</strong> “Mobile Apps UI Designer is required for Tech…”</p>",
    time: 11,
  },
  {
    id: 5,
    read: true,
    img: "assets/images/profile-5.png",
    notification:
      "<p>Janeta Ethel <strong>@janetaethel</strong> commented your post : “That’s really coll dude”</p>",
    time: 13,
  },
  {
    id: 6,
    read: true,
    img: "assets/images/profile-6.png",
    notification:
      "<p>Grigoriy Kozhukhov <strong>@grigoriyk</strong> “Mobile Apps UI Designer is required for Tech…”</p>",
    time: 18,
  },
];

export const notificationLength = 5;

export const resumes = [
  {
    id: 1,
    tags: true,
    date: "20/01/2022",
    email: "smita_chopra@hotmail.com",
    phone: "(671) 555-0110",
    designation: "Senior Accountant (P.3961)",
    name: "Flores, Esther",
    rating: 2,
    zip: 382415,
  },
  {
    id: 2,
    tags: true,
    date: "20/01/2022",
    email: "smita_chopra@hotmail.com",
    phone: "(671) 555-0110",
    designation: "Senior Accountant (P.3961)",
    name: "Flores, Arthur",
    rating: 3,
    zip: 382415,
  },
  {
    id: 3,
    tags: true,
    date: "20/01/2022",
    email: "smita_chopra@hotmail.com",
    phone: "(671) 555-0110",
    designation: "Senior Accountant (P.3961)",
    name: "Flores, Kristin",
    rating: 2,
    zip: 382415,
  },
  {
    id: 4,
    tags: true,
    date: "20/01/2022",
    email: "smita_chopra@hotmail.com",
    phone: "(671) 555-0110",
    designation: "Senior Accountant (P.3961)",
    name: "Flores, Juanita",
    rating: 4,
    zip: 382415,
  },
  {
    id: 5,
    tags: true,
    date: "20/01/2022",
    email: "smita_chopra@hotmail.com",
    phone: "(671) 555-0110",
    designation: "Senior Accountant (P.3961)",
    name: "Flores, Marvin",
    rating: 2,
    zip: 382415,
  },
];

export const applicants = [
  {
    id: 1,
    name: "Adorno, Martine",
    date: "20/01/2022",
    designation: "Behavioral Health Techni (SDC.CH.5298)",
    status: "Application Moved Forward",
  },
  {
    id: 2,
    name: "Bellomy, Christopher",
    date: "20/01/2022",
    designation: "Senior Accountant (P.3961)",
    status: "Application Moved Forward",
  },
  {
    id: 3,
    name: "Blanc, Dario",
    date: "20/01/2022",
    designation: "Senior Accountant (P.3961)",
    status: "Application Moved Forward",
  },
  {
    id: 4,
    name: "Brown, Mitchell ",
    date: "20/01/2022",
    designation: "Senior Accountant (P.3961)",
    status: "Application Moved Forward",
  },
  {
    id: 5,
    name: "Callahan, Michele",
    date: "20/01/2022",
    designation: "Senior Accountant (P.3961)",
    status: "Application Moved Forward",
  },
];

export const userAsCompany = () => {
  if (
    process.env.REACT_APP_PUBLIC_URL ===
    "https://talentats-react.openxcell.dev/"
  ) {
    return false;
  } else if (
    process.env.REACT_APP_PUBLIC_URL ===
    "https://company-talentats.openxcell.dev/"
  ) {
    return true;
  } else if (
    process.env.REACT_APP_PUBLIC_URL === "https://admin-staging.talent-ats.com/"
  ) {
    return false;
  } else if (
    process.env.REACT_APP_PUBLIC_URL ===
    "https://company-staging.talent-ats.com/"
  ) {
    return true;
  } else if (
    process.env.REACT_APP_PUBLIC_URL === "https://company.talent-ats.com/"
  ) {
    return true;
  } else if (
    process.env.REACT_APP_PUBLIC_URL === "https://admin.talent-ats.com/"
  ) {
    return false;
  } else {
    return false;
  }
};

export const chartProp = {
  series: [
    {
      name: "series1",
      data: [31, 40, 38, 51, 42, 72, 70, 100],
      colors: ["#5B93FF", "#E6492D", "#FFD66B", "#61BD6E"],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#61BD6E"],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: [
        "01 Jan",
        "02 Jan",
        "03 Jan",
        "04 Jan",
        "05 Jan",
        "06 Jan",
        "07 Jan",
        "08 Jan",
      ],
    },
    tooltip: {
      x: {
        format: "dd",
      },
    },
    fill: {
      colors: ["#61BD6E"],
    },
  },
};

export const barChartProp = {
  series: [
    {
      data: [21, 24, 24, 28, 30, 35, 38, 39],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    colors: [
      "#FF4267",
      "#0890FE",
      "#783EFD",
      "#FFAF2A",
      "#E6492D",
      "#34AA44",
      "#3954A5",
      "#9C27B0",
    ],
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    legend: {
      markers: {
        radius: 12,
      },
      horizontalAlign: "left",
    },
    yaxis: {
      categories: [21, 24, 24, 28, 30, 35, 38, 39],
      labels: {
        show: true,
      },
    },
    xaxis: {
      categories: [
        "Video Interview Invited",
        "Appointment Notification Viewed",
        "Video Viewed",
        "Interview Canceled",
        "Chat Message sent",
        "Interview Accepted",
        "Application Move Forward",
        "Video Interview Reviewed",
      ],
      labels: {
        show: false,
      },
    },
  },
};

export const colChartProps = {
  series: [
    {
      name: "Net Profit",
      data: [21, 24, 22, 28, 30, 35, 38, 39, 45, 48],
    },
    {
      name: "Revenue",
      data: [22, 24, 26, 25, 31, 37, 38, 43, 42, 49],
    },
    {
      name: "Free Cash Flow",
      data: [23, 24, 26, 24, 30, 35, 42, 39, 46, 47],
    },
  ],
  // series: [
  //   {
  //     data: [
  //       { x: "1", y: 21, fillColor: "#3396F7", label: "Free Cash Flow" },
  //       { x: "3", y: 47, fillColor: "#EC4498", label: "Revenue" },
  //       { x: "3", y: 47, fillColor: "#EC4498", label: "A" },
  //       { x: "4", y: 24, fillColor: "#EC4498", label: "D" },
  //       { x: "5", y: 26, fillColor: "#EC4498", label: "D" },
  //       { x: "7", y: 30, fillColor: "#EC4498", label: "D" },
  //       { x: "8", y: 44, fillColor: "#EC4498", label: "D" },
  //       { x: "6", y: 14, fillColor: "#EC4498", label: "D" },
  //     ],
  //   },
  //   {
  //     data: [
  //       {
  //         x: "New Delhi",
  //         y: 218,
  //         fillColor: "#3396F7",
  //       },
  //       {
  //         x: "Kolkata",
  //         y: 149,
  //         fillColor: "#EC4498",
  //       },
  //       {
  //         x: "Mumbai",
  //         y: 184,
  //         fillColor: "#61CF35",
  //       },
  //       { x: "3", y: 47, fillColor: "#EC4498" },
  //       { x: "4", y: 24, fillColor: "#EC4498" },
  //       { x: "5", y: 26, fillColor: "#EC4498" },
  //       { x: "7", y: 30, fillColor: "#EC4498" },
  //       { x: "8", y: 44, fillColor: "#EC4498" },
  //       { x: "6", y: 149, fillColor: "#EC4498" },
  //     ],
  //   },
  //   {
  //     data: [
  //       {
  //         x: "New Delhi",
  //         y: 218,
  //         fillColor: "#61CF35",
  //       },
  //       {
  //         x: "Kolkata",
  //         y: 149,
  //         fillColor: "#EC4498",
  //       },
  //       {
  //         x: "Mumbai",
  //         y: 184,
  //         fillColor: "#61CF35",
  //       },
  //       { x: "3", y: 47, fillColor: "#EC4498" },
  //       { x: "4", y: 24, fillColor: "#EC4498" },
  //       { x: "5", y: 26, fillColor: "#EC4498" },
  //       { x: "7", y: 30, fillColor: "#EC4498" },
  //       { x: "8", y: 44, fillColor: "#EC4498" },
  //       { x: "6", y: 149, fillColor: "#EC4498" },
  //     ],
  //   },
  // ],
  options: {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        distributed: true,
        endingShape: "rounded",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -30,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
      formatter: function (val, opts) {
        var a = 0;
        if (opts.seriesIndex == 1) {
          a = a + val;
        }
        // console.log(opts.seriesIndex);
        if (a == 0) {
        } else {
          return a;
        }
      },
    },
    colors: [
      "#FF4267",
      "#0890FE",
      "#783EFD",
      "#FFAF2A",
      "#34AA44",
      "#E6492D",
      "#3954A5",
      "#9C27B0",
      "#FF4267",
      "#FF6885",
    ],
    legend: {
      markers: {
        radius: 12,
      },
      horizontalAlign: "left",
      customLegendItems: [
        "Group 1",
        "Group 2",
        "Group 3",
        "Group 4",
        "Group 5",
        "Group 6",
        "Group 7",
        "Group 8",
        "Group 9",
        "Group 10",
      ],
    },
    yaxis: {
      // categories: [21, 24, 24, 28, 30, 35, 38, 39],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
  },
};

export const videoUserAppID = "a-677c5520-3a08-0133-e314-22000bdd42d0";
export const dataSourceType = "record";
export const videoID = "DemoCamera";
export const maxLengthCamera = 400;
export const minLengthCamera = 0;
export const preRollCount = 0;

export const hrs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const mins = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
];

export const getGreetingText = () => {
  const today = new Date();
  const currentHour = today.getHours();

  return (
    <span style={{ textTransform: "capitalize" }}>
      {currentHour < 12
        ? "good morning"
        : currentHour < 18
        ? "good afternoon"
        : "good evening"}
    </span>
  );
};

export const getTagColor = (status) => {
  let color;
  switch (status) {
    case "Terminated":
      color = "status-label dblue";
      break;
    case "Hired":
      color = "status-label purple";
      break;
    case "Quite":
      color = "status-label orange";
      break;
    case "Furlough":
      color = "status-label brown";
      break;
    case "Canceled":
      color = "status-label";
      break;
    case "Filled":
      color = "status-label yellow";
      break;
    case "Deleted":
      color = "status-label red";
      break;
    case "Withdrawn":
      color = "status-label lblue";
      break;
    case "Unqualified":
      color = "status-label grey";
      break;
    case "Ineligible":
      color = "status-label blue";
      break;
    case "Active":
      color = "status-label active";
      break;
    default:
      color = "status-label";
      break;
  }
  return color;
};
