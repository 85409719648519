import { Description } from "./description";
import { useSelector } from "react-redux";
import { Benefits } from "./benefits";
import { CheckListItems } from "./checkListItems";
import { DocumentPacks } from "./documentPacks";
import { JobTempBoards } from "./jobBoards";
import { Documents } from "./documents";
import { Activity } from "./activity";
import { Notes } from "./notes/index";
import { JobTempAppointments } from "./appointments/index";
import Api from "../../../../../helper/Api";
import { JOB_TEMPLATE_ACTIONS } from "../../../../../redux/jobTemplate/actions";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

export const JobTemplateContent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentMenu = useSelector(
    (state) => state.jobTemplateReducer.currentMenu
  );

  const getJobTempById = () => {
    Api(
      "GET",
      `JobTemplate/GetJobTemplateById?companyId=${location.state.companyId}&jobTemplateId=${location.state.data.jobTemplateId}`
    )
      .then((res) => {
        dispatch({
          type: JOB_TEMPLATE_ACTIONS.SET_JOB_TEMP_DATA,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="user-detail">
      {
        {
          description: <Description />,
          activity: <Activity />,
          appointments: <JobTempAppointments />,
          benefits: <Benefits getJobTempById={getJobTempById} />,
          "checklist-items": <CheckListItems getJobTempById={getJobTempById} />,
          documents: <Documents getJobTempById={getJobTempById} />,
          "document-packs": <DocumentPacks getJobTempById={getJobTempById} />,
          "job-boards": <JobTempBoards getJobTempById={getJobTempById} />,
          notes: <Notes />,
        }[currentMenu]
      }
    </div>
  );
};
