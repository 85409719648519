export const ThankYouPage = () => {
  return (
    <div>
      <div className="white-container">
        <div className="mb-10">
          <img
            src={require("../../../../../../../assets/images/logo.png")}
            width="125px"
          />
        </div>
        <strong className="mb-10">
          Thank you for attending the interview, Soon our Team will reach out to
          you.
        </strong>
      </div>
    </div>
  );
};
