import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Api from "../../helper/Api";
import {
  dataSourceType,
  maxLengthCamera,
  minLengthCamera,
  preRollCount,
  videoID,
  videoUserAppID,
} from "../../helper/constants";
import { useState } from "react";

import Loader from "../common/Loader";
// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  script.onload = () =>
    window.CameraTag.observe("DemoCamera", "initialized", function () {
      window.CameraTag.cameras["DemoCamera"].connect();
    });
  document.body.appendChild(script);
}

export function Video() {
  const videoMain = {
    height: "100%",
    padding: "0",
    overflow: "auto",
  };
  const videoHeight = {
    width: "380px",
    minHeight: "280px",
  };
  const header = {
    background: "#3954A5",
    padding: "13px 24px",
  };

  const videoStyle = {
    main: {
      height: "calc(100% - 70px)",
      overflow: "auto",
    },
    box: {
      width: "100%",
      maxWidth: " 450px",
      border: "1px solid #b1bad5",
      borderRadius: " 5px",
      padding: "15px 20px",
      height: "auto",
      maxHeight: "100%",
      textAlign: "center",
      background: "#F2F5FF",
    },
    borderCust: {
      border: "1px solid #cecece",
      borderRadius: "4px",
      marginBottom: "15px",
    },
  };

  // const Camera = () => (
  //   <React.Fragment>
  //     <camera
  //       id={videoID}
  //       data-sources={dataSourceType}
  //       data-uuid={videoUserAppID}
  //       data-maxlength={maxLengthCamera}
  //       data-pre-roll-length={preRollCount}
  //       data-minlength={minLengthCamera}
  //       data-autopreview="false"
  //       data-simple-security="true"
  //       style={videoHeight}
  //     ></camera>
  //   </React.Fragment>
  // );

  const preQueTime = 10;
  const questionTime = 180;
  let globalIndex = 0;
  let timeInterval = React.useRef();
  let setTimeOut = React.useRef();

  let { id } = useParams();

  // const [globalIndex, setGlobalIndex] = useState(0);
  const [quesList, setQuesList] = useState([]);
  const [stepsActive, setStepsActive] = useState(1);

  const [recStatus, setRecStatus] = useState(false);
  const [showTimer, setShowTimer] = useState("");
  const [showCamera, setShowCamera] = useState(true);
  const [startInterview, setStartInterview] = useState(false);

  const [btnDisableStartREC, setBtnDisableStartREC] = useState(false);
  const [btnDisableStopREC, setBtnDisableStopREC] = useState(true);
  const [btnDisableAnotherREC, setBtnDisableAnotherREC] = useState(true);

  const [btnStart, setBtnStart] = useState(true);
  const [btnFinished, setBtnFinished] = useState(false);

  const [btnDISplayVideo, setBtnDISplayVideo] = useState(false);
  const [btnDISstopVideo, setBtnDISstopVideo] = useState(true);

  const [isLoader, setIsLoader] = useState(true);

  const startTimer = (duration) => {
    let minutes = duration;
    let seconds = duration;
    let timer = duration;

    timeInterval.current = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setShowTimer(minutes + ":" + seconds);
      // console.log(minutes + ":" + seconds);

      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  };

  function intializedCamera() {
    window.location.reload(false);
  }

  const cmConnect = (event) => {
    window["connectCamera"](videoID);
  };

  useEffect(() => {
    if (stepsActive === -1 || stepsActive >= quesList.length + 4) {
      setShowCamera(false);
    }
    console.log(stepsActive);
  }, [stepsActive]);

  const startRecording = (event) => {
    setBtnDisableStartREC(true);
    setTimeout(() => {
      setBtnDisableStopREC(false);
    }, minLengthCamera * 1000);
    setRecStatus(true);
  };
  const stopRecording = (event) => {
    setBtnDisableStopREC(true);
    setBtnDisableStartREC(true);
    setBtnDisableAnotherREC(false);
    setRecStatus(false);
    // setTimeout(() => {
    //   window["publish"](videoID);
    // }, 500);
  };

  const playVideo = (event) => {
    setBtnDISplayVideo(true);
    setBtnDISstopVideo(false);
  };
  const stopVideo = (event) => {
    setBtnDISplayVideo(false);
    setBtnDISstopVideo(true);
  };

  function beginFunc(respTime) {
    clearTimeout(setTimeOut.current);
    clearInterval(timeInterval.current);
    setBtnFinished(true);
    setBtnStart(false);
    setRecStatus(true);
    window["playCamera"](videoID);
    startTimer(respTime);

    setTimeOut.current = setTimeout(() => {
      answerFunc(
        quesList[globalIndex].questionsId,
        quesList[globalIndex].responseTime
      );
    }, quesList[globalIndex].responseTime * 1000);
  }

  const updateQueStatus = (qID, mediaID, nextTime) => {
    const params = {
      id: id,
      qId: qID,
      mediaFile: mediaID,
    };

    Api("PUT", "Job/UpdateQuestionStatus", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          console.log(res);
          finishedFunc(nextTime);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function finishedFunc(nextTime) {
    clearInterval(timeInterval.current);
    // clearTimeout(setTimeOut.current);
    window.CameraTag.cameras["DemoCamera"].reset();
    window["connectCamera"](videoID);
    setStepsActive(globalIndex + 5);
    setBtnStart(true);
    setBtnFinished(false);
    setRecStatus(false);
    startTimer(nextTime);
    setIsLoader(false);
    globalIndex++;
    console.log(globalIndex, "globalindex");
    setTimeOut.current = setTimeout(() => {
      beginFunc(quesList[globalIndex].prepTime);
    }, quesList[globalIndex].prepTime * 1000);
  }

  function answerFunc(quesID, nextTime) {
    clearTimeout(setTimeOut.current);
    if (globalIndex <= quesList.length) {
      stopF();
      window.CameraTag.observe("DemoCamera", "published", function () {
        setTimeout(() => {
          let myCamera = window.CameraTag.cameras["DemoCamera"];
          let myVideo = myCamera.getVideo();
          // let uuid = myVideo.uuid;
          let mp4_url = myVideo.medias["360p_mp4"];

          updateQueStatus(quesID, mp4_url, nextTime);
        }, 2000);
      });
    }
  }

  function stopF() {
    setIsLoader(true);
    window.CameraTag.cameras["DemoCamera"].stopRecording();
    window.CameraTag.observe("DemoCamera", "readyToPublish", function () {
      window.CameraTag.cameras["DemoCamera"].publish();
    });
  }

  // console.log(searchParams);
  useEffect(() => {
    Api("GET", `Job/GetQuestionsForRequestVideoInterview?Id=${id}`)
      .then((res) => {
        // sorting questions as per order - 1
        const sortedQuestions = res.data.sort(
          (a, b) => parseFloat(a.order) - parseFloat(b.order)
        );
        const pendingQuestions = sortedQuestions.filter(
          (x) => x.videoQuestionStatus == "PENDING_VIDEO_INTERVIEW_QUESTIONS"
        );
        setQuesList(pendingQuestions);
        // console.log(pendingQuestions);

        if (sortedQuestions.length == 0) {
          setStepsActive(0);
          setShowCamera(false);
        } else {
          // console.log("ques");
          setStepsActive(1);
          setShowCamera(true);
        }

        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(quesList);

  // setTimeout(() => {
  //   setBtnDisableStartREC(true);
  //   console.log(btnDisableStartREC, "hello");
  // }, 3000);

  return (
    <div style={videoMain}>
      {/* Call the function to add a library */}
      {AddLibrary("https://cameratag.com/api/v12/js/cameratag.min.js")}
      <Loader inShow={isLoader} />
      <div style={header}>
        <img src={require("../../assets/images/video-logo.svg").default} />
      </div>
      <div
        style={videoStyle.main}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div style={videoStyle.box}>
          <div className={showCamera ? "show" : "hide"}>
            <p className="mt-0">
              <b className={recStatus ? "red" : ""}>
                {recStatus ? "RECORDING" : "NOT RECORDING"}
              </b>
            </p>
            <div className={recStatus ? "red-border" : ""}>
              <camera
                id={videoID}
                data-sources={dataSourceType}
                data-uuid={videoUserAppID}
                data-maxlength={maxLengthCamera}
                data-pre-roll-length={preRollCount}
                data-minlength={minLengthCamera}
                data-record-on-connect="false"
                data-autopreview="false"
                data-simple-security="true"
                style={videoHeight}
              ></camera>
            </div>
            <div id={videoID + "-start-screen"}></div>
            <div id={videoID + "-accept-screen"}></div>
            <div id={videoID + "-countdown-screen"}></div>
          </div>
          <div className={stepsActive === 0 ? "steps active" : "steps"}>
            <p>Link has been expired.</p>
          </div>
          <div className={stepsActive === 1 ? "steps active" : "steps"}>
            <p>
              Please test your camera and microphone to be sure they are
              recording well and that your voice is audible.
            </p>
            <p>You may need to re-position your camera for best results.</p>
            <div className="d-flex justify-content-center">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  onClick={() => (
                    window["playCamera"](videoID), startRecording()
                  )}
                  disabled={btnDisableStartREC}
                >
                  Start Recording
                </Button>
                <Button
                  variant="contained"
                  onClick={() => (
                    window["stopCamera"](videoID),
                    stopRecording(),
                    setStepsActive(2)
                  )}
                  disabled={btnDisableStopREC}
                >
                  Stop Recording
                </Button>
              </Stack>
            </div>
          </div>
          <div className={stepsActive === 2 ? "steps active" : "steps"}>
            <p>Please review your test video:</p>
            <div className="d-flex justify-content-center">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  onClick={() => (window["playVideo"](videoID), playVideo())}
                  disabled={btnDISplayVideo}
                >
                  Review Test Video
                </Button>
                <Button
                  variant="contained"
                  onClick={() => (window["stopVideo"](videoID), stopVideo())}
                  disabled={btnDISstopVideo}
                >
                  Stop Test Video
                </Button>
              </Stack>
            </div>
            <p>If necessary you may record another test:</p>
            <Button
              variant="contained"
              onClick={intializedCamera}
              disabled={btnDisableAnotherREC}
            >
              Record Another Test
            </Button>
            <p>If you are satisfied with your testing, Click Continue: </p>
            <Button
              variant="contained"
              onClick={() => (setStepsActive(3), cmConnect())}
            >
              Continue
            </Button>
          </div>
          <div className={stepsActive === 3 ? "steps active" : "steps"}>
            {/* <p className="question-p">Question 1 of {quesList.length}</p> */}
            <p>
              You will have <b>30 seconds</b> to read the question and prepare
              your answer.
            </p>
            <p>
              Recording will begin automatically after 30 seconds. If you do not
              need the entire 30 seconds, you may click the{" "}
              <b>Start Answer Now</b> button
            </p>
            <p>
              You will have then <b>3 minutes </b>to record you answer
            </p>
            <p>
              If you finish early, click the <b>Finished</b> button
            </p>
            <div className="d-flex justify-content-center">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  onClick={() => (
                    setStepsActive(4),
                    startTimer(quesList[0].prepTime),
                    (setTimeOut.current = setTimeout(() => {
                      beginFunc(quesList[0].responseTime);
                    }, quesList[0].prepTime * 1000))
                  )}
                >
                  Begin
                </Button>
              </Stack>
            </div>
          </div>
          {/* Question list started */}
          {quesList?.map((list, index) =>
            list.videoQuestionStatus == "PENDING_VIDEO_INTERVIEW_QUESTIONS" ? (
              <div
                key={list.questionsId}
                className={stepsActive === index + 4 ? "steps active" : "steps"}
              >
                <p className="question-p">
                  Question {index + 1} of {quesList.length}
                </p>
                <div style={videoStyle.borderCust}>
                  <p>{list.question}</p>
                </div>
                <div className="d-flex justify-content-center">
                  <Stack spacing={2} direction="row">
                    {btnStart ? (
                      <Button
                        variant="contained"
                        onClick={() => beginFunc(list.responseTime)}
                      >
                        Start Answer Now
                      </Button>
                    ) : null}

                    {btnFinished ? (
                      <Button
                        variant="contained"
                        onClick={() =>
                          answerFunc(list.questionsId, list.prepTime)
                        }
                      >
                        Finished
                      </Button>
                    ) : null}
                  </Stack>
                </div>
                <p className="bold-p">{showTimer}</p>
              </div>
            ) : null
          )}
          {/* <div className={stepsActive === 4 ? "steps active" : "steps"}>
            <p>
              Please tell us about yourself, including your education and
              background (include degrees, schools attended, licenses /
              Certifications, etc.)
            </p>
            <div className="d-flex justify-content-center">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  onClick={() => (
                    clearInterval(timeInterval),
                    setStepsActive(5),
                    console.log("hello"),
                    setRecStatus(true),
                    window["playCamera"](videoID),
                    startTimer(questionTime)
                  )}
                >
                  Start Answer Now
                </Button>
              </Stack>
            </div>
            <p className="bold-p">{showTimer}</p>
          </div> */}
          {/* <div className={stepsActive === 5 ? "steps active" : "steps"}>
            <p>
              Please tell us about yourself, including your education and
              background (include degrees, schools attended, licenses /
              Certifications, etc.)
            </p>
            <div className="d-flex justify-content-center">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  onClick={() => (
                    setStepsActive(-1),
                    setRecStatus(false),
                    window["stopCamera"](videoID),
                    window["publish"](videoID),
                    setShowCamera(false),
                    clearInterval(timeInterval)
                  )}
                >
                  Finished
                </Button>
              </Stack>
            </div>
            <p className="bold-p">{showTimer}</p>
          </div> */}
          <div
            className={
              stepsActive === -1 || stepsActive >= quesList.length + 4
                ? "steps active"
                : "steps"
            }
          >
            <div className="d-flex justify-content-center">
              <p>
                You have completed the interview <br /> Thank you for your
                participation.{" "}
              </p>
            </div>
          </div>
          {/* main-parent */}
        </div>
        {/* <player
          id="DemoPlayer"
          data-uuid="v-5421b13c-cb3e-4479-b963-079fbbf5e509"
        ></player> */}
        {/* <videowall id="DemoWall" data-app-id={videoUserAppID}></videowall> */}
      </div>
    </div>
  );
}
