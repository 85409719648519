export const OutlinedEmailIcon = ({...props}) => {
    return (
        <svg {...props} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M19 1L10 9.25L1 1M19 1H1M19 1V13.75C19 13.9489 18.921 14.1397 18.7803 14.2803C18.6397 14.421 18.4489 14.5 18.25 14.5H1.75C1.55109 14.5 1.36032 14.421 1.21967 14.2803C1.07902 14.1397 1 13.9489 1 13.75V1M8.36363 7.75001L1.23126 14.2881M18.7688 14.2881L11.6363 7.74997" 
                stroke="#3954A5" 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    );
}
