import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const TimeSelector = ({value, setFunction}) => {

    const theme = createTheme({
        typography: {
          fontSize: 12
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    value={value}
                    onChange={(newValue) => {
                        setFunction(newValue);
                    }}
                    renderInput={(params) => <TextField size="small" {...params}  />}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
