import { IconButton, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import { EyeIcon, EmailIcon, PrintIcon } from "../../../icons";

export const Reports = () => {
  return (
    <div className="company_content">
      <div className="content_header">
        <div className="company_text mr-20">Reports</div>
      </div>
      <div className="company_table">
        <table>
          <thead>
            <tr>
              <th className="th_name">
                Report Name
                <button className="icon icon_button">
                  <img
                    src={require("../../../assets/images/tabsort.png")}
                    alt="sort-icon"
                    className="icon sort_icon"
                  />
                </button>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td_name">Time To Hire Report</td>
              <td>
                <Tooltip title="View">
                  <Link to="/reports/time-to-hire">
                    <IconButton>
                      <EyeIcon width="24" height="16" className="icon eye" />
                    </IconButton>
                  </Link>
                </Tooltip>
                {/* <IconButton>
                  <EmailIcon width="20" height="16" className="icon email" />
                </IconButton> */}
                {/* <Tooltip title="Download">
                  <IconButton>
                    <PrintIcon width="22" height="19" className="icon print" />
                  </IconButton>
                </Tooltip> */}
              </td>
            </tr>
            <tr>
              <td className="td_name">Hire Report</td>
              <td>
                <Tooltip title="View">
                  <Link to="/reports/hire">
                    <IconButton>
                      <EyeIcon width="24" height="16" className="icon eye" />
                    </IconButton>
                  </Link>
                </Tooltip>
                {/* <Tooltip title="Download">
                  <IconButton>
                    <PrintIcon width="22" height="19" className="icon print" />
                  </IconButton>
                </Tooltip> */}
              </td>
            </tr>
            <tr>
              <td className="td_name">Jobs Report</td>
              <td>
                <Tooltip title="View">
                  <Link to="/reports/job">
                    <IconButton>
                      <EyeIcon width="24" height="16" className="icon eye" />
                    </IconButton>
                  </Link>
                </Tooltip>
                {/* <Tooltip title="Download">
                  <IconButton>
                    <PrintIcon width="22" height="19" className="icon print" />
                  </IconButton>
                </Tooltip> */}
              </td>
            </tr>
            <tr>
              <td className="td_name">Applicant Source Report</td>
              <td>
                <Tooltip title="View">
                  <Link to="/reports/applicant-source">
                    <IconButton>
                      <EyeIcon width="24" height="16" className="icon eye" />
                    </IconButton>
                  </Link>
                </Tooltip>
                {/* <Tooltip title="Download">
                  <IconButton>
                    <PrintIcon width="22" height="19" className="icon print" />
                  </IconButton>
                </Tooltip> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
