import { IconButton } from "@mui/material";
import { ButtonUI } from "../../ui/button";
import CloseIcon from "@mui/icons-material/Close";
import { createPortal } from "react-dom";
import { useState } from "react";
import Typography from "@mui/material/Typography";

export const MultiStepCol = (props) => {
  const domNode = document?.getElementById(props.applicantId) || "";
  const steps = props.steps;

  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const manageNext = (e) => {
    e.preventDefault();
    const res = props.handleNext();
    if (res === false) {
      return;
    }

    if (props.activeStep === steps - 1) {
      props.handleSubmit();
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(props.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(props.activeStep);
    }

    props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (props.activeStep === 0) {
      props.toggleForm(false);
      return;
    }

    props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      {domNode && props.openForm === true
        ? createPortal(
            <div
              className="col-form-container"
              id={"form_" + props.applicantId}
            >
              <header className="header">
                <span>{props.title}</span>
                <IconButton
                  onClick={() => props.toggleForm(false)}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </header>

              <form className="innerContainer">
                <div className="inForms">
                  <Typography
                    sx={{ mt: 2, mb: 1, height: "calc(100% - 82px)" }}
                  >
                    {props.children}
                  </Typography>
                </div>
              </form>
              <div className="btnContainer">
                <ButtonUI
                  p="10px"
                  variant="outlined"
                  className="header close"
                  onClick={handleBack}
                >
                  {props.activeStep === 0 ? "Cancel" : "Back"}
                </ButtonUI>
                <ButtonUI p="10px" onClick={manageNext}>
                  {props.activeStep === steps - 1 ? "Submit" : "Next"}
                </ButtonUI>
              </div>
            </div>,
            domNode
          )
        : null}
    </>
  );
};
