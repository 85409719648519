import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { indigo } from "@mui/material/colors";
import { useState } from "react";
import Slide from "@mui/material/Slide";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

export const NotificationAlert = ({ message, isOpen, setOpen }) => {
  const [state, setState] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }

  return (
    <div>
      <Snackbar
        open={isOpen}
        onClose={handleClose}
        autoHideDuration={3000}
        TransitionComponent={TransitionDown}
        anchorOrigin={{ vertical, horizontal }}
        message={<span id="message-id"> {message} </span>}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
      >
        <Alert
          onClose={handleClose}
          style={{ background: indigo[50] }}
          iconMapping={{
            success: (
              <NotificationsActiveIcon
                fontSize="inherit"
                sx={{ color: indigo[500] }}
              />
            ),
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
