import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../../../helper/Api";
import { API_types } from "../../../../../helper/enum";
import { validations } from "../../../../../helper/validations";
import { UserRole } from "./UserRole";

export const Roles = ({
  getUsersData,
  usersRoles,
  setUsersRoles,
  usersDataIndi,
  setIsActive,
}) => {
  const [openUserRoleForm, setUserRoleForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const errors = {};
  const [isSubmitting, setSubmitting] = useState(false);
  const location = useLocation();

  useEffect(() => {
    Api(
      "GET",
      `RoleData/GetRoles?companyId=${companyData.companyId}&pageNumber=1&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        let userRoles = res.data.data.map(({ roleId, roleName }) => ({
          roleId,
          roleName,
        }));
        setUsersRoles(userRoles);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // delete user role from user
  const handleDelete = (userId, id, type) => {
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      // Delete User role api call
      Api(
        API_types.DELETE,
        `Users/DeleteUserRole?companyId=${companyData.companyId}&userId=${userId}&roleId=${id}`
      )
        .then((res) => {
          if (res.data.isSuccess) {
            setIsActive(false);
            getUsersData();
            toast.success(res.data.message);
            setIsActive(userId);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleUserRole = (anchor, open) => {
    setUserRoleForm({ ...openUserRoleForm, [anchor]: open });
  };

  const validateRoles = (values) => {
    var data = [];
    values?.roleCheck?.map((rec) => {
      if (rec.checked === true) {
        data.push(rec);
      }
    });

    if (data.length === 0) {
      errors.roleCheck = validations?.urole?.req;
    }

    return errors;
  };

  const handleRole = () => {
    setFormErrors(validateRoles(formValues));
    if (Object.values(formErrors).length > 0) {
      return;
    }

    var data = [];
    formValues.roleCheck.map((rec) => {
      if (rec.checked === true) {
        data.push(rec);
      }
    });

    setSubmitting(true);
    data[0].userId = usersDataIndi.userId;

    // Manage User Roles API
    Api(API_types.POST, `Users/SaveUserRole`, data)
      .then((res) => {
        if (res.data.isSuccess) {
          setIsActive(false);
          getUsersData();
          toast.success(res.data.message);
          setIsActive(formValues.roleCheck[0].userId);
          toggleUserRole("right", false);
          setFormValues({});
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="user-roles">
      {!!usersRoles && (
        <UserRole
          openSidebar={openUserRoleForm}
          usersRoles={usersRoles}
          toggleDrawer={toggleUserRole}
          handleRole={handleRole}
          formValues={formValues}
          selectedUserRoles={usersDataIndi}
          formErrors={formErrors}
          setFormValues={setFormValues}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
        />
      )}
      <div className="d-flex justify-content-between mb-15">
        <span className="main-head">User Role</span>
        <img
          src={
            require("../../../../../assets/images/add-user-roles.svg").default
          }
          alt=""
          className="cursor-pointer"
          onClick={() => (
            toggleUserRole("right", true), setFormErrors({}), setFormValues([])
          )}
        />
      </div>
      <div className="add-roles-container">
        {usersDataIndi.roleDetails?.map((items) => (
          <div className="role-tabs" key={items.roleId}>
            <span>{items.roleName}</span>
            <img
              src={
                require("../../../../../assets/images/delete-tab.svg").default
              }
              alt=""
              className="cursor-pointer delete-can"
              onClick={() => handleDelete(usersDataIndi.userId, items.roleId)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
