import { ManageNotification } from "../../../components/common/layouts/users/ManageNotification";

export const CompanyManageNotifications = () => {
  return (
    <div>
      <div className="company_content help-container applicants">
        <div className="top-url">
          <p>
            {/* {breadCrumb} &gt; */}
            <strong>
              {" "}
              {/* {applicantData.firstName + " " + applicantData.lastName} */}
            </strong>
          </p>
        </div>

        <div className="common-container users company">
          <div className="inner-container company">
            <ManageNotification />
          </div>
        </div>
      </div>
    </div>
  );
};
