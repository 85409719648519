import { Rating } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../../helper/Api";
import { ButtonUI } from "../../ui/button";

export default function Carousel(props) {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [formValues, setFormValues] = useState({});
  const [reviewers, setReviewers] = useState([]);

  useEffect(() => {
    setReviewers(props.video.reviewers);
  }, [props]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = [...reviewers];
    data.forEach((rec) => {
      if (rec.userId === localStorage.getItem("user")) {
        rec[name] = [value];
      }
    });
    setReviewers(data);
  };

  const addQuesRatings = () => {
    var record = {};
    const data = [...reviewers];
    data.forEach((rec) => {
      if (rec.userId === localStorage.getItem("user")) {
        record.rate = rec.rating;
        record.comment = rec.ratingNote;
      }
    });

    const params = {
      companyId: companyData.companyId,
      jobId: props.jobId,
      applicantId: props.applicantId,
      jobApplicantVideoInterviewId: props.jobApplicantVideoInterviewId,
      videoQuestionId: props.video.videoQuestionId,
      rate: record.rate,
      comment: record.comment,
    };

    Api("POST", `Job/AddRateingQuestionWise`, params).then((res) => {
      if (res.data.isSuccess === true) {
        setReviewers(props.video.reviewers);
      }
    });
  };

  return (
    <div className="mainDetails">
      <div class="video-section">
        <video
          className="poster-video"
          key={props.video.videoQuestionId}
          alt={props.video.videoQuestionId}
          controls
        >
          <source
            src={"https://" + props.video?.mediaFile?.slice(6)}
            type="video/mp4"
          />
        </video>
      </div>

      <div className="rating-section">
        <div className="d-flex flex-column">
          <strong>Question : </strong>
          <span className="mt-8 mb-15">{props.video.videoQuestionName}</span>
        </div>
        <div className="d-flex flex-row align-items-center">
          <strong>Status : </strong>
          <span
            className={
              props.video.videoQuestionStatus === "Complete"
                ? "stretch status green-active ml-10"
                : "stretch status ml-10"
            }
          >
            {props.video.videoQuestionStatus}
          </span>
        </div>
        <div className="d-flex flex-column">
          <strong>Rating :</strong>

          {reviewers.map((rec) => (
            <>
              {rec.userId === localStorage.getItem("user") && (
                <>
                  <div className="d-flex flex-row align-items-center justify-content-between font-12 mt-5">
                    <strong>Your Rating :</strong>
                    <div className="ml-10">
                      <Rating
                        name="rate"
                        value={rec.rating}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <strong className="mt-10">Comment: </strong>
                  <div className="forms-input mt-5">
                    <textarea
                      placeholder="Comment"
                      name="comment"
                      value={rec.ratingNote}
                      onChange={handleChange}
                    />
                  </div>
                  <ButtonUI p="9px" onClick={addQuesRatings}>
                    Save Ratings
                  </ButtonUI>
                </>
              )}
              <div className="d-flex mt-5 align-items-center justify-content-between font-12">
                <span>{rec.firstName + " " + rec.lastName}</span>
                <Rating name="rate" value={rec.rating} readOnly />
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
