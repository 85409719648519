import { useSelector } from "react-redux";

export const Description = () => {
  const descriptionInfo = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.description
  );
  const templateData = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.templateData
  );

  return (
    <div>
      <div className="top-header-user">
        <span className="main-name">Description</span>
      </div>
      <div className="main_content">
        <span className="field">Position Title :</span>
        <label>{templateData?.name}</label>
        <p>{descriptionInfo?.description}</p>
        <div className="personal-info job-t">
          <div className="personal-data job-template">
            <div className="p-entity">
              <div>
                <p className="p-label">Exempt from Overtime:</p>
                <p className="p-data">
                  {descriptionInfo?.excemptOverTime
                    ? descriptionInfo?.excemptOverTime == true
                      ? "Yes"
                      : "No"
                    : "-"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div>
                <p className="p-label">Facility:</p>
                <p className="p-data">
                  {descriptionInfo?.facilityName
                    ? descriptionInfo?.facilityName
                    : "-"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div>
                <p className="p-label">Department:</p>
                <p className="p-data">
                  {descriptionInfo?.departmentName
                    ? descriptionInfo?.departmentName
                    : "-"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div>
                <p className="p-label">Rate:</p>
                <p className="p-data">
                  {descriptionInfo?.rate ? descriptionInfo?.rate : "-"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div>
                <p className="p-label">Rate Type:</p>
                <p className="p-data">
                  {descriptionInfo?.rateTypeName
                    ? descriptionInfo?.rateTypeName
                    : "-"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div>
                <p className="p-label">Shift Type:</p>
                <p className="p-data">
                  {descriptionInfo?.shiftTypeName
                    ? descriptionInfo?.shiftTypeName
                    : "-"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div>
                <p className="p-label">Schedule Type:</p>
                <p className="p-data">
                  {descriptionInfo?.scheduleTypeName
                    ? descriptionInfo?.scheduleTypeName
                    : "-"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div>
                <p className="p-label">Employment Type:</p>
                <p className="p-data">
                  {descriptionInfo?.employmentTypeName
                    ? descriptionInfo?.employmentTypeName
                    : "-"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div>
                <p className="p-label">Video Interview:</p>
                <p className="p-data">
                  {descriptionInfo?.videoInterviewName
                    ? descriptionInfo?.videoInterviewName
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <span className="blue-t mb-15">Previous Employee:</span>{" "}
        {descriptionInfo?.previousEmp}
        <span>Maximum Applicants Allowed</span>
        <div className="personal-info job-t">
          <div className="personal-data job-template wd-45">
            <div className="p-entity">
              <div>
                <p className="p-label">Total:</p>
                <p className="p-data">
                  {descriptionInfo?.totalApplication
                    ? descriptionInfo?.totalApplication
                    : "Unlimited"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div>
                <p className="p-label">Per Job Board:</p>
                <p className="p-data">
                  {descriptionInfo?.appPerJobBoard
                    ? descriptionInfo?.appPerJobBoard
                    : "Unlimited"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
