import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { JobInfo } from "../../../common/layouts/jobInfo";

export const JobDetails = () => {
  const location = useLocation();
  const { jobData } = useSelector((state) => state.jobDataReducer);

  return (
    <div className="common-container users">
      <div className="inner-container">
        <div className="top-url">
          <p>
            <Link to="/dashboard"> Dashboard &gt; </Link>
            <Link to="/companylist"> Companies &gt; </Link>
            <Link to="/jobs" state={location.state}>
              Jobs &gt;
            </Link>
            <strong> {jobData.name}</strong>
          </p>
        </div>
        <JobInfo redirectUrl="/jobs/applicant-details" />
      </div>
    </div>
  );
};
