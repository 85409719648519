import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ButtonUI } from "../../../components/ui/button";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import { validations } from "../../../helper/validations";

export const ChangePassword = () => {
  const [formValues, setFormValues] = useState([]);
  const userId = localStorage.getItem("user");
  const [formErrors, setFormErrors] = useState({});
  const errors = {};

  const close = () => {
    // setSideBar(false);
  };
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      currentpassword: formValues.CurrentPassword,
      newpassword: formValues.NewPassword,
      newconfirmpassword: formValues.ConfirmPassword,
      userid: userId,
    };

    Api("POST", `Account/ChangePassword`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          e.target.reset();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Validations
  const validate = (values) => {
    if (!values.CurrentPassword) {
      errors.CurrentPassword = validations?.crntPass?.req;
    }

    if (!values.NewPassword) {
      errors.NewPassword = validations?.newPass?.req;
    } else if (!validations?.password?.custom?.isValid(values.NewPassword)) {
      errors.NewPassword = validations?.password?.custom?.message;
    }

    if (!values.ConfirmPassword) {
      errors.ConfirmPassword = validations?.conPass?.req;
    } else if (values.NewPassword !== values.ConfirmPassword) {
      errors.ConfirmPassword = validations?.password?.match?.message;
    }

    return errors;
  };

  return (
    <div className="personal-settings password-tab">
      <div className="personal-settings-title">
        <span className="td_name"> Change your password </span>
        <p className="title_2"> Here you can manage your password. </p>
      </div>
      <form method="POST" className="inner-container" onSubmit={handleSubmit}>
        <div className="forms-input">
          <label htmlFor="CurrentPassword">Current Password</label>
          <input
            type="password"
            name="CurrentPassword"
            id="CurrentPassword"
            placeholder="Current Password"
            onChange={handleChange}
          />
          {formErrors.CurrentPassword ? (
            <span className="error_msg">{formErrors.CurrentPassword}</span>
          ) : null}
        </div>
        <div className="forms-input">
          <label htmlFor="NewPassword">New Password</label>
          <input
            type="password"
            name="NewPassword"
            id="NewPassword"
            placeholder="New Password"
            onChange={handleChange}
          />
          {formErrors.NewPassword ? (
            <span className="error_msg">{formErrors.NewPassword}</span>
          ) : null}
        </div>
        <div className="forms-input">
          <label htmlFor="ConfirmPassword">Confirm New Password</label>
          <input
            type="password"
            name="ConfirmPassword"
            id="ConfirmPassword"
            placeholder="Confirm Password"
            onChange={handleChange}
          />
          {formErrors.ConfirmPassword ? (
            <span className="error_msg">{formErrors.ConfirmPassword}</span>
          ) : null}
        </div>
        <div className="forms-input align-items-center">
          <ButtonUI
            color="#ffffff"
            variant="contained"
            p="12px"
            width="300px"
            fontSize="16px"
            mt="30px"
            type="submit"
          >
            Save
          </ButtonUI>
        </div>
      </form>
    </div>
  );
};
