import { IconButton } from "@material-ui/core";
import { CancelOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { ButtonUI } from "../../../../../../ui/button";
import EditOfferLetter from "./editOfferLetter";
import { OfferLetterForm } from "./offerLetterForm";
import { SendOfferLetterForm } from "./sendOfferLetterForm";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { RescindedForm } from "./rescindedForm";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { red } from "@mui/material/colors";
import axios from "axios";
import fileDownload from "js-file-download";

export const OfferLetterStatus = ({
  selectedSuitCase,
  applicantId,
  applicantName,
}) => {
  const [openOfferLetterForm, setOfferLetterForm] = useState(false);
  const [offerLetterList, setOfferLetterList] = useState([]);
  const [openSendForm, setSendForm] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [formValues, setFormValues] = useState({});
  const [offerLetterId, setOfferLetterId] = useState("");
  const [openRescindedForm, setRescindedOfferForm] = useState(false);

  useEffect(() => {
    getOfferLetterList();
  }, []);

  const getOfferLetterList = () => {
    Api(
      "GET",
      `Job/GetOfferLetterList?companyId=${companyData.companyId}&applicantId=${applicantId}`
    )
      .then((res) => {
        setOfferLetterList(res.data);
      })
      .catch((err) => console.log(err));
  };

  const toggleOfferLetterForm = (open) => {
    setOfferLetterForm(open);
  };

  const toggleSendForm = (open) => {
    setSendForm(open);
  };

  const toggleRescindedOfferForm = (open) => {
    setRescindedOfferForm(open);
  };

  // Create offer letter
  const handleSaveOfferLetter = (documentId) => {
    const params = {
      documentId: documentId,
      companyId: companyData.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantId,
    };

    Api("POST", `Job/CreateOfferLetter`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleOfferLetterForm(false);
          getOfferLetterList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  // Send Offer letter notification
  const handleSendOfferLetter = (id) => {
    const params = {
      applicantOfferLetterId: id,
      applicantMessage: formValues.applicantMessage,
      expireDate: formValues.expireDate,
    };

    Api("POST", `Job/SendOfferLetterToApplicant`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleSendForm(false);
          getOfferLetterList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  // Delete Offer letter
  const handleDeleteOfferLetter = (status, id) => {
    if (status === "Created" || status === "Edited") {
      var answer = window.confirm(
        "Are you sure you want to delete this offer letter ?"
      );
      if (answer) {
        // DELETE Offer letter api call
        Api(
          "DELETE",
          `Job/DeleteOfferLetter?jobId=${selectedSuitCase.jobId}&companyId=${companyData.companyId}&applicantId=${applicantId}&applicantOfferLetterId=${id}`
        )
          .then((res) => {
            if (res.data.isSuccess === true) {
              toast.success(res.data.message);
              getOfferLetterList();
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      // Rescinded offer letter form
      toggleRescindedOfferForm(true);
      setOfferLetterId(id);
    }
  };

  const handleRescindedOffer = (e) => {
    e.preventDefault();
    const params = {
      applicantOfferLetterId: offerLetterId,
      applicantMessage: formValues.applicantMessage,
    };

    Api("POST", `Job/RescindedOfferLetter`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleRescindedOfferForm(false);
          getOfferLetterList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  // Download the document
  const downloadDocument = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "Offer_letter_" + url.split("/").at(-1));
      });
  };

  return (
    <div>
      <OfferLetterForm
        toggleForm={toggleOfferLetterForm}
        openForm={openOfferLetterForm}
        handleSubmit={handleSaveOfferLetter}
        applicantId={applicantId}
      />

      <SendOfferLetterForm
        toggleForm={toggleSendForm}
        handleSubmit={handleSendOfferLetter}
        openForm={openSendForm}
        formValues={formValues}
        setFormValues={setFormValues}
        offerLetterId={offerLetterId}
        applicantId={applicantId}
      />

      <RescindedForm
        openForm={openRescindedForm}
        toggleForm={toggleRescindedOfferForm}
        formValues={formValues}
        setFormValues={setFormValues}
        handleSubmit={handleRescindedOffer}
        applicantId={applicantId}
      />

      {offerLetterList.length === 0 ||
      offerLetterList[0]?.offerLetterStatus === "Declined" ||
      offerLetterList[0]?.offerLetterStatus === "Rescinded" ? (
        <button
          className="add-btn manage-btn job"
          onClick={() => toggleOfferLetterForm(true)}
        >
          Create Offer Letter
          <img
            src={require("../../../../../../../assets/images/Vector.png")}
            alt="right-arrow"
            className="right-arrow-btn"
          />
        </button>
      ) : null}

      <div className="profile-data">
        {offerLetterList?.map((offer, index) => (
          <div className="section border-bottom" key={index}>
            <div className="d-flex align-items-center">
              <strong>Offer letter</strong>
              <span
                className={
                  offer.offerLetterStatus === "Created"
                    ? "status green-active ml-20"
                    : offer.offerLetterStatus === "Edited" ||
                      offer.offerLetterStatus === "Pending"
                    ? "status blue-completed ml-20"
                    : "status ml-20"
                }
              >
                {offer.offerLetterStatus}
              </span>
            </div>

            {offer.offerLetterStatus === "Created" ||
            offer.offerLetterStatus === "Edited" ||
            offer.offerLetterStatus === "Pending" ? (
              <ButtonUI
                variant="outlined"
                p="8px"
                m="10px"
                onClick={() => (
                  toggleSendForm(true),
                  setOfferLetterId(offer.applicantOfferLetterId)
                )}
              >
                Send Offer Letter
              </ButtonUI>
            ) : null}

            <span className="scheduled">
              <label>
                {applicantName + "_" + offer.applicantOfferLetterId}
              </label>
            </span>
            <span className="d-flex">
              {(offer.offerLetterStatus === "Created" ||
                offer.offerLetterStatus === "Edited") && (
                <EditOfferLetter id={offer.applicantOfferLetterId} />
              )}

              {offer.offerLetterStatus !== "Declined" &&
              offer.offerLetterStatus !== "Rescinded" ? (
                <ButtonUI
                  variant="outlined"
                  fontSize="12px"
                  borderColor="#B80000"
                  color="#B80000"
                  mr="6px"
                  mt="6px"
                  pr="0px"
                  onClick={() =>
                    handleDeleteOfferLetter(
                      offer.offerLetterStatus,
                      offer.applicantOfferLetterId
                    )
                  }
                  rightIcon={<ChevronRightIcon fontSize="small" />}
                >
                  Cancel
                </ButtonUI>
              ) : null}

              {offer.offerLetterStatus === "Signed" && (
                <ButtonUI
                  variant="outlined"
                  fontSize="12px"
                  color="#3954A5"
                  mt="6px"
                  mr="6px"
                  pr="0px"
                  onClick={() => downloadDocument(offer.offerLetterURL)}
                  rightIcon={<ChevronRightIcon fontSize="small" />}
                >
                  Download
                </ButtonUI>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
