import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../helper/Api";
import { CheckboxList } from "../../CheckboxList";
import { AddEditPopup } from "./../../../addEditPopup";

export const SendApplicantForm = (props) => {
  const hasSelectAll = false;
  const [allData, setAllData] = useState([]);
  const { applicantData } = useSelector((state) => state.applicantDataReducer);

  useEffect(() => {
    Api(
      "GET",
      `Job/GetSendApplicanttoUsersList?companyId=${applicantData.companyId}`
    )
      .then((res) => {
        const response = res.data.map((d) => {
          return { ...d, name: d.userFirstName + " " + d.userLastName };
        });

        setAllData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("in this");
  };

  const handleChange = (checkName, isChecked) => {
    const checkList = allData.map((data) => {
      if (data.id === checkName) {
        data.checked = isChecked;
      }
      return data;
    });

    setAllData(checkList);
  };

  return (
    <AddEditPopup
      title="Send Applicant to Users"
      openSidebar={props.openSidebar}
      toggleDrawer={props.toggleDrawer}
      handleSubmit={handleSubmit}
    >
      <CheckboxList
        allData={allData}
        setAllData={setAllData}
        handleSubmit={handleSubmit}
        hasSelectAll={hasSelectAll}
        showSaveContainer={false}
        handleChange={handleChange}
      />
    </AddEditPopup>
  );
};
