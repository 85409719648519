export const LinkIcon = ({...props}) => {
    return (
        <svg viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path 
                d="M10.3515 4.78758L11.1052 4.03383C11.4244 3.71439 11.8033 3.46095 12.2204 3.288C12.6376 3.11505 13.0847 3.02598 13.5362 3.02588C13.9878 3.02577 14.4349 3.11464 14.8521 3.28739C15.2693 3.46014 15.6484 3.7134 15.9677 4.0327C16.287 4.352 16.5403 4.73107 16.713 5.14827C16.8858 5.56548 16.9746 6.01263 16.9745 6.46418C16.9744 6.91573 16.8854 7.36284 16.7124 7.77996C16.5395 8.19708 16.286 8.57604 15.9666 8.89519L13.6006 11.2612C13.2814 11.5804 12.9025 11.8336 12.4854 12.0063C12.0684 12.1791 11.6214 12.268 11.1699 12.268C10.7185 12.268 10.2715 12.1791 9.85446 12.0063C9.43741 11.8336 9.05846 11.5804 8.73926 11.2612" 
                stroke="#006AFF" 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M9.64831 15.2127L8.8947 15.9663C8.57555 16.2858 8.19659 16.5392 7.77947 16.7121C7.36235 16.8851 6.91524 16.9742 6.46369 16.9743C6.01214 16.9744 5.56499 16.8855 5.14779 16.7128C4.73058 16.54 4.35151 16.2867 4.03221 15.9674C3.71291 15.6482 3.45966 15.2691 3.2869 14.8519C3.11415 14.4347 3.02529 13.9875 3.02539 13.536C3.0255 13.0844 3.11457 12.6373 3.28751 12.2202C3.46046 11.8031 3.7139 11.4241 4.03334 11.105L6.3993 8.739C6.7185 8.4198 7.09745 8.16659 7.51451 7.99384C7.93156 7.82109 8.37856 7.73218 8.82998 7.73218C9.2814 7.73218 9.7284 7.82109 10.1455 7.99384C10.5625 8.16659 10.9415 8.4198 11.2607 8.739" 
                stroke="#006AFF" 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    );
}
