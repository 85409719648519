import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import makeStyles from "@material-ui/core/styles/makeStyles";
import "../../assets/css/common/_draggable.css";
import { EditDeleteMenu } from "../ui/editDeleteMenu";

export type Item = {
  id: string,
  title: string,
  question: string,
  content: string,
};

const useStyles = makeStyles({
  draggingListItem: {
    background: "rgb(235,235,235)",
  },
});

export type DraggableListItemProps = {
  item: Item,
  index: number,
  handleEdit: Object,
  handleDelete: Object,
};

const DraggableListItem = ({
  item,
  index,
  handleEdit,
  handleDelete,
}: DraggableListItemProps) => {
  const classes = useStyles();

  return (
    <Draggable draggableId={item.questionsId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ""}
        >
          <div className="d-content">
            <img src={require("../../assets/images/vector.svg").default} />
            <div className="d-item">
              <strong>Question #{item.order}</strong>
              <span>{item.question} </span>
              <div>
                <span>
                  Prep Time:<strong> {item.prepTime} seconds</strong>
                </span>
                <span>
                  Response Time:<strong> {item.responseTime} seconds</strong>
                </span>
              </div>
            </div>
            <EditDeleteMenu
              onEdit={handleEdit}
              onDelete={handleDelete}
              id={item}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
