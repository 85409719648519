import { JobInfo } from "../../../components/common/layouts/jobInfo";
import { useSelector } from "react-redux";

export const CompanyJobDetails = () => {
  const { jobData } = useSelector((state) => state.jobDataReducer);

  return (
    <div>
      <div className="company_content help-container applicants">
        <div className="top-url">
          <p>
            Jobs &gt;
            <strong> {jobData.name}</strong>
          </p>
        </div>

        <div className="common-container users company">
          <div className="inner-container company">
            <JobInfo redirectUrl="/all-jobs/applicant-details" isCompany={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
