import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// For table cell
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`td&.${tableCellClasses.head}`]: {
      border: 0
    },
    [`&.${tableCellClasses.head}`]: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "20px",
      color: "#9494AE",
      backgroundColor: "#f6f6f8",
      padding: "10px",
      border: "none"
    },
    [`&.${tableCellClasses.body}`]: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
      textTransform: "unset",
      backgroundColor: "#fff",
      opacity: 1,
      height: "calc(100% - 120px)",
      overflow: "hidden",
    },
    [`td&.${tableCellClasses.body}`]: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
    }
}));

// For table row
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    'td, th': {
      borderBottom: "1px solid #F6F6F8",
      padding: "10px 16px",
    }
}));