import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../../../../../helper/Api";
import "../../../../../../../assets/css/public/_docPack.css";
import { ButtonUI } from "../../../../../../ui/button";
import { IconButton, Tooltip } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { green, indigo } from "@mui/material/colors";
import fileDownload from "js-file-download";
import axios from "axios";
import { useRef } from "react";
import { toast } from "react-toastify";

export const ViewDocPack = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [formValues, setFormValues] = useState({});
  const [documentList, setDocumentList] = useState([]);
  const [uploadDocs, setUploadDocs] = useState([]);
  const [downloadDocs, setDownloadDocs] = useState([]);
  const docRef = useRef(null);
  const formData = new FormData();
  const preHireDocumentPackId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    Api("GET", `Job/checkDocumentPasswordExists/${preHireDocumentPackId}`)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Create password if not saved
  const savePassword = () => {
    const params = {
      packId: preHireDocumentPackId,
      password: formValues.password,
      confirmPassword: formValues.confirmPassword,
      flag: message,
    };
    Api("POST", `Job/setDocumentPackPassword`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setDocumentList(res.data.data.doc);
          const upload = [];
          const download = [];
          const documents = [...res.data.data.doc];
          documents.forEach((rec) => {
            if (rec.documentUrl === "") {
              upload.push(rec);
            } else {
              download.push(rec);
            }
          });
          setUploadDocs(upload);
          setDownloadDocs(download);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Download the document
  const downloadDocument = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "document_" + url.split("/").at(-1));
      });
  };

  // Upload the document
  const uploadDocument = (e, documentId) => {
    formData.append("documentId", documentId);
    formData.append("onboardingId", preHireDocumentPackId);
    formData.append("document", e.target.files[0]);

    Api("POST", `Job/UploadOnboardingDocument`, formData)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openAttachment = () => {
    docRef.current.click();
  };

  return (
    <div>
      <div className="white-container">
        <div className="mb-10">
          <img
            src={require("../../../../../../../assets/images/logo.png")}
            width="125px"
          />
        </div>
        {documentList.length > 0 ? (
          <div className="doc-tab-container">
            {uploadDocs.length > 0 && (
              <span className="doc-type-title">
                Please upload the following document(s) :
              </span>
            )}
            {uploadDocs?.map((rec, index) => (
              <div className="border-bottom doc-tab" key={index}>
                <label>
                  {rec.documentName} (Upload) <br />
                </label>
                <input
                  type="file"
                  hidden
                  ref={docRef}
                  onChange={(e) => uploadDocument(e, rec.documentId)}
                />
                <Tooltip title="Upload">
                  <IconButton onClick={openAttachment}>
                    <FileUploadOutlinedIcon style={{ color: indigo[600] }} />
                  </IconButton>
                </Tooltip>
              </div>
            ))}

            <div className="doc-section"></div>
            <span className="doc-type-title">
              You may download the following document(s) :
            </span>
            {downloadDocs?.map((doc, dindex) => (
              <div className="border-bottom doc-tab" key={dindex}>
                <label>
                  {doc.documentUrl.split("/").at(-1)} <br />
                </label>
                <Tooltip title="Download">
                  <IconButton onClick={() => downloadDocument(doc.documentUrl)}>
                    <FileDownloadOutlinedIcon style={{ color: green[600] }} />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
          </div>
        ) : (
          <>
            <label>
              Please create your password (you will use this password to accesss
              your documents in TalentATS).
            </label>

            <div className="input-container">
              <div className="txt_box">
                <input
                  className="email_input"
                  type="password"
                  name="password"
                  id="userName"
                  placeholder="Password"
                  onChange={handleChange}
                />
              </div>
              {message === "0" && (
                <div className="txt_box">
                  <input
                    className="email_input"
                    type="password"
                    name="confirmPassword"
                    id="userName"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                  />
                </div>
              )}
              <ButtonUI p="10px" ml="120px" onClick={savePassword}>
                Submit
              </ButtonUI>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
