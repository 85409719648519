import React from "react";

export const ChatWithBadgeIcon = (props) => {
  return (
    <svg viewBox="0 0 32 34" fill="none" {...props}>
      <path
        d="M5.6786 24.1247C4.18768 21.6114 3.66554 18.6402 4.21024 15.7692C4.75494 12.8981 6.329 10.3247 8.63685 8.53203C10.9447 6.73941 13.8276 5.85093 16.7442 6.03344C19.6607 6.21595 22.4103 7.4569 24.4767 9.52325C26.5431 11.5896 27.784 14.3392 27.9666 17.2558C28.1491 20.1724 27.2606 23.0552 25.468 25.3631C23.6754 27.671 21.1019 29.245 18.2309 29.7897C15.3598 30.3345 12.3887 29.8123 9.87532 28.3214L9.87534 28.3213L5.73077 29.5055C5.5593 29.5545 5.37784 29.5567 5.20521 29.512C5.03257 29.4672 4.87504 29.3772 4.74894 29.2511C4.62283 29.125 4.53275 28.9674 4.48802 28.7948C4.44328 28.6221 4.44553 28.4407 4.49452 28.2692L5.67869 24.1246L5.6786 24.1247Z"
        stroke={!!props.stroke ? props.stroke : "#8898C9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16H20"
        stroke={!!props.stroke ? props.stroke : "#8898C9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20H20"
        stroke={!!props.stroke ? props.stroke : "#8898C9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="24"
        cy="7"
        r="5.75"
        fill="#E6492D"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};
