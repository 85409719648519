import React, { useEffect, useState } from "react";
import { ButtonUI } from "../../../components/ui/button";
import { API_types } from "../../../helper/enum";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import { validations } from "../../../helper/validations";
import Loader from "../../../components/common/Loader";

export const UserProfile = (props) => {
  const userId = localStorage.getItem("user");
  const [formErrors, setFormErrors] = useState({});
  const [results, setResults] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [isLoader, setisLoader] = useState(false);
  const errors = {};

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      companyId: null,
      userId: userId,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
    };

    Api(API_types.PUT, `Users/SaveUserProfileInformation`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          getUserInfo();
          props.getUserName(formValues.firstName, formValues.lastName);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = () => {
    Api(API_types.GET, `Users/GetUserById?companyId=null&userId=${userId}`)
      .then((response) => {
        if (response.data) {
          setResults(response.data);
          props.getUserName(
            response.data.firstName,
            response.data.lastName,
            response.data.isMasterDesktopNotification,
            response.data.isMasterEmailNotification
          );
          setFormValues({
            ["firstName"]: response.data.firstName,
            ["lastName"]: response.data.lastName,
            ["email"]: response.data.emails[0],
            ["phone"]: response.data.phoneNumbers[0],
          });
        } else {
          setFormValues({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Validations
  const validate = (values) => {
    if (!values.firstName) {
      errors.firstName = validations?.fname?.req;
    }

    if (!values.lastName) {
      errors.lastName = validations?.lname?.req;
    }

    if (!values.email) {
      errors.email = validations?.email?.req;
    } else if (!validations?.email?.pattern?.value.test(values.email)) {
      errors.email = validations?.email?.pattern?.message;
    }

    if (!values.phone) {
      errors.phone = validations?.phoneNumber?.req;
    }

    return errors;
  };

  return (
    <div className="personal-settings password-tab">
      <div className="personal-settings-title">
        <span className="td_name"> Profile Information </span>
        <p className="title_2"> Here you can manage your account. </p>
      </div>
      <Loader inShow={isLoader} />
      <form className="inner-container" onSubmit={handleSubmit}>
        {/* <div className="two-side-by"> */}
        <div className="forms-input">
          <label htmlFor="firstName">First Name*</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            placeholder="First Name"
            onChange={handleChange}
            value={formValues.firstName}
          />
          {formErrors.firstName ? (
            <span className="error_msg">{formErrors.firstName}</span>
          ) : null}
        </div>
        <div className="forms-input">
          <label htmlFor="lastName">Last Name*</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            placeholder="Last Name"
            onChange={handleChange}
            value={formValues.lastName}
          />
          {formErrors.lastName ? (
            <span className="error_msg">{formErrors.lastName}</span>
          ) : null}
        </div>
        {/* </div> */}
        <div className="forms-input">
          <label htmlFor="email">Email*</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Email"
            onChange={handleChange}
            value={formValues.email}
          />
          {formErrors.email ? (
            <span className="error_msg">{formErrors.email}</span>
          ) : null}
        </div>
        <div className="forms-input">
          <label htmlFor="phone">Phone*</label>
          <input
            type="text"
            name="phone"
            id="phone"
            placeholder="Phone"
            onChange={handleChange}
            value={formValues.phone}
          />
          {formErrors.phone ? (
            <span className="error_msg">{formErrors.phone}</span>
          ) : null}
        </div>
        <div className="forms-input align-items-center">
          <ButtonUI
            color="#ffffff"
            variant="contained"
            p="12px"
            width="300px"
            fontSize="16px"
            mt="30px"
          >
            Save
          </ButtonUI>
        </div>
      </form>
    </div>
  );
};
