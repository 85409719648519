import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  CloseXIcon,
  DownloadIcon,
  FilesIcon,
} from "../../../../../../../icons";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { ButtonUI } from "../../../../../../ui/button";
import { useState } from "react";
import { AddDocument } from "./addDocument";
import Api from "../../../../../../../helper/Api";
import { toast } from "react-toastify";
import axios from "axios";
import fileDownload from "js-file-download";
import { NoColDataFound } from "../../../../../NoColDataFound";
import { ColoredLinearProgress } from "../../../../../coloredProgressBar";

export const DocumentsTab = ({ applicantData, selectedSuitCase }) => {
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [documents, setDocuments] = useState([]);
  const [isLoader, setLoader] = useState(false);

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  function handleSubmit() {
    console.log(formValues);
  }
  const handelCancel = (applicantDocumentsId) => {
    let answer = window.confirm(
      "Are you sure you want to delete this Document ?"
    );

    if (answer) {
      Api(
        "DELETE",
        `Job/DeleteApplicantDocuments?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId}&jobId=${selectedSuitCase.jobId}&applicantDocumentsId=${applicantDocumentsId}`
      )
        .then((res) => {
          res.data.isSuccess == true
            ? toast.success(res.data.message)
            : toast.error(res.data.message);
          getListDocument();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getListDocument();
  }, []);

  const getListDocument = () => {
    setLoader(true);
    Api(
      "GET",
      `Job/ListApplicantDocuments?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        if (res.data.length > 0) {
          setDocuments(res.data);
        } else {
          setDocuments([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleDownload = (file, fileName) => {
    axios
      .get(file, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  };

  return (
    <div className="tab-container">
      {openSidebar ? (
        <AddDocument
          openSidebar={openSidebar}
          toggleDrawer={toggleDrawer}
          handleSubmit={handleSubmit}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      ) : null}

      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : documents.length > 0 ? (
        <>
          <div className="notes-header-container p-16">
            <span className="heading-label">Documents({documents.length})</span>
            <ButtonUI
              p="8px"
              leftIcon={<AddIcon sx={{ height: "16px", width: "16px" }} />}
            >
              Add
            </ButtonUI>
          </div>
          <div className="notes-cards-container p-16">
            {documents.map((doc) => (
              <div className="doucments-card" id={doc.applicantDocumentsId}>
                <div className="doc-detail">
                  <FilesIcon
                    stroke="#9EA0A5"
                    line="#fff"
                    width="32px"
                    height="32px"
                  />
                  <div className="file-name-label">{doc.displayFileName}</div>
                </div>
                <div className="doc-detail">
                  <div role="button" className="pointer">
                    <DownloadIcon
                      className="doc-icon"
                      stroke="#3954A5"
                      width="28px"
                      height="28px"
                      onClick={() => {
                        handleDownload(
                          doc.document,
                          doc.document.substr(doc.document.lastIndexOf("/") + 1)
                        );
                      }}
                    ></DownloadIcon>
                  </div>
                  <div role="button" className="pointer">
                    <CloseXIcon
                      width="28px"
                      height="28px"
                      onClick={() => handelCancel(doc.applicantDocumentsId)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="notes-header-container p-16">
            <span className="heading-label font-blue">
              Documents: ({documents.length})
            </span>
            <ButtonUI
              p="8px"
              leftIcon={<AddIcon sx={{ height: "16px", width: "16px" }} />}
              onClick={() => toggleDrawer("right", true)}
            >
              Add
            </ButtonUI>
          </div>
          <NoColDataFound />
        </>
      )}
    </div>
  );
};
