import * as React from "react";
import { useState } from "react";
import DraggableListItem from "./../DraggableListItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Api from "../../../helper/Api";
import { API_types } from "../../../helper/enum";
import { toast } from "react-toastify";

export type Item = {
  id: string,
  title: string,
  question: string,
};

export type DraggableListProps = {
  items: Item[],
  handleEdit: Object,
  handleDelete: Object,
};

const DraggableList = ({
  items,
  handleEdit,
  handleDelete,
  formValues,
}: DraggableListProps) => {
  const [allQuestions, updateQuestions] = useState(items);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(allQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.map((item, index) => {
      item.order = index + 1;
    });

    updateQuestions(items);
    const params = {
      videoInterviewId: formValues.videoInterviewId,
      companyId: formValues.companyId,
      questions: items,
    };

    Api(API_types.POST, `VideoInterview/ResetQuestionOrder`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {allQuestions.length > 0 ? (
              allQuestions.map((item, index) => (
                <DraggableListItem
                  item={item}
                  index={index}
                  key={index}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              ))
            ) : (
              <p className="message text-center">Please Add Questions First!</p>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
