import React, { useEffect, useState } from "react";
import "../../../assets/css/components/search/_search.css";
import Rating from "@mui/material/Rating";
import Api from "../../../helper/Api";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import moment from "moment";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import Loader from "../../../components/common/Loader";
import { ProfileImage } from "../../../components/common/layouts/ProfileImage";
import BackgroundLetterAvatars from "../../../components/common/layouts/BackgroundLetterAvatars";
import { ButtonUI } from "../../../components/ui/button";

export function Search() {
  const [isLoader, setIsLoader] = useState(false);
  const [type, setType] = useState(2);
  const [typeText, setTypeText] = useState("job");
  const [page, setPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItem, setTotalItem] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const pageLimit = 12;
  const userId = localStorage.getItem("user");

  const handleChange = (event) => {
    setTypeText(event.target.selectedOptions[0].text.toLowerCase());
    setType(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearch();
    }
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const getSearch = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      getSearchData(searchInput);
      setIsLoader(true);
    }
  };

  useEffect(() => {
    setIsLoader(true);
  }, []);

  useEffect(() => {
    if (searchInput == "" && page) {
      getSearchData("");
      setIsLoader(true);
    } else {
      setIsLoader(false);
    }
  }, [searchInput, page, typeText]);

  const getSearchData = (searchInput) => {
    Api(
      "GET",
      `Search/GetSearchList?userId=${userId}&type=${typeText}&pageLimit=${pageLimit}&pageNumber=${page}&OrderbyDirection=asc&OrderBy=firstName&searchText=${searchInput}`
    )
      .then((res) => {
        if (res.data.data.length) {
          setSearchData(res.data.data);
          setTotalPage(res.data.totalPage);
          setTotalItem(res.data.totalRecord);
          setIsLoader(false);
        } else {
          setTotalPage(res.data.totalPage);
          setTotalItem(res.data.totalRecord);
          setSearchData([]);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //excel sheet
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <React.Fragment>
      <div className="company_content help-container">
        <div className="content_header remove-border">
          <div className="company_text">Search</div>
          <div
            className="exports"
            onClick={(e) => exportToCSV(searchData, "report")}
          >
            <img
              src={
                require("../../../assets/images/download-export.svg").default
              }
              alt="download"
            />
            <span>Reports</span>
          </div>
        </div>
        <div className="company_table search-page">
          <div className="top-filters">
            <div className="top-inputs">
              <p>Type</p>
              <select
                name=""
                value={type}
                id=""
                defaultValue={type}
                onChange={handleChange}
              >
                <option value="1">Global</option>
                <option value="2">Job</option>
                <option value="3">Candidate</option>
              </select>
            </div>
            <div className="top-inputs">
              <p>Search Terms</p>
              <input
                type="text"
                name=""
                id=""
                className="search-in-filter"
                onChange={handleSearch}
                onKeyPress={handleKeyPress}
              />
            </div>
            {(type == 2 || type == 1) && (
              <div className="top-inputs">
                <p>Zip Code</p>
                <input type="text" name="" id="" />
              </div>
            )}
            {(type == 3 || type == 1) && (
              <div className="top-inputs">
                <p>Rating</p>
                <input type="text" name="" id="" />
              </div>
            )}
            <ButtonUI>Search</ButtonUI>
          </div>
          <p className="resume-found">{totalItem} resumes found</p>
          <Loader inShow={isLoader} />
          {searchData.length > 0 ? (
            <div className="resume-cards">
              {type == 1
                ? searchData.map((items) => (
                    <div className="perticular-cards flex-start" key={items.id}>
                      <ProfileImage
                        name={items.firstName + " " + items.lastName}
                        class="profile-img global"
                      />
                      <div className="d-flex flex-column right-side-content">
                        <div className="d-flex mt-1">
                          <p className="resume-person global">
                            {items.firstName + " " + items.lastName}
                          </p>
                          <span
                            className={
                              items.status !== "NA" ? "tags global" : ""
                            }
                          >
                            {items.status !== "NA" ? items.status : ""}
                          </span>
                          <p className="dates-resume">
                            {moment(items.date).format("DD/MM/YYYY")}
                          </p>
                        </div>
                        <p className="work-design">
                          {items.designation + " (" + items.jobCode + ")"}
                        </p>
                        <div className="res-details res-details-global">
                          <div className="d-flex align-items-center">
                            <p className="email-resume">{items.emailid}</p>
                            <p className="dot"></p>
                            <p className="phone-num">{items.phoneno}</p>
                          </div>
                          <span className="rating-stars d-flex align-items-center">
                            Rating :
                            <Rating
                              name="avg-rating"
                              defaultValue={items.rating}
                              size="small"
                              readOnly
                            />
                          </span>
                        </div>
                      </div>
                      <img
                        className="right-arrow-resume cursor-pointer"
                        src={
                          require("../../../assets/images/right-arrow.svg")
                            .default
                        }
                        alt="stars"
                      />
                    </div>
                  ))
                : searchData.map((items) => (
                    <div className="perticular-cards" key={items.id}>
                      <BackgroundLetterAvatars
                        name={items.firstName + " " + items.lastName}
                      />
                      <div className="d-flex flex-column right-side-content">
                        <p className="resume-person">
                          {items.firstName + " " + items.lastName}
                        </p>
                        <div className="res-details res-details-job">
                          <p className="work-design">{items.designation}</p>
                          <p className="zip-code">
                            {items.zipCode ? "Zip:" : ""} {items.zipCode}
                          </p>
                          <span className="rating-stars d-flex align-items-center">
                            Rating :
                            <Rating
                              name="avg-rating"
                              defaultValue={items.rating}
                              size="small"
                              readOnly
                            />
                          </span>
                          <p className={items.status !== "NA" ? "tags" : ""}>
                            {items.status !== "NA" ? items.status : ""}
                          </p>
                        </div>
                      </div>
                      <img
                        className="right-arrow-resume cursor-pointer"
                        src={
                          require("../../../assets/images/right-arrow.svg")
                            .default
                        }
                        alt="stars"
                      />
                    </div>
                  ))}
            </div>
          ) : (
            <NoRecordsFound />
          )}
          {searchData.length > 0 ? (
            <StyledPagination
              count={totalPage}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
