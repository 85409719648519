import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../assets/css/components/company-detail/_stopReasons.css";
import Api from "../../../helper/Api";
import { reasonPageLimit } from "../../../helper/constants";
import NoRecordsFound from "../../common/NoRecordsFound";
import { toast } from "react-toastify";
import { StyledPagination } from "../../ui/pagination/pagination";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";
import { ButtonUI } from "../../ui/button";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../common/Loader";

export function StopReasons() {
  const location = useLocation();
  const [openForm, setOpenForm] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [showAddEditLabel, setLabel] = useState("Add Stop Reason");
  const initialValues = { name: "", defaultChatMessage: "" };
  const [formErrors, setFormErrors] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [reasonsList, setReasonsList] = useState([]);
  const [id, setId] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNumber, setPage] = useState(1);
  const [loading, setLoader] = useState(false);
  const errors = {};

  const getStopReasonsList = () => {
    setLoader(true);
    Api(
      "GET",
      `StopReasons/GetStopReasonsList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&searchText=${searchInput}&pageLimit=${reasonPageLimit}&pageNumber=${pageNumber}&OrderBy=updated_at&OrderbyDirection=desc`
    )
      .then((res) => {
        setTotalPage(res.data.totalPage);
        setReasonsList(res.data.data);
      })
      .finally(() => setLoader(false));
  };

  const validate = (values) => {
    if (!values.name) {
      errors.name = "Reason Name is required!";
    } else if (!isNaN(values.name)) {
      errors.name = "Reason name must be a text!";
    }

    return errors;
  };

  useEffect(() => {
    getStopReasonsList();
  }, []);

  useEffect(() => {
    if (searchInput == "") {
      getStopReasonsList();
    }
  }, [searchInput, pageNumber]);

  const addReason = () => {
    setEditMode(false);
    setLabel("Add Stop Reason");
    setFormValues(initialValues);
    setOpenForm(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formValues.companyId = location.state.companyId;
    formValues.companyHirerchy = location.state.companyHirerchy;
    setFormErrors(validate(formValues));

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    if (editMode === true) {
      // Edit stop reason api call
      formValues.stopReasonsId = id;
      Api("PUT", "StopReasons/EditStopReasons", formValues)
        .then((res) => {
          if (res.data.isSuccess) {
            delete formValues.companyHirerchy;
            reasonsList.map((reason) => {
              if (reason.stopReasonsId == id) {
                reasonsList.splice(reasonsList.indexOf(reason), 1);
                reasonsList.unshift(formValues);
              }
            });
            setReasonsList(reasonsList);
            toast.success(res.data.message);
            setOpenForm(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Add stop reason api call
      Api("POST", "StopReasons/AddStopReasons", formValues)
        .then((res) => {
          if (res.data.isSuccess) {
            toast.success(res.data.message);
            setOpenForm(false);
            getStopReasonsList();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const formErrorClear = () => {
    formErrors.name = "";
    formErrors.defaultChatMessage = "";
  };

  const getSearchReason = () => {
    if (!searchInput) {
      alert("Please enter the string to search");
    } else {
      getStopReasonsList();
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleEdit = (stopReasonsId) => {
    // Edit the reason
    const reason = reasonsList.find((reason) =>
      reason.stopReasonsId == stopReasonsId ? reason : ""
    );
    setLabel("Edit Stop Reason");
    setEditMode(true);
    setFormValues(reason);
    setId(stopReasonsId);
    setOpenForm(true);
  };

  const handleDelete = (stopReasonsId) => {
    // Delete the reason
    setId(stopReasonsId);
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      // Delete stop reason API call
      Api(
        "DELETE",
        "StopReasons/DeleteStopReasons?stopReasonsId=" + stopReasonsId
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            getStopReasonsList();
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearchReason();
    }
  };

  return (
    <div>
      {openForm ? <div className="back-shadow"></div> : null}
      {openForm ? (
        <div
          className={openForm ? "side-bar-parent active" : "side-bar-parent"}
        >
          <header>
            <span>{showAddEditLabel}</span>
            <img
              src={require("../../../assets/images/close.svg").default}
              alt="close-img"
              className="close-img"
              onClick={() => (
                setOpenForm(false),
                setFormValues(initialValues),
                formErrorClear()
              )}
            />
          </header>
          <form onSubmit={handleSubmit} className="inner-container">
            <div className="in-forms">
              <div className="forms-input">
                <label>Reason Name</label>
                <input
                  type="text"
                  placeholder="Reason Name here"
                  name="name"
                  id="name"
                  value={formValues.name}
                  onChange={handleChange}
                />
                {formErrors.name ? (
                  <span className="error_msg">{formErrors.name}</span>
                ) : null}
              </div>
              <div className="forms-input">
                <label>Default Chat Message</label>
                <textarea
                  placeholder="Enter a message..."
                  name="defaultChatMessage"
                  id="defaultChatMessage"
                  value={formValues.defaultChatMessage}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className="btns-container">
              <input type="submit" className="add-btn" value="Submit" />
              <button
                className="cancel-btn"
                type="reset"
                onClick={() => (
                  setOpenForm(false),
                  setFormValues(initialValues),
                  formErrorClear()
                )}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : null}
      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>Stop Reasons</strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="content_header_reasons">
                  <div className="company_search">
                    <img
                      src={require("../../../assets/images/search_icon.png")}
                      alt="search-icon"
                      className="search_img"
                    />
                    <input
                      type="text"
                      placeholder="Search Stop Reason"
                      className="search_input appointments"
                      name="search_input"
                      value={searchInput}
                      onChange={handleSearch}
                      onKeyPress={handleKeyPress}
                    />
                    <button className="search-btn" onClick={getSearchReason}>
                      Search
                    </button>
                  </div>
                  <div>
                    <ButtonUI
                      p="12px"
                      leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
                      onClick={() => addReason()}
                    >
                      Add
                    </ButtonUI>
                  </div>
                </div>

                {loading === true ? (
                  <Loader inShow={true} />
                ) : reasonsList.length > 0 ? (
                  <>
                    <div className="reason_container">
                      {reasonsList.map((reason, k) => (
                        <div className="reason_grid" key={k}>
                          <div className="reason_card_header">
                            <span>{reason.name}</span>
                            <EditDeleteMenu
                              id={reason.stopReasonsId}
                              onEdit={handleEdit}
                              onDelete={handleDelete}
                            />
                          </div>
                          <div className="reason_card_content">
                            {reason.defaultChatMessage}
                          </div>
                        </div>
                      ))}
                    </div>
                    <StyledPagination
                      count={totalPage}
                      page={pageNumber}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageChange}
                    />
                  </>
                ) : (
                  <NoRecordsFound />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
