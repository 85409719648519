import {
  OutlinedEmailIcon,
  CellPhoneIcon,
  SourceIcon,
  ClockIcon,
  LocationIcon,
} from "../../../../icons";
import { useState } from "react";
import { SendApplicantForm } from "./content/sendApplicantForm";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Api from "../../../../helper/Api";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../../redux/applicantData/actions";
import { AddApplicantForm } from "../jobInfo/content/addApplicant";
import { ToastAlert } from "../../../../helper/constants";
import { toast } from "react-toastify";
import { EditDeleteMenu } from "../../../ui/editDeleteMenu";
import BackgroundLetterAvatars from "../BackgroundLetterAvatars";

export const ApplicantSidebar = () => {
  const [sendApplicant, setSendApplicant] = useState(false);
  const [applicantInfo, setApplicantInfo] = useState({});
  const { applicantData } = useSelector((state) => state.applicantDataReducer);
  const { morePosition } = useSelector((state) => state.applicantDataReducer);
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [openToastBar, setOpenToastBar] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formData = new FormData();

  const getApplicantById = () => {
    Api(
      "GET",
      `Job/GetApplicantById?companyId=${applicantData.companyId}&jobId=${applicantData.jobId}&applicantId=${applicantData.applicantId}`
    )
      .then((res) => {
        setApplicantInfo(res.data);
        setFormValues(res.data);
        dispatch({
          type: APPLICANT_DATA_ACTIONS.SET_APPLICANT_DATA,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getApplicantById();
  }, []);

  const editAction = () => {
    toggleEditForm("right", true);
  };

  const toggleEditForm = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const handleSubmit = () => {
    formData.append("firstName", formValues?.firstName ?? "");
    formData.append("middleName", formValues?.middleName ?? "");
    formData.append("lastName", formValues?.lastName ?? "");
    formData.append("nickName", formValues?.nickName ?? "");
    formData.append("title", formValues?.title ?? "");
    formData.append("phoneNumber", formValues?.phoneNumber ?? "");
    formData.append("resume", formValues?.resume ?? "");
    formData.append("viewResume", applicantData?.viewResume ?? "");
    formData.append("companyId", applicantData?.companyId);
    formData.append("jobId", applicantData.jobId);
    formData.append("applicantId", applicantData.applicantId);
    formData.append(
      "address.addressLineOne",
      formValues?.address?.addressLineOne ?? ""
    );
    formData.append("address.addressLineTwo", "");
    formData.append("address.country", "");
    formData.append("address.city", formValues?.address?.city ?? "");
    formData.append("address.state", formValues?.address?.state ?? "");
    formData.append("address.zipCode", formValues?.address?.zipCode ?? "");
    formData.append("source", formValues?.source ?? "");

    Api("PUT", `Job/EditApplicant`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          getApplicantById();
          toast.success(res.data.message);
          toggleEditForm("right", false);
        } else {
          toast.error(res.data.message);
        }
        setOpenToastBar(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleDrawer = (anchor, open) => {
    setSendApplicant({ ...sendApplicant, [anchor]: open });
  };

  const morePositionsScreen = () => {
    navigate(location.pathname.replace(/[^/]*$/, "more-positions"), {
      state: { ...location.state },
    });
  };

  return (
    <div className="user-list">
      {openToastBar ? <ToastAlert /> : null}
      {/* Share applicant form */}
      {sendApplicant ? (
        <SendApplicantForm
          openSidebar={sendApplicant}
          toggleDrawer={toggleDrawer}
        />
      ) : null}

      {/* Edit Applicant form */}
      {openSidebar ? (
        <AddApplicantForm
          openAddForm={openSidebar}
          toggleDrawer={toggleEditForm}
          handleSubmit={handleSubmit}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      ) : null}

      <div className="applicant-profile">
        <div className="applicant-data">
          <BackgroundLetterAvatars
            name={applicantInfo.firstName + " " + applicantInfo.lastName}
            width="90"
            height="90"
          />
          <strong>
            {applicantInfo.firstName + " " + applicantInfo.lastName}
          </strong>
          <EditDeleteMenu
            deleteOption={false}
            onEdit={editAction}
            id={applicantInfo.applicantId}
          />
        </div>
      </div>

      <div className="inner-h applicant-sidebar">
        <div>
          <button
            className="add-btn manage-btn job"
            onClick={() => morePositionsScreen()}
          >
            See more positions ({morePosition})
            <img
              src={require("../../../../assets/images/Vector.png")}
              alt="right-arrow"
              className="right-arrow-btn"
            />
          </button>
          <button className="add-btn manage-btn job">
            Send Applicant to Users
            <img
              src={require("../../../../assets/images/Vector.png")}
              alt="right-arrow"
              className="right-arrow-btn"
            />
          </button>
        </div>
        <div>
          <p>
            <strong>Applicant Details</strong>
          </p>
          <div className="personal-data">
            <div className="p-entity">
              <div className="w-13">
                <OutlinedEmailIcon width="20" height="16" />
              </div>
              <div>
                <p className="p-label">Email Address</p>
                <p className="p-data">{applicantInfo.emailAddress}</p>
              </div>
            </div>
            <div className="p-entity">
              <div className="w-13">
                <CellPhoneIcon width="14" height="22" />
              </div>
              <div>
                <p className="p-label">Cell Phone</p>
                <p className="p-data">{applicantInfo.phoneNumber}</p>
              </div>
            </div>
            <div className="p-entity">
              <div className="w-13">
                <SourceIcon width="19" height="18" />
              </div>
              <div>
                <p className="p-label">Source</p>
                <p className="p-data">
                  {applicantInfo.source ? applicantInfo?.sourceName : "--"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div className="w-13">
                <ClockIcon width="20" height="20" />
              </div>
              <div>
                <p className="p-label">Time Zone</p>
                <p className="p-data">
                  {applicantInfo.timeZone ? applicantInfo?.timeZone : "--"}
                </p>
              </div>
            </div>
            <div className="p-entity">
              <div className="w-13">
                <LocationIcon width="18" height="22" />
              </div>
              <div>
                <p className="p-label">Address</p>
                <p className="p-data">
                  {applicantInfo?.address?.addressLineOne
                    ? applicantInfo?.address?.addressLineOne +
                      ", " +
                      applicantInfo?.address?.addressLineTwo +
                      ", " +
                      applicantInfo?.address?.city +
                      ", " +
                      applicantInfo?.address?.state +
                      ", " +
                      applicantInfo?.address?.zipCode
                    : "--"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
