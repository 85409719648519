import {
  createTheme,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/ui/table/table";
import Paper from "@mui/material/Paper";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import { ButtonUI } from "../../../components/ui/button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export const TimeToHire = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageNumber, setPage] = useState(1);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [totalPage, setTotalPage] = useState(1);

  const theme = createTheme({
    palette: {
      action: {
        hover: "#fff",
      },
    },
  });

  useEffect(() => {
    getTimeToHireReport();
  }, [pageNumber]);

  const getTimeToHireReport = () => {
    setIsLoader(true);

    const query = `companyId=${companyData.companyId}&pageLimit=10&pageNumber=${pageNumber}&orderBy=desc&orderByDirection=createdAt`;
    Api("GET", `Report/TimetoHireReport?${query}`)
      .then((res) => {
        setRows(res.data.data);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
    console.log(isLoader);
    console.log("after api call");
  };

  const handleSort = () => {};

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const Row = rows.map((val, key) => {
    return (
      <tr key={key}>
        <td className="td_name">{val.applicantName}</td>
        <td>{val.jobNameAndNumber}</td>
        <td>{val.companyName}</td>
        <td>{val.departmentName}</td>
        <td>{val.facility}</td>
        <td>{val.source}</td>
        <td>{val.daysToHire}</td>
        <td>{val.termination}</td>
      </tr>
    );
  });

  return (
    <div className="">
      <div className="company_content">
        <div className="content_header">
          <div className="company_text mr-20">Time To Hire Report</div>
          <div className="company_view">
            {rows.length > 0 && (
              <ButtonUI
                p="6px"
                className="icon icon_button"
                leftIcon={<FileDownloadOutlinedIcon />}
                onClick={() => exportToCSV(rows, "Time_To_hire_Report")}
              >
                Download
              </ButtonUI>
            )}
          </div>
        </div>

        {isLoader === true ? (
          <Loader inShow={true} />
        ) : rows?.length > 0 ? (
          <>
            <div className="company_table">
              <table>
                <thead>
                  <tr>
                    <th className="th_name">
                      Name
                      <button onClick={handleSort} className="icon icon_button">
                        <img
                          src={
                            require("../../../assets/images/tabsort.svg")
                              .default
                          }
                          alt="sort-icon"
                          className="icon sort_icon"
                        />
                      </button>
                    </th>
                    <th>Job Name</th>
                    <th>Company</th>
                    <th>Department</th>
                    <th>Facility</th>
                    <th>Source</th>
                    <th>Days to Hire</th>
                    <th>Termination</th>
                  </tr>
                </thead>
                <tbody>{Row}</tbody>
              </table>
            </div>
            <StyledPagination
              count={totalPage}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </div>
  );
};
