import React, { useEffect, useState } from "react";
import moment from "moment";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { ButtonUI } from "../../../../../../ui/button";
import { AddEditNote } from "./addEditNote";
import Api from "../../../../../../../helper/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../../../Loader";
import { validations } from "../../../../../../../helper/validations";
import { EditDeleteMenu } from "../../../../../../ui/editDeleteMenu";
import { NoColDataFound } from "../../../../../NoColDataFound";
import { ColoredLinearProgress } from "../../../../../coloredProgressBar";

export const NotesTab = ({ applicantData, selectedSuitCase }) => {
  const [openSidebar, setSidebar] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [formValues, setFormValues] = useState({});
  const [notesList, setNotesList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const errors = {};

  const toggleDrawer = (open) => {
    setSidebar(open);
  };

  function getNotesList() {
    setLoader(true);
    Api(
      "GET",
      `Job/ListApplicantNotes?companyId=${companyData.companyId}&applicantId=${applicantData.applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setNotesList(res.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  useEffect(() => {
    getNotesList();
  }, []);

  const onEdit = (note) => {
    // Edit Note
    setFormValues(note);
    setFormErrors({});
    setEdit(true);
    toggleDrawer(true);
  };

  const onDelete = (note) => {
    // Delete Note
    var answer = window.confirm("Are you sure you want to delete this ?");
    if (answer) {
      // Delete note API call
      Api(
        "DELETE",
        `Job/DeleteApplicantNotes?companyId=${applicantData.companyId}&jobId=${selectedSuitCase.jobId}&applicantId=${applicantData.applicantId}&notesId=${note.notesId}`
      )
        .then((res) => {
          if (res.data.isSuccess) {
            toast.success(res.data.message);
            let list = [...notesList];
            list.splice(list.indexOf(note), 1);
            setNotesList(list);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSubmit = () => {
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      companyId: applicantData.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantData.applicantId,
      quickNoteId: formValues.quickNoteId,
      description: formValues?.description ?? "",
      saveAsQuickNote: formValues?.saveAsQuickNote ?? false,
    };

    if (edit === true) {
      params.notesId = formValues.notesId;

      // Edit note API call
      Api("PUT", "Job/EditApplicantNotes", params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleDrawer(false);
            getNotesList();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // Add note API call
      Api("POST", "Job/AddApplicantNotes", params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleDrawer(false);
            getNotesList();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Validations
  const validate = (values) => {
    if (!values.description) {
      errors.description = validations?.description?.req;
    }

    return errors;
  };

  return (
    <div className="tab-container">
      {/* Add Note Form */}
      {openSidebar ? (
        <AddEditNote
          openForm={openSidebar}
          toggleForm={toggleDrawer}
          handleSubmit={handleSubmit}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          edit={edit}
          applicantId={applicantData.applicantId}
        />
      ) : null}

      {notesList.length > 0 ? (
        <>
          <div className="notes-header-container p-0-16 mt-15">
            <span className="heading-label font-blue">Notes({notesList.length})</span>
            <ButtonUI
              p="8px"
              radius="4px"
              onClick={() => (
                toggleDrawer(true), setFormValues({}), setFormErrors({})
              )}
            >
              Add Note
            </ButtonUI>
          </div>
          <div className="notes-cards-container p-16">
            {notesList?.map((note) => (
              <div className="notes-card" key={note.notesId}>
                <div className="card-header">
                  <span className="name-label">{note.userName}</span>
                  <div className="header-detail">
                    <span className="header-labels">
                      {moment(note.notesDate).format("MM/DD/YYYY")}
                    </span>
                    <div className="dot-div" />
                    <span className="header-labels">
                      {moment(note.notesDate).format("LT")}
                    </span>
                  </div>
                  <EditDeleteMenu
                    onEdit={() => onEdit(note)}
                    onDelete={() => onDelete(note)}
                    id={note}
                  />
                </div>
                <div className="notes-desc">{note.description}</div>
              </div>
            ))}
          </div>
        </>
      ) : isLoader === true ? (
        <ColoredLinearProgress />
      ) : (
        <>
          <div className="notes-header-container p-16">
            <span className="heading-label font-blue">Notes({notesList.length})</span>
            <ButtonUI
              p="8px"
              radius="4px"
              onClick={() => (
                toggleDrawer(true), setFormValues({}), setFormErrors({})
              )}
            >
              Add Note
            </ButtonUI>
          </div>
          <NoColDataFound />
        </>
      )}
    </div>
  );
};
