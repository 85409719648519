import { AddEditPopup } from "../../../addEditPopup";

export const UserOverview = ({
  formValues,
  setCreateForm,
  handleSubmit,
  toggleDrawer,
  usersRoles,
  openSidebar,
  disable,
}) => {
  // Filter names based on userroleid value
  const role_name = usersRoles.filter((col) => {
    return formValues?.roles?.find((selected) => selected === col.roleId);
  });

  return (
    <AddEditPopup
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
      openSidebar={openSidebar}
    >
      <div className="forms-inputfill">
        <label className="note-label">
          Review the following user information and click Create.
        </label>
      </div>
      <div className="forms-inputfill">
        <label className="company_label">
          {localStorage.getItem("companyName")}
        </label>
      </div>
      <div className="forms-inputfill">
        <label>Title:</label>
        <span>{formValues.user_title}</span>
      </div>
      <div className="forms-inputfill">
        <label>Login ID:</label>
        <span>{formValues.login_id}</span>
      </div>
      <div className="forms-inputfill">
        <label>Email Address:</label>
        <span>{formValues.email_id}</span>
      </div>
      <div className="forms-inputfill">
        <label>Cell Phone:</label>
        <span>{formValues.cell_phone}</span>
      </div>
      <div className="forms-inputfill">
        <label>Time Zone:</label>
        <span>{formValues.timeZone}</span>
      </div>
      <div className="forms-inputfill">
        <label>User Role:</label>
        <span>
          {role_name.map((roles, i, arr) =>
            arr.length - 1 === i ? roles.roleName + "." : roles.roleName + ", "
          )}
        </span>
      </div>
      <div className="forms-inputfill">
        <label>Job Access: </label>
        <span>{formValues.jobAccess}</span>
      </div>
      <div className="forms-inputfill d-flex flex-column">
        <div className="d-flex flex-column">
          {formValues?.jobAccessData?.map((jobs) => (
            <div className="d-flex flex-column" key={jobs.id}>
              <span className="main-desc">{jobs.name}</span>
              {jobs.address ? (
                <span className="sub-desc">{jobs.address}</span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </AddEditPopup>
  );
};
