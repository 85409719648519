import { COMPANY_DATA_ACTIONS } from './actions';

const initialState = {
    companyData: {}
}

export const companyDataReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case COMPANY_DATA_ACTIONS.SET_COMP_DATA:
            return {...state, companyData: payload};
            
        default:
            return state;
    }
}
