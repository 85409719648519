import { AddEditPopup } from "../../../addEditPopup";
import { ApplyForJob } from "../../ApplyForJob";
import { useEffect, useState } from "react";
import { validations } from "../../../../../helper/validations";

export const AddApplicantForm = (props) => {
  const [formErrors, setFormErrors] = useState({});
  const errors = {};

  const validate = (values) => {
    if (!values.firstName) {
      errors.firstName = validations?.fname?.req;
    }

    if (!values.emailAddress) {
      errors.emailAddress = validations?.email?.req;
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = validations?.phoneNumber?.req;
    }

    return errors;
  };

  useEffect(() => {
    setFormErrors({});
  }, [props.toggleDrawer]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(props.formValues));
    if (Object.keys(validate(props.formValues)).length > 0) {
      return;
    }

    props.handleAddApplicant();
  };

  return (
    <AddEditPopup
      title="New Applicant JOB"
      toggleDrawer={props.toggleDrawer}
      handleSubmit={handleSubmit}
      openSidebar={props.openAddForm}
      type="jobTemplate"
    >
      <ApplyForJob
        formValues={props.formValues}
        setFormValues={props.setFormValues}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
      />
    </AddEditPopup>
  );
};
