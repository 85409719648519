export const DarkSuitCaseIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="8" fill="#3954A5" />
      <path
        d="M30.625 14H26.2491V13.125C26.2484 12.4291 25.9715 11.7618 25.4794 11.2697C24.9873 10.7776 24.3201 10.5008 23.6241 10.5H18.3741C17.6782 10.5008 17.011 10.7776 16.5189 11.2697C16.0268 11.7618 15.7499 12.4291 15.7491 13.125V14H11.375C10.911 14.0005 10.4662 14.1851 10.1381 14.5131C9.81008 14.8412 9.62553 15.286 9.625 15.75V29.75C9.62553 30.214 9.81008 30.6588 10.1381 30.9869C10.4662 31.3149 10.911 31.4995 11.375 31.5H30.625C31.089 31.4995 31.5338 31.3149 31.8619 30.9869C32.1899 30.6588 32.3745 30.214 32.375 29.75V15.75C32.3745 15.286 32.1899 14.8412 31.8619 14.5131C31.5338 14.1851 31.089 14.0005 30.625 14ZM17.4991 15.75H24.4991V29.75H17.4991V15.75ZM17.4991 13.125C17.4994 12.893 17.5917 12.6706 17.7557 12.5066C17.9198 12.3425 18.1422 12.2503 18.3741 12.25H23.6241C23.8561 12.2503 24.0785 12.3425 24.2426 12.5066C24.4066 12.6706 24.4989 12.893 24.4991 13.125V14H17.4991V13.125Z"
        fill="white"
      />
    </svg>
  );
};
