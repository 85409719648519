import React, { useState } from "react";
import { LinkCalIcon, CircleIcon } from "../../../icons";
import { FullCal } from "../../../components/ui/pickers/fullCal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { ButtonUI } from "../../../components/ui/button";
import { EditTask } from "./editTask";
import FormControl from "@mui/material/FormControl";
import {
  Autocomplete,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { EditAvailability } from "./editAvailability";
import SyncCalDialogBox from "../../../components/common/dialogBox/syncCalDialogBox";
import { EditDeleteMenu } from "../../../components/ui/editDeleteMenu";
import BackgroundLetterAvatars from "../../../components/common/layouts/BackgroundLetterAvatars";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import moment from "moment";
import { NoColDataFound } from "../../../components/common/NoColDataFound";
import { ColoredLinearProgress } from "../../../components/common/coloredProgressBar";
import Loader from "../../../components/common/Loader";

export function Calendar() {
  // Tab Value
  const [value, setValue] = useState(0);
  const [openSidebar, setSidebar] = useState(false);
  const [newTask, setNewTask] = useState(false);
  const [newAvail, setNewAvail] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [label, setLabel] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [open, setOpen] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [users, setUsers] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [pageNumber, setPage] = useState(0);
  const [userId, setUserId] = useState(localStorage.getItem("user"));
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [formErrors, setFormErrors] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [allTasks, setAllTasks] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [monthlyTasks, setMonthlyTasks] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);

  const CustomTab = styled(Tab)`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: normal;
    text-transform: capitalize;

    &.Mui-selected {
      font-weight: 700;
      color: #333;
    }
  `;

  useEffect(() => {
    setPageLoading(true);
    Api(
      "GET",
      `Calendar/GetCalendarUserList?companyId=${companyData.companyId}`
    )
      .then((res) => {
        const data = [...res.data];
        data.forEach((rec) => {
          rec.label = rec.userFirstName + " " + rec.userLastName;
          if (rec.userId === localStorage.getItem("user")) {
            setUserInfo(rec);
          }
        });

        setUsers(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPageLoading(false);
      });

    // getAvailability(date, userId);
  }, []);

  useEffect(() => {
    getTasks();
  }, [userInfo, selectedDate]);

  useEffect(() => {
    getAllTasks();
  }, [userInfo]);

  const getAvailability = () => {
    Api(
      "GET",
      `Calendar/GetAvailabilityList?scheduledDate=${selectedDate}&userId=${userInfo.userId}&pageLimit=12&pageNumber=${pageNumber}`
    )
      .then((res) => {
        setAvailabilities(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTasks = () => {
    setLoader(true);
    Api(
      "GET",
      `Calendar/GetTaskList?scheduledDate=${selectedDate}&companyId=${companyData.companyId}&userId=${userInfo.userId}&pageLimit=6&pageNumber=1`
    )
      .then((res) => {
        setAllTasks(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getAllTasks = () => {
    Api(
      "GET",
      `Calendar/GetAllTaskList?scheduledDate=${new Date(
        "2023-01-01"
      ).toISOString()}&companyId=${companyData.companyId}&userId=${
        userInfo.userId
      }`
    )
      .then((res) => {
        const data = [...res.data.data];
        data.forEach((rec) => {
          rec.start = new Date(rec.scheduledDate);
          rec.title = rec.name + " " + rec.time;
        });

        setMonthlyTasks(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //seperate file
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div className="h-100">{children}</div>}
      </div>
    );
  }

  //seperate file
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  //seperate file
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleEdit = (id) => {
    toggleDrawer("right", true);
    setLabel("Edit Task");
    setEditMode(true);
    setNewAvail(false);
    setNewTask(true);
    setNewUser(false);

    const data = [...allTasks];
    var rec = data.find((d) => d.calendarId === id);

    rec.users = [];
    rec.timeHour = rec?.time.split(" ")[0].split(":")[0];
    rec.timeMinute = rec?.time.split(" ")[0].split(":")[1];
    rec.timeAMPM = rec?.time.split(" ")[1].toLowerCase();

    rec.userName.map((u, index) => {
      rec.users.push({
        userId: rec.userId[index],
        userFirstName: u.split(" ")[0],
        userLastName: u.split(" ")[1],
      });
    });

    setFormValues(rec);
  };

  const handleDelete = (id) => {
    var ans = window.confirm("Are your sure you want to delete this task ?");
    if (ans) {
      Api("DELETE", `Calendar/DeleteTask?calendarId=${id}`)
        .then((res) => {
          if (res.data.isSuccess === true) {
            getTasks();
            getAllTasks();
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Availability methods
  const saveAvailability = (fields) => {
    if (validate(fields).length > 0) {
      return;
    }

    // console.log(availability);

    // const params = {
    //   companyId: companyData.companyId,
    //   userId: userId,
    //   scheduledDate: date,
    //   location: location,
    //   availabilityTimeSlotViewModels: availability,
    // };

    // console.log(params);

    // Api("POST", `Calendar/AddAvailability`, params)
    //   .then((res) => {
    //     if (res.data.isSuccess === true) {
    //       toast.success(res.data.message);
    //       getAvailability(date, userId);
    //       toggleDrawer("right", false);
    //     } else {
    //       toast.error(res.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const deleteAvailability = (id) => {
    var answer = window.confirm(
      "Are you sure you want to delete this availability ?"
    );

    if (answer) {
      Api("DELETE", `Calendar/DeleteAvailability?availabilityId=${id}`)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            getAvailability(selectedDate, userInfo.userId);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const editAvail = (id) => {
    const params = {
      availabilityId: id,
      companyId: companyData.companyId,
      userId: userInfo.userId,
      scheduledDate: selectedDate,
      // location:
      // availabilityTimeSlotViewModels,
    };

    Api("POST", `Calendar/EditAvailability`, params)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openEditForm = () => {
    toggleDrawer("right", true);
    setLabel("Edit Availability");
    setEditMode(true);
    setFormValues({});
    setNewTask(false);
    setNewAvail(true);
  };

  const validate = (values) => {
    console.log(values);
  };

  const addTask = (userIds) => {
    const userForTasks = formValues?.usersForTasks
      ? formValues?.usersForTasks
      : [];
    const data = [...userForTasks, ...userIds];

    const params = {
      companyId: companyData.companyId,
      userId: userInfo.userId,
      scheduledDate: selectedDate,
      name: formValues?.name ?? "",
      timeHour: formValues?.timeHour ?? 0,
      timeMinute: formValues?.timeMinute ?? 0,
      timeAMPM: formValues?.timeAMPM ?? "",
      duration: formValues?.duration,
      notes: formValues?.notes ?? "",
      usersForTasks: data,
    };

    Api("POST", `Calendar/AddTask`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
          setNewTask(false);
          getTasks();
          getAllTasks();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTask = (userIds) => {
    const userForTasks = formValues?.usersForTasks
      ? formValues?.usersForTasks
      : [];
    const data = [...userIds, ...userForTasks];

    const params = {
      calendarId: formValues.calendarId,
      companyId: companyData.companyId,
      userId: userInfo.userId,
      scheduledDate: moment(formValues.scheduledDate).format("YYYY-MM-DD"),
      name: formValues?.name ?? "",
      timeHour: +formValues?.timeHour ?? 0,
      timeMinute: +formValues?.timeMinute ?? 0,
      timeAMPM: formValues?.timeAMPM ?? "",
      duration: formValues?.duration,
      notes: formValues?.notes ?? "",
      usersForTasks: data,
    };

    Api("POST", `Calendar/EditTask`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
          setNewTask(false);
          getTasks();
          getAllTasks();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUserChange = (value) => {
    if (value !== null) {
      setUserInfo(value);
    }
  };

  return (
    <div className="company_content help-container applicants">
      {openSidebar && newTask ? (
        <EditTask
          openSidebar={openSidebar}
          toggleDrawer={toggleDrawer}
          formValues={formValues}
          setFormValues={setFormValues}
          label={label}
          editMode={editMode}
          setNewUser={setNewUser}
          newUser={newUser}
          users={users}
          formErrors={formErrors}
          addTask={addTask}
          updateTask={updateTask}
        />
      ) : null}

      {openSidebar && newAvail ? (
        <EditAvailability
          openSidebar={openSidebar}
          toggleDrawer={toggleDrawer}
          formValues={formValues}
          setFormValues={setFormValues}
          label={label}
          editMode={editMode}
          saveAvailability={saveAvailability}
        />
      ) : null}

      {isPageLoading === true ? (
        <Loader inShow={true} />
      ) : (
        <>
          <div className="top-url">
            <div className="content_header">
              <div className="company_text mr-auto">Calendar</div>
              <FormControl sx={{ minWidth: 300 }} size="small">
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={users}
                  defaultValue={
                    userInfo.userFirstName + " " + userInfo.userLastName
                  }
                  onChange={(event, value) => handleUserChange(value)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="User" />
                  )}
                />
              </FormControl>
              <SyncCalDialogBox open={open} onClose={handleClose} />
            </div>
          </div>
          <div className="common-container users company">
            <div className="inner-container company calendar">
              <div className="user-container company">
                <div className="w-100 h-100 position-relative d-flex gap-20">
                  <div className="user-list">
                    <div className="border-bottom p-0-24 pb-10">
                      <div className="d-flex align-items-center">
                        <BackgroundLetterAvatars
                          name={
                            userInfo?.userFirstName +
                            " " +
                            userInfo?.userLastName
                          }
                        />
                        <div className="col-flex">
                          <label className="heading-label">
                            {userInfo?.userFirstName +
                              " " +
                              userInfo?.userLastName}
                          </label>
                        </div>
                      </div>
                    </div>
                    {isLoader === true ? (
                      <ColoredLinearProgress />
                    ) : allTasks.length > 0 ? (
                      <div className="inner-h overflow-unset">
                        <div>
                          {/* <Tabs
                      value={value}
                      aria-label="Tab"
                      onChange={handleChangeTab}
                    >
                      <CustomTab label="Tasks" {...a11yProps(0)} />
                      <CustomTab label="Availability" {...a11yProps(1)} />
                    </Tabs> */}
                        </div>
                        {/* <TabPanel
                          value={value}
                          index={0}
                          className="tab-panel-calendar"
                        > */}
                        <div className="tab-panel-calendar">
                          {allTasks.map((task) => (
                            <div className="calendar-card">
                              <div className="doc-detail">
                                <div>
                                  <CircleIcon
                                    className="mr-10"
                                    fill="#34AA44"
                                  />
                                  <span className="cal-title">
                                    {task.time} ({task.duration} minutes)
                                  </span>
                                </div>
                                {new Date().setHours(0, 0, 0, 0) <=
                                  new Date(selectedDate).setHours(
                                    0,
                                    0,
                                    0,
                                    0
                                  ) && (
                                  <EditDeleteMenu
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                    id={task.calendarId}
                                  />
                                )}
                              </div>
                              <span className="cal-title">
                                {task.scheduledDate}
                              </span>
                              <strong className="mt-10">{task.name}</strong>
                              <label className="mt-5">{task.notes}</label>
                              <p>{task.userName.join(", ")}</p>
                            </div>
                          ))}
                        </div>
                        <div>
                          {new Date().setHours(0, 0, 0, 0) <=
                            new Date(selectedDate).setHours(0, 0, 0, 0) && (
                            <ButtonUI
                              p="12px"
                              mt="40px"
                              width="298px"
                              onClick={() => (
                                toggleDrawer("right", true),
                                setLabel("Add New Task"),
                                setEditMode(false),
                                setFormValues({}),
                                setNewAvail(false),
                                setNewTask(true),
                                setNewUser(false)
                              )}
                            >
                              Add New Task
                            </ButtonUI>
                          )}
                        </div>
                        {/* </TabPanel> */}

                        {/* Availability section start */}
                        <TabPanel
                          value={value}
                          index={1}
                          className="tab-panel-calendar"
                        >
                          <div className="tab-panel-calendar">
                            {availabilities.length > 0 ? (
                              <>
                                {availabilities?.map((rec) => (
                                  <div className="calendar-card">
                                    <div className="doc-detail">
                                      <div>
                                        <CircleIcon
                                          className="mr-10"
                                          fill="#F6AB2F"
                                        />
                                        <span className="cal-title">
                                          {rec.scheduledDate}
                                        </span>
                                      </div>
                                      <EditDeleteMenu
                                        onEdit={openEditForm}
                                        onDelete={deleteAvailability}
                                        id={rec.availabilityId}
                                      />
                                    </div>
                                    <p>{rec.locationName}</p>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <NoColDataFound />
                            )}
                          </div>
                          <div className="">
                            <ButtonUI
                              p="12px"
                              mt="40px"
                              width="298px"
                              onClick={() => (
                                toggleDrawer("right", true),
                                setLabel("Add Availability"),
                                setEditMode(false),
                                setFormValues({}),
                                setNewTask(false),
                                setNewAvail(true)
                              )}
                            >
                              Add Availability
                            </ButtonUI>
                          </div>
                        </TabPanel>
                        {/* Availability section end */}
                      </div>
                    ) : (
                      <div className="inner-h overflow-unset">
                        <div className="tab-panel-calendar">
                          <NoColDataFound />
                          <div>
                            {new Date().setHours(0, 0, 0, 0) <=
                              new Date(selectedDate).setHours(0, 0, 0, 0) && (
                              <ButtonUI
                                p="12px"
                                width="298px"
                                mt="220px"
                                onClick={() => (
                                  toggleDrawer("right", true),
                                  setLabel("Add New Task"),
                                  setEditMode(false),
                                  setFormValues({}),
                                  setNewAvail(false),
                                  setNewTask(true),
                                  setNewUser(false)
                                )}
                              >
                                Add New Task
                              </ButtonUI>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="user-detail position-relative overflow-unset">
                    <div>
                      <FullCal
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        userId={userInfo?.userId}
                        monthlyTasks={monthlyTasks}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
