import { AddEditPopup } from "../../common/addEditPopup";
import { FormGroup } from "@mui/material";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckboxWithBlueCheck } from "../../ui/checkbox/checkboxWithBlueTick";
import { useState } from "react";
import { useEffect } from "react";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";

export const EditJobTemplate = (props) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [companyList, setCompanyList] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [rateTypes, setRateTypes] = useState([]);
  const [shiftTypes, setShiftTypes] = useState([]);
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [interviewList, setInterviewList] = useState([]);

  const getAllApiData = async () => {
    try {
      const [
        allCompanies,
        allFacilities,
        allDepartments,
        allSources,
        allInterviews,
      ] = await Promise.all([
        Api(
          "GET",
          `Companies/GetCompany?parentCompanyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
        ),
        Api(
          "GET",
          `Facilities/GetFacilitiesList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
        ),
        Api(
          "GET",
          `Department/GetDepartmentList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
        ),
        Api(
          "GET",
          `Sources/GetSourceList?type=all&companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
        ),
        Api(
          "GET",
          `VideoInterview/GetVideoInterviewList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
        ),
      ]);

      const companyRecord = {
        companyId: companyData.companyId,
        name: localStorage.getItem("companyName"),
      };

      allCompanies.data.data.push(companyRecord);
      setCompanyList(allCompanies?.data?.data);
      setFacilities(allFacilities?.data?.data);
      setDepartments(allDepartments?.data);
      setInterviewList(allInterviews?.data?.data);

      const sourcesData = allSources.data.reduce(function (rv, x) {
        (rv[x["type"]] = rv[x["type"]] || []).push(x);
        return rv;
      }, {});

      setRateTypes(sourcesData?.ratetypes);
      setShiftTypes(sourcesData?.shifttypes);
      setScheduleTypes(sourcesData?.scheduletypes);
      setEmploymentTypes(sourcesData?.employmenttypes);
    } catch {
      throw Error("Promise failed");
    }
  };

  useEffect(() => {
    getAllApiData();
  }, [props.openSidebar]);

  const onCheckBoxChange = (checkName, isChecked) => {
    props.setFormValues({ ...props.formValues, [checkName]: isChecked });
  };

  const handleChange = (e) => {
    props.setFormValues({
      ...props.formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <AddEditPopup
      title={props.label}
      openSidebar={props.openSidebar}
      toggleDrawer={props.toggleDrawer}
      handleSubmit={props.handleSubmit}
      type="jobTemplate"
    >
      <div className="forms-input sections">
        <div className="col-2">
          <label>Company:</label>
          <select
            name="companyId"
            id="companyId"
            onChange={handleChange}
            value={props.formValues?.companyId}
          >
            <option>Select</option>
            {companyList?.map((company) => (
              <option value={company.companyId} key={company.companyId}>
                {company.name}
              </option>
            ))}
          </select>
          {props.formErrors.companyId ? (
            <span className="error_msg">{props.formErrors.companyId}</span>
          ) : null}
        </div>
        <div className="col-2">
          <label>Status:</label>
          <select
            name="isActive"
            id="status"
            value={props.formValues?.isActive}
            onChange={handleChange}
          >
            <option>Select</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        </div>
      </div>
      <div className="forms-input">
        <div className="template-section">
          <label>Template Name:</label>
        </div>
        <input
          type="text"
          placeholder="Enter a name"
          name="name"
          id="template"
          value={props.formValues?.name}
          onChange={handleChange}
        />
        {props.formErrors.name ? (
          <span className="error_msg">{props.formErrors.name}</span>
        ) : null}
      </div>
      <div className="forms-input">
        <label>Description:</label>
        <textarea
          name="description"
          placeholder="Description here"
          id="description"
          cols="20"
          rows="4"
          value={props.formValues?.description}
          onChange={handleChange}
        ></textarea>
        {props.formErrors.description ? (
          <span className="error_msg">{props.formErrors?.description}</span>
        ) : null}
      </div>
      <div className="forms-input">
        <FormGroup>
          <FormControlLabel
            control={
              <CheckboxWithBlueCheck
                disableRipple
                checked={!!props.formValues?.excemptOverTime}
                onChange={(e) =>
                  onCheckBoxChange("excemptOverTime", e.target.checked)
                }
                value="excemptOverTime"
                key="excemptOverTime"
              />
            }
            label="Exempt from overtime"
          />
        </FormGroup>
      </div>
      <div className="forms-input">
        <label>Facility:</label>
        <select
          name="facilityId"
          id="facilityId"
          onChange={handleChange}
          value={props.formValues?.facilityId}
        >
          <option value="none">Select</option>
          {facilities?.map((facility) => (
            <option value={facility.facilitiesId} key={facility.facilitiesId}>
              {facility.name}
            </option>
          ))}
        </select>
      </div>
      <div className="forms-input sections">
        <div className="col-2">
          <label>Department:</label>
          <select
            name="departmentId"
            id="department"
            onChange={handleChange}
            value={props.formValues?.departmentId}
          >
            <option>Select</option>
            {departments?.map((dept) => (
              <option value={dept.departmentId} key={dept.departmentId}>
                {dept.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <label>Rate:</label>
          <input
            type="text"
            placeholder="Rate here"
            name="rate"
            id="rate"
            value={props.formValues?.rate}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="forms-input sections">
        <div className="col-2">
          <label>Rate Type:</label>
          <select
            name="rateTypeId"
            id="rate_type"
            onChange={handleChange}
            value={props.formValues?.rateTypeId}
          >
            <option>Select</option>
            {rateTypes?.map((rate) => (
              <option value={rate.sourceId} key={rate.sourceId}>
                {rate.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <label>Shift Type:</label>
          <select
            name="shiftTypeId"
            id="shift_type"
            onChange={handleChange}
            value={props.formValues?.shiftTypeId}
          >
            <option>Select</option>
            {shiftTypes?.map((shift) => (
              <option value={shift.sourceId} key={shift.sourceId}>
                {shift.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="forms-input sections">
        <div className="col-2">
          <label>Schedule Type:</label>
          <select
            name="scheduleTypeId"
            id="schedule_type"
            onChange={handleChange}
            value={props.formValues?.scheduleTypeId}
          >
            <option>Select</option>
            {scheduleTypes?.map((schedule) => (
              <option value={schedule.sourceId} key={schedule.sourceId}>
                {schedule.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <label>Employment Type:</label>
          <select
            name="employmentTypeId"
            id="emp_type"
            onChange={handleChange}
            value={props.formValues?.employmentTypeId}
          >
            <option>Select</option>
            {employmentTypes?.map((emp) => (
              <option value={emp.sourceId} key={emp.sourceId}>
                {emp.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="forms-input sections">
        <div className="col-2">
          <label>Video Interview:</label>
          <select
            name="videoInterviewId"
            id="video_interview"
            onChange={handleChange}
            value={props.formValues?.videoInterviewId}
          >
            <option>Select</option>
            {interviewList?.map((video) => (
              <option
                value={video.videoInterviewId}
                key={video.videoInterviewId}
              >
                {video.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-2">
          <label>Previous Employee:</label>
          <input
            type="text"
            placeholder="Enter an employee name"
            name="previousEmp"
            id="previousEmp"
            value={props.formValues?.previousEmp}
            onChange={handleChange}
          />
        </div>
      </div>
      <label>
        <span>Maximum Applicants Allowed </span>(leave blank for unlimited)
      </label>
      <div className="forms-input sections">
        <div className="col-2">
          <label>Total:</label>
          <input
            type="text"
            placeholder="Enter Total"
            name="totalApplication"
            id="totalApplication"
            value={props.formValues?.totalApplication}
            onChange={handleChange}
          />
        </div>
        <div className="col-2">
          <label>Per Job Board:</label>
          <input
            type="text"
            placeholder="Enter the allowed no."
            name="appPerJobBoard"
            id="per_job_board"
            value={props.formValues?.appPerJobBoard}
            onChange={handleChange}
          />
        </div>
      </div>
    </AddEditPopup>
  );
};
