import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Api from "../../../helper/Api";
import { FilesIcon, CloseXIcon } from "../../../icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

export const ApplyForJob = ({ ...props }) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [sources, setSources] = useState([]);
  const attachRef = useRef("null");
  const [attachment, setAttachment] = useState("");
  const [docData, setDocData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    props.setFormValues({ ...props.formValues, [name]: value });
  };

  const openAttachment = () => {
    attachRef.current.click();
  };

  const handleResume = (e) => {
    props.setFormValues({ ...props.formValues, ["resume"]: e.target.files[0] });
    setDocData({ ...docData, ["resume"]: e.target.files[0] });
    setAttachment(e.target.files[0].name);
  };

  useEffect(
    () =>
      Api(
        "GET",
        `Sources/GetSourceList?type=applicantsources&companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
      )
        .then((res) => {
          setSources(res.data);
        })
        .catch((err) => {
          console.log(err);
        }),
    []
  );

  return (
    <div>
      <div className="forms-input">
        <input
          type="file"
          ref={attachRef}
          hidden
          onChange={handleResume}
          key={attachment}
          accept="image/png, image/jpeg, image/jpg, .doc, .docx, .pdf"
        />

        {attachment ? (
          <div className="doucments-card applicant">
            <div className="doc-detail">
              <FilesIcon
                stroke="#9EA0A5"
                line="#fff"
                width="32px"
                height="32px"
              />
              <div className="file-name-label">{attachment}</div>
            </div>
            <div
              className="doc-detail"
              role="button"
              onClick={() => setAttachment("")}
            >
              <div className="pointer">
                <CloseXIcon width="28px" height="28px" />
              </div>
            </div>
          </div>
        ) : (
          <div
            role="button"
            className="document applicant pointer"
            onClick={() => openAttachment()}
          >
            <div className="documents-upload">
              <span>Upload Document</span>
              <CloudUploadOutlinedIcon width="32" height="32" />
            </div>
          </div>
        )}
        <span className="success_msg">
          * Allowed File types are .doc, .dcox, .pdf, .jpg, .jpeg, .png
        </span>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={props.formValues.firstName}
            placeholder="Name here"
            onChange={handleChange}
          />
          <span className="error_msg">{props.formErrors.firstName}</span>
        </div>
        <div className="col-2">
          <label>Middle Name:</label>
          <input
            type="text"
            name="middleName"
            id="middleName"
            value={props.formValues.middleName}
            placeholder="Name here"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            value={props.formValues.lastName}
            placeholder="Name here"
            onChange={handleChange}
          />
        </div>
        <div className="col-2">
          <label>Nick Name:</label>
          <input
            type="text"
            id="nickName"
            name="nickName"
            value={props.formValues.nickName}
            placeholder="Name here"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Title:</label>
          <input
            type="text"
            name="title"
            id="title"
            value={props.formValues.title}
            placeholder="Title here"
            onChange={handleChange}
          />
        </div>
        <div className="col-2">
          <label>Email Address:</label>
          <input
            type="text"
            id="emailAddress"
            name="emailAddress"
            value={props.formValues.emailAddress}
            placeholder="Email here"
            onChange={handleChange}
          />
          <span className="error_msg">{props.formErrors.emailAddress}</span>
        </div>
      </div>

      <div className="forms-input">
        <label>Address</label>
        <input
          type="text"
          name="addressLineOne"
          id="address"
          value={props.formValues?.address?.addressLineOne}
          placeholder="Address here"
          onChange={handleChange}
        />
      </div>

      <div className="forms-input sections">
        <div className="col-3">
          <input
            type="text"
            id="address1"
            name="city"
            value={props.formValues?.address?.city}
            placeholder="City here"
            onChange={handleChange}
          />
        </div>
        <div className="col-3">
          <input
            type="text"
            id="address2"
            name="state"
            value={props.formValues?.address?.state}
            placeholder="State here"
            onChange={handleChange}
          />
        </div>
        <div className="col-3">
          <input
            type="text"
            id="address3"
            name="zipCode"
            value={props.formValues?.address?.zipCode}
            placeholder="Zipcode here"
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Cell Phone:</label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            value={props.formValues.phoneNumber}
            placeholder="Cell Phone here"
            onChange={handleChange}
          />
          <span className="error_msg">{props.formErrors.phoneNumber}</span>
        </div>
        <div className="col-2">
          <label>Source:</label>
          <select
            id="source"
            name="source"
            onChange={handleChange}
            value={props.formValues.source}
          >
            <option>Select</option>
            {sources.map((source) => (
              <option value={source.sourceId} key={source.sourceId}>
                {source.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
