import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DropzoneArea } from "material-ui-dropzone";
import NoRecordsFound from "../../common/NoRecordsFound";
import { toast } from "react-toastify";
import OutsideClickHandler from "react-outside-click-handler";
import Loader from "../../common/Loader";
import Api from "../../../helper/Api";
import "../../../assets/css/components/company-detail/_appointments.css";
import { StyledTableCell, StyledTableRow } from "../../ui/table/table";
import { ButtonUI } from "../../ui/button";
import AddIcon from "@mui/icons-material/Add";

export function SubCompanies() {
  const location = useLocation();
  const [view, setView] = useState("list");
  const [showActions, setEditActions] = useState();
  const [companyId, setCompanyId] = useState(0);
  const [showPopup, setPopup] = useState(false);
  const [sortType, setSorted] = useState("desc");
  const [isLoader, setIsLoader] = useState(false);
  const [allTimeZone, setAllTimeZone] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [editImageShow, setEditImageShow] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("");
  const initialValues = {
    name: "",
    jobPrefix: "",
    emailAddress: "",
    timeZone: "",
    companyLogo: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const errors = {};
  const pageLimit = 500;

  let navigate = useNavigate();

  const getSubCompanyData = (sortType, sortColumnName) => {
    Api(
      "GET",
      `companies/getcompany?pageLimit=${pageLimit}&parentCompanyId=${location.state.companyId}&pageNumber=1&OrderbyDirection=${sortType}&OrderBy=${sortColumnName}&companyHirerchy=${location.state.companyHirerchy}`
    )
      .then((response) => {
        if (response.data.data.length) {
          setRows(response.data.data);
        } else {
          setRows([]);
        }
        setIsLoader(false);
      })
      .catch(() => {
        setIsLoader(false);
      });
  };

  // Get timezone dropdown data
  const getTimeZone = () => {
    Api("GET", "TimeZone/GetTimeZone?isActive=true")
      .then((response) => {
        if (response.data) {
          setAllTimeZone(response.data);
          setIsLoader(false);
        } else {
          setAllTimeZone([]);
          setIsLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (searchInput == "") {
      setIsLoader(true);
      getTimeZone();
      getSubCompanyData("desc", "updatedAt", searchInput);
    }
  }, [searchInput]);

  // On every search input added
  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  // Return searched records
  const getSearchCompany = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      let data = rows.filter((subCompany) =>
        subCompany.name.toLowerCase().match(searchInput.toLowerCase())
      );
      setRows(data);
    }
  };

  // Perticular card wise actions box open
  const handleClick = (id) => {
    setEditActions(id !== showActions ? id : null);
  };

  // On every input change (Add and Edit form)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Sort data
  function handleSort(columnType) {
    const sortOrder = sortType === "asc" ? "desc" : "asc";
    setSorted(sortOrder);
    getSubCompanyData(sortType, columnType, "");
  }

  // Open Add form
  const addDataTable = () => {
    setEditImageShow(false);
    setEditMode(false);
    setFormValues({});
    setAddEditLabel("Add Sub Company");
    setShowSideBar(true);
  };

  // On click of actions buttons returns individual id
  function getActionId(type, compId) {
    if (type === "edit") {
      // Edit button clicked in - popup
      setEditMode(true);
      setEditImageShow(true);
      setAddEditLabel("Edit Sub Company");
      setCompanyId(compId);

      const listItemValue = rows.filter((items) => {
        return items.companyId === compId ? items : null;
      });

      setFormValues({
        ["name"]: listItemValue[0].name,
        ["jobPrefix"]: listItemValue[0].jobPrefix,
        ["emailAddress"]: listItemValue[0].emailAddress,
        ["timeZone"]: listItemValue[0].timeZone,
        ["companyLogo"]: listItemValue[0].companyImage,
      });
      setShowSideBar(true);
    } else if (type === "delete") {
      // Delete data of row table - popup clicked delte
      var answer = window.confirm("Are you sure you want to delete?");
      if (answer) {
        setIsLoader(true);
        // Delete comapny api call
        Api("DELETE", `Companies/DeleteCompany?companyId=${compId}`)
          .then((res) => {
            res.data.isSuccess == true
              ? toast.success(res.data.message)
              : toast.error(res.data.message);
            getSubCompanyData("desc", "updatedAt", "");
          })
          .catch((err) => {
            setIsLoader(false);
          });
      }
    }
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      getSubCompanyData("desc", "updatedAt", "");
      setEditMode(false);
      setFormValues("");
    }
  }, [formErrors]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearchCompany();
    }
  };

  // On submit
  const handleSubmit = (e) => {
    e.preventDefault();
    formValues.parentCompanyId = location.state.companyId;
    formValues.companyHirerchy = location.state.companyHirerchy;
    setFormErrors(validate(formValues));

    if (Object.keys(validate(formValues)).length == 0) {
      if (editMode) {
        formValues.companyId = companyId;
        Api("PUT", "Companies/EditCompany", formValues, "multipart")
          .then((res) => {
            if (res.data.isSuccess) {
              toast.success(res.data.message);
              setShowSideBar(false);
              setEditImageShow(false);
              setEditMode(false);
              getSubCompanyData("desc", "updatedAt", "");
            } else {
              setShowSideBar(true);
              setFormValues(formValues);
              setEditImageShow(true);
              setEditMode(true);
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Api("POST", "Companies/AddCompany", formValues, "multipart")
          .then((res) => {
            if (res.data.isSuccess === true) {
              toast.success(res.data.message);
              setShowSideBar(false);
              getSubCompanyData("desc", "updatedAt", "");
            } else {
              setShowSideBar(true);
              toast.error(res.data.message);
              setFormValues(formValues);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setIsSubmit(true);
    }
  };

  // Validations
  const validate = (values) => {
    if (!values.name) {
      errors.name = "Name is required.";
    } else if (!isNaN(values.name)) {
      errors.name = "Name must be a text!";
    }

    if (!values.jobPrefix) {
      errors.jobPrefix = "Job prefix is required.";
    } else if (!isNaN(values.jobPrefix)) {
      errors.jobPrefix = "Job prefix must be a text!";
    } else if (values.jobPrefix.length < 2 || values.jobPrefix.length > 5) {
      errors.jobPrefix = "Job prefix length must be between 2 to 5 characters!";
    }

    if (!editMode) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!values.emailAddress) {
        errors.emailAddress = "Email id is required!";
      } else if (!values.emailAddress.match(validRegex)) {
        errors.emailAddress = "Please enter a valid email address!";
      }
    }

    if (!values.timeZone) {
      errors.timeZone = "Time zone is required.";
    }

    if (!values.companyLogo) {
      errors.companyLogo = "Company Logo is required!";
    }

    return errors;
  };

  const formErrorClear = () => {
    formErrors.name = "";
    formErrors.jobPrefix = "";
    formErrors.emailAddress = "";
    formErrors.timeZone = "";
    formErrors.companyLogo = "";
  };

  const handleCellClick = (compId, compHierarchy) => {
    navigate("/sources", {
      state: { companyId: compId, companyHirerchy: compHierarchy },
    });
    window.location.reload(false);
  };

  const displayData = (method) => {
    setView(method);
  };

  // Card view
  const subCompaniesList =
    rows.length > 0 ? (
      rows.map((val, key) => {
        return (
          <div key={key} className="company_grid">
            <div
              className="grid_1"
              onClick={(e) =>
                handleCellClick(val.companyId, val.companyHirerchy)
              }
            >
              {val.companyImage != "" ? (
                <img
                  src={val.companyImage}
                  alt="logo"
                  className="company_list_logo"
                  width="80px"
                  height="70px"
                />
              ) : (
                <img
                  src={require("../../../assets/images/company_img.jpg")}
                  alt="logo"
                  className="company_list_logo"
                  width="80px"
                  height="70px"
                />
              )}
              <button type="submit" className="icon icon_button option_menu">
                <img
                  src={require("../../../assets/images/option_menu.png")}
                  alt="company-logo"
                  className=""
                  onClick={() => (
                    handleClick(val.companyId),
                    showPopup ? setPopup(false) : setPopup(true)
                  )}
                />
                {/* edit actions popup html */}
                {showActions === val.companyId && showPopup && (
                  <OutsideClickHandler
                    onOutsideClick={() => {
                      setPopup(false);
                    }}
                  >
                    {showPopup === true ? (
                      <div className="edit-popup">
                        <ul>
                          <li
                            key={val.companyId}
                            onClick={() => getActionId("edit", val.companyId)}
                          >
                            <img
                              src={require("../../../assets/images/edit.png")}
                              className="actionIcon"
                            />
                            <span>Edit</span>
                          </li>
                          <li
                            key={val.name}
                            onClick={() => getActionId("delete", val.companyId)}
                          >
                            <img
                              src={require("../../../assets/images/delete.png")}
                              alt="delete"
                              className="actionIcon"
                            />
                            <span>Delete</span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </OutsideClickHandler>
                )}
              </button>
            </div>
            <div className="grid_2 td_name">{val.name}</div>
            <div className="grid_3 grid_values">
              {val.jobPrefix}
              <br />
              <span className="grid_keys">Job Number Prefix</span>
            </div>
            <div className="grid_4 grid_values">
              {val.timeZoneOffSet}
              <br />
              <span className="grid_keys">Time Zone</span>
            </div>
          </div>
        );
      })
    ) : (
      <NoRecordsFound />
    );

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell
            component="th"
            scope="row"
            align="left"
            sx={{ cursor: "pointer" }}
            onClick={(e) => handleCellClick(row.companyId, row.companyHirerchy)}
          >
            {row.companyImage != "" ? (
              <img
                src={row.companyImage}
                alt="logo"
                className="company_list_logo"
                width="80px"
                height="70px"
                id={row.companyId}
              />
            ) : (
              <img
                src={require("../../../assets/images/company_img.jpg")}
                alt="logo"
                className="company_list_logo"
                id={row.companyId}
                width="80px"
                height="70px"
              />
            )}
            {row.name}
          </StyledTableCell>
          <StyledTableCell align="center">{row.jobPrefix}</StyledTableCell>
          <StyledTableCell align="center">{row.timeZoneOffSet}</StyledTableCell>
          <StyledTableCell align="right" sx={{ cursor: "pointer" }}>
            <img
              src={require("../../../assets/images/edit.png")}
              alt="edit"
              className="actionIcon"
              onClick={() => getActionId("edit", row.companyId)}
            />
            <img
              src={require("../../../assets/images/delete.png")}
              alt="delete"
              className="actionIcon"
              onClick={() => getActionId("delete", row.companyId)}
            />
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <div>
                  <p className="notes-table">Notes</p>
                  <p className="content-table">{row.note}</p>
                </div>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <div>
      {showSideBar ? <div className="back-shadow"></div> : null}
      {showSideBar ? (
        <div
          className={showSideBar ? "side-bar-parent active" : "side-bar-parent"}
        >
          <header>
            <span>{showAddEditLabel}</span>
            <img
              src={require("../../../assets/images/close.svg").default}
              alt="close-img"
              className="close-img"
              onClick={() => (
                setShowSideBar(false),
                setFormValues(initialValues),
                formErrorClear(),
                setEditImageShow(false)
              )}
            />
          </header>
          <form onSubmit={handleSubmit} className="inner-container">
            <div className="in-forms">
              <div className="forms-input">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Name here"
                  name="name"
                  id="name"
                  value={formValues.name}
                  onChange={handleChange}
                />
                {formErrors.name ? (
                  <span className="error_msg">{formErrors.name}</span>
                ) : null}
              </div>
              <div className="forms-input">
                <label>Job Number Prefix</label>
                <input
                  type="text"
                  placeholder="Job Prefix here"
                  name="jobPrefix"
                  id="jobPrefix"
                  value={formValues.jobPrefix}
                  onChange={handleChange}
                  readOnly={editMode}
                  style={{ backgroundColor: editMode ? "#DCDCDC" : "" }}
                />
                {formErrors.jobPrefix ? (
                  <span className="error_msg">{formErrors.jobPrefix}</span>
                ) : null}
              </div>
              {!editMode ? (
                <div className="forms-input">
                  <label>Email Address</label>
                  <input
                    type="text"
                    placeholder="Email Address here"
                    name="emailAddress"
                    id="emailAddress"
                    value={formValues.emailAddress}
                    onChange={handleChange}
                  />
                  {formErrors.emailAddress ? (
                    <span className="error_msg">{formErrors.emailAddress}</span>
                  ) : null}
                </div>
              ) : null}
              <div className="forms-input">
                <label>Timezone</label>
                <select
                  name="timeZone"
                  id="timeZone"
                  value={formValues.timeZone}
                  onChange={handleChange}
                >
                  <option value="none">Select</option>
                  {allTimeZone.map((time) => (
                    <option key={time.id} value={time.timeZone}>
                      {time.timeZone}
                    </option>
                  ))}
                </select>
                {formErrors.timeZone ? (
                  <span className="error_msg">{formErrors.timeZone}</span>
                ) : null}
              </div>
              {editImageShow ? (
                <div className="upload-file-shower">
                  <img
                    src={formValues.companyLogo}
                    alt="preview-img"
                    className="show-img"
                  />
                  <button
                    onClick={() => (
                      setEditImageShow(false), (formValues.companyLogo = "")
                    )}
                    className="icon icon_button"
                  >
                    <img
                      src={require("../../../assets/images/close-upload.png")}
                      alt="close"
                      className="close-img"
                    />
                  </button>
                </div>
              ) : (
                <div className="forms-input">
                  <label>Company logo:</label>
                  <DropzoneArea
                    acceptedFiles={["image/*"]}
                    dropzoneText={"Drag and drop an image here or click"}
                    onChange={(files) => (formValues.companyLogo = files[0])}
                    maxFileSize={500000}
                    filesLimit={1}
                  />
                  <span className="error_msg">{formErrors.companyLogo}</span>
                </div>
              )}
            </div>
            <div className="btns-container">
              <input type="submit" className="add-btn" value="Submit" />
              <button
                className="cancel-btn"
                type="reset"
                onClick={() => (
                  setShowSideBar(false),
                  setFormValues(initialValues),
                  formErrorClear(),
                  setEditImageShow(false)
                )}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : null}
      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>
                Sub-Companies {"(" + Object.keys(rows).length + ")"}
              </strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="content_header">
                  <div className="company_search">
                    <img
                      src={require("../../../assets/images/search_icon.png")}
                      alt="search-icon"
                      className="search_img"
                    />
                    <input
                      type="text"
                      placeholder="Search Sub Company"
                      className="search_input appointments"
                      name="search_input"
                      value={searchInput}
                      onChange={handleSearch}
                      onKeyPress={handleKeyPress}
                    />
                    <button onClick={getSearchCompany} className="search-btn">
                      Search
                    </button>
                  </div>
                  {view === "grid" ? (
                    <div className="company_sort">
                      <div className="sort_text">
                        <span className="text_light"> SORT:</span> A-Z
                      </div>
                      <button
                        className="icon icon_button"
                        onClick={() => handleSort("name")}
                      >
                        <img
                          src={require("../../../assets/images/drop_down.png")}
                          alt="sort-icon"
                          className="icon sort_img"
                        />
                      </button>
                    </div>
                  ) : null}
                  <div className="company_view">
                    <button
                      type="submit"
                      onClick={() => displayData("grid")}
                      className="icon icon_button"
                    >
                      {view === "grid" ? (
                        <img
                          src={require("../../../assets/images/select_grid_view.png")}
                          alt="grid-icon"
                        />
                      ) : (
                        <img
                          src={require("../../../assets/images/grid_view.png")}
                          alt="grid-icon"
                        />
                      )}
                    </button>
                    <button
                      type="submit"
                      onClick={() => displayData("list")}
                      className="icon icon_button"
                    >
                      {view === "list" ? (
                        <img
                          src={require("../../../assets/images/select_list_view.png")}
                          alt="list-icon"
                        />
                      ) : (
                        <img
                          src={require("../../../assets/images/list_view.png")}
                          alt="list-icon"
                        />
                      )}
                    </button>
                  </div>
                  <div className="company_refresh">
                    <button
                      type="submit"
                      onClick={() => (
                        getSubCompanyData("desc", "updatedAt", ""),
                        setSearchInput("")
                      )}
                      className="icon icon_button"
                    >
                      <img
                        src={require("../../../assets/images/refresh.png")}
                        alt="sync-icon"
                        className="icon"
                      />
                    </button>
                  </div>
                  <ButtonUI p="12px" ml="6px" onClick={addDataTable}>
                    Add
                  </ButtonUI>
                </div>
                {view === "list" ? (
                  <div className="manage-checkboxes-none position-relative">
                    <Loader inShow={isLoader} />
                    {rows.length > 0 ? (
                      <TableContainer
                        component={Paper}
                        className="box-shadow-none t-bg"
                      >
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">
                                Company Name:{" "}
                                <img
                                  src={
                                    require("../../../assets/images/tabsort.svg")
                                      .default
                                  }
                                  alt="sort-icon"
                                  className="cursor-pointer"
                                  onClick={() => handleSort("name")}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center" width="100px">
                                Job Prefix
                              </StyledTableCell>
                              <StyledTableCell align="center" width="100px">
                                Time zone
                              </StyledTableCell>
                              <StyledTableCell align="center" width="100px">
                                Action
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <Row key={row.companyId} row={row} />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <NoRecordsFound />
                    )}
                  </div>
                ) : (
                  <div className="company_card">{subCompaniesList}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
