import { LinkIcon } from "../../../icons";
import { Fragment, useEffect, useState } from "react";
import { URLDialogbox } from "../../common/dialogBox/urlDialogbox";
import { useSelector } from "react-redux";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";

export const JobCard = (props) => {
  const [statusClass, setStatusClass] = useState("status-label");
  const [priorityClass, setPriorityClass] = useState("status-label");
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("Dione");
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const urlJobData = window.btoa(
    "jobId=" + props.jobId + "&companyId=" + companyData.companyId
  );

  const url = window.location.origin + "/apply-job/" + urlJobData;
  const date_1 = new Date(props.createdAt);
  const date_2 = new Date();
  const days = Math.ceil(
    (date_2.getTime() - date_1.getTime()) / (1000 * 3600 * 24)
  );

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    switch (props.jobStatus) {
      case "ON_HOLD":
        setStatusClass("status-label red");
        break;
      case "ACTIVE":
        setStatusClass("status-label active");
        break;
      case "CANCELLED":
        setStatusClass("status-label");
        break;
      case "FILLED":
        setStatusClass("status-label brown");
        break;
      case "HIRING":
        setStatusClass("status-label lblue");
        break;
      case "PENDING":
        setStatusClass("status-label yellow");
        break;
      default:
        break;
    }

    let label =
      props.priority === "MEDIUM"
        ? "status-label yellow"
        : props.priority === "NORMAL"
        ? "status-label"
        : "status-label red";
    setPriorityClass(label);
  }, [props]);

  return (
    <Fragment>
      <div key={props.jobCode}>
        <URLDialogbox
          id="copy-url-box"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
          url={url}
          jobCode={props.jobCode}
        />
        <div className="j-card-header">
          <div>
            <span>{props.jobCode}</span>
            <LinkIcon height="20" width="20" onClick={handleClickListItem} />
          </div>

          {props.type === "card" ? (
            <div className="w-50">
              {props.jobStatus ? (
                <span className={statusClass}>
                  {props.jobStatus[0] +
                    props.jobStatus.slice(1).toLowerCase().replace("_", " ")}
                </span>
              ) : null}
              {props.priority ? (
                <span className={priorityClass}>
                  {props.priority[0] + props.priority.slice(1).toLowerCase()}
                </span>
              ) : null}
              <EditDeleteMenu
                id={props.jobId}
                onEdit={() => props.editAction(props.jobId)}
                deleteOption={false}
              />
            </div>
          ) : (
            <EditDeleteMenu
              id={props.jobId}
              onEdit={() => props.editAction(props.jobId)}
              deleteOption={false}
            />
          )}
        </div>
        <div className="j-card-content">
          <span className="title" onClick={() => props.cardClickEvent(props)}>
            {props.name}
          </span>
          <div
            onClick={() => props.cardClickEvent(props)}
            className={
              props.type === "sidebar" ? "show-more less" : "show-more"
            }
            dangerouslySetInnerHTML={{
              __html: props.description.substring(0, 101),
            }}
          />

          <div
            onClick={() => props.cardClickEvent(props)}
            className="job-posted"
          >
            <span>
              Positions : <label>{props.positions}</label>
            </span>
            <span>
              Days Posted : <label>{days}</label>
            </span>
          </div>
        </div>
        {props.type === "sidebar" ? (
          <div className="sidebar-status">
            {props.jobStatus ? (
              <span className={statusClass}>
                {props.jobStatus[0] +
                  props.jobStatus.slice(1).toLowerCase().replace("_", " ")}
              </span>
            ) : null}
            {props.priority ? (
              <span className={priorityClass}>
                {props.priority[0] + props.priority.slice(1).toLowerCase()}
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};
