import { Fragment } from "react";
import { SuitCaseIcon } from "../../../icons";

export const JobInfoCard = (props) => {
  return (
    <Fragment>
      <div className="g-card job-data" key={props.jobId}>
        <div className="header">
          <div className="d-flex align-items-center">
            <span className="mr-10">{props.title}</span>
          </div>
          {props?.jobStatus ? (
            <span className="status">{props?.jobStatus}</span>
          ) : null}
        </div>

        <div
          className="j-content"
          dangerouslySetInnerHTML={{
            __html: props.jobDescription.substring(0, 50),
          }}
        ></div>
      </div>
    </Fragment>
  );
};
