import React, { useEffect } from "react";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import {
  DeleteIcon,
  DownloadIcon,
  PDFIcon,
  ReplaceIcon,
} from "../../../../../../../icons";
import fileDownload from "js-file-download";
import axios from "axios";
import Api from "../../../../../../../helper/Api";
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { CVDocumentIcon } from "../../../../../../../icons/document/cv";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../../../../../redux/applicantData/actions";
import { ButtonUI } from "../../../../../../ui/button";
import { Tooltip } from "@material-ui/core";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { FilesIcon, CloseXIcon } from "../../../../../../../icons";
import { ColoredLinearProgress } from "../../../../../coloredProgressBar";
import { useSelector } from "react-redux";

export const ResumeTab = ({ applicantData, selectedSuitCase }) => {
  const [resume, setResume] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const attachRef = useRef(null);
  const formData = new FormData();
  const dispatch = useDispatch();
  const [cvName, setCvName] = useState(null);
  const [attachment, setAttachment] = useState("");
  const [cvLink, setcvLink] = useState("");
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );
  const [resumeData, setResumeData] = useState([]);

  useEffect(() => {
    getApplicantById();
  }, []);

  const getApplicantById = () => {
    Api(
      "GET",
      `Job/GetApplicantById?companyId=${applicantData.companyId}&jobId=${selectedSuitCase.jobId}&applicantId=${applicantData.applicantId}`
    )
      .then((res) => {
        const data = [...applicantsArray];
        data.map((rec) => {
          if (rec.applicantId === applicantData.applicantId) {
            rec.resume = res.data.viewResume;
          }
        });
        setResumeData(res.data);
        setCvName(res.data.viewResume);
        setcvLink(res.data.viewResume);
        dispatch({
          type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
          payload: data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadResume = () => {
    axios
      .get(cvLink, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "Resume_" + cvName);
      });
  };

  const deleteResume = () => {
    let answer = window.confirm(
      "Are you sure you want to delete this Resume ?"
    );

    if (answer) {
      Api(
        "DELETE",
        `Job/DeleteResume?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId}&jobId=${selectedSuitCase.jobId}`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            let applicanInfo = { ...applicantData };
            applicanInfo.viewResume = "";
            dispatch({
              type: APPLICANT_DATA_ACTIONS.SET_APPLICANT_DATA,
              payload: applicanInfo,
            });
            getApplicantById();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleResume = (e) => {
    setResume(e.target.files[0]);
    setFormErrors({});
    setAttachment(e.target.files[0].name);
  };

  const uploadResume = () => {
    setLoading(true);
    if (!resume) {
      setFormErrors({ resume: "Please select a file to upload!" });
      return;
    }

    formData.append("resume", resume ?? "");
    formData.append("companyId", applicantData?.companyId);
    formData.append("applicantId", applicantData?.applicantId);
    formData.append("jobId", selectedSuitCase?.jobId);

    replaceResumeAPI(formData);
  };

  const handleClick = () => {
    attachRef.current.click();
  };

  const replaceResume = (e) => {
    setLoading(true);
    formData.append("resume", e.target.files[0]);
    formData.append("companyId", applicantData?.companyId);
    formData.append("applicantId", applicantData?.applicantId);
    formData.append("jobId", selectedSuitCase?.jobId);

    replaceResumeAPI(formData);
  };

  const replaceResumeAPI = (data) => {
    Api("POST", "Job/ReplaceResume", data)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          getApplicantById();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <input
        type="file"
        ref={attachRef}
        hidden
        onChange={handleResume}
        key={attachment}
        accept="image/png, image/jpeg, image/jpg, .doc, .docx, .pdf"
      />

      {loading ? (
        <ColoredLinearProgress />
      ) : cvName ? (
        <div className="tab-container">
          <input
            type="file"
            multiple
            ref={attachRef}
            hidden
            onChange={replaceResume}
            accept=".pdf, .doc, .docx"
          />
          <div className="d-flex justify-content-between align-items-center p-0-16 mt-15">
            <span className="heading-label font-blue">Resume:</span>
            <div className="options-container">
              <div className="cv-icon-info icon-border-right">
                <Tooltip title="Download">
                  <div role="button" className="icon-container">
                    <DownloadIcon
                      height="20px"
                      width="20px"
                      onClick={downloadResume}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="cv-icon-info icon-border-right">
                <Tooltip title="Replace">
                  <div
                    role="button"
                    className="icon-container"
                    onClick={handleClick}
                  >
                    <ReplaceIcon height="20px" width="20px" type="file" />
                  </div>
                </Tooltip>
              </div>
              <div className="cv-icon-info">
                <Tooltip title="Delete">
                  <div role="button" className="icon-container">
                    <DeleteIcon
                      height="20px"
                      width="20px"
                      onClick={deleteResume}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            className={
              cvName
                ? "resume-content-container p-0-16 mt-5"
                : "resume-content-container file-upload p-0-16 mt-5"
            }
          >
            <div className="resume-details">
              <div
                className={
                  cvName?.split(".")?.pop() === "pdf"
                    ? "cv-icon-container pdf"
                    : "cv-icon-container doc"
                }
              >
                {cvName?.split(".")?.pop() === "pdf" ? (
                  <PDFIcon height="32px" width="32px" />
                ) : (
                  <CVDocumentIcon height="28px" width="28px" />
                )}
              </div>
              <div className="details-container">
                <div className="resume-name">{resumeData?.fileDisplayName}</div>
                <div className="resume-status">
                  Uploaded on{" "}
                  {moment(applicantData.resumeUploadedDate).format(
                    "MM/DD/YYYY"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="tab-container">
          {attachment ? (
            <div className="p-0-16 mt-15">
              <div className="doucments-card applicant">
                <div className="doc-detail">
                  <FilesIcon
                    stroke="#9EA0A5"
                    line="#fff"
                    width="30px"
                    height="30px"
                  />
                  <div className="file-name-label">{attachment}</div>
                </div>
                <div
                  className="doc-detail"
                  role="button"
                  onClick={() => setAttachment("")}
                >
                  <div className="pointer">
                    <CloseXIcon width="20px" height="20px" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-0-16">
              <strong className="font-14 font-blue">Resume:</strong>
              <div
                role="button"
                className="mt-10 document applicant pointer"
                onClick={() => handleClick()}
              >
                <div className="documents-upload">
                  <span>Upload Document</span>
                  <CloudUploadOutlinedIcon width="32" height="32" />
                </div>
              </div>
              <span className="success_msg">
                * Allowed File types are .doc, .dcox, .pdf, .jpg, .jpeg, .png
              </span>
              <span className="error_msg">{formErrors?.resume}</span>
            </div>
          )}
          <div className="job-document resume p-0-16">
            <ButtonUI p="8px" mt="5px" radius="4px" onClick={uploadResume}>
              Submit
            </ButtonUI>
          </div>
        </div>
      )}
    </>
  );
};
