import { AddEditPopup } from "../../../../../common/addEditPopup/index";
import "../../../../../../assets/css/common/_drawer.css";

export const EditAppointment = (props) => {
  const handleChange = (e) => {
    props.setFormValues({
      ...props.formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <AddEditPopup
      title="Edit Appointment"
      openSidebar={props.openSidebar}
      toggleDrawer={props.toggleDrawer}
      handleSubmit={props.handleSubmit}
    >
      <div className="forms-input">
        <label>Duration (minutes):</label>
        <input
          type="text"
          placeholder="Duration here"
          name="duration"
          id="duration"
          value={props.formValues.duration}
          onChange={handleChange}
        />
        {props.formErrors.duration ? (
          <span className="error_msg">{props.formErrors.duration}</span>
        ) : null}
      </div>
      <div className="forms-input">
        <label>Time Between Interviews (minutes):</label>
        <input
          type="text"
          placeholder="Time between interviews here"
          name="timeBetween"
          id="timeBetween"
          value={props.formValues.timeBetween}
          onChange={handleChange}
        />
        {props.formErrors.timeBetween ? (
          <span className="error_msg">{props.formErrors.timeBetween}</span>
        ) : null}
      </div>
      <div className="forms-input">
        <label>Slot Close (hours):</label>
        <input
          type="text"
          placeholder="Slot closing hrs here"
          name="slotClose"
          id="slotClose"
          value={props.formValues.slotClose}
          onChange={handleChange}
        />
        {props.formErrors.slotClose ? (
          <span className="error_msg">{props.formErrors.slotClose}</span>
        ) : null}
      </div>
    </AddEditPopup>
  );
};
