import {
  LightSuitCaseIcon,
  DarkSuitCaseIcon,
  BlueAddIcon,
} from "../../../../../../icons";
import { JobSelectForm } from "./jobSelectForm";
import { JobInfoCard } from "../../../../cards/JobInfoCard";
import { useState } from "react";
import { useEffect } from "react";
import Api from "../../../../../../helper/Api";
import { useMemo } from "react";
import { ReviewingStatus } from "./Reviewing";
import { InterviewingStatus } from "./Interviewing";
import { VideoInterviewStatus } from "./VideoInterview";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../../../../redux/applicantData/actions";
import { OfferingStatus } from "./Offering/index";
import { OfferLetterStatus } from "./OfferLetter";
import { PreHire } from "./PreHire";
import DoneIcon from "@mui/icons-material/Done";
import { Tooltip } from "@mui/material";
import { OnBoarding } from "./OnBoarding";
import { HireStatus } from "./Hire";

export const ApplicantJobData = ({
  applicantData,
  selectedSuitCase,
  applicantInfo,
  getApplicantJobData,
}) => {
  const [selectJob, setSelectedJob] = useState(false);
  // const [applicantInfo, setApplicantInfo] = useState([]);
  // const [selectedSuitCase, setSelectedSuitCase] = useState({});
  const [formValues, setFormValues] = useState({});
  const [jobCycleStatusList, setJobCycleStatusList] = useState([]);
  const [resendForm, setResendForm] = useState(false);
  const [resendInterviewForm, setResendInterviewForm] = useState(false);
  const dispatch = useDispatch();

  const toggleJobSelectForm = (open) => {
    setSelectedJob(open);
  };

  useEffect(() => {
    Api("GET", "Job/GetJobCycleStatus")
      .then((res) => {
        setJobCycleStatusList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getIndex = useMemo(() => {
    const index = jobCycleStatusList.findIndex(
      (status) => status.id === selectedSuitCase["jobCycleStatus"]
    );
    return index > -1 && index;
  }, [jobCycleStatusList, selectedSuitCase]);

  function changeJobCycleStatus(status) {
    var answer = window.confirm("Are you sure you want to update the status ?");
    if (answer) {
      Api(
        "PUT",
        `Job/UpdateCurrentStatus?companyId=${applicantData.companyId}&jobId=${selectedSuitCase.jobId}&applicantId=${applicantData.applicantId}&status=${status}`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            getApplicantJobData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const handleJobSelect = () => {};

  return (
    <div className={"inner-section"}>
      <JobSelectForm
        openForm={selectJob}
        setOpenForm={setSelectedJob}
        handleJobSelect={handleJobSelect}
        companyId={applicantData.companyId}
        applicantId={applicantData.applicantId}
        toggleForm={toggleJobSelectForm}
      />

      <div className="job-status font-14 mb-10">
        <strong className="font-blue">Status:</strong>
        <div className="forms-input curr-status">
          <select
            value={selectedSuitCase["jobCycleStatus"]}
            onChange={(e) => changeJobCycleStatus(e.target.value)}
          >
            <option>Select</option>
            {jobCycleStatusList.map((status) => (
              <option value={status.name}>{status.description}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="d-flex">
        {jobCycleStatusList.map((status, index) => (
          <Tooltip title={"Go to " + status.description}>
            <div
              className={
                index === getIndex
                  ? "check-sections blue pointer"
                  : index > getIndex
                  ? "check-sections grey pointer"
                  : "check-sections green pointer"
              }
              onClick={() => changeJobCycleStatus(status.name)}
            >
              {index <= getIndex && (
                <DoneIcon sx={{ color: "#FFFF" }} fontSize="small" />
              )}
            </div>
          </Tooltip>
        ))}
      </div>

      {selectedSuitCase["jobCycleStatus"] === "REVIEWING" && (
        <ReviewingStatus
          selectedSuitCase={selectedSuitCase}
          getApplicantJobData={getApplicantJobData}
          applicantId={applicantData.applicantId}
          companyId={applicantData.companyId}
        />
      )}

      {selectedSuitCase["jobCycleStatus"] === "VIDEO_INTERVIEW" && (
        <VideoInterviewStatus
          setResendInterviewForm={setResendInterviewForm}
          formValues={formValues}
          setFormValues={setFormValues}
          selectedSuitCase={selectedSuitCase}
          resendForm={resendForm}
          resendInterviewForm={resendInterviewForm}
          applicantData={applicantData}
        />
      )}

      {selectedSuitCase["jobCycleStatus"] === "INTERVIEWING" && (
        <InterviewingStatus
          selectedSuitCase={selectedSuitCase}
          applicantId={applicantData.applicantId}
        />
      )}

      {selectedSuitCase["jobCycleStatus"] === "OFFERING" && (
        <OfferingStatus
          selectedSuitCase={selectedSuitCase}
          applicantId={applicantData.applicantId}
        />
      )}

      {selectedSuitCase["jobCycleStatus"] === "OFFER_LETTER" && (
        <OfferLetterStatus
          selectedSuitCase={selectedSuitCase}
          applicantId={applicantData.applicantId}
          applicantName={applicantData.name}
        />
      )}

      {selectedSuitCase["jobCycleStatus"] === "PREHIRE" && (
        <PreHire
          selectedSuitCase={selectedSuitCase}
          applicantName={applicantData.name}
          applicantId={applicantData.applicantId}
        />
      )}

      {selectedSuitCase["jobCycleStatus"] === "ONBOARDING" && (
        <OnBoarding
          selectedSuitCase={selectedSuitCase}
          applicantName={applicantData.name}
          applicantId={applicantData.applicantId}
        />
      )}

      {selectedSuitCase["jobCycleStatus"] === "HIRE" && (
        <HireStatus
          selectedSuitCase={selectedSuitCase}
          applicantId={applicantData.applicantId}
        />
      )}

      <p className="font-14">
        <strong>
          {selectedSuitCase["jobName"]} ({selectedSuitCase["jobNo"]})
        </strong>
      </p>
      {applicantInfo?.map((job) => (
        <JobInfoCard
          title={job.jobName}
          jobDescription={job.jobDescription}
          jobStatus={job.jobStatus}
        />
      ))}
    </div>
  );
};
