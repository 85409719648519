import { useState } from "react";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";

export const SendOnboardingPackForm = ({
  openForm,
  toggleForm,
  applicantId,
  handleSendPack,
}) => {
  const [cancelComment, setCancelComment] = useState("");

  const handleChange = (e) => {
    setCancelComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendPack(cancelComment);
  };

  return (
    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      title="Send Document pack form"
      handleSubmit={handleSubmit}
    >
      <div className="forms-input">
        <label>Message to Applicant: </label>
        <textarea
          placeholder="Message"
          name="applicantMessage"
          onChange={handleChange}
        />
      </div>
    </AddEditCol>
  );
};
