import { TextareaAutosize } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { AttachmentIcon, CloseXIcon } from "../../../../../../../icons";
import { ButtonUI } from "../../../../../../ui/button";
import ChatRight from "../../../../../../../assets/images/chat-right.png";
import ChatLeft from "../../../../../../../assets/images/chat-left.png";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import Api from "../../../../../../../helper/Api";
import { useLocation } from "react-router-dom";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";

export const ChatByApplicant = () => {
  const attachRef = useRef("null");
  const [attachments, setAttachments] = useState([]);
  const [connectionString, setConnection] = useState(null);
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState({});
  const location = useLocation();
  const applicantId = new URLSearchParams(location.search).get("applicantId");
  const jobId = new URLSearchParams(location.search).get("jobId");
  const BASEURL = process.env.REACT_APP_BASE_URL;
  const openAttachment = () => {
    attachRef.current.click();
  };

  useEffect(async () => {
    var the_arr = BASEURL.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/chatHub`)
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(connect);
    connect.on("ReceiveMessage", (message, textAreaId) => {
      setMessages((messages) => [
        ...messages,
        { message: message, textAreaId: textAreaId },
      ]);
    });

    await connect.start();
    setConnectionId(connect.connection.connectionId);
    getChatConnection();
  }, []);

  const getChatConnection = () => {
    const args = {
      isCandidate: true,
      applicantId: applicantId,
      jobId: jobId,
    };

    Api("POST", `Chat/GetApplicantSocketConnection`, args)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setConnectionId = (connectionId) => {
    const args = {
      connectionId: connectionId,
      applicantId: applicantId,
      isCandidate: true,
      jobId: jobId,
    };

    Api("POST", `Chat/setConnection`, args)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Api("GET", `Chat/GetAllChat?id=${applicantId}&textAreaId=applicant`)
      .then((res) => {
        setMessages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleFiles = (e) => {
    setAttachments([...attachments, ...e.target.files]);
  };

  const removeAttachment = (index) => {
    const updateAttachments = [...attachments];
    updateAttachments.splice(index, 1);
    setAttachments([...updateAttachments]);
  };

  const sendMessage = async () => {
    const paramObj = {
      message: inputText,
      id: applicantId,
      attachments: [],
    };
    setInputText("");
    await connectionString.send(
      "SendApplicantPrivateMessage",
      paramObj,
      "applicant",
      data.connectionId,
      jobId
    );
  };

  return (
    <div className="applicant-chat">
      <div className="tab-container">
        <input
          type="file"
          multiple
          ref={attachRef}
          hidden
          onChange={handleFiles}
        />
        <span className="heading-label font-blue">
          Chat Messages({messages.length})
        </span>
        <div className="chat-container applicant">
          {messages.length > 0 ? (
            <>
              {messages.map((message, index) => (
                <div
                  className="message-chat-container"
                  style={{
                    justifyContent:
                      message.textAreaId === "applicant"
                        ? "flex-end"
                        : "flex-start",
                    position: "relative",
                    paddingRight: !!message.textAreaId && "24px",
                    paddingLeft: message.textAreaId !== "applicant" && "24px",
                  }}
                  key={index}
                >
                  <div
                    className="chat-design"
                    style={{
                      right: !!message.textAreaId && "10px",
                      left: message.textAreaId !== "applicant" && "10px",
                    }}
                  >
                    <img
                      src={
                        message.textAreaId === "applicant"
                          ? ChatRight
                          : ChatLeft
                      }
                      height="100%"
                      width="100%"
                    />
                  </div>
                  <div
                    className="message-chat"
                    style={{
                      backgroundColor:
                        message.textAreaId === "applicant"
                          ? "#CCDCFF"
                          : "#F2F6FF",
                    }}
                  >
                    <span className="sender-label">
                      {message.textAreaId === "applicant"
                        ? "You"
                        : message.userName}
                    </span>
                    <div className="message-text">{message.message}</div>
                    <div className="chat-footer">
                      <div className="chat-footer-detail">
                        <span className="chat-footer-labels">
                          {moment(message.createdAt).format("MM/DD/YYYY")}
                        </span>
                        <div className="dot-div" />
                        <span className="chat-footer-labels">
                          {moment(message.createdAt).format("LT")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="no-message">
              <QuestionAnswerOutlinedIcon sx={{ fontSize: 100 }} />
              <span>
                Start your chat with our representative by sending your first
                message !
              </span>
            </div>
          )}
        </div>
        <div className="chat-quick-note-container">
          <div className="text-area-container">
            <TextareaAutosize
              aria-label="minimum height"
              minRows={4}
              maxRows={4}
              style={{
                resize: "none",
                width: "100%",
                border: "none",
                outline: "none",
              }}
              onChange={(input) => {
                setInputText(input.target.value);
              }}
            />
          </div>
        </div>
        <div className="chat-main-footer">
          <div></div>
          <div className="attach-send">
            {/* <div role="button" className="pointer" onClick={openAttachment}>
              <AttachmentIcon width="32px" height="32px" />
            </div> */}
            <ButtonUI
              width={"80px"}
              ml={"42px"}
              p={"12px"}
              onClick={sendMessage}
            >
              Send
            </ButtonUI>
          </div>
        </div>

        <div className="attachment-container">
          {attachments.map((attachment, index) => (
            <div className="attachemt-item" key={index}>
              <div className="attachment">
                <span>{attachment.name}</span>
                <div
                  className="attachment-close-icon-container"
                  onClick={() => removeAttachment(index)}
                >
                  <CloseXIcon height="24px" width="24px" fill="#333333" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
