import { useState, useEffect } from "react";
import Api from "../../../../../../../helper/Api";
import { useSelector } from "react-redux";
import { CheckboxWithBlueCheck } from "../../../../../../ui/checkbox/checkboxWithBlueTick";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";

export const AddEditNote = (props) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [quickNotesList, setQuickNotesList] = useState([]);

  useEffect(() => {
    Api(
      "GET",
      `QuickNote/GetQuickNoteList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}&quickNoteType=applicant`
    )
      .then((res) => {
        setQuickNotesList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [companyData]);

  const handleChange = (e) => {
    props.setFormValues({
      ...props.formValues,
      ["description"]: e.target.options[e.target.selectedIndex].dataset.desc,
      ["quickNoteId"]: e.target.value,
    });
  };

  const handleDescription = (e) => {
    props.setFormValues({
      ...props.formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckbox = (e) => {
    props.setFormValues({
      ...props.formValues,
      ["saveAsQuickNote"]: e.target.checked,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit();
  };

  return (
    <AddEditCol
      applicantId={props.applicantId}
      openForm={props.openForm}
      title={props.edit === true ? "Edit Note" : "Add Note"}
      toggleForm={props.toggleForm}
      handleSubmit={handleSubmit}
    >
      <div className="forms-input">
        <label>Quick Notes:</label>
        <select
          onChange={handleChange}
          name="quickNoteId"
          value={props.formValues.quickNoteId}
        >
          <option>Select</option>
          {quickNotesList.map((note) => (
            <option
              key={note.quickNoteId}
              value={note.quickNoteId}
              data-desc={note.note}
            >
              {note.note.substring(0, 25) + "..."}
            </option>
          ))}
        </select>
        {props.formErrors.description ? (
          <span className="error_msg">{props.formErrors.quickNoteId}</span>
        ) : null}
      </div>

      <div className="forms-input">
        <textarea
          name="description"
          id="note"
          value={props.formValues.description}
          placeholder="Quick note description here"
          onChange={handleDescription}
          rows={10}
        />
        {props.formErrors.description ? (
          <span className="error_msg">{props.formErrors.description}</span>
        ) : null}
      </div>
      {/* <CheckboxWithBlueCheck disableRipple onChange={handleCheckbox} /> */}
      {/* <span>Save as Quick Note</span> */}
    </AddEditCol>
  );
};
