import { Fragment, useState } from "react";
import { BlueCheckbox } from "../../ui/checkbox/BlueCheckBox";
import moment from "moment";
import { useSelector } from "react-redux";
import BackgroundLetterAvatars from "../layouts/BackgroundLetterAvatars";
import { useEffect } from "react";

export const ProfileCard = (props) => {
  const { jobCycleStatus } = useSelector((state) => state.jobDataReducer);
  const [statusClass, setStatusClass] = useState("");

  useEffect(() => {
    switch (props.status) {
      case "Terminated":
        setStatusClass("status-label dblue");
        break;
      case "Hired":
        setStatusClass("status-label purple");
        break;
      case "Quite":
        setStatusClass("status-label orange");
        break;
      case "Furlough":
        setStatusClass("status-label brown");
        break;
      case "Canceled":
        setStatusClass("status-label");
        break;
      case "Filled":
        setStatusClass("status-label yellow");
        break;
      case "Deleted":
        setStatusClass("status-label red");
        break;
      case "Withdrawn":
        setStatusClass("status-label lblue");
        break;
      case "Unqualified":
        setStatusClass("status-label grey");
        break;
      case "Ineligible":
        setStatusClass("status-label blue");
        break;
      case "Active":
        setStatusClass("status-label active");
        break;
      default:
        setStatusClass("status-label");
        break;
    }
  }, []);

  const onCheckBoxChange = (checkId, isChecked) => {
    const data = [...props.applicantList];
    data.map((applicant) => {
      if (applicant.applicantId == checkId) {
        applicant.checked = isChecked;
      }
    });

    const allChecked = (element) => element.checked === true;
    props.setAllChecked(data.every(allChecked));
    props.setApplicantList(data);
  };

  return (
    <Fragment>
      <div className="g-card profile pointer" key={props.applicantId}>
        {jobCycleStatus === "REVIEWING" ? (
          <div className="d-flex">
            <BlueCheckbox
              checked={props.checked}
              onChange={(e) =>
                onCheckBoxChange(props.applicantId, e.target.checked)
              }
            />
          </div>
        ) : null}
        <BackgroundLetterAvatars name={props.name} width={44} height={44} />
        <div
          className="profile-content"
          onClick={() => props.openApplicantDetails(props)}
        >
          <div className="g-card-dark-header">
            <span>{props.name}</span>
            <div className="date-arrow">
              <div>
                {moment(props.createdAt).format("DD/MM/YYYY")}
                <img src={require("../../../assets/images/arrow.png")} />
              </div>
              {props.status && (
                <div className="d-flex justify-content-center">
                  <label className={statusClass}>{props.status}</label>
                </div>
              )}
            </div>
          </div>
          <div className="g-card-header-2">
            {props.jobName} ({props.jobCode})
          </div>
          <div className="g-card-header-2 mt-10 p-content">
            <label>{props?.latestActivityMessage?.substring(0, 150)}</label>
          </div>
          <div className="g-card-header-2 p-content">
            <span className="blue-t-normal">
              {props?.morePositions > 1
                ? "+" + (props?.morePositions - 1) + " more positions"
                : null}
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
