export const SourceIcon = ({...props}) => {
    return (
        <svg {...props} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="M7.81353 12.9375L11.75 9L7.81353 5.0625M1.25 9H11.7472M11.75 0.75H17C17.1989 0.75 17.3897 0.829018 17.5303 0.96967C17.671 1.11032 17.75 1.30109 17.75 1.5V16.5C17.75 16.6989 17.671 16.8897 17.5303 17.0303C17.3897 17.171 17.1989 17.25 17 17.25H11.75" 
                stroke="#3954A5" 
                strokeWidth="1.5" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    );
}
