import { Users } from "../../../components/common/layouts/users";

export const CompanyUsers = () => {
  return (
    <div>
      <div className="company_content help-container applicants">
        <div className="top-url">
          <p>
            <strong> Users</strong>
          </p>
        </div>

        <div className="common-container users company">
          <div className="inner-container company">
            <Users isCompany={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
