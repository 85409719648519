import { MenuTwoTone } from "@material-ui/icons";
import moment from "moment";
import React, { forwardRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({ handleChange }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [year, setYear] = useState(new Date());
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="custom-date-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));
  return (
    <>
      <ReactDatePicker
        selected={startDate}
        onChange={(date) => {
          handleChange(date, endDate);
          setStartDate(date);
        }}
        dateFormat="MMM"
        showMonthYearPicker
        customInput={<CustomInput />}
        disabledKeyboardNavigation
        nextYearAriaLabel={"hideYear"}
        previousYearAriaLabel={"hideYear"}
      />
      <span className="date-to">to</span>
      <ReactDatePicker
        selected={endDate}
        onChange={(date) => {
          handleChange(startDate, date);
          setEndDate(date);
        }}
        dateFormat="MMM"
        showMonthYearPicker
        customInput={<CustomInput />}
        disabledKeyboardNavigation
        nextYearAriaLabel={"hideYear"}
        previousYearAriaLabel={"hideYear"}
      />
      <ReactDatePicker
        selected={year}
        onChange={(date) => {
          const startYear = new Date(startDate);
          startYear.setFullYear(date.getFullYear());
          const endYear = new Date(endDate);
          endYear.setFullYear(date.getFullYear());
          handleChange(startYear, endYear);
          setStartDate(startYear);
          setEndDate(endYear);
          setYear(date);
        }}
        showYearPicker
        dateFormat="yyyy"
        yearItemNumber={10}
        minDate={new Date("01-01-2011")}
        maxDate={new Date()}
        customInput={<CustomInput />}
        wrapperClassName={"year-picker"}
      />
    </>
  );
};

export default DatePicker;
