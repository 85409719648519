import { Fragment, useState } from "react";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";

export const FlexiCard = ({
  cardClickEvent,
  data,
  handleEdit,
  handleDelete,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [showMoreLabel, setShowMoreLabel] = useState("Show More");

  const toggleShowMore = () => {
    setShowMore(!showMore);
    showMore === true
      ? setShowMoreLabel("Show More")
      : setShowMoreLabel("less");
  };

  return (
    <Fragment>
      <div className="reason_grid pointer">
        <div className="reason_card_header">
          <label>{data.jobTemplateNo}</label>
          <div className="reason_card_header">
            <span
              className={
                data.isActive == true ? "status-label active" : "status-label"
              }
            >
              {data.isActive == true ? "Active" : "Inactive"}
            </span>
            <EditDeleteMenu
              id={data.jobTemplateId}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          </div>
        </div>
        <div
          className="reason_card_header pointer"
          onClick={() => cardClickEvent(data)}
        >
          <span>{data.name}</span>
        </div>
        {showMore ? (
          <div
            onClick={() => cardClickEvent(data)}
            className="reason_card_content pointer show-more"
            dangerouslySetInnerHTML={{
              __html: data?.description?.substring(0, 350),
            }}
          />
        ) : (
          <div
            onClick={() => cardClickEvent(data)}
            className="reason_card_content pointer show-more"
            dangerouslySetInnerHTML={{
              __html: data?.description?.substring(0, 150),
            }}
          />
        )}
        {data?.description?.length > 150 ? (
          <label
            type="button"
            className="blue-text pointer"
            onClick={toggleShowMore}
          >
            {showMoreLabel}
          </label>
        ) : null}
      </div>
    </Fragment>
  );
};
