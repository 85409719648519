import { Link, useLocation } from "react-router-dom";
import { Users } from "../../common/layouts/users";

export const CompanyUsersFromAdmin = () => {
  const location = useLocation();
  return (
    <div className="common-container users">
      <div className="inner-container">
        <div className="top-url">
          <p>
            <Link to="/companylist"> Companies &gt; </Link>
            <Link
              to="/users"
              state={{
                companyId: location.state.companyId,
                companyHirerchy: location.state.companyHirerchy,
              }}
            >
              {" "}
              Users{" "}
            </Link>
          </p>
        </div>
        <Users />
      </div>
    </div>
  );
};
