import React from "react";

export const ReplaceIcon = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M18.75 8.25L21 6L18.75 3.75"
        stroke={!!props.stroke ? props.stroke : "#34AA44"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12C3 10.4087 3.63214 8.88258 4.75736 7.75736C5.88258 6.63214 7.4087 6 9 6H21"
        stroke={!!props.stroke ? props.stroke : "#34AA44"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 15.75L3 18L5.25 20.25"
        stroke={!!props.stroke ? props.stroke : "#34AA44"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12C21 13.5913 20.3679 15.1174 19.2426 16.2426C18.1174 17.3679 16.5913 18 15 18H3"
        stroke={!!props.stroke ? props.stroke : "#34AA44"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
